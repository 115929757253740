import {LOGOUT, AUTHENTICATE} from '../types/authentication.types';

const initialState = {
  keycloak: null,
  isAuthenticated: false
};

const authenticationReducer = (state = initialState, action) => {

  switch (action.type) {

    case AUTHENTICATE: {
      //state.keycloak = action.keycloak;
      state.isAuthenticated = action.isAuthenticated;
      state.user = action.user;
      return state;
    }

    case LOGOUT: {

      if (state.keycloak) {
        state.keycloak.logout();
      }

      return {
        keycloak: null,
        isAuthenticated: false
      }
    }

    default: {
      return state;
    }
  }
}

export default authenticationReducer;