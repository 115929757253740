import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import classNames from 'classnames'
import {Airplay, ChevronDown, ChevronRight, DollarSign, Users} from 'react-feather'
import {NavHeader, NavText} from '../Navigation/Navigation.styles'
import {useLocation} from 'react-router'
import {useSelector} from 'react-redux'

const BontiNavigation = () => {
  const location = useLocation()
  const layoutSettings = useSelector(state => state.layout.settings)

  const [isTotalOpen, setTotalOpen] = useState(location.pathname.indexOf('/bonti/total') > -1)
  const [isTeamOpen, setTeamOpen] = useState(false)

  const [teams] = useState([
    {
      name: 'NK',
      path: 'nk'
    },
    {
      name: 'Odenplan',
      path: 'odenplan'
    },
    {
      name: 'Täby',
      path: 'taby'
    }
  ])

  useEffect(() => {
    if (location.pathname.indexOf('/bonti/team') > -1) {
      setTeamOpen(true)
    }

    switch (location.pathname) {
      case '/bonti/total':
        setTotalOpen(!isTotalOpen)

        if (isTeamOpen) {
          setTeamOpen(false)
        }
        break
      case '/bonti/team':
        setTeamOpen(!isTeamOpen)

        if (isTotalOpen) {
          setTotalOpen(false)
        }
        break
    }
  }, [location])

  return (
    <React.Fragment>
      <ul className={classNames({ 'nav': true, 'pt-1': layoutSettings.aside === 'folded' })}>
        <NavHeader className="nav-header hidden-folded">
          <span className="text-muted">Bonti</span>
        </NavHeader>

        <li className={classNames({active: location.pathname.indexOf('/bonti/total') > -1})}>
          <Link to={{pathname: '/bonti/total'}} title="Total">
            <span className="nav-icon">
              <DollarSign size={16}/>
            </span>
            <NavText className="nav-text">Total</NavText>

            { layoutSettings.aside !== 'folded' &&
              <div>
                { isTotalOpen ? <ChevronDown size={14} style={{marginBottom: '5px'}}/> : <ChevronRight size={14} style={{marginBottom: '5px'}}/> }
              </div>
            }
          </Link>

          { layoutSettings.aside !== 'folded' && isTotalOpen &&
            <ul className="nav">
              <li className={classNames({active: location.pathname === `/bonti/total/charts`})}>
                <Link to={{pathname: `/bonti/total/charts`}}>
                  <NavText className="nav-text">Diagram</NavText>
                </Link>
              </li>

              <li className={classNames({active: location.pathname === `/bonti/total/details`})}>
                <Link to={{pathname: `/bonti/total/details`}}>
                  <NavText className="nav-text">Detaljer</NavText>
                </Link>
              </li>
            </ul>
          }
        </li>

        <li className={classNames({active: location.pathname.indexOf('/bonti/team') > -1})}>
          <Link to={{pathname: '/bonti/team'}} title="Lag">
            <span className="nav-icon">
              <Users size={16}/>
            </span>
            <NavText className="nav-text">Lag</NavText>

            { layoutSettings.aside !== 'folded' &&
              <div>
                { isTeamOpen ? <ChevronDown size={14} style={{marginBottom: '5px'}}/> : <ChevronRight size={14} style={{marginBottom: '5px'}}/> }
              </div>
            }
          </Link>

          { layoutSettings.aside !== 'folded' && isTeamOpen &&
            <ul className="nav">
              {teams.map((team, i) => (
                <li className={classNames({active: location.pathname === `/bonti/team/${team.path}`})} key={i}>
                  <Link to={{pathname: `/bonti/team/${team.path}`}}>
                    <NavText className="nav-text">{team.name}</NavText>
                  </Link>
                </li>
              ))}
            </ul>
          }
        </li>

        <li className={classNames({active: location.pathname.indexOf('/bonti/ecommerce') > -1})}>
          <Link to={{pathname: '/bonti/ecommerce'}} title="E-handel">
              <span className="nav-icon">
                <Airplay size={16}/>
              </span>
            <NavText className="nav-text">E-handel</NavText>
          </Link>
        </li>
      </ul>
    </React.Fragment>
  )
}

export default BontiNavigation
