import React, {useEffect, useState} from 'react';
import {history} from '../../helpers';
import {Button, Form, FormFeedback, FormGroup, Input, Label,} from 'reactstrap';
import {EventAttributesApi} from "../../api/EventAttribute";
import {audioService} from '../../services/audio.service';
import Select from 'react-select';
import EventAttributeForm from './EventAttributeForm';
import {EventApi} from '../../api/Event/Event.api';

const EventForm = (props) => {
  let eventId = props.match.params.id;

  const defaultEvent = {id: eventId, active: 1, name: '', unit: '', triggersEventMessage: true};
  const [event, setEvent] = useState({...defaultEvent});

  const [audioOptions, setAudioOptions] = useState([]);
  const [selectedAudioOption, setSelectedAudioOption] = useState(null);

  const defaultAttribute = {description: '', type: '', eventAttributeOptions: [], isEditing: false}
  const [attribute, setAttribute] = useState({...defaultAttribute})

  const [attributeList, setAttributeList] = useState([]);

  const handleNameChange = (e) => {
    let newEvent = {...event};
    newEvent.name = e.target.value;
    setEvent(newEvent);
  };

  const handleUnitChange = (e) => {
    let newEvent = {...event};
    newEvent.unit = e.target.value;
    setEvent(newEvent);
  };

  const handleAudioChange = (e) => {
    let newEvent = {...event};
    newEvent.audioUniqueId = e.value;
    setEvent(newEvent);
    let audioOption = audioOptions.find(o => o.value === e.value);
    setSelectedAudioOption(audioOption);
  };

  const handleFixedValueCheck = (e, event) => {
    let newEvent = {...event};
    newEvent.useFixedValue = e.target.checked;
    newEvent.fixedValue = e.target.checked ? '' : null;
    setEvent(newEvent);
  };

  const handleFixedValueChange = (e, event) => {
    let newEvent = {...event};
    newEvent.fixedValue = e.target.value;
    setEvent(newEvent);
  };

  const handleEventMessageTriggerChange = (e, event) => {
    let newEvent = {...event};
    newEvent.triggersEventMessage = e.target.checked;
    setEvent(newEvent);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (event.id) {
      EventApi
        .update(event.id, event)
        .then(async () => {
          history.push('/events');
        });
    } else {
      EventApi
        .create(event)
        .then(async (result) => {
          const { id } = result;
          const attributes = await EventAttributesApi.findAll(id)

          if (attributes.length === 0) {
            setEvent(result)
            setAttributeList(attributes)
          } else {
            history.push('/events')
          }
        })
    }
  }

  const cancel = () => {
    history.push('/events');
  }

  useEffect(async () => {
    const audioList = await audioService.findAll()

    let audioItems = []
    audioList.forEach(audio => {
      let audioItem = {
        value: audio.unique_id, label: audio.name
      }
      audioItems.push(audioItem)
    })

    audioItems.sort((a, b) => b.name > a.name ? 1 : -1)

    let noSelectionOption = {
      value: null, label: 'Ingen ljudeffekt'
    }
    audioItems.unshift(noSelectionOption)

    setAudioOptions(audioItems)

    if (eventId) {
      const event = await EventApi.get(eventId)
      const attributes = await EventAttributesApi.findAll(eventId)

      setEvent(event)
      setAttributeList(attributes)

      if (event.audioUniqueId !== null && audioOptions) {
        let selectedOption = audioItems.find(o => o.value === event.audioUniqueId)

        if (selectedOption !== null) {
          setSelectedAudioOption(selectedOption)
        } else {
          setSelectedAudioOption(audioItems.find(a => a.value === null))
        }
      } else {
        setSelectedAudioOption(audioItems.find(a => a.value === null))
      }
    }
  }, [])

  if (!event) {
    return null
  }

  return (
    <div className="page-content page-container">
      <div className="padding">
        <div className="card">
          <div className="card-header">
            <strong>{event.id && event.id.length > 0 ? 'Redigera ' + event.name : 'Ny aktivitet'}</strong>
          </div>

          <div className="card-body">
            <Form id="event-form" onSubmit={handleSubmit}>
              <div className="form-row">
                <FormGroup className="col-md-6">
                  <Label className="text-muted">Namn</Label>

                  <Input type="text"
                         invalid={event.name.length > 45}
                         className="form-control"
                         name="event-name"
                         placeholder="Namn"
                         required
                         value={event.name}
                         onChange={handleNameChange}/>

                  <FormFeedback>
                    Ogiltigt namn (1 - 45 tecken)
                  </FormFeedback>

                </FormGroup>

                <FormGroup className="col-md-6">
                  <Label className="text-muted">Enhet</Label>

                  <input type="text"
                         className="form-control"
                         name="event-unit"
                         placeholder="Enhet"
                         required
                         defaultValue={event.unit}
                         onChange={handleUnitChange}/>
                </FormGroup>
              </div>

              {audioOptions && audioOptions.length > 0 &&
                <div className="form-row">
                  <FormGroup className="col-md-6">
                    <Label className="text-muted">Ljudeffekt</Label>

                    <Select
                      value={selectedAudioOption ? selectedAudioOption : null}
                      defaultValue={selectedAudioOption}
                      options={audioOptions}
                      onChange={handleAudioChange}
                    />
                  </FormGroup>
                </div>
              }

              <div className="form-row">
                <FormGroup className="col-md-6">
                  <div className="checkbox">
                    <label className="ui-check">
                      <input type="checkbox"
                             checked={event?.useFixedValue ?? false}
                             onChange={(e) => handleFixedValueCheck(e, event)}/>
                      <i className="mr-2"></i>
                      Fast värde
                    </label>
                  </div>
                </FormGroup>
              </div>

              {event.useFixedValue &&
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <Input type="number"
                           placeholder="Fast värde"
                           required
                           defaultValue={event.fixedValue}
                           onChange={(e) => handleFixedValueChange(e, event)}/>
                  </div>
                </div>
              }

              <div className="form-row">
                <FormGroup className="col-md-6">
                  <div className="checkbox">
                    <label className="ui-check">
                      <input type="checkbox"
                             defaultChecked={event.triggersEventMessage}
                             onChange={(e) => handleEventMessageTriggerChange(e, event)}/>
                      <i className="mr-2"></i>
                      Visa i aktivitetslistan
                    </label>
                  </div>
                </FormGroup>
              </div>

              <div className='form-row justify-content-end'>
                <div className='col-md-2 d-flex justify-content-end'>
                  <Button color="secondary mr-1" onClick={cancel}>Avbryt</Button>
                  <Button type="submit" color="primary"
                          disabled={event.name.length === 0 || attribute && attribute.isEditing}>
                    Spara
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>

        {event && event.id && <EventAttributeForm event={event}/>}
      </div>
    </div>
  )
}

export default EventForm;