import React, {useEffect, useState} from 'react'
import {Container, Flex, FlexWrapper, Wrapper} from './pages.style'
import {useDispatch, useSelector} from "react-redux"
import CampaignTotalSum from '../components/Dashboard/CampaignTotalSum.component'
import CampaignTotal from '../components/Dashboard/CampaignTotalSum.component'
import {campaignService} from '../services/campaign.service'
import {setCampaignList} from '../actions/campaign.actions'
import {setDocumentTitle} from '../helpers'
import {CampaignApi} from '../api/Campaign/Campaign.api'
import CampaignTotalLineGraph from "../components/Dashboard/CampaignTotalLineGraph.component";
import {Card} from '../components/Shared/Card';
import styled from 'styled-components';
import {BontiApi} from "../api/Bonti/Bonti.api";
import CampaignMonthlyTeam from "../components/Dashboard/CampaignMonthlyTeam.component";
import CampaignDoughnutGraph from "../components/Dashboard/CampaignDoughnutGraph";
import {BONTI_COMPANY_ID} from "../helpers/integrations/bonti";
import CampaignRSCountData from "../components/Dashboard/CampaignRSCountData";

const TotalDashboard = (props) => {
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.authentication.user)

    const [activeCampaign, setActiveCampaign] = useState(null)
    const [companyName, setCompanyName] = useState('')

    const [campaignTotal, setCampaignTotal] = useState(null)
    const [campaignTotalSum, setCampaignTotalSum] = useState(null)

    const [teams, setTeams] = useState([])
    const [orders, setOrders] = useState([])


    let testss = {
        id: "7e4b608e-5dee-420c-955d-6a27b879621b",
        companyId: "379e3018-171a-459a-a2ae-01697e2eeea5",
        teamId: "c346b8d2-fcc0-4949-869a-6b0fbe03dc63",
        name: "Total",
        path: "totaldashboard",
        isPrimary: 0,
        settings: [{
            id: null,
            dashboardId: "7e4b608e-5dee-420c-955d-6a27b879621b",
            companyId: "379e3018-171a-459a-a2ae-01697e2eeea5",
            component: "CampaignTotalSum",
            size: 12,
            order: 1
        }, {
            id: null,
            dashboardId: "7e4b608e-5dee-420c-955d-6a27b879621b",
            companyId: "379e3018-171a-459a-a2ae-01697e2eeea5",
            component: "CampaignTotalLineGraph",
            size: 6,
            order: 2
        }, {
            id: null,
            dashboardId: "7e4b608e-5dee-420c-955d-6a27b879621b",
            companyId: "379e3018-171a-459a-a2ae-01697e2eeea5",
            component: "CampaignMonthlyTeam",
            size: 3,
            order: 3
        }, {
            id: null,
            dashboardId: "7e4b608e-5dee-420c-955d-6a27b879621b",
            companyId: "379e3018-171a-459a-a2ae-01697e2eeea5",
            component: "CampaignDoughnutGraph",
            size: 3,
            order: 5
        }, /*{
                "id": null,
                "dashboardId": "7e4b608e-5dee-420c-955d-6a27b879621b",
                "companyId": "379e3018-171a-459a-a2ae-01697e2eeea5",
                "component": "CampaignRSCountData",
                "size": 3,
                "order": 4
            }*/]
    }


    useEffect(() => {
        BontiApi.findOrdersSum(BONTI_COMPANY_ID, undefined).then(orders => {

            if (!orders) {
                return
            }

            setOrders(orders)
        })

        CampaignApi.getParentCampaignTotal(currentUser.companyId).then(total => {
            setCampaignTotal(total)
        })

        CampaignApi.getCampaignTotal(currentUser.companyId).then(data => {
            if (!data) {
                return
            }
            setCampaignTotalSum(data)
            setTeams(data.teams)
        })
        BontiApi.getOrderTotal().then((result) => {
        })

        setDocumentTitle(currentUser.companyName)
    }, [])

    useEffect(() => {
        if (currentUser) {
            if (currentUser.companyName) {
                setCompanyName(currentUser.companyName)
            }

            campaignService.findByTeamMember(currentUser)
                .then(campaigns => {
                    dispatch(setCampaignList(campaigns))

                    const activeCampaign = campaigns.find(campaign => campaign.active === 'Active' && campaign.parent !== null)

                    if (activeCampaign) {
                        // setDocumentTitle(activeCampaign.name)
                        setActiveCampaign(activeCampaign)
                    }
                })
        }
    }, [currentUser])

    return (<Container>
            <FlexWrapper>
                <div>
                    <h2 className="text-md text-highlight">
                        {companyName + ' Total'}
                    </h2>
                </div>
                <Flex/>
            </FlexWrapper>
            <Wrapper style={{paddingTop: 0}}>
                <div className="row">
                    {testss.settings.map((settings, i) => (<div className={`col col-md-${settings.size} mb-4`} key={i}>
                            <DashboardCard>
                                <div className="card-body">
                                    {settings.component === 'CampaignTotalSum' &&
                                        <CampaignTotal campaignTotal={campaignTotal}/>}
                                    {settings.component === 'CampaignTotalLineGraph' &&
                                        <CampaignTotalLineGraph campaignTotal={campaignTotal}
                                                                campaignSum={campaignTotalSum}/>}
                                    {settings.component === 'CampaignMonthlyTeam' && <>
                                        <CampaignMonthlyTeam teams={teams}/>
                                    </>}
                                    {settings.component === 'CampaignDoughnutGraph' && <>
                                        <CampaignDoughnutGraph teams={teams} orders={orders}/>
                                        <CampaignRSCountData/>
                                    </>}
                                    {settings.component === 'CampaignRSCountData' &&
                                        <CampaignRSCountData/>
                                    }
                                </div>
                            </DashboardCard>
                        </div>))}

                </div>
            </Wrapper>
        </Container>)
}

export default TotalDashboard

const DashboardCard = styled(Card)`
  height: 100%;
`