import {Redirect, Router, Switch} from 'react-router-dom';
import React from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {history} from './helpers';

import PublicRoute from './components/PrivateRoute/PublicRoute.component';
import PrivateRoute from './components/PrivateRoute/PrivateRoute.component';
import LeftNavigation from './components/Navigation/LeftNavigation.component';
import TopNavigation from './components/Navigation/TopNavigation.component';
import Dashboard from './pages/Dashboard.page';
import Order from './pages/Order.page';
import TotalDashboard from './pages/TotalDashboard.page';
import Leaderboard from './pages/Leaderboard.page';
import Campaign from './pages/Campaign.page';
import CampaignWizard from './components/Campaign/CampaignWizard.component';
import User from './pages/User.page';
import TeamList from './components/Team/TeamList.component';
import TeamForm from './components/Team/TeamForm.component';
import EventList from './components/Event/EventList.component';
import EventForm from './components/Event/EventForm.component';
import Profile from './pages/Profile.page';
import Settings from './pages/Settings';
import TvLeaderboard from './pages/TvLeaderboard.page';
import UserListComponent from './components/User/UserList.component';
import CompanyForm from './components/Company/CompanyForm.component';
import TotalCarousel from './pages/bonti/TotalCarousel.page';
import TotalCharts from './pages/bonti/TotalCharts.page';
import Total from './pages/bonti/Total.page';
import TeamCarousel from './pages/bonti/TeamCarousel.page';
import Team from './pages/bonti/Team.page';
import Magnus from './pages/Magnus.page';
import Ecommerce from './pages/bonti/Ecommerce.page';
import {CompanyList} from './components/Company/CompanyList.component';
import CampaignLeaderboard from './pages/CampaignLeaderboard.page';

// import IntegrationList from './components/Integration/IntegrationList.component';
import NewDashboard from "./pages/NewDashboard.page";

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import CampaignDashboard from "./pages/TeamLeaderboard.page";

const queryClient = new QueryClient()

const Routes = () => {
  const user = useSelector(state => state.authentication.user);
  const alert = useSelector(state => state.alert);
  const layoutSettings = useSelector(state => state.layout.settings);
  const cls = classNames('app layout-row', layoutSettings.bg);
  const fillDark = classNames('layout-column flex', layoutSettings.main);

  return (
    <Router history={history}>
      <div className={cls}>
        <LeftNavigation />

        <div id="main" className={fillDark}>
          { user && <TopNavigation/> }

          <div id="content" className="flex">
            {alert.message &&
              <div className={`alert ${alert.type}`}>{alert.message}</div>
            }

            <QueryClientProvider client={queryClient}>
              <Switch>
                <PrivateRoute key="campaignleaderboard" exact path="/campaignleaderboard" component={CampaignLeaderboard}/>
                <PrivateRoute key="campaignleaderboard" exact path="/TeamLeaderboard" component={CampaignDashboard}/>
                <PrivateRoute key="dashboard" exact path="/" component={Dashboard}/>
                <PrivateRoute key="new-dashboard" exact path="/dashboard/add" component={NewDashboard}/>
                <PrivateRoute key="magnus" exact path="/dashboard/:name" component={Magnus}/>
                <PrivateRoute key="totaldashboard" exact path="/totaldashboard" component={TotalDashboard}/>
                <PrivateRoute key="leaderboard" exact path="/leaderboard" component={Leaderboard}/>
                <PrivateRoute key="view-leaderboard" exact path="/leaderboard/:id" component={Leaderboard}/>
                <PrivateRoute key="tv-leaderboard" exact path="/tv-leaderboard" component={TvLeaderboard}/>
                <PrivateRoute key="view-tv-leaderboard" exact path="/tv-leaderboard/:id" component={TvLeaderboard}/>
                <PrivateRoute key="order" exact path="/orders" component={Order}/>
                <PrivateRoute key="view-order" exact path="/orders/:id" component={Order}/>
                <PrivateRoute key="campaign" exact path="/campaigns" role={['Admin', 'TeamLeader']} component={Campaign}/>
                <PrivateRoute key="add-campaign" exact path="/campaigns/add" role={['Admin', 'TeamLeader']} component={CampaignWizard}/>
                <PrivateRoute key="edit-campaign" exact path="/campaigns/:id" role={['Admin', 'TeamLeader']} component={CampaignWizard}/>
                <PrivateRoute key="user" exact path="/users" role={['Admin', 'TeamLeader']} component={UserListComponent}/>
                <PrivateRoute key="add-user" exact path="/users/add" role={['Admin', 'TeamLeader']} component={User}/>
                <PrivateRoute key="edit-user" exact path="/users/:id" role={['Admin', 'TeamLeader']} component={User}/>
                <PrivateRoute key="profile" exact path="/profile" component={Profile}/>
                <PublicRoute key="bonti-total-carousel" exact path="/bonti/total" component={TotalCarousel}/>
                <PublicRoute key="bonti-total-charts" exact path="/bonti/total/charts" component={TotalCharts}/>
                <PublicRoute key="bonti-total-details" exact path="/bonti/total/details" component={Total}/>
                <PublicRoute key="bonti-team-carousel" exact path="/bonti/team" component={TeamCarousel}/>
                <PublicRoute key="bonti-team" exact path="/bonti/team/:team" component={Team}/>
                <PublicRoute key="bonti-ecommerce" exact path="/bonti/ecommerce" component={Ecommerce}/>
                <PrivateRoute key="view-profile" exact path="/profile/:id" component={Profile}/>
                <PrivateRoute key="settings" exact path="/settings" component={Settings}/>
                <PrivateRoute key="team" exact path="/teams" role={['Admin', 'TeamLeader']} component={TeamList}/>
                <PrivateRoute key="add-team" exact path="/teams/add" role={['Admin', 'TeamLeader']} component={TeamForm}/>
                <PrivateRoute key="edit-team" exact path="/teams/:id" role={['Admin', 'TeamLeader']} component={TeamForm}/>
                <PrivateRoute key="event" exact path="/events" role={['Admin', 'TeamLeader']} component={EventList}/>
                <PrivateRoute key="add-event" exact path="/events/add" role={['Admin', 'TeamLeader']} component={EventForm}/>
                <PrivateRoute key="edit-event" exact path="/events/:id" role={['Admin', 'TeamLeader']} component={EventForm}/>
                <PrivateRoute exact path="/companies" role={['Admin']} component={CompanyList}/>
                <PrivateRoute key="add-company" exact path="/companies/add" role={['Admin']} component={CompanyForm}/>
                <PrivateRoute key="edit-company" exact path="/companies/:id" role={['Admin']} component={CompanyForm}/>
                <PrivateRoute key="edit-company" exact path="/companies/:id" role={['Admin']} component={CompanyForm}/>
                {/*<PrivateRoute key="integrations" exact path="/integrations" role={['Admin']} component={IntegrationList}/>*/}
                {/*<Route exact path="/login" name="login" component={Login}/>*/}
                <Redirect from="*" to="/"/>
              </Switch>
            </QueryClientProvider>
          </div>
        </div>
      </div>
    </Router>
  )
};

export default Routes;
