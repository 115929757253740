import styled from 'styled-components';

export const Pointer = styled.div`
  cursor: pointer;
`

export const FieldError = styled.div`
  color: #FB2640;
  font-size: 0.825rem;
  margin: 0.3rem 0;
`

export const Toolbar = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  margin: 0 -0.25rem;
  
  > * {
    margin: 0 0.25rem;
  }
`

export * from './Text.style'