import React, { useEffect, useState } from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {formatDate, parseDate} from 'react-day-picker/moment';
import {campaignService} from '../../services/campaign.service';

const CampaignFilter = (props) => {

  const {
    startDate,
    endDate,
    changeCampaign,
    changeStartDate,
    changeEndDate
  } = props;

  const currentUser = useSelector(state => state.authentication.user);

  const dateFormat = 'YYYY-MM-DD';

  const [campaignOptions, setCampaignOptions] = useState([]);

  const [selectedCampaignFilter, setSelectedCampaignFilter] = useState({
    value: '0',
    title: 'Alla'
  });

  const getUserCampaigns = () => {
    campaignService.findByTeamMember(currentUser)
      .then(campaigns => {
        let options = [];
        options.push({value: '0', label: 'Alla'});

        campaigns.map((campaign) => {
          options.push({value: campaign.id, label: campaign.name});
        });

        setCampaignOptions(options);
      })
  }

  useEffect(() => {
    getUserCampaigns(currentUser);
  }, [])

  return (
    <Container>
      <Campaign>
        <div className="small">Kampanj</div>
        {
          campaignOptions.length > 0 ?
            <div>
              <Select
                options={campaignOptions}
                defaultValue={campaignOptions[0]}
                onChange={changeCampaign}
                id='campaign-select'
              />
            </div>
            :
            <Select
              options={campaignOptions}
              onChange={changeCampaign}
              id='campaign-select'
            />
        }
      </Campaign>

      <From>
        <div className="small">Från</div>

        <DayPickerInput
          inputProps={{
            className: 'form-control', //+ startDateClass,
            required: 'required'
          }}
          formatDate={formatDate}
          format={dateFormat}
          parseDate={parseDate}
          placeholder="YYYY-MM-DD"
          value={startDate}
          onDayChange={changeStartDate}
          id='start-date'
          style={{width: '100%'}}
        />
      </From>

      <To>
        <div className="small">Till</div>

        <DayPickerInput
          inputProps={{
            className: 'form-control', //+ startDateClass,
            required: 'required'
          }}
          formatDate={formatDate}
          format={dateFormat}
          parseDate={parseDate}
          placeholder="YYYY-MM-DD"
          value={endDate}
          onDayChange={changeEndDate}
          id='end-date'
          style={{width: '100%'}}
        />
      </To>
    </Container>
  )
}

export default CampaignFilter;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  margin: 0 -15px;
  margin-bottom: 0.5rem;
`

const Column = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`

const Campaign = styled(Column)`
  flex: 0 0 50%;
  max-width: 50%;
  
  @media (max-width: 767.98px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }
`

const From = styled(Column)`
  flex: 0 0 25%;
  max-width: 25%;
  
  @media (max-width: 767.98px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }
`

const To = styled(Column)`
  flex: 0 0 25%;
  max-width: 25%;
  
  @media (max-width: 767.98px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }
`