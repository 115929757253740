
export {
  getLocalStorage
}

/**
 * Localstorage
 * @returns {{}}
 */
const getLocalStorage = () => {

  const hasItem = (key) => {
    removeIfUndefined(key);
    return localStorage.getItem(key);
  }

  const getItem = (key) => {
    removeIfUndefined(key);
    return JSON.parse(localStorage.getItem(key));
  }

  const setItem = (key, value) => localStorage.setItem(key, JSON.stringify(value));

  const removeIfUndefined = (key) => {
    let item = localStorage.getItem(key);

    if (item === 'undefined') { // || item === 'null'
      localStorage.removeItem(key);
    }
  }

  return {
    hasItem,
    getItem,
    setItem
  }
}