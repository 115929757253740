import React, {useEffect, useState} from 'react'

import { SwitchTransition, CSSTransition } from 'react-transition-group'

import TeamContainer from '../../containers/bonti/Team.container'

const TotalCarousel = () => {
  const [teams] = useState(['nk', 'odenplan', 'taby'])
  const [activeTeam, setActiveTeam] = useState(teams[0])

  useEffect(() => {
    setTimeout(() => {
      const i = teams.indexOf(activeTeam)

      if ((i + 1) === teams.length) {
        setActiveTeam(teams[0])
      }
      else {
        setActiveTeam(teams[i + 1])
      }
    }, 1000 * 30)
  }, [activeTeam])

  return (
    <>
      <SwitchTransition mode={'out-in'}>
        <CSSTransition
          key={activeTeam}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="button-container">
            <TeamContainer teamName={activeTeam} />
          </div>
        </CSSTransition>
      </SwitchTransition>
    </>
  )
}

export default TotalCarousel
