import styled from 'styled-components';

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  background: #FFFFFF;
  border: 0 solid rgba(19, 24, 44, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
`

export const CardHeader = styled.div`
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(160, 175, 185, 0.15);
  background-color: transparent;
  background-clip: padding-box;
`

export const CardFooter = styled.div`
  background-color: transparent;
  border-color: rgba(160, 175, 185, 0.15);
  background-clip: padding-box;
`

export const CardBody = styled.div`
  flex: 1 1 auto;
  padding: 1.25rem;
  /*p:last-child{
    margin-bottom: 0;
  }
  .card-hide-body &{
    display: none;
  }*/
`

export const CardTitle = styled.div`
  line-height: 1.5;
`

export const CardFold = styled.div`
  &:before,
  &:after{
    content: '';
    display: block;
    background-color: inherit;
    box-shadow: inherit;
    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: absolute;
    width: 90%;
    left: 5%;
    height: 0.35rem;
    bottom: -0.35rem;
    opacity: 0.6;
    transition: all .1s;
  }
  &:before{
    width: 80%;
    left: 10%;
    bottom: -0.7rem;
    opacity: 0.3;
  }
  &:hover,
  &:active{
    &:before{
      opacity: 0.5;
    }
    &:after{
      opacity: 0.8;
    }
  }
`