import {
  ADD_COMMENT,
  ADD_MESSAGE,
  CLOSE_COMMENTS,
  EDIT_COMMENT,
  UPDATE_COMMENT,
  OPEN_COMMENT,
  REMOVE_COMMENT,
  REMOVE_MESSAGE,
  SET_LATEST_ACTIVITIES,
} from '../types/latestActivityList.types';

const initialState = {
  messages: []
};

const latestActivityList = (state = initialState, action) => {

  switch (action.type) {

    case SET_LATEST_ACTIVITIES: {
      var messages = [];

      action.messages.forEach(message => {
        message.isCommenting = false;
        messages.push(message);
      });

      state.messages = messages;

      return state;
    }

    case ADD_MESSAGE: {
      let messages = [];

      [...state.messages].forEach(msg => {
        msg.isCommenting = false;
        messages.push(msg);

        if (msg.id === action.message.parentUniqueId) {
          msg.comments.push(action.message);
        }
      });

      if (!action.message.parentUniqueId) {
        messages.unshift(action.message);
      }

      state.messages = messages;

      return state;
    }

    case REMOVE_MESSAGE: {
      state.messages = state.messages
        .filter(msg => msg.id !== action.message.id);

      return state;
    }

    /**
     * Comments
     */

    case OPEN_COMMENT: {
      let messages = [];

      [...state.messages].forEach((message, i) => {
        if (message.id === action.message.id) {
          message.isCommenting = true;
        }

        messages.push(message);
      });

      state.messages = messages;

      return state;
    }

    case CLOSE_COMMENTS: {
      let messages = [];

      [...state.messages].forEach((message, i) => {
        message.isCommenting = false;
        message.comments.map(comment => comment.isEditing = false);
        messages.push(message);
      });

      state.messages = messages;

      return state;
    }

    case ADD_COMMENT: {
      let messages = [];

      [...state.messages].forEach(message => {
        message.isCommenting = false;

        if (message.id === action.comment.parentUniqueId) {
          message.comments.push(action.comment);
        }

        messages.push(message);
      });

      state.messages = messages;

      return state;
    }

    case EDIT_COMMENT: {
      let messages = [];

      [...state.messages].forEach((message) => {
        message.comments.forEach((comment, i) => {
          comment.isEditing = (comment.id === action.comment.id);
        });

        messages.push(message);
      });

      state.messages = messages;

      return state;
    }

    case UPDATE_COMMENT: {
      let messages = [];

      [...state.messages].forEach((message, i) => {
        message.comments.forEach((comment, j) => {
          if (comment.id === action.comment.id) {
            message.comments[j] = action.comment;
          }
        });

        messages.push(message);
      });

      state.messages = messages;

      return state;
    }

    case REMOVE_COMMENT: {
      let messages = [];

      [...state.messages].forEach((message) => {
        message.comments.forEach((comment, i) => {
          if (comment.id === action.comment.id) {
            message.comments.splice(i, 1);
          }
        });

        messages.push(message);
      });

      state.messages = messages;

      return state;
    }

    default:
      return state;
  }
};

export default latestActivityList;