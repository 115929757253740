import {
  SET_AUDIO_LIST,
  SET_LATEST_AUDIO_EVENT,
} from '../types/audio.types';

const initialState = {
  audioList:  [],
  latestAudioEvent: {
    audioUniqueId: null,
    eventUniqueId: null,
    createdDate: new Date(),
    audioPlayed: 0
  }
};

const audioReducer = (state = initialState, action) => {

  switch (action.type) {

    case SET_AUDIO_LIST:
      state.audioList = action.audioList;
      return state;

    case SET_LATEST_AUDIO_EVENT:
      state.latestAudioEvent = action.audioEvent;
      return state;

    default:
      return state;
  }
};

export default audioReducer;