import styled from 'styled-components';

export const Title = styled.p`
  color: #99A0AC;
  font-weight: 500;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
`

export const LogoutButton = styled.div`
  font-size: 0.825rem;
  color: #448bff;
  cursor: pointer;
`

export const FieldError = styled.div`
  color: #FB2640;
  font-size: 0.825rem;
  margin: 0.3rem 0;
`

export const UpdateImageButton = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #F2F2F2;
  cursor: pointer;
  margin-right: 1rem;
  
  &:hover {
    background-color: #E5E5E5;
  }
`

export const DeleteImageButton = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #FFFFFF;
  cursor: pointer;
  margin-right: 1rem;
  
  &:hover {
    background-color: #E5E5E5;
  }
`