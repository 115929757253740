import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ArrowRight, Trash} from 'react-feather';
import 'react-day-picker/lib/style.css';
import 'moment/locale/it';
import './Campaign.styles.scss';

import {history, setDocumentTitle} from '../../helpers';
import {clearCampaignForm, setActiveCampaignStep, setInfoFormValidation, setInitialCampaign, setInitialCampaignStep} from '../../actions/campaign.actions';
import {campaignService} from '../../services/campaign.service';

import CampaignTeam from './CampaignTeam.component';
import CampaignInfo from './CampaignInfo.component';
import CampaignEvent from './CampaignEvent.component';
import CampaignGoal from './CampaignGoal.component';
import CampaignDone from './CampaignDone.component';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

const CampaignWizard = (props) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [title, setTitle] = useState('Skapa ny kampanj');

  const getCurrentStepIndex = () => campaignSteps.map(step => step.name).indexOf(currentStep);

  const campaignSteps = useSelector(state => state.campaign.steps);
  const currentStep = useSelector(state => state.campaign.step);

  const dirtyFields = useSelector(state => state.campaign.dirtyFields);
  const campaignInfo = useSelector(state => state.campaign.campaignInfo);
  const campaignTeams = useSelector(state => state.campaign.campaignTeams);
  const campaignEvents = useSelector(state => state.campaign.campaignEvents);

  const campaignId = props.match.params.id;

  const { data: campaign } = useQuery(['campaign', campaignId], () => campaignService.get(campaignId).then(campaign => {
    campaign.startDate = new Date(Date.parse(campaign.startDate));
    campaign.endDate = new Date(Date.parse(campaign.endDate));
    return campaign;
  }), {
    enabled: !!campaignId && campaignId !== 'add',
    refetchOnWindowFocus: false,
  });

  const { mutate: createCampaign} = useMutation(['createCampaign'], (campaign) => campaignService.create(campaign), {
    onSuccess: (campaign) => {
      if (!campaignId && campaign.id) {
        dispatch(setInitialCampaignStep('team'));
        history.push(`/campaigns/${campaign.id}`);
      } 
    }
  });

  const { mutateAsync: updateCampaign } = useMutation(['updateCampaign'], (data) => campaignService.update(data.id, data), {
    onSuccess: (res) => {
      queryClient.invalidateQueries(['campaign'])
    }
  });

  const { mutate: deleteCampaign } = useMutation(['deleteCampaign'], (campaignId) => campaignService.delete(campaignId), {
    onSuccess: () => {
      history.push('/campaigns');
    }
  });

  const renderStep = () => {
    switch (currentStep) {
      case 'info':
        return <CampaignInfo />;
      case 'team':
        return <CampaignTeam />;
      case 'event':
        return <CampaignEvent />;
      case 'goal':
        return <CampaignGoal />;
      case 'done':
        return <CampaignDone />;
      default:
        return null
    }
  }

  /**
   * @typedef Step
   * @property {string} name
   * @property {boolean} disabled
   * @param {Step} step 
   * @param {React.ChangeEvent<HTMLButtonElement>} e 
   */
  const stepClick = (step, e) => {
    e.preventDefault();

    if (!step.disabled) {
      let index = getCurrentStepIndex();

      if (index > -1) {
        if (index < 4) {
          saveInfo(() => {
            dispatch(setActiveCampaignStep(step.name))
          });
        }
        else {
          dispatch(setActiveCampaignStep(step.name))
        }
      }
    }
  }

  const previousStep = (e) => {
    e.preventDefault();
    let index = getCurrentStepIndex();

    if (index > -1) {
      saveInfo(() => {
        const nextStep = campaignSteps[index - 1];
        dispatch(setActiveCampaignStep(nextStep.name))
      });
    }
  }

  const nextStep = (e) => {
    e.preventDefault();
    let index = getCurrentStepIndex();

    if (index > -1) {
      saveInfo(() => {
        const nextStep = campaignSteps[index + 1];
        dispatch(setActiveCampaignStep(nextStep.name))
      });
    }
  }

  const saveInfo = (callback) => {
    dispatch(setInfoFormValidation(true));

    let form = document.getElementById('campaign-info-form');

    if (form) {
      let isValid = form.checkValidity() && campaignInfo.startDate !== null && campaignInfo.endDate !== null;

      if (!isValid) {
        dispatch(setInfoFormValidation(false));
        return;
      }
    }

    dispatch(setInfoFormValidation(false));

    if (campaignInfo.id === null) {
      createCampaign({
        name: campaignInfo.name,
        startDate: campaignInfo.startDate,
        endDate: campaignInfo.endDate,
        comment: campaignInfo.comment,
        campaignText: campaignInfo.campaignText
      });
    }
    else {
      if (dirtyFields.length === 0) {
        if (callback) {
          callback()
        }
        return
      }

      let campaignTeamList = [];
      let campaignEventList = [];

      campaignTeams.forEach(campaignTeam => {
        campaignTeamList.push(campaignTeam);
      });

      campaignEvents.forEach(campaignEvent => {
        campaignEventList.push(campaignEvent);
      });

      campaignInfo.campaignTeams = campaignTeamList;
      campaignInfo.campaignEvents = campaignEventList;

      updateCampaign({
        ...campaignInfo,
        campaignTeams: campaignTeamList,
        campaignEvents: campaignEventList,
      }).then(callback);
    }
  }

  const handleCancel = () => {
    let campaignId = props.match.params.id;

    if (campaignInfo.id === null) {
      history.push("/campaigns")
    } else {
      if (!campaignId) {
        deleteCampaign(campaignInfo.id)
      } else {
        history.push("/campaigns")
      }
    }
  }

  const [isConfirmDelete, setConfirmDelete] = useState(false);

  /**
   * New campaign
   */
  useEffect(() => {
    if (!campaignId) {
      dispatch(setInitialCampaign({
        id: null,
        name: '',
        startDate: null,
        endDate: null,
        comment: '',
        campaignText: ''
      }, [], []));
    }

    return () => {
      queryClient.invalidateQueries(['campaign']);
      dispatch(clearCampaignForm());
    }
  }, [])

  /**
   * Edit campaign
   */
  useEffect(() => {
    if (campaign) {
      setDocumentTitle('Redigera kampanj - "' + campaign.name +'"');
      setTitle('Redigera kampanj  - "' + campaign.name +'"');
      dispatch(setInitialCampaign(campaign, campaign.campaignTeams, campaign.campaignEvents));
    }
  }, [campaign])

  useEffect(() => {
    setDocumentTitle(title);
  }, [title]);

  return (
    <div id="campaign-container">
        <div>
          <div>
            <div className="page-content page-container" id="page-content">
              <div className="padding">
                <div className="card">
                  <div className="card-header d-flex align-items-center justify-content-between w-100">
                    <strong>{title}</strong>
                    {campaignId && (
                      <>
                        <Button onClick={() => setConfirmDelete(true)} color='primary' title="Ta bort">
                          <Trash size={18} />
                        </Button>

                        <Modal isOpen={isConfirmDelete} toggle={setConfirmDelete} size="lg">
                          <ModalHeader>Ta bort kampanjen?</ModalHeader>
                          <ModalBody>Är du säker på att du vill ta bort kampanjen?</ModalBody>
                          <ModalFooter>
                            <Button color='primary' onClick={() => deleteCampaign(campaignId)}>Ja</Button>
                            <Button>Avbryt</Button>
                          </ModalFooter>
                        </Modal>
                      </>
                    )}
                  </div>
                  <div className="card-body">
                      <div id="rootwizard">
                        <ul className="nav mb-3">
                          {
                            campaignSteps.map((step, index) => {
                              let linkClasses = [
                                'nav-link',
                                'text-center',
                                currentStep === step.name ? 'active' : '',
                                step.disabled ? 'disabled' : ''
                              ].join(' ');

                              return (
                                <li className="nav-item" key={index}>
                                  <a href="" type="button" 
                                     className={linkClasses}
                                     onClick={(e) => stepClick(step, e)}>

                                    <span className="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-success">{index + 1}</span>
                                    <div className="mt-2">
                                      <div className="text-muted">{step.text}</div>
                                    </div>
                                  </a>
                                </li>
                              );
                            })
                          }
                        </ul>

                        <div className="tab-content p-3">
                          { renderStep() }

                          <WizardButtons step={currentStep} onCancel={handleCancel} onPreviousStep={previousStep} onNextStep={nextStep} />
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default CampaignWizard;

/**
 * @typedef WizardButtonsProps
 * @property {string} step
 * @property {() => void} onCancel
 * @property {() => void} onPreviousStep
 * @property {() => void} onNextStep
 * @param {WizardButtonsProps} props 
 */
const WizardButtons = ({ step, onCancel, onPreviousStep, onNextStep }) => {
  const campaignInfo = useSelector(state => state.campaign.campaignInfo);
  const formValidation = useSelector(state => state.campaign.formValidation);

// TODO: oncancel ,confirm do you want to save your changes... 

  const handleSave = () => {
    if (!campaignInfo.id) {
      return
    }

    campaignService.update(campaignInfo.id, campaignInfo)
        .then(() => {
          campaignService.get(campaignInfo.id).then(campaign => {
            if (!campaign) return

            if (campaign.campaignTeams) {
              // dispatch(setCampaignTeams(campaign.campaignTeams));
            }

            if (campaign.campaignEvents) {
              // dispatch(setCampaignEvents(campaign.campaignEvents));
            }
          });

        /* if (callback) {
          callback();
        } */
      });
  }

  return (
    <div className="d-flex flex- justify-content-between pt-4">
      
      {step === 'info' && (
        <>
          <Button onClick={onCancel} className='0'>
            Tillbaka till kampanjer
          </Button>
       
          <Button onClick={onNextStep} disabled={formValidation.indexOf('campaignInfo') === -1} className='d-flex align-items-center' style={{ gap: '0.25rem' }}>
            Fortsätt till lag
            <ArrowRight size={16} />
          </Button>
        </>
      )}

      {step === 'team' && (
        <>
          <Button onClick={onPreviousStep} className='0'>Tillbaka till information</Button>
       
          <Button onClick={onNextStep} disabled={formValidation.indexOf('campaignTeams') === -1} className='d-flex align-items-center' style={{ gap: '0.5rem' }}>
            Fortsätt till aktiviteter
            <ArrowRight size={16} />
          </Button>
        </>
      )}

      {step === 'event' && (
        <>
          <Button onClick={onPreviousStep} className='0'>Tillbaka till lag</Button>
       
          <Button onClick={onNextStep} disabled={formValidation.indexOf('campaignEvents') === -1} className='d-flex align-items-center' style={{ gap: '0.5rem' }}>
            Fortsätt till målsättning
            <ArrowRight size={16} />
          </Button>
        </>
      )}

      {step === 'goal' && (
        <>
          <Button onClick={onPreviousStep} className='0'>Tillbaka till aktiviteter</Button>
       
          <Button onClick={onNextStep} className='d-flex align-items-center' style={{ gap: '0.5rem' }}>
            Slutför
            <ArrowRight size={16} />
          </Button>
        </>
      )}
    </div>
  )
}

const ConfirmModal = ({ isOpen, setOpen }) => {
  return (
    <Modal isOpen={isOpen} toggle={setOpen} size="lg">
      <ModalHeader></ModalHeader>
    </Modal>
  )
}