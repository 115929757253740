import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import { CompanyApi } from '../../api/Company';
import { CompanySchema } from '../../api/Company/Company.schema';
import { FieldError } from '../Shared/Style';
import { history, setDocumentTitle } from '../../helpers';
import UserForm from '../User/UserForm.component';
import CompanyUserList from '../User/CompanyUserList.component';
import { BreadCrumb } from '../Breadcrumb/Breadcrumb';

import { Trash } from 'react-feather';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const CompanyForm = (props) => {
  let companyId = props.match.params.id;

  const queryClient = useQueryClient();

  const [isUpdating, setUpdating] = useState(false);
  const [isUserFormOpen, setUserFormOpen] = useState(false);

  let [company, setCompany] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { mutate: deleteCompany } = useMutation(
    ['deleteCompany'],
    (companyId) => CompanyApi.deleteCompany(companyId),
    {
      onSuccess: () => {
        history.push('/companies');
      },
    }
  );

  const submitCompany = (values) => {
    setUpdating(true);

    if (company?.id) {
      CompanyApi.update(company.id, {
        ...company,
        name: values.name,
      }).then(() => {
        setUpdating(false);
        //history.push('/events');
      });
    } else {
      CompanyApi.create({
        name: values.name,
      }).then((company) => {
        setUpdating(false);
        history.push(`/companies/${company.id}`);
      });
    }
  };

  const cancel = () => {
    history.push('/companies');
  };

  useEffect(() => {
    if (companyId) {
      CompanyApi.get(companyId).then((company) => {
        if (!company) {
          return;
        }

        setDocumentTitle(company.name);
        setCompany(company);
      });
    }
  }, []);

  return (
    <div className="d-flex flex-column gap-4">
      <BreadCrumb
        title={company?.name ?? 'Skapa företag'}
        breadcrumbs={[{ name: 'Företag', href: '/companies' }]}
      />

      <div className="page-content px-3">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <strong>{company?.name ? 'Företag' : 'Skapa företag'}</strong>

            {company && (
              <Button
                type="submit"
                color="primary"
                className="d-flex gap-2"
                onClick={() => setConfirmDelete(true)}
              >
                <Trash size={20} />
                Ta bort
              </Button>
            )}
          </div>

          <div className="card-body">
            <Formik
              initialValues={{
                name: company?.name ?? '',
              }}
              enableReinitialize={true}
              validationSchema={CompanySchema}
              onSubmit={submitCompany}
            >
              {({ errors, touched, isValid }) => (
                <Form>
                  <div className="form-row">
                    <FormGroup className="col-md-12">
                      <Label>Namn</Label>

                      <Field
                        name="name"
                        className={`form-control${
                          errors.name && touched.name ? ' has-error' : ''
                        }`}
                        placeholder="Namn"
                        readOnly={isUserFormOpen}
                      />
                      {errors.name && touched.name && (
                        <FieldError>{errors.name}</FieldError>
                      )}
                    </FormGroup>
                  </div>

                  {!isUserFormOpen && (
                    <ButtonContainer>
                      <Button color="secondary" onClick={cancel}>
                        Avbryt
                      </Button>

                      <Button
                        type="submit"
                        color="primary"
                        disabled={!isValid && touched.name}
                      >
                        {isUpdating && (
                          <React.Fragment>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            />
                            &nbsp; Sparar
                          </React.Fragment>
                        )}

                        {!isUpdating && 'Spara'}
                      </Button>
                    </ButtonContainer>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>

        {isUserFormOpen && (
          <div>
            <UserForm
              companyId={companyId}
              onSubmit={() => {
                queryClient.invalidateQueries(['companyUsers']);
                setUserFormOpen(false);
              }}
              onCancel={() => setUserFormOpen(false)}
            />
          </div>
        )}

        {!isUserFormOpen && (
          <>
            {companyId && !isUserFormOpen && (
              <CompanyUserList
                companyId={companyId}
                onToggleForm={() => setUserFormOpen(!isUserFormOpen)}
              />
            )}
          </>
        )}
      </div>

      <Modal isOpen={confirmDelete} toggle={() => setConfirmDelete(false)}>
        <ModalHeader toggle={() => setConfirmDelete(false)}>
          Ta bort {company?.name}?
        </ModalHeader>
        <ModalBody>Är du säker på att du vill ta bort företaget?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteCompany(company.id)}>
            Ja
          </Button>{' '}
          <Button color="secondary" onClick={() => setConfirmDelete(false)}>
            Avbryt
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CompanyForm;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > :not(:first-child) {
    margin-left: 0.25rem;
  }

  > :not(:last-child) {
    margin-right: 0.25rem;
  }
`;
