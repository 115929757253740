import * as moment from 'moment';
import {DateInterval} from '../../helpers';

const getPercentageFromTeamMembers = (teamMembers) => {
  let totalPercentage = 0;
  let teamSize = teamMembers.length;

  teamMembers.forEach(teamMember => {
    let percentage = getPercentageFromTeamMember(teamMember);
    totalPercentage += percentage;
  });

  let percentage = (totalPercentage > 0) ? Math.ceil((totalPercentage / (teamSize * 100)) * 100) : 0;

  return (percentage < 100) ? percentage : 100;
}

const getCampaignTotalPercentageFromTeamMembers = (teamMembers, goal, eventIds) => {
  let totalPercentage = 0
  let teamSize = teamMembers.length
  
  for (const teamMember of teamMembers) {
    const teamMemberPercentage = getPercentageFromTeamMember(teamMember)
    totalPercentage += teamMemberPercentage
  }
  
  const percentage = totalPercentage > 0 ? Math.ceil((totalPercentage / (teamSize * 100)) * 100) : 0
  
  return percentage < 100 ? percentage : 100
}

const getCampaignTotalPercentageFromTeamMember = (teamMember, goal, eventIds = []) => {
  let total = 0
  
  const sum = (r, a) => {
    return Array.isArray(a) ? a.reduce(sum, r) : r + a
  }
  
  if (!teamMember)
    return 0
  
  for (const eventId of eventIds) {
    const teamMemberOrders = teamMember.orders.filter(order => order.eventId === eventId)
    
    if (!teamMemberOrders)
      return 0
    
    const ordersSum = teamMemberOrders
      .map(order => order.value)
      .reduce(sum, 0)
    
    total += ordersSum
  }
  
  const percentage = total && total > 0 ? Math.ceil((total / goal) * 100) : 0
  
  return percentage < 100 ? percentage : 100
}

const getPercentageFromTeamMember = (teamMember) => {
  if (!teamMember)
    return 0;

  let eventAmount = 0;

  const totalPercentage = teamMember.eventGoals.reduce((total, eventGoal) => {
    let eventOrders = teamMember.orders.filter(order => order.eventId === eventGoal.eventId);
    let eventValue = eventOrders.reduce((total, order) => total + order.value, 0);
    let goal = eventGoal.goal;

    if (goal > 0) {
      eventAmount++;
      let eventPercent = (eventValue > 0) ? Math.ceil(eventValue / eventGoal.goal * 100) : 0;
      return total + (eventPercent < 100 ? eventPercent : 100);
    }

    return total + 0;
  }, 0);

  const amount = eventAmount;

  const percentage = Math.ceil((totalPercentage / (amount * 100)) * 100) || 0;
  
  return (percentage < 100) ? percentage : 100;
}

const getPercentageTodayFromTeamMember = (teamMember) => {
  if (!teamMember)
    return 0

  let eventAmount = 0

  const totalPercentage = teamMember.eventGoals.reduce((total, eventGoal) => {
    const today = moment().format("YYYY-MM-DD")
    let ordersToday = teamMember.orders.filter(order => moment(order.orderDate).format("YYYY-MM-DD") === today && order.eventId === eventGoal.eventId)

    if (!ordersToday[0])
      ordersToday = []

    const eventValueToday = ordersToday?.reduce((total, order) => total + order.value, 0)
    const goal = eventGoal.goal

    if (goal > 0) {
      eventAmount++

      let eventPercent = (eventValueToday && eventValueToday > 0) ? Math.ceil(eventValueToday / goal * 100) : 0

      return total + (eventPercent < 100) ? eventPercent : 100
    }

    return total + 0
  }, 0)

  const percentage = Math.ceil((totalPercentage / (eventAmount * 100)) * 100) || 0

  return (percentage < 100) ? percentage : 100
}

const getCampaignTotalPercentageTodayFromTeamMember = (teamMember) => {
  const percentage = Math.floor((teamMember.valueToday / teamMember.parentCampaignGoal) * 100) || 0
  
  return percentage < 100 ? percentage : 100
}

const isTeamGoalObtainable = (campaign, teamMembers) => {

  if (!campaign)
    return;

  let startDate = moment(campaign.startDate);
  let endDate = moment(campaign.endDate);
  let totalDays = DateInterval(startDate, endDate).length;
  let daysLeft = (DateInterval(moment(), endDate).length) > 0 ? DateInterval(moment(), endDate).length : 1;

  let teamPercentage = getPercentageFromTeamMembers(teamMembers);
  let averagePercentPerDay = Math.ceil(teamPercentage / (totalDays - daysLeft) * 100) / 100;
  let minAveragePerDayRequired = Math.ceil((100 / teamPercentage) * 100) / 100;
  return averagePercentPerDay >= minAveragePerDayRequired;
}

export default {
  getPercentageFromTeamMembers,
  getPercentageFromTeamMember,
  getPercentageTodayFromTeamMember,
  getCampaignTotalPercentageFromTeamMember,
  getCampaignTotalPercentageTodayFromTeamMember,
  getCampaignTotalPercentageFromTeamMembers,
  isTeamGoalObtainable
}