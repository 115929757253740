import React, {Fragment, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import * as moment from 'moment';

import ActivityScoreCard from "../ActivityScoreCard.component";
import {DateInterval} from "../../../helpers";
import UserTotalScore from "../UserTotalScore.component";
import TeamTotalScore from "../Team/TeamTotalScore.component";
import ScoreUtil from "../ScoreUtil";
import {EventApi} from '../../../api/Event/Event.api';

const UserScore = () => {
  const currentUser = useSelector(state => state.authentication.user);
  const selectedCampaign = useSelector(state => state.campaign.selectedCampaign);
  const campaignScore = useSelector(state => state.score.score);

  const [userTotalScore, setUserTotalScore] = useState(0);

  const [teamTotalValue, setTeamTotalValue] = useState(0);
  const [teamTotalGoal, setTeamTotalGoal] = useState(0);

  const [eventScores] = useState(new Map());
  const [eventValues] = useState(new Map());
  const [eventGoals] = useState(new Map());

  const [campaignEvents, setCampaignEvents] = useState([]);
  const [userScores, setUserScores] = useState([]);
  const [eventUnits, setEventUnits] = useState(null);

  const [teamMember, setTeamMember] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    EventApi.findAll()
        .then(events => {
          let eventUnits = new Map();

          events.forEach(event => {
            eventUnits.set(event.id, event.unit);
          });

          setEventUnits(eventUnits);
        });
  }, []);

  useEffect(() => {
    if (campaignScore) {
      setUserScores([]);
      setUserTotalScore(0);
      setTeamTotalValue(0);
      setTeamTotalGoal(0);
      eventScores.clear();
      eventValues.clear();
      eventGoals.clear();

      if (campaignScore.campaignTeams) {
        let userTeam = campaignScore.campaignTeams.find(team => team.campaignTeamMembers
            .find(teamMember => teamMember.userUniqueId === currentUser.id));

        if (userTeam) {
          let totalTeamGoal = 0;
          userTeam?.campaignTeamMembers.forEach(teamMember => {
            teamMember.eventGoals.forEach(eventGoal => {
              totalTeamGoal += eventGoal.goal;
            });
          });
          setTeamTotalGoal(totalTeamGoal);
        }


        let teamMembers = [];

        campaignScore.campaignTeams.forEach(campaignTeam => {
          let isUserTeam = campaignTeam?.campaignTeamMembers.some(teamMember => teamMember.userUniqueId === currentUser.id);
  
          if (isUserTeam) {
            campaignTeam?.campaignTeamMembers.forEach(teamMember => {
              teamMembers.push(teamMember);
            });
          }
  
          campaignTeam?.campaignTeamMembers.forEach(teamMemberScore => {
            if (teamMemberScore.userUniqueId === currentUser.id) {
              setTeamMember(teamMemberScore);
              setUserTotalScore(Math.floor(parseFloat(teamMemberScore.value) * 100) / 100);
              setTeamTotalValue(Math.floor(parseFloat(campaignTeam.value) * 100) / 100);
  
              let userScores = [];
  
              teamMemberScore?.orders.forEach(campaignScore => {
                userScores.push(campaignScore);
  
                if (eventScores.has(campaignScore.eventId)) {
                  let current = eventScores.get(campaignScore.eventId);
                  eventScores.set(campaignScore.eventId, current + campaignScore.value);
                }
                else {
                  eventScores.set(campaignScore.eventId, campaignScore.value);
                }
  
                if(eventValues.has(campaignScore.eventId)) {
                  let current = eventValues.get(campaignScore.eventId);
                  eventValues.set(campaignScore.eventId, current + campaignScore.value)
                }
                else {
                  eventValues.set(campaignScore.eventId, campaignScore.value)
                }
              });
  
              teamMemberScore?.eventGoals.forEach(eventGoal => {
                if (!eventGoals.has(eventGoal.eventId)) {
                  eventGoals.set(eventGoal.eventId, eventGoal.goal);
                }
              });
  
              setUserScores(userScores);
            }
          });
        });
  
        setTeamMembers(teamMembers);
      }
    }

    if (selectedCampaign) {
      let campaignEvents = [];
      selectedCampaign?.campaignEvents.forEach(campaignEvent => {
        campaignEvents.push({
          ...campaignEvent
        });
      });

      // Sort on highest score descending, then eventName ascending
      campaignEvents.sort((a, b) => {
        // Only sort on eventName if there are no scores
        if (a.eventName < b.eventName) {
          return -1;
        }

        if (a.eventName > b.eventName) {
          return 1;
        }

        return 0;
      });

      setCampaignEvents(campaignEvents);
    }
  }, [campaignScore]);

  const isEventGoalObtainable = (eventCampaignScores, eventGoal) => {
    let orderValues = new Map();
    let startDate = moment(selectedCampaign.startDate);
    let endDate = moment(selectedCampaign.endDate);

    eventCampaignScores.forEach(userScore => {
      let scoreDate = moment(userScore.createdTimestamp);

      if (scoreDate.isBetween(startDate, endDate)) {
        let day = scoreDate.format('YYYY-MM-DD');

        if (orderValues.has(day)) {
          let current = orderValues.get(day);
          orderValues.set(day, current + userScore.value);
        }
        else {
          orderValues.set(day, userScore.value);
        }
      }
    });

    let totalOrderValue = [...orderValues.entries()]
      .map(item => item[1])
      .reduce((total, value) => total + value, 0);

    if (orderValues.size > 0) {
      let averagePerDay = Math.ceil((totalOrderValue / orderValues.size) * 100) / 100;

      let daysLeft = DateInterval(moment(), endDate)
        .filter(date => !orderValues.has(date))
        .length;

      return (daysLeft * averagePerDay) >= eventGoal;
    }

    return false;
  };

  const isUserGoalObtainable = (teamMember) => {
    let startDate = moment(selectedCampaign.startDate);
    let endDate = moment(selectedCampaign.endDate);
    let totalDays = DateInterval(startDate, endDate).length;
    let daysLeft = (DateInterval(moment(), endDate).length) > 0 ? DateInterval(moment(), endDate).length : 1;

    let totalValue = teamMember.orders.reduce((total, order) => {
      return total + order.value;
    }, 0);

    let averagePerDay = Math.ceil(totalValue / (totalDays - daysLeft) * 100) / 100;
    let currentPercentage = ScoreUtil.getPercentageFromTeamMember(teamMember);
    let minAveragePerDayRequired = Math.ceil((100 / currentPercentage) * 100) / 100;

    return averagePerDay >= minAveragePerDayRequired;
  };

  return (
    <div className="col-md-12 col-lg-8">
        <div className="row row-sm">
          <div className="col-md-9">

            { campaignEvents.length === 0 &&
              <div className="row row-sm">
                <div className="col-12 text-muted">
                  Kampanjen saknar aktiviteter
                </div>
              </div>
            }

            { campaignEvents.length > 0 &&
              <Fragment>
                <div className="row row-sm">
                  { campaignEvents
                    .map((campaignEvent, i) => {
                      let eventValue = eventValues.get(campaignEvent.eventId) || 0;
                      let eventUnit = eventUnits && eventUnits.get(campaignEvent.eventId) || '';
                      let eventGoal = eventGoals.get(campaignEvent.eventId) || 0;
                      let eventCampaignScores = userScores.filter(campaignScore => campaignScore.eventId === campaignEvent.eventId);
                      let isGoalObtainable = isEventGoalObtainable(eventCampaignScores, 0);

                      return (
                        <div className="col-4" key={i}>
                          <ActivityScoreCard
                            event={campaignEvent}
                            value={eventValue}
                            unit={eventUnit}
                            goal={eventGoal}
                            isGoalObtainable={isGoalObtainable}
                          />
                        </div>
                      )
                    })
                  }
                </div>
              </Fragment>
            }
          </div>

          { teamMember &&
            <div className="col-md-3 d-flex">
              <UserTotalScore teamMember={teamMember} isGoalObtainable={isUserGoalObtainable(teamMember)} />
              {/*<TeamTotalScore teamMembers={teamMembers} isGoalObtainable={ScoreUtil.isTeamGoalObtainable(selectedCampaign, teamMembers)} />*/}
            </div>
          }
        </div>
    </div>
  );
};

export default UserScore;