export const SET_CAMPAIGN_LIST = 'CAMPAIGN:SET_CAMPAIGN_LIST';
export const SET_SELECTED_CAMPAIGN = 'CAMPAIGN:SET_SELECTED_CAMPAIGN';

export const SET_CAMPAIGN_DIRTY = 'CAMPAIGN:SET_CAMPAIGN_DIRTY';

export const CLEAR_FORM = 'CAMPAIGN:CLEAR_FORM';
export const SET_INFO_FORM_VALIDATION = 'CAMPAIGN:SET_INFO_FORM_VALIDATION';
export const SET_DIRTY_FIELD = 'CAMPAIGN:SET_DIRTY_FIELD';
export const SET_INITIAL_CAMPAIGN_STEP = 'CAMPAIGN:SET_INITIAL_CAMPAIGN_STEP';
export const SET_ACTIVE_CAMPAIGN_STEP = 'CAMPAIGN:SET_ACTIVE_CAMPAIGN_STEP';
export const SET_CAMPAIGN_STEPS = 'CAMPAIGN:SET_CAMPAIGN_STEPS';
export const SET_INITIAL_CAMPAIGN = 'CAMPAIGN:SET_INITIAL_CAMPAIGN';
export const SET_CAMPAIGN_INFO = 'CAMPAIGN:SET_CAMPAIGN_INFO';

export const SET_CAMPAIGN_TEAMS = 'CAMPAIGN:SET_CAMPAIGN_TEAMS';
export const SET_CAMPAIGN_TEAM_MEMBERS = 'CAMPAIGN:SET_CAMPAIGN_TEAM_MEMBERS';
export const SELECT_TEAM = 'CAMPAIGN:SELECT_TEAM';
export const REMOVE_TEAM = 'CAMPAIGN:REMOVE_TEAM';

export const SET_CAMPAIGN_EVENTS = 'CAMPAIGN:SET_CAMPAIGN_EVENTS';
export const SET_EVENT_WEIGHT = 'CAMPAIGN:SET_EVENT_WEIGHT';
export const SELECT_EVENT = 'CAMPAIGN:SELECT_EVENT';
export const REMOVE_EVENT = 'CAMPAIGN:REMOVE_EVENT';

export const SET_CAMPAIGN_GOAL = 'CAMPAIGN:SET_CAMPAIGN_GOAL';
export const SET_EVENT_TEAM_GOAL = 'CAMPAIGN:SET_EVENT_TEAM_GOAL';
export const SET_EVENT_GOAL = 'CAMPAIGN:SET_EVENT_GOAL';

export const OPEN_ORDER_MODAL = 'CAMPAIGN:OPEN_ORDER_MODAL';
export const CLOSE_ORDER_MODAL = 'CAMPAIGN:CLOSE_ORDER_MODAL';