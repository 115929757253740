import {
  FIND_ALL_REQUEST,
  FIND_ALL_SUCCESS,
  FIND_ALL_ERROR,
  SET_USER_LIST,
} from '../types/user.types';

const initialState = { users: [] };

const userListReducer = (state = initialState, action) => {

  switch (action.type) {
    case FIND_ALL_REQUEST:
      return {
        users: []
      };

    case FIND_ALL_SUCCESS:
      return {
        users: action.users
      };

    case FIND_ALL_ERROR:
      return {
        users: []
      };

    case SET_USER_LIST: {
      state.users = action.users;
      return state;
    }

    default:
      return state;
  }
};

export default userListReducer;