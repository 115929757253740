export const BONTI_COMPANY_ID = "379e3018-171a-459a-a2ae-01697e2eeea5"

export const calculateTeamPercentage = (orders, team) => {
  let totalPercentage = 0
  let teamSize = team.teamMembers.length

  team.teamMembers.forEach(teamMember => {
    let percentage = getPercentageFromTeamMember(teamMember, orders)
    totalPercentage += percentage
  })

  let percentage = (totalPercentage > 0) ? Math.ceil((totalPercentage / (teamSize * 100)) * 100) : 0

  return (percentage < 100) ? percentage : 100
}

export const getPercentageFromTeamMember = (teamMember, orders) => {

  if (!teamMember)
    return 0

  const teamMemberOrders = orders.filter(o => o.userEmail === teamMember.teamMemberEmail)
  let value = teamMemberOrders.reduce((total, order) => total + order.value, 0)

  let percentage = 0

  if (teamMember.goal > 0) {
    percentage = (value > 0) ? Math.ceil(value / teamMember.goal * 100) : 0
  }

  return (percentage < 100) ? percentage : 100
}

export const BONTI_ASKAS_TEAM_ID = "99b1f533-1f3d-40e9-a084-9c38dc4dc29f"

export const bontiTeams = {
  nk: {
    storeId: 1,
    teamId: "c346b8d2-fcc0-4949-869a-6b0fbe03dc63",
    storeName: "Bonti Nordiska Kompaniet (City)"
  },
  odenplan: {
    storeId: 2,
    teamId: "76c36e83-667f-421e-a4c4-a3808f192b85",
    storeName: "Bonti Odenplan"
  },
  taby: {
    storeId: 3,
    teamId: "89b71b1c-0487-4d93-8fe1-1ba3f89e984a",
    storeName: "Bonti Täby Centrum"
  }
}