import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

const UserImage = ({userId, type}) => {
  const version = Math.random() * 1000;
  const userList = useSelector(state => state.userList.users);

  const imageUpdated = useSelector(state => state.user.imageUpdated);
  const [url, setUrl] = useState('');

  if (imageUpdated) {}

  useEffect(() => {
    if (userList && userList.length > 0) {
      let user = userList.find(user => user.id === userId);

      if (user) {
        if (type === 'thumb') {
          setUrl(user.profileImage + '64x64?v=' + version);
        }
        else {
          setUrl(user.profileImage + '?v=' + version);
        }
      }
    }
  }, [userId, userList, imageUpdated]);

  if (!url)
    return <React.Fragment/>

  return <img src={url} alt=""/>;
};

export default UserImage;