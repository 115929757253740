import React, {useEffect, useState} from "react";
import CountUp from "react-countup";
import {Col, Collapse, Row} from "reactstrap";
import {CardBody} from "../Shared/Card";
import {BontiApi} from "../../api/Bonti/Bonti.api";
import {useSelector} from "react-redux";

import {DashboardCard} from './Dashboard.style'
import styled from "styled-components";
import {ChevronDown, ChevronUp} from "react-feather";
import SortableComponent from "./SortableComponent.component";
import {Scrollbars} from "react-custom-scrollbars-2";
import DashboardCollapse from "./DashboardCollapse.component";
import {Wrapper} from './Dashboard.style'

const CampaignRSCountData = ({name, component, isEditing}) => {

    const currentUser = useSelector(state => state.authentication.user)
    const [customers, setCustomers] = useState(0)
    const [orderValuesYesterday, setOrderValuesYesterday] = useState([])
    const [isCollapsed, setCollapsed] = useState(true)

    const toggle = () => setCollapsed(!isCollapsed);


    const fetchData = () => {
        BontiApi.getYesterdaysCustomers()
            .then(result => {
                if (!result || result == null || result == "") {
                    return
                }

                setCustomers(result)
            })
            .catch(err => {
                console.log({err})
            })

        BontiApi.getYesterdaysOrders()
            .then(result => {
                if (!result || result == null || result == "") {
                    return
                }

                setOrderValuesYesterday(result)
            })
            .catch(err => {
                console.log({err})
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
    <Wrapper>
        <DashboardCollapse component={component}>
            <RSCardBody>
                <Scrollbars style={{height: 515, scrollWidth: 5}}>
                <div className="cards w-100 px-4">
                    <div className='d-flex justify-content-between w-100'>
                        <div className="align-self-center"></div>
                    </div>
                        <Row>
                            <Col md={3}>
                                <div className="avatar w-96 m-4 no-shadow gd-success">
                                    <CountUp start={customers} end={customers} duration={2} decimal="0"
                                             separator={' '}/>
                                </div>
                            </Col>

                            <Col md={9} style={{ display: "flex", alignItems: "center" }}>
                                <RSCountData>
                                    <strong>Besökare</strong>
                                </RSCountData>
                            </Col>

                            <Col md={3}>
                                <div className="avatar w-96 m-4 no-shadow gd-success">
                                    <CountUp
                                        start={parseInt((orderValuesYesterday / customers).toFixed(2))}
                                        end={parseInt((orderValuesYesterday / customers).toFixed(2))}
                                        decimal="0"
                                        separator={' '}/>
                                </div>
                            </Col>

                            <Col md={9}  style={{ display: "flex", alignItems: "center" }}>
                                <RSCountData>
                                    <strong>kr/h</strong>
                                </RSCountData>
                            </Col>
                        </Row>
                </div>
                </Scrollbars>
            </RSCardBody>
        </DashboardCollapse>
    </Wrapper>
    )
}
export default CampaignRSCountData

const RSCountData = styled.div`
  color: #192039;
  font-size: 30px;
  margin-left: 30%;
  
  //margin-top: 25%;
  @media (min-width: 1450px) {
    font-size: 35px;
    //margin-top: 13%;
    margin-left: 5%;
  }
  @media (min-width: 3000px) {
    font-size: 35px;
    //margin-top: 10%;
    margin-left: -5%;
  }
`

const RSCardBody = styled(CardBody)`
  @media (max-width: 1281px) {
    width: 400px;
    
  }
  display: flex;
  align-items: center;
`

