import React, {useEffect, useState} from 'react';

import {PlusCircle} from 'react-feather';
import {Progress} from 'reactstrap';

import styled from 'styled-components';
import CountUp from 'react-countup';
import {useDispatch, useSelector} from 'react-redux';
import {Clickable} from '../../globalStyles';
import {openOrderModal} from '../../actions/campaign.actions';

const ProgressWrapper = styled.div`
  margin-top: 0.5rem;
  .progress-bar {
    font-size: 0.65625rem;
    transition: width 2s ease;
  }
`;

const ProgressValue = styled.div``;

const ActivityScoreCard = (props) => {
  let {
    event,
    value,
    unit,
    goal,
    isGoalObtainable
  } = props;

  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.authentication.user);
  const [goalPercentage, setGoalPercentage] = useState(0);

  useEffect(() => {
    let percent = 0;

    if(goal > 0)
      percent = Math.ceil((value / goal) * 100);

    setGoalPercentage(percent);
  }, [value, goal]);

  const handlePlusClick = (eventId) => {
    dispatch(openOrderModal(eventId));
  };

  return (
    <div className={`card${value === 0 ? ' o5' : ''}`}>
      <div className="card-body">
        <div className="d-md-flex">
          <div className="flex">
            <div className="item-amount text-highlight">
              {event.eventName}
            </div>
          </div>

          {currentUser && !currentUser.isBontiUser() &&
            <Clickable title='Registrera'>
              <small className="text-muted">
                <PlusCircle size={16} onClick={() => {handlePlusClick(event.id)}}/>
              </small>
            </Clickable>
          }
        </div>

        <ProgressWrapper>
          <div className="text-muted mb-1">{value} / {goal} {unit}</div>

          <Progress
            value={goalPercentage}
            color={isGoalObtainable ? 'success' : 'danger'}
            title={goalPercentage + '%'}>
            { goalPercentage >= 15 &&
              <ProgressValue title={goalPercentage + '%'}>
                <CountUp end={goalPercentage} duration={"2"}/>%
              </ProgressValue>
            }
          </Progress>
        </ProgressWrapper>
      </div>
    </div>
  );
}

export default ActivityScoreCard;