import client from '../../helpers/client';

const baseUrl = '/companies';
const lastActivityUrl = '/lastactivity';

export function getLastActivity() {
  return client
    .getClient()
    .get(baseUrl + lastActivityUrl)
    .then((response) => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch((err) => {
      if (err.response) {
        let errorMessage = err.response.data || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function findAll() {
  return client
    .getClient()
    .get(baseUrl)
    .then((response) => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch((err) => {
      if (err.response) {
        let errorMessage = err.response.data || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function get(userId) {
  const url = `${baseUrl}/${userId}`;

  return client
    .getClient()
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      if (err.response) {
        let errorMessage = err.response.data || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function create(data = {}) {
  return client
    .getClient()
    .post(baseUrl, data)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      if (err.response) {
        let errorMessage = err.response.data || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function update(userId, data = {}) {
  const url = `${baseUrl}/${userId}`;

  return client
    .getClient()
    .put(url, data)
    .then((response) => Promise.resolve(response.data))
    .catch((err) => {
      if (err.response) {
        let errorMessage = err.response.data || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export const deleteCompany = (companyId) => {
  return client
    .getClient()
    .delete(`${baseUrl}/${companyId}`)
    .then((response) => Promise.resolve(response.data))
    .catch((err) => {
      if (err.response) {
        let errorMessage = err.response.data || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
};

export const CompanyApi = {
  getLastActivity,
  findAll,
  get,
  create,
  update,
  deleteCompany,
};
