import React, {useEffect, useState} from 'react'
import {Container, ContentWrapper, Flex, FlexWrapper, Wrapper} from '../pages.style'
import {useDispatch, useSelector} from 'react-redux'
import {BontiApi} from '../../api/Bonti/Bonti.api'
import { AskasApi } from "../../api/Bonti/Askas.api";
import {foldLeftNav, unfoldLeftNav} from "../../actions/layout.actions";
import {Menu} from 'react-feather';
import {CampaignApi} from "../../api/Campaign/Campaign.api";
import {BONTI_ASKAS_TEAM_ID, BONTI_COMPANY_ID} from "../../helpers/integrations/bonti";
import CampaignTotalLineGraph from "../../components/Dashboard/CampaignTotalLineGraph.component";
import CampaignTotal from "../../components/Dashboard/CampaignTotalSum.component";
import {Col, Row} from "reactstrap";
import {Column} from "../../components/Shared/Grid/Grid.style";
import CampaignBestSellSum from "../../components/Dashboard/CampaignBestSellSum.component";
import CampaignBestSellQuantity from "../../components/Dashboard/CampaignBestSellQuantity.component";

const Ecommerce = () => {
    const currentUser = useSelector(state => state.authentication.user)
    const selectedCampaign = useSelector(state => state.campaign.selectedCampaign)

    const [orderTotal, setOrderTotal] = useState({year: 0, month: 0, week: 0})
    const [orders, setOrders] = useState(null)
    const [campaignTotal, setCampaignTotal] = useState(null)
    const [campaignTotalSum, setCampaignTotalSum] = useState(null)
    const [topItems, setTopItems] = useState(null)
    const [topSumArticles, setTopSumArticle] = useState(null)

    const layoutSettings = useSelector(state => state.layout.settings)

    const dispatch = useDispatch()

    const toggleLeftNav = () => {
        if (layoutSettings.aside === 'folded') {
            dispatch(unfoldLeftNav());
        } else {
            dispatch(foldLeftNav());
        }
    }

    useEffect(async () => {
        try {
            const result = await BontiApi.findEcommerceOrderArticles()
            const articleCount = await BontiApi.findEcommerceOrderArticles(true)

            if (result !== null) {
                setTopItems(result)
            }

            if (articleCount) {
                setTopSumArticle(articleCount)
            }

        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        dispatch(foldLeftNav())

        // BontiApi.getEcommerceOrderTotal().then((result) => {
        //
        //     if (!result) {
        //         return
        //     }
        //
        //     setOrderTotal({
        //         total: result.total,
        //         year: result.year,
        //         month: result.month,
        //         week: result.week,
        //     })
        // })

        CampaignApi.getTotal(BONTI_COMPANY_ID, BONTI_ASKAS_TEAM_ID).then(total => {
            setCampaignTotal(total)
        })

        CampaignApi.getCampaignTotal(BONTI_COMPANY_ID, BONTI_ASKAS_TEAM_ID).then(total => {
            setCampaignTotalSum(total)
        })

    }, [])


    return (
        <Container id="bonti-container" className="ecommerce">
            {!currentUser &&
            <div className="pointer ml-2 mt-2" title={layoutSettings.aside === 'folded' ? "Visa" : "Göm"}>
                <Menu size={16} onClick={toggleLeftNav}/>
            </div>
            }

            <FlexWrapper>
                <div>
                    <h2 className="text-md text-highlight">Bonti E-handel</h2>
                </div>
                <Flex/>
            </FlexWrapper>

            <Wrapper className="pt-0">
                <div className="d-md-flex" style={{justifyContent: 'space-between'}}>
                    <ContentWrapper style={{width: '100%'}}>

                        <div className="row">
                            <div className="col-12">
                                {selectedCampaign &&
                                <div className="pb-3">
                                    <div className="text-muted text-sm sr-item">Bontis årliga resultat</div>
                                </div>
                                }
                            </div>
                        </div>
                        <Row>
                            <Column lg={12}>
                                <CampaignTotal campaignTotal={campaignTotal} />
                            </Column>
                        </Row>
                        <Row>
                            <Column lg={6}>
                                <CampaignTotalLineGraph campaignTotal={campaignTotal} campaignSum={campaignTotalSum}/>
                            </Column>
                            <Column lg={3}>
                                <CampaignBestSellSum articles={topItems} />
                            </Column>
                            <Column lg={3}>
                                <CampaignBestSellQuantity articles={topSumArticles} />
                            </Column>
                        </Row>
                    </ContentWrapper>
                </div>
            </Wrapper>
        </Container>
    )
}

export default Ecommerce
