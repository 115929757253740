import {
  SET_AUDIO_LIST,
  SET_LATEST_AUDIO_EVENT
} from "../types/audio.types";

export function setAudioList(audioList) {
  return (dispatch) => dispatch({ type: SET_AUDIO_LIST, audioList });
}

export function setLatestAudioEvent(audioEvent) {
  return (dispatch) => dispatch({ type: SET_LATEST_AUDIO_EVENT, audioEvent });
}