import {combineReducers} from 'redux';
import authenticationReducer from './authentication.reducer';
import alertReducer from './alert.reducer';
import audioReducer from './audio.reducer';
import companyReducer from './company.reducer';
import userReducer from './user.reducer';
import userListReducer from './userList.reducer';
import campaignReducer from './campaign.reducer';
import latestActivityList from './latestActivityList.reducer';
import shoutoutReducer from './shoutout';
import scoreReducer from './score.reducer';
import dashboardReducer from './dashboard.reducer';
import orderHistoryListReducer from './orderHistoryList.reducer';
import statisticsReducer from './statistics.reducer';
import profileReducer from './profile.reducer';
import layoutReducer from './layout.reducer';
import bontiReducer from './bonti/bonti.reducer';

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  alert: alertReducer,
  audio: audioReducer,
  campaign: campaignReducer,
  company: companyReducer,
  dashboard: dashboardReducer,
  latestActivityList: latestActivityList,
  layout: layoutReducer,
  orderHistoryList: orderHistoryListReducer,
  profile: profileReducer,
  score: scoreReducer,
  shoutout: shoutoutReducer,
  statistics: statisticsReducer,
  user: userReducer,
  userList: userListReducer,
  bonti: bontiReducer,
});

export default rootReducer;