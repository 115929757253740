import React, { useEffect, useState } from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import classNames from 'classnames';
import withSizes from 'react-sizes';

import {MoreVertical, Edit, Trash, CheckCircle, XCircle} from 'react-feather';
import {UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle} from 'reactstrap';

import {Tabs, Tab} from '../Shared/Tabs';
import {Pagination} from '../Shared/Pagination';
import UserImage from '../User/UserImage.component';
import {TableHeader} from "../Shared/Table";

import CampaignFilter from '../Order/CampaignFilter';
import { history, dateFormat } from '../../helpers';
import {BoldMutedText} from '../Shared/Style';
import {OrderApi} from '../../api/Order/Order.api';

const OrderList = () => {
  const currentUser = useSelector(state => state.authentication.user);
  const selectedCampaign = useSelector(state => state.campaign.selectedCampaign)

  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchCampaign, setSearchCampaign] = useState({
    value: '0',
    title: 'Alla'
  });

  const startDateOnInit = moment().startOf('month').format(dateFormat);
  const endDateOnOInit = moment().endOf('month').format(dateFormat);

  const [sortColumn, setSortColumn] = useState('orderDate');
  const [sortDirection, setSortDirection] = useState('desc');

  const [activeTab, setActiveTab] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);

  const [startDate, setStartDate] = useState(startDateOnInit);
  const [endDate, setEndDate] = useState(endDateOnOInit);

  const [showDropdown, setShowDropdown] = useState({});
  const [orderToDelete, setOrderToDelete] = useState(null);
  const [customers, setCustomers] = useState(0);
  const [hasRsCountData, setHasRsCountData] = useState(false);

  const [orderOffset, setOrderOffset] = useState(0);
  const [orderCount, setOrderCount] = useState(0);

  const handleStartDate = (date) => {
    if (date !== undefined) {
      setStartDate(moment(date).format(dateFormat));
    }
  }

  const handleEndDate = (date) => {
    if (date !== undefined) {
      setEndDate(moment(date).format(dateFormat));
    }
  }

  const handleCampaignChange = (value) => {
    setSearchCampaign(value);
  }

  const handleActiveTabChange = (tabKey) => {
    setActiveTab(tabKey);
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  const handleColumnClick = (column) => {
    setSortColumn(column);
    if (sortDirection === 'asc')
      setSortDirection("desc");
    else
      setSortDirection("asc");
  }

  const orderSort = (a, b) => {
    let isDate = moment(a[sortColumn]).isValid();

    if (sortDirection === 'asc') {
      if (isDate) {
        return new Date(a[sortColumn]) - new Date(b[sortColumn]);
      } else {
        return a[sortColumn].localeCompare(b[sortColumn]);
      }
    } else {
      if (isDate) {
        return new Date(b[sortColumn]) - new Date(a[sortColumn]);
      } else {
        return b[sortColumn].localeCompare(a[sortColumn]);
      }
    }
  }

  const formatDate = (campaignOrder) => {
    let today = moment();
    let orderDate = moment(campaignOrder.orderDate);

    if (orderDate.isSame(today, 'd')) {
      return 'Idag';
    }

    return moment(campaignOrder.orderDate).locale(moment.locale('sv')).format('MMMM DD, Y');
  }


  const customerConversion = () => {
    let totalValue = 0

    orders.forEach(order => {
      totalValue += order.value
    })

    return (totalValue / customers).toFixed(2) || 0
  }

  useEffect(() => {
    if (searchCampaign) {
      OrderApi.findPagedOrders(activeTab, searchCampaign.value, startDate, endDate, orderOffset, 20)
        .then(orders => {

          setOrders(orders);
        });
      OrderApi.findOrderCount(activeTab, searchCampaign.value, startDate, endDate)
          .then(orderCount => {

            setOrderCount(orderCount);
          });
    }

  }, [searchCampaign, startDate, endDate, orderOffset, activeTab])



  useEffect(() => {
    filterOrders();
  }, [orders, activeTab])

  useEffect(() => {
    const offset = currentPage - 1

    setOrderOffset(offset)
  }, [currentPage])

  const filterOrders = () => {
    let filteredOrders = orders.filter(order => {

      if (activeTab && activeTab === 'user') {
        return order.userUniqueId === currentUser.id
      }

      return true;
    });

    setFilteredOrders(filteredOrders);
  }

  const canEditOrder = (order) => order.userUniqueId === currentUser.id || currentUser.hasRole('Admin', 'TeamLeader')

  const editOrder = (orderId) => {
    history.push(`/orders/${orderId}`);
  }

  const markForDelete = (orderId) => {
    setOrderToDelete(orderId);
  }

  const deleteOrder = (orderId) => {
    OrderApi.delete(orderId)
      .then(() => {
        setOrderToDelete(null);

        let campaignId = (searchCampaign && searchCampaign.value) ? searchCampaign.value : 0;

        OrderApi.findOrders(campaignId, startDate, endDate)
          .then(orders => {
            setOrders(orders);
          });
      });
  }

  const renderTable = () => {
    let paged = [];
    let itemsPerPage = 20;

    for (let i = (currentPage - 1) * itemsPerPage; i < (currentPage * itemsPerPage) && i < filteredOrders.length; i++) {
      paged.push(filteredOrders[i])
    }

    return (
      <React.Fragment>
        <CampaignFilter
          startDate={startDate}
          endDate={endDate}
          changeCampaign={handleCampaignChange}
          changeStartDate={handleStartDate}
          changeEndDate={handleEndDate}
        />

        {orders.length === 0 && <BoldMutedText>Inga ordrar</BoldMutedText>}

        {/*{ props.isMobile &&
          <OrderCardList orders={paged}/>
        }*/}

        { orders.length > 0 &&
          <div className="table-responsive">
            <Table className="table table-theme table-row v-middle">
              <thead>
                <tr>
                  {/*<TableHeader columnName='userName' columnText='Namn' sortColumn={sortColumn} sortDirection={sortDirection} onClick={() => handleColumnClick('userName')}/>*/}
                  <TableHeader columnName='userName' columnText='Namn'/>
                  <TableHeader columnName='campaignName' columnText='Aktivitet'/>
                  <TableHeader columnName='value' columnText='Värde'/>
                  <TableHeader columnName='score' columnText='Poäng'/>
                  <TableHeader columnName='orderDate' columnText='Datum'/>
                  <th style={{ width: (orderToDelete !== null) ? '150px' : '50px' }}/>
                </tr>
              </thead>
              <tbody>
              { orders && orders
                .sort(orderSort)
                .map((campaignOrder, i) => {
                    const isOrderDelete = campaignOrder.id === orderToDelete;

                    const rowClasses = classNames({
                      'v-middle': true,
                      'delete-item': isOrderDelete
                    });

                    return (
                      <tr className={rowClasses} key={i}>
                        <td>
                          <AvatarGroup className="avatar-group">
                            <a href="#" className="avatar ajax w-32" title={campaignOrder.userName}>
                              <UserImage userId={campaignOrder.userUniqueId} type="thumb" />
                            </a>

                            <div>
                              <div>{campaignOrder.userName}</div>
                              <div className="small text-muted">{campaignOrder.teamName}</div>
                            </div>
                          </AvatarGroup>
                        </td>

                        <td className="flex">
                          <div>{campaignOrder.campaignName}</div>
                          <div className="tl-ordertype">
                            <span className="badge badge-success text-uppercase mr-1">{campaignOrder.eventName}</span>
                          </div>
                        </td>

                        <td>
                          <span className="text-sm">
                            {campaignOrder.value} {campaignOrder.unit}
                          </span>
                        </td>

                        <td>
                          <span className="text-sm">
                            { campaignOrder.score ? (Math.floor(campaignOrder.score * 100) / 100) : 0}
                          </span>
                        </td>

                        <td>
                          <span className="text-sm " style={{textTransform: 'capitalize'}} title={campaignOrder.orderDate}>
                            {formatDate(campaignOrder)}
                          </span>
                        </td>

                        <td>
                          <div className="item-action">
                            {
                              (isOrderDelete === false && canEditOrder(campaignOrder)) &&
                              <UncontrolledDropdown>
                                <DropdownToggle tag="div" className="text-muted" style={{cursor: 'pointer'}}>
                                  <MoreVertical size={16}/>
                                </DropdownToggle>

                                <DropdownMenu className="dropdown-menu-right bg-black">
                                  <DropdownItem onClick={() => editOrder(campaignOrder.id)}>
                                    <Edit size={16}/>
                                    Redigera
                                  </DropdownItem>

                                  <DropdownItem onClick={() => markForDelete(campaignOrder.id)}>
                                    <Trash size={16}/>
                                    Ta bort
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            }

                            {
                              isOrderDelete === true &&
                              <DeleteConfirm>
                                <DeleteConfirmText>Ta bort?</DeleteConfirmText>

                                <DeleteConfirmYes title="Ta bort" onClick={() => deleteOrder(campaignOrder.id)}>
                                  <CheckCircle size={20} color="white"/>
                                </DeleteConfirmYes>

                                <DeleteConfirmNo title="Avbryt" onClick={() => setOrderToDelete(null)}>
                                  <XCircle size={20} color="white" />
                                </DeleteConfirmNo>
                              </DeleteConfirm>
                            }
                          </div>
                        </td>
                      </tr>
                    )
                  }
                )}
              </tbody>
            </Table>
          </div>
        }
      </React.Fragment>
    );
  };

  return (
    <div className="mb-5">
      <Tabs activeKey={activeTab} onChange={handleActiveTabChange}>
        <Tab tabKey="all" title="Alla ordrar">{renderTable()}</Tab>
        <Tab tabKey="user" title="Mina ordrar">{renderTable()}</Tab>
      </Tabs>

      { filteredOrders.length > 0 &&
          <React.Fragment>
          <div className="d-flex">
            <small className="text-muted py-2">Totalt <span id="count">{orderCount}</span> ordrar</small>
          </div>

          <div className="d-flex">
            <Pagination count={orderCount} pageLimit={20} itemsPerPage={20} currentPage={currentPage} onChange={handlePageChange}/>
          </div>
          </React.Fragment>
      }
    </div>
  );
}

const mapSizesToProps = ({width}) => ({
  isMobile: width < 768
});

export default withSizes(mapSizesToProps)(OrderList);

const Table = styled.table`
  tr.delete-item {
    td {
      background-color: #FB2640;
      color: #FFFFFF;
      
      .text-muted {
        color: #FFFFFF !important;
      }
    }
  }
`

const AvatarGroup = styled.div`
  > div {
    margin-left: 10px;
  }
`

const DeleteConfirm = styled.div`
  display: flex;
  flex-direction: row;
`

const DeleteConfirmText = styled.div`
  flex-grow: 0.3;
`

const DeleteConfirmYes = styled.div`
  flex-grow: 0.3;
  cursor: pointer;
`

const DeleteConfirmNo = styled.div`
  cursor: pointer;
`
