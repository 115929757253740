import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {ArrowLeft, RefreshCw, ZoomIn, ZoomOut, X, Upload, Edit} from 'react-feather';
import {Modal, Button} from 'reactstrap';

import AvatarEditor from 'react-avatar-editor';
import {userService} from '../../services/user.service';
import { updateImage } from '../../actions/user.actions';
import { closeImageModal } from '../../actions/profile.actions';

import Slider from 'rc-slider';
import './Slider.css';

const UserImageModal = ({type}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.authentication.user);
  const isModalOpen = useSelector(state => state.profile.isImageModalOpen);

  const fileInput = useRef(null);

  const [isEdit, setEdit] = useState(type === 'edit');
  const [tmpImage, setTmpImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [image, setImage] = useState(null);

  const [profileImageUrl, setProfileImageUrl] = useState('');

  const [avatarSettings, setAvatarSettings] = useState({
    allowZoomOut: false,
    position: {x: 0.5, y: 0.5},
    scale: 1,
    rotate: 0,
    borderRadius: 0,
    preview: null,
    width: 550,
    height: 550,
  });

  /*const handleScale = (e) => {
    setAvatarSettings({
      ...avatarSettings,
      scale: parseFloat(e.target.value)
    })
    paintImage();
  }*/

  const goBack = () => {
    setEdit(false);
    setTmpImage('');
    setImageUrl('');
  }

  const closeModal = () => {
    dispatch(closeImageModal());
  }

  const newImage = () => {
    fileInput.current.click();
  }

  const handleFileChange = (e) => {
    let img = new Image();

    img.onload = function () {
      setTmpImage(this);
    };

    let imageUrl = URL.createObjectURL(e.target.files[0]);
    img.src = imageUrl;
    setImageUrl(imageUrl);
    setEdit(true);
  }

  const editImage = () => {
    setEdit(true);

    let img = new Image();
    img.src = profileImageUrl;
    img.setAttribute('crossOrigin', 'Anonymous')

    img.onload = function () {
      setTmpImage(this);
    };

    setImageUrl(profileImageUrl);
  }

  const handlePositionChange = (position) => {
    setAvatarSettings({
      ...avatarSettings,
      position: position
    });
  }

  const handleRotateClick = (e) => {
    let currentRotation = avatarSettings.rotate;

    setAvatarSettings({
      ...avatarSettings,
      rotate: currentRotation + 90
    });
  }

  const handleSliderChange = (value) => {
    setAvatarSettings({
      ...avatarSettings,
      scale: 1 + (value / 100)
    });
  }

  const handleSubmit = () => {
    let data = new FormData();
    data.append('file', image);
    data.append('filename', 'profile.png');

    userService.uploadImage(currentUser.id, data)
      .then(() => {
        dispatch(updateImage());
        closeModal();
      });
  }

  const paintImage = () => {
    const canvas = document.createElement('canvas');
    canvas.width = avatarSettings.width;
    canvas.height = avatarSettings.height;

    const ctx = canvas.getContext('2d');
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((avatarSettings.rotate * Math.PI) / 180);
    ctx.translate(-(canvas.width / 2), -(canvas.height / 2));
    ctx.scale(avatarSettings.scale, avatarSettings.scale);

    ctx.drawImage(
      tmpImage,
      avatarSettings.position.x,
      avatarSettings.position.y,
      avatarSettings.width,
      avatarSettings.height
    );

    const reader = new FileReader();

    canvas.toBlob(blob => {
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        dataURLtoFile(reader.result, 'profile.png')
      }
    });
  }

  const dataURLtoFile = function (dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    let croppedImage = new File([u8arr], filename, {type: mime});
    setImage(croppedImage);
  }

  /*const calculateImageWidth = () => {
    let aspectRatio = (tmpImage.width / tmpImage.height);
    return (550 * aspectRatio);
  }*/

  useEffect(() => {
    if (tmpImage) {
      paintImage();
    }
  }, [tmpImage, avatarSettings]);

  useEffect(() => {
    userService.get(currentUser.id)
        .then(user => setProfileImageUrl(user.profileImage));
  }, []);

  return (
    <Modal isOpen={isModalOpen} size='md'>
      <ModalHeader>
        { isEdit &&
          <ModalHeaderButton title="Tillbaka">
            <ArrowLeft size={22} color="#FB2640" onClick={goBack}/>
          </ModalHeaderButton>
        }

        <ModalHeaderText>
          Uppdatera profilbild
        </ModalHeaderText>

        <ModalHeaderButton title="Stäng">
          <X size={22} color="#FB2640" onClick={closeModal}/>
        </ModalHeaderButton>
      </ModalHeader>

      <ModalBody style={ isEdit ? { height: 650 } : {} }>
        { !isEdit &&
          <div style={{ margin: '1rem' }}>
            <div style={{ display: 'flex', width: '100%', backgroundColor: '#FFFFFF', borderRadius: '0.4rem', marginBottom: '1rem' }}>
              <ModalButton onClick={newImage}>
                <ModalButtonContent>
                  <Upload size={20}/>
                  <div className="mt-2">Ladda upp bild</div>
                </ModalButtonContent>
              </ModalButton>
            </div>

            <div style={{ display: 'flex', width: '100%', backgroundColor: '#FFFFFF', borderRadius: '0.4rem'}}>
              <ModalButton onClick={editImage}>
                <ModalButtonContent>
                  <Edit size={20}/>
                  <div className="mt-2">Redigera bild</div>
                </ModalButtonContent>
              </ModalButton>
            </div>

            <FileInput accept="image/jpeg,image/png,image/webp" ref={fileInput} onChange={handleFileChange}/>
          </div>
        }

        { isEdit &&
          <React.Fragment>
            <PreviewWrapper>
              <Preview>
                <AvatarEditor
                  scale={parseFloat(avatarSettings.scale)}
                  width={avatarSettings.width}
                  height={avatarSettings.height}
                  position={avatarSettings.position}
                  onPositionChange={handlePositionChange}
                  rotate={parseFloat(avatarSettings.rotate)}
                  color={[29, 161, 242, 0.6]}
                  border={10}
                  borderRadius={avatarSettings.width / (100 / avatarSettings.borderRadius)}
                  image={imageUrl}
                  className="editor-canvas"
                />
              </Preview>
            </PreviewWrapper>

            <Toolbar>
              <ToolbarContent>
                <RotateWrapper title="Rotera">
                  <RefreshCw size={20} onClick={handleRotateClick}/>
                </RotateWrapper>

                <ZoomOutWrapper>
                  <ZoomOut size={20}/>
                </ZoomOutWrapper>

                <Wrapper5>
                  <Slider onAfterChange={handleSliderChange}/>
                </Wrapper5>

                <ZoomInWrapper>
                  <ZoomIn size={20}/>
                </ZoomInWrapper>

                <SaveButton type="button" onClick={handleSubmit}>Spara</SaveButton>
              </ToolbarContent>
            </Toolbar>
          </React.Fragment>
        }
      </ModalBody>
    </Modal>
  );
};

export default UserImageModal;

const FlexWrapper = styled.div`
  position: relative;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  align-items: stretch;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  text-align: center;
  color: #000000;
  font-weight: bold;
  padding: 1rem
`

const ModalHeaderText = styled.div`
  flex: 1 1 auto;
  text-align: center;
  font-weight: bold;
`

const ModalHeaderButton = styled.div`
  text-align: center;
  cursor: pointer;
`

const ModalBody = styled(FlexWrapper)`
  flex-shrink: 1;
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 600px;
  background-color: #EDF2F4;
  border-radius: 0.3rem;
`

const ModalButton = styled.button`
  background: 0 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  text-align: inherit;
  display: block !important;
  width: 100% !important;
  border: none;
 
  &:hover {
    background-color: #e5e5e5;
  }
`

const ModalButtonContent = styled.div`
  padding: 5rem;
  text-align: center;
  font-weight: bold;
  color: #FB2640
`

const FileInput = styled.input.attrs({
  type: 'file'
})`
  display: none;
`

const PreviewWrapper = styled(FlexWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: hidden;
  cursor: move;
`

const Preview = styled(FlexWrapper)`
  position: absolute;
  flex-basis: auto;
  z-index: 0;
  overflow-x: hidden;
  overflow-y: hidden;
`

const Toolbar = styled.div`
  display: flex;
  min-height: 20px;
  //align-self: stretch;
  //align-items: center;
  //justify-content: center;
  flex-direction: row;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
  background-color: rgba(255,255,255,1.00);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`

/*const Wrapper3 = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: stretch;
  justify-content: center;
  //max-width: 400px;
  height: 39px;
  flex-basis: 0px;
  flex-shrink: 1;
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
`*/

const ToolbarContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
`

const Wrapper5 = styled.div`
  //height: 20px;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
`

const Wrapper6 = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  height: 5px;
  background-color: rgb(142, 208, 249);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

const RotateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 30px;
`

const ZoomInWrapper = styled.div`
  display: flex;
  margin-left: 30px;
`

const ZoomOutWrapper = styled.div`
  display: flex;
  margin-right: 30px;
`

const SaveButton = styled.button`
  display: flex;
  margin-left: 30px;
  background-color: #FB2640;
  color: #FFFFFF;
  border: 0;
  padding: 6px 10px;
  border-radius: 0.4rem;
  font-size: 0.8rem;
`