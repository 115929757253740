import React from 'react';
import styled from "styled-components";
import {Input} from "reactstrap";

const OrderAttributes = ({ eventAttributes, onChange}) => {


    return (
        <div>
            {eventAttributes.map((attribute, i) => (
                <div key={i}>
                    {attribute.type === 'TEXT' &&
                        <>
                            <EventAttributeVertical key={i}>
                                <strong>{attribute.description}</strong>
                                <div>
                                    <Input key={attribute.id} type="text"
                                           name={`description_${i}`}
                                           placeholder="Värde"
                                           defaultValue={attribute.value}
                                           onChange={(e) => {
                                               attribute.value = e.target.value;
                                               onChange(eventAttributes)
                                           }}

                                    />
                                </div>
                            </EventAttributeVertical>
                        </>
                    }

                    {attribute.type === 'RADIO' &&
                        <>
                            <EventAttributeVertical key={i}>
                                <strong>{attribute.description}</strong>
                                {attribute.eventAttributeOptions.map((option, x) => (
                                    <div key={x}>
                                        <label>
                                            <input type="radio" name={attribute.id} value={option.id}
                                                   defaultChecked={option.id === attribute.optionId}
                                                   onChange={() => {
                                                       attribute.optionId = option.id
                                                       attribute.value = option.name
                                                       onChange(eventAttributes)
                                                   }}/> {option.name}
                                        </label>
                                    </div>))}
                            </EventAttributeVertical>
                        </>
                    }
                </div>
            ))}
        </div>
    )
};

export default OrderAttributes;

const EventAttributeVertical = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`