import {
  OPEN_CREATE_MODAL,
  CLOSE_CREATE_MODAL,
  CLEAR_CREATE_ERROR,
  CREATE_SUCCESS,
  CREATE_REQUEST,
  CREATE_ERROR,
  IMAGE_UPDATED
} from '../types/user.types';

const initialState = { isCreateModalOpen: false, imageUpdated: false };

const userReducer = (state = initialState, action) => {

  switch (action.type) {
    case OPEN_CREATE_MODAL: {
      return {
        isCreateModalOpen: true
      };
    }

    case CLOSE_CREATE_MODAL:
      return {
      isCreateModalOpen: false
    };

    case CLEAR_CREATE_ERROR: {
      return {
        isCreateModalOpen: true,
      };
    }

    case CREATE_REQUEST: {
      return {
        isCreateModalOpen: true
      };
    }

    case CREATE_SUCCESS: {
      return state;
    }

    case CREATE_ERROR: {
      return {
        isCreateModalOpen: true,
        createError: action.error
      };
    }

    case IMAGE_UPDATED: {
      return {
        ...state,
        imageUpdated: true
      };
    }

    default:
      return state;
  }
};

export default userReducer;