import client from '../../helpers/client';
import axios from "axios";

const baseUrl = '/campaigns';

function findAll() {
  return client.getClient()
    .get(baseUrl)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });
}

function get(campaignId) {
  return client.getClient()
    .get(baseUrl + `/${campaignId}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject({
          message: errorMessage,
          status: err.response.status
        });
      }
    });
}

function findByTeamMember(user, status = null) {
  status = (status !== null ? '?status=' + status : '');

  return client.getClient()
    .get(baseUrl + status)
    .then(response => {

      //if (user.hasRole('Admin', 'TeamLeader')) {
        return Promise.resolve(response.data ? response.data : []);
      //}

      /*
      let campaigns = response.data.filter(campaign => {
        if (campaign.active === 'Active') {
          let teamWithUser = campaign.campaignTeams
            .find(team => team.teamMembers
              .find(teamMember => teamMember.userId === user.id));

          return teamWithUser !== undefined;
        }

        return false;
      });

      return Promise.resolve(campaigns ? campaigns : []);
      */
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });
}

function getCampaignTotal(companyId, teamId) {

  const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  let url = baseUrl + `/orders/sum?companyId=${companyId}`

  if (teamId) {
    url += `&teamId=${teamId}`
  }

  return http
    .get(url)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        const errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject({
          message: errorMessage,
          status: err.response.status
        });
      }
    });
}

function getCampaignTeamTotal (companyId, teamId){
  let url = `/campaigns/orders/sum/teams?companyId=${companyId}&teamId=${teamId}`

  const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  return http
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function getParentCampaignTotal(companyId) {
  const url = `${baseUrl}/total?companyId=${companyId}`
  
  return client.getClient()
    .get(url)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      console.log(err)
      if (err.response) {
        const errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject({
          message: errorMessage,
          status: err.response.status
        });
      }
    });
}

function getTotal(companyId, teamId) {

  const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  let url = `${baseUrl}/_total?companyId=${companyId}`

  if (teamId) {
    url += `&teamId=${teamId}`
  }

  return http
    .get(url)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      console.log(err)
      if (err.response) {
        const errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject({
          message: errorMessage,
          status: err.response.status
        });
      }
    });
}

export function create(data = {}) {
  return client.getClient()
    .post(baseUrl, data)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}



export function update(userId, data = {}) {
  const url = `${baseUrl}/${userId}`;

  return client.getClient()
    .put(url, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export const CampaignApi = {
  get,
  getCampaignTotal,
  getCampaignTeamTotal,
  getParentCampaignTotal,
  getTotal,
  create,
  update,
}