import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import {
  DateTimePicker,
} from "react-tempusdominus-bootstrap";

import {closeShoutoutModal, openShoutoutModal} from "../../actions/shoutout.actions";
import {shoutoutService} from "../../services/shoutout.service";
import {audioService} from "../../services/audio.service";

import Select from "react-select";

const ShoutoutModal = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(state => state.authentication.user);
  const isModalOpen = useSelector(state => state.shoutout.isModalOpen);

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [endDate, setEndDate] = useState(null);

  const [audioOptions, setAudioOptions] = useState([]);
  const [selectedAudioOption, setSelectedAudioOption] = useState(null);

  const toggleModal = () => {
    if (isModalOpen) {
      dispatch(closeShoutoutModal())
    } else {
      dispatch(openShoutoutModal());
    }
  };

  const isFormDisabled = () => {
    return title.length === 0 ||
      text.length === 0 ||
      endDate === null;
  };

  /**
   * @typedef {Object} DateEvent
   * @property {Date | Moment | null} date
   * @property {Date} oldDate
   * @property {boolean} isClear
   * @property {boolean} isInvalid
   * @param e {DateEvent}
   */
  const handleExpirationDateChange = (e) => {
    setEndDate(e.date);
  };


  const handleAudioChange = (e) => {
    let audioOption = audioOptions.find(o => o.value === e.value);
    setSelectedAudioOption(audioOption);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    shoutoutService.create({
      title: title,
      text: text,
      audioUniqueId: selectedAudioOption.value,
      endDate: endDate.format('YYYY-MM-DD HH:mm:ss'),
      userId: currentUser.userId,
    })
    .then(() => dispatch(closeShoutoutModal()));
  };

  useEffect(() => {
    audioService.findAll()
      .then(audioList => {

        let audioItems = [];

        audioList.forEach(audio => {
          let audioItem = {
            value: audio.unique_id,
            label: audio.name
          };
          audioItems.push(audioItem);
        });

        audioItems.sort((a, b) => b.name > a.name ? 1 : -1);

        let noSelectionOption = {
          value: null,
          label: 'Ingen ljudeffekt'
        };
        audioItems.unshift(noSelectionOption);
        setAudioOptions(audioItems);
        setSelectedAudioOption(audioItems.find(a => a.value === null));
      });
  }, []);

  return (
    <div id="shoutout-modal">
      <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>Skicka meddelande</ModalHeader>

          <ModalBody>
            <FormGroup>
              <Label>Rubrik</Label>
              <Input type="text" placeholder="Rubrik" onChange={(e) => setTitle(e.target.value)}/>
            </FormGroup>

            <FormGroup>
              <Label>Meddelande</Label>
              <Input type="textarea" placeholder="Meddelande" onChange={(e) => setText(e.target.value)} style={{minHeight: '120px', resize: 'none'}}/>
            </FormGroup>

            { audioOptions && audioOptions.length > 0 &&
              <FormGroup>
                <Label className="text-muted">Ljudeffekt</Label>

                <Select
                  value={selectedAudioOption ? selectedAudioOption : null}
                  defaultValue={selectedAudioOption}
                  options={audioOptions}
                  onChange={handleAudioChange}
                />
              </FormGroup>
            }

            <FormGroup>
              <Label>Slutdatum</Label>

              <DateTimePicker
                date={endDate}
                onChange={handleExpirationDateChange}
                locale="sv-SE"
              />
            </FormGroup>
          </ModalBody>

          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Avbryt</Button>
            <Button type="submit" color="primary" disabled={isFormDisabled()}>Skicka</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default ShoutoutModal;