import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

/**
 * @param {Props} props The props for the component.
 * @param {string} props.title The title of the page.
 * @param {NextRouter} props.match The match object for the current route.
 * @param {string} props.href The href of the current route.
 * @param {string} props.pageName The name of the current page.
 * @param {boolean} props.enableBreadCrumb Whether to show the breadcrumb.
 * @param {{ name: string, href: string }[]} props.breadcrumbs The breadcrumb items.
 */
export const BreadCrumb = ({
  title,
  match,
  href,
  pageName,
  breadcrumbs,
  enable = true,
}) => {
  return (
    <div className="d-flex flex-column gap-2">
      {enable && (
        <div className="d-flex gap-2 px-3">
          {breadcrumbs.map((breadcrumb, i) => (
            <div key={i} className="d-flex gap-2">
              <Link
                to={breadcrumb.href}
                className="text-dark font-semibold hover:underline"
              >
                {breadcrumb.name}
              </Link>

              <div>{'/'}</div>
            </div>
          ))}

          <strong className="text-muted">{title}</strong>
        </div>
      )}

      <h1 className="text-md px-3 m-0">{title}</h1>
    </div>
  )
}
