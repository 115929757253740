import React, {useEffect, useState} from 'react';
import {PlusCircle} from 'react-feather';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {addCampaignEvent, removeCampaignEvent, setCampaignEvents} from '../../actions/campaign.actions';
import {campaignService} from '../../services/campaign.service';
import {EventApi} from '../../api/Event/Event.api';
import {EventAttributesApi} from "../../api/EventAttribute";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const CampaignEvent = () => {
  const dispatch = useDispatch();

  const campaignInfo = useSelector(state => state.campaign.campaignInfo);
  const selectedEvents = useSelector(state => state.campaign.campaignEvents);
  
  const [checkedEvents, setCheckedEvents] = useState([]);
  const [isAllChecked, setAllChecked] = useState(false);

  const { data: events } = useQuery(['events'], () => EventApi.findAll(), {
    initialData: [], 
    refetchOnWindowFocus: false
   });

  /* const { data: campaignEvents } = useQuery(['campaignEvents', campaignInfo.id], () => campaignService.findEvents(campaignInfo.id), {
    initialData: [],
    refetchOnWindowFocus: false,
  }); */
  
  const selectAllEvents = () => {
    if (isAllChecked) {
      dispatch(setCampaignEvents([]));
      setCheckedEvents([])
    } else {
      let campaignEvents = [];

      events.forEach(event => {
        let campaignEvent = {
          id: null,
          eventId: event.id,
          eventName: event.name
        };

        let existing = campaignEvents.find(campaignEvent => campaignEvent.teamId === event.id);

        if (existing) {
          campaignEvent.id = existing.id
        }

        campaignEvents.push(campaignEvent);

        if (checkedEvents.indexOf(event.id) === -1) {
          checkedEvents.push(event.id);
        }
      });

      setCheckedEvents(checkedEvents)
      dispatch(setCampaignEvents(campaignEvents));
    }

    setAllChecked(!isAllChecked);
  }

  // TODO: potential bug where event is not removed when clicking close to checkbox..
  const handleSelectEvent = (event) => {
    let isEventSelected = selectedEvents.some(campaignEvent => campaignEvent.eventId === event.id);

    if (!isEventSelected) {
      let existing = selectedEvents.find(campaignEvent => campaignEvent.eventId === event.id);

      dispatch(addCampaignEvent({
        id: (existing) ? existing.id : null,
        eventId: event.id,
        eventName: event.name,
      }));
    } else {
      dispatch(removeCampaignEvent(event.id));
    }
  }

  const defaultEvent = {id: null, active: 1, name: '', unit: '', triggersEventMessage: true};
  let [event, setEvent] = useState(null);

  const toggleEventModal = (eventToOpen) => {
    if (event) {
      setEvent(null);
    } else {
      if (eventToOpen) {
        setEvent(eventToOpen);
      } else {
        setEvent({...defaultEvent});
      }
    }
  }

  const onNameChange = (e) => {
    let newEvent = {...event};
    newEvent.name = e.target.value;
    setEvent(newEvent);
  }

  const onUnitChange = (e) => {
    let newEvent = {...event};
    newEvent.unit = e.target.value;
    setEvent(newEvent);
  }

  const onFixedValueCheck = (e, event) => {
    let newEvent = {...event};
    newEvent.useFixedValue = e.target.checked;
    newEvent.fixedValue = e.target.checked ? '' : null;
    setEvent(newEvent);
  }

  const onFixedValueChange = (e, event) => {
    let newEvent = {...event};
    newEvent.fixedValue = e.target.value;
    setEvent(newEvent);
  }

  const onEventMessageTriggerChange = (e, event) => {
    let newEvent = {...event};
    newEvent.triggersEventMessage = e.target.checked;
    setEvent(newEvent);
  }

  useEffect(() => {
    const checkedEvents = [];

      if (events) {
        events.forEach(event => {
          let campaignEvent = selectedEvents.find(campaignEvent => campaignEvent.eventId === event.id);

          if (campaignEvent) {
            checkedEvents.push(campaignEvent.eventId);
          }
        });

        setAllChecked(events.length === selectedEvents.length);
      }

      setCheckedEvents(checkedEvents)
  }, [events, selectedEvents]);

  return (
    <div id="campaign-event-container" className="tab-pane active">
      <p>
        <strong>Välj vilka aktiviteter som ska vara med i kampanjen.</strong>
      </p>

      <div id="event-list" className="table-responsive">
        <table className="table table-theme table-row v-middle">
          <thead>
          <tr>
            <th style={{width: '20px'}}>
              <Label check className="ui-check m-0">
                <Input type="checkbox" checked={isAllChecked} onChange={selectAllEvents}/>{' '}
                <i/>
              </Label>
            </th>
            <th className="text-muted" style={{width: '50px'}}></th>
            <th className="text-muted sortable" data-toggle-class="asc">Aktivitet</th>
            <th style={{width: '50px'}}></th>
          </tr>
          </thead>
          <tbody>

          {
            events.map((event, index) => {
              return (
                <tr className=" v-middle t-r" data-id="15" key={index}>

                  <td>
                    <Label check className="ui-check m-0">
                      <Input type="checkbox"
                             checked={checkedEvents.indexOf(event.id) > -1}
                             onChange={() => handleSelectEvent(event)} />{' '}
                      <i />
                    </Label>
                  </td>

                  <td>
                    <a href="#">
                      <span className="w-40 avatar gd-warning" title={event.name}>
                        {event.name.substring(0, 1).toUpperCase()}
                      </span>
                    </a>
                  </td>

                  <td className="flex">
                    <button type="button" className="btn btn-primary-outline item-title text-primary" onClick={() => toggleEventModal(event)}
                       title={event.name}>{event.name}
                    </button>
                  </td>

                  <td>
                    <span className="item-amount d-none d-sm-block text-sm "> </span>
                  </td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>

      <div className="btn btn-md btn-block btn-white pointer">
        <PlusCircle size={16}/>
        <span className="d-none d-sm-inline mx-1" onClick={() => toggleEventModal(event)}>Skapa ny aktivitet</span>
      </div>

      <EventModal
        event={event}
        toggle={toggleEventModal}
        onNameChange={onNameChange}
        onUnitChange={onUnitChange}
        onFixedValueCheck={onFixedValueCheck}
        onFixedValueChange={onFixedValueChange}
        onEventMessageTriggerChange={onEventMessageTriggerChange}
      />
    </div>
  );
};

export default CampaignEvent;

/**
 * @typedef {Object} EventModalProps
 * @property {Event} event
 * @property {function} toggle
 * @property {function} onNameChange
 * @property {function} onUnitChange
 * @property {function} onFixedValueCheck
 * @property {function} onFixedValueChange
 * @property {function} onEventMessageTriggerChange
 * @param {EventModalProps} props 
 */
const EventModal = ({
  toggle,
  onNameChange,
  onUnitChange,
  onFixedValueCheck,
  onFixedValueChange,
  onEventMessageTriggerChange,
  event,
}) => {
  const queryClient = useQueryClient();

  const { data: eventAttributes } = useQuery(['eventAttributes'], () => EventAttributesApi.findAll(), { 
    enabled: event?.hasAttribute,
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: saveEvent } = useMutation(['updateTeam'], (event) => event.id ?
    EventApi.update(event.id, event) : 
    EventApi.create(event), 
  {
    onSuccess: (res) => {
      queryClient.invalidateQueries(['events'])
      toggle();
    }
  });

  /**
   * @param {React.FormEvent<HTMLFormElement>} e 
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    saveEvent(event);
  }

  if (!event) {
    return null
  }

  return (
    <Modal isOpen={event !== null} toggle={toggle} size="lg">
      <Form onSubmit={handleSubmit}>
        <ModalHeader toggle={toggle}>
          {event.id && event.id.length > 0 ? 'Redigera ' + event.name : 'Ny aktivitet'}
        </ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label>Namn</Label>

            <input type="text"
                   className="form-control"
                   name="event-name"
                   placeholder="Namn"
                   required
                   defaultValue={event.name}
                   onChange={onNameChange}
            />
          </FormGroup>

          <FormGroup>
            <Label>Enhet</Label>

            <input type="text"
                   className="form-control"
                   name="event-unit"
                   placeholder="Enhet"
                   required
                   defaultValue={event.unit}
                   onChange={onUnitChange}
            />
          </FormGroup>

          <FormGroup>
            <div className="checkbox">
              <label className="ui-check">
                <input type="checkbox"
                       defaultChecked={event.useFixedValue}
                       onChange={(e) => onFixedValueCheck(e, event)}
                />
                <i className="mr-2"/>
                Fast värde
              </label>
            </div>
          </FormGroup>

          {
            event.useFixedValue &&
            <FormGroup>
              <Input type="number"
                     placeholder="Fast värde"
                     required
                     defaultValue={event.fixedValue}
                     onChange={(e) => onFixedValueChange(e, event)}
              />
            </FormGroup>
          }

          <FormGroup>
            <div className="checkbox">
              <label className="ui-check">
                <input type="checkbox"
                       defaultChecked={event.triggersEventMessage}
                       onChange={(e) => onEventMessageTriggerChange(e, event)}
                />
                <i className="mr-2"/>
                Visa i aktivitetslistan
              </label>
            </div>
          </FormGroup>


          {event.hasAttribute && (
            <React.Fragment>
              <FormGroup>
                Kopplat attribut
              </FormGroup>
              {eventAttributes.map((attribute, i) => <strong>{attribute.description}</strong>)}
            </React.Fragment>
          )}
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Avbryt</Button>
          <Button type="submit" color="primary" disabled={event.name?.length === 0}>Spara</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
