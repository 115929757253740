import styled from 'styled-components'

export const ResultValue = styled.div`
  color: var(--green);
  font-size: 45px;
  font-weight: 700;
`

export const ProgressWrapper = styled.div`
  margin-top: 0.5rem;
  font-weight: 700;
  font-size: 45px;
  
  .progress-bar {
    font-size: 0.65625rem;
    transition: width 2s ease;
  }
`

export const ProgressValue = styled.div`
  margin-left: 0.5rem;
  font-size: 36px;
`

export const BestDailyOrder = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`
