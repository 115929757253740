'use strict';

const moment = require('moment');
const dateFormat = 'YYYY-MM-DD';
const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

/**
 * Returns a {key: value} object where the key is a start date and the value is the date + 1 of the type of interval
 * to the start date. When for weeks or months, it shows just the first date of the week/month.
 *
 ** For days (start: '2017-12-25', end: '2018-01-02', interval: 'day'):
 { '2017-12-25': '2017-12-26',
  '2017-12-26': '2017-12-27',
  '2017-12-27': '2017-12-28',
  '2017-12-28': '2017-12-29',
  '2017-12-29': '2017-12-30',
  '2017-12-30': '2017-12-31',
  '2017-12-31': '2018-01-01',
  '2018-01-01': '2018-01-02' }
 *
 ** For weeks (start: '2017-12-18', end: '2018-02-18', interval: 'week'):
 { '2017-12-18': '2017-12-25',
  '2017-12-25': '2018-01-01',
  '2018-01-01': '2018-01-08',
  '2018-01-08': '2018-01-15',
  '2018-01-15': '2018-01-22',
  '2018-01-22': '2018-01-29',
  '2018-01-29': '2018-02-05',
  '2018-02-05': '2018-02-12' }
 *
 ** For months (start: '2017-12-01', end: '2018-08-31', interval: 'month'):
 { '2017-12-01': '2018-01-01',
  '2018-01-01': '2018-02-01',
  '2018-02-01': '2018-03-01',
  '2018-03-01': '2018-04-01',
  '2018-04-01': '2018-05-01',
  '2018-05-01': '2018-06-01',
  '2018-06-01': '2018-07-01',
  '2018-07-01': '2018-08-01' }
 *
 * @param {string} start
 * @param {string} end
 * @param {string} interval
 * @return {{}}
 */
const DateInterval = (start, end, interval) => {
  /*const intervals = {};
  const diffUnitOfTime = `${interval}s`;

  while (moment(end).diff(start, diffUnitOfTime) > 0) {
    const currentEnd = moment(moment(start).add(1, diffUnitOfTime)).format('YYYY-MM-DD');

    Object.assign(intervals, { [start]: currentEnd });

    start = currentEnd;
  }

  return intervals;*/

  let now = start.clone();
  let dates = [];

  while (now.isSameOrBefore(end)) {
    dates.push(now.format('YYYY-MM-DD'));
    now.add(1, 'days');
  }

  return dates;
};

export {
  dateFormat,
  dateTimeFormat,
  DateInterval
};