import {
  SET_TEAM_LIST,
  SET_TEAM,
} from '../../types/bonti/bonti.types'

const initialState = {
  teams: []
}

const bontiReducer = (state = initialState, action) => {

  switch (action.type) {

    case SET_TEAM_LIST:
      state.teams = [...action.teams]
      return state

    case SET_TEAM:
      const { team } = action
      const teams = [...state.teams]

      const existing = teams.find(t => t.id === team.id)

      if (!existing) {
        teams.push(team)
      }

      /*teams.forEach(t => {
        if (t.id === team.id) {
          if (team.orderTotal) {
            t.orderTotal = team.orderTotal
          }
        }
      })*/

      state.teams = teams

      return state

    default:
      return state
  }
};

export default bontiReducer
