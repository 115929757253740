import client from '../../helpers/client';

const baseUrl = '/dashboards';

function findAll(companyId) {

  let url = `${baseUrl}`;
  if (companyId)
    url += "?companyId=" + companyId;

  return client.getClient()
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });
}

function findComponents() {

  let url = `${baseUrl}/components`;

  return client.getClient()
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });

}

export function create(data = {}) {
  return client.getClient()
    .post(baseUrl, data)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

function deleteDashboard(dashboardId) {
  let url = baseUrl + '/' + dashboardId;

  return client.getClient()
    .delete(url)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function update(dashboardId, data) {
  let url = baseUrl + '/' + dashboardId;

  return client.getClient()
    .patch(url, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

function getData(data) {
  let url = `${baseUrl}/components/data`;

  return client.getClient()
    .post(url, data)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });
}

export const DashboardApi = {
  findAll,
  findComponents,
  getData,
  create,
  update,
  delete: deleteDashboard,
}