import axios from 'axios';

const baseUrl = '/bonti';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

export function findOrdersSum(companyId, teamId){

  let teamIdUrl = '';
  if(typeof teamId !== 'undefined'){
    teamIdUrl = `&teamId=${teamId}`
  }

  let url = `/campaigns/orders/sum?companyId=${companyId}${teamIdUrl}`

  return http
      .get(url)
      .then(response => {
        return Promise.resolve(response.data ? response.data : []);
      })
      .catch(err => {
        if (err.response) {
          let errorMessage = (err.response.data) || err.response.statusText;
          return Promise.reject(errorMessage);
        }
      });
}

export function findTeamOrderSum(companyId, teamId){
  let url = `/campaigns/orders/sum/teams?companyId=${companyId}&teamId=${teamId}`

  return http
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function findOrders(teamId) {

  let url = `${baseUrl}/orders`

  if (teamId) {
    url += `?teamId=${teamId}`
  }

  return http
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function findEcommerceOrders() {
  return http
    .get(`${baseUrl}/orders/ecommerce`)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export const findTeams = () => {
  return http
    .get(`${baseUrl}/teams`)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export const findTeamById = (id) => {
  return http
    .get(`${baseUrl}/teams/${id}`)
    .then(response => {
      return Promise.resolve(response.data ? response.data : [])
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    })
}

export const getOrderTotal = (teamId) => {
  let url = `${baseUrl}/orders/total`;

  if (teamId) {
    url += `?teamId=${teamId}`
  }

  return http
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : null);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export const getEcommerceOrderTotal = () => {
  return http
    .get(`${baseUrl}/orders/ecommerce/total`)
    .then(response => {
      return Promise.resolve(response.data ? response.data : null);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

function findEcommerceOrderArticles(count) {
  let url = `${baseUrl}/orders/articles`

  if (count) {
    url += '?count=true'
  }

  return http
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function getGoal(teamId) {
  let url = `${baseUrl}/goal`

  if (teamId) {
    url += `?teamId=${teamId}`
  }

  return http
    .get(url)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export const getRsCounts = (companyId, start, end) => {
  const url = `/rscount/${companyId}?from=${start}&to=${end}`

  const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  });

  return http
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : 0)
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = err.response.data || err.response.statusText

        return Promise.reject(errorMessage)
      }
    })
}

export function getYesterdaysCustomers() {
  const url = "/rscount/counts/yesterday"

  const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  return http
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : 0)
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = err.response.data || err.response.statusText

        return Promise.reject(errorMessage)
      }
    })
}

export function getYesterdaysOrders() {
  const url = baseUrl + "/yesterday/orders"

  const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  return http
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : 0)
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = err.response.data || err.response.statusText

        return Promise.reject(errorMessage)
      }
    })
}

export const BontiApi = {
  findOrdersSum,
  findTeamOrderSum,
  findOrders,
  findEcommerceOrders,
  findTeams,
  findTeamById,
  getOrderTotal,
  getEcommerceOrderTotal,
  getGoal,
  getRsCounts,
  getYesterdaysCustomers,
  getYesterdaysOrders,
  findEcommerceOrderArticles
}