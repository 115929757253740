import React from 'react';
import {ChevronUp, ChevronDown} from 'react-feather';
import styled from 'styled-components'

const TableHeader = (props) => {

  const {
    columnName,
    columnText,
    sortColumn,
    sortDirection,
    onClick
  } = props

  return (
    <TH onClick={onClick}>{columnText}
      {sortColumn === columnName && sortDirection === 'asc' && <ChevronUp size={16}/>}
      {sortColumn === columnName && sortDirection === 'desc' && <ChevronDown size={16}/>}
    </TH>
  )
}

export {TableHeader}

const TH = styled.th`
  color: #99a0ac;
  cursor: pointer;
`