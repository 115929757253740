import {Col, Card, Progress, Row, Collapse} from 'reactstrap'
import {Strong} from '../Shared/Style'
import React, {useEffect, useState} from 'react'
import {Wrapper} from './Dashboard.style'
import {CardBody} from '../../components/Shared/Card'
import CountUp from "react-countup";
import {ResultValue} from "../Bonti/Bonti.style";
import {ChevronDown, ChevronUp} from "react-feather";
import SortableComponent from "./SortableComponent.component";
import Scrollbar from "react-scrollbars-custom";
import {Scrollbars} from "react-custom-scrollbars-2";
import DashboardCollapse from "./DashboardCollapse.component";

const CampaignBestSellQuantity = ({ articles, name, component, isEditing }) => {
    const [isCollapsed, setCollapsed] = useState(true)

    const toggle = () => setCollapsed(!isCollapsed);


    const getTrophy = (position) => {

        switch(position) {
            case 1:
                return '/assets/img/trophy/T1.png'
            case 2:
                return '/assets/img/trophy/T12.png'
            case 3:
                return '/assets/img/trophy/T4.png'
        }

        return ''
    }

    return (
        <Wrapper>
            <DashboardCollapse component={component}>
                <Scrollbars style={{height: 540, scrollWidth: 5}}>
                <div className="cards px-4">
                    <div className='d-flex justify-content-between w-100'>
                        <div className="align-self-center"></div>
                    </div>
                        {/*<Row >*/}
                        {/*    <Col lg={12}>*/}
                        {/*        <Strong style={{ fontSize: 30 }} className="mb-2">Topp 10 lista bästsäljare</Strong>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        <table className="table table-theme v-middle table-hover">
                            <tbody>
                            {
                                articles && articles
                                    .sort((a, b) => b.articleValue - a.articleValue)
                                    .map((article, i) =>
                                        <tr className="v-middle" key={i} style={{ fontSize: 20 }}>
                                            <td>
                                                <div className="avatar-group">
                                                    {i < 3 &&
                                                        <div title={'#' + (i + 1)}>
                                                <span className="w-32 avatar no-shadow">
                                                  <img src={getTrophy(i + 1)} alt="."/>
                                                </span>
                                                        </div>
                                                    }

                                                    {i > 2 &&
                                                        <div className="avatar ajax w-32 bg-white no-shadow" title={'#' + (i + 1)}>
                                                            <small className="text-muted font-weight-bold">#{i + 1}</small>
                                                        </div>
                                                    }
                                                </div>
                                            </td>

                                            <td className="flex">
                                                <div className="item-title text-color">{article.articleName}</div>
                                            </td>

                                            <td>
                                                <Strong style={{ fontSize: 15, width: 100 }}>
                                                    <CountUp start={0} end={article.articleValue} duration={2} decimal="0" separator={' '}/> kr
                                                </Strong>
                                            </td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </table>

                </div>
                </Scrollbars>
            </DashboardCollapse>
        </Wrapper>
    )
}

export default CampaignBestSellQuantity