import {
  FIND_BY_USER_ID,
  FIND_BY_USER_ID_SUCCESS,
  FIND_BY_USER_ID_ERROR,
  SET_STATISTICS
} from "../types/statistics.types";

import {statisticsService} from '../services/statistics.service';

export function findByUserId(userId) {
  const request = () => ({ type: FIND_BY_USER_ID });
  const success = (statistics) => ({ type: FIND_BY_USER_ID_SUCCESS, statistics });
  const error = (error) => ({ type: FIND_BY_USER_ID_ERROR, error });

  return (dispatch) => {
    dispatch(request());
    statisticsService.findByUser(userId)
      .then(
        async (statistics) => {
          dispatch(success(statistics));
        },
        async (err) => {
          dispatch(error(err.toString()));
        }
      );
  };
}

export function setStatistics(statistics) {
  return (dispatch) => dispatch({ type: SET_STATISTICS, statistics });
}