import React, {useState} from 'react';
import {CardBody} from '../Shared/Card';
import {ChevronDown, ChevronUp} from 'react-feather';
import {Collapse} from 'reactstrap';
import {DashboardCard} from './Dashboard.style';
import styled from 'styled-components';
import {Strong} from '../Shared/Style';
import {Wrapper} from './Dashboard.style'

const DashboardCollapse = ({ component, children}) => {
  const [isCollapsed, setCollapsed] = useState(true)

  const toggle = () => setCollapsed(!isCollapsed);

  if (!component) return null

  return (
  <Wrapper>
    <DashboardCard>
      <CardBody>
        <div className="cards">
          <div className='d-flex justify-content-between w-100'>
            <div>
              {component.category && <div className="text-muted">{component.category}</div>}
              <div className="align-self-center">
                <Strong>
                  {component.name}
                </Strong>
              </div>
            </div>

            <CollapseButton className="btn" onClick={toggle}>
              {isCollapsed ? <ChevronDown/> : <ChevronUp/>}
            </CollapseButton>
          </div>
          <Collapse className="mt-2" isOpen={isCollapsed}>{children}</Collapse>
        </div>
      </CardBody>
    </DashboardCard>
  </Wrapper>
  )
}

export default DashboardCollapse

const CollapseButton = styled.button`
    padding: 0 !important;
    &:hover {
        background: transparent !important;
        border: 0;
        box-shadow: none;
    }
`
