import React, {useEffect, useState} from 'react'
import {Container, FlexWrapper, SubHeader, Wrapper} from './pages.style'
import {useDispatch, useSelector} from "react-redux"
import CampaignTotalSum from '../components/Dashboard/CampaignTotalSum.component'
import CampaignTotal from '../components/Dashboard/CampaignTotalSum.component'
import CampaignTotalBar from '../components/Dashboard/CampaignTotalBar.component'
import {campaignService} from '../services/campaign.service'
import {setCampaignList} from '../actions/campaign.actions'
import {history, setDocumentTitle} from '../helpers'
import {CampaignApi} from '../api/Campaign/Campaign.api'
import CampaignTotalLineGraph from "../components/Dashboard/CampaignTotalLineGraph.component";
import BestTeamMember from "../components/Dashboard/BestTeamMember.component";
import CampaignDailySales from "../components/Dashboard/CampaignDailySales.component";
import styled from 'styled-components';
import CampaignBestSellSum from "../components/Dashboard/CampaignBestSellSum.component";
import CampaignBestSellQuantity from "../components/Dashboard/CampaignBestSellQuantity.component";
import CampaignMonthlyTeam from "../components/Dashboard/CampaignMonthlyTeam.component";
import CampaignDoughnutGraph from "../components/Dashboard/CampaignDoughnutGraph";
import {BontiApi} from "../api/Bonti/Bonti.api";
import CampaignRSCountData from "../components/Dashboard/CampaignRSCountData";
import {DashboardApi} from "../api/Dashboard/Dashboard.api";
import {FormGroup, Label, Collapse, CardBody, Card, Button} from "reactstrap";
import Select from "react-select";
import {UserInfoSchema} from "../api/User";
import {UpdateImageButton} from "../components/Settings/Settings.style";
import {openImageModal} from "../actions/profile.actions";
import {Field, Form, Formik} from "formik";
import {DashboardSchema} from "../api/Dashboard/Dashboard.schema";
import {FieldError} from "../components/Shared/Style";
import {
  getDashboards, setColumnSize,
  setSelectedComponents,
  setSortOrder
} from "../actions/dashboard.actions";
import {ChevronUp, ChevronDown, MoreVertical} from 'react-feather';
import RGL, {Responsive, WidthProvider} from "react-grid-layout";
import DashboardGrid from "../components/Dashboard/DashboardGrid.component";


const NewDashboard = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.authentication.user)
  const selectedComponents = useSelector(state => state.dashboard.selectedComponents)
  const [components, setComponents] = useState([])
  const [allComponents, setAllComponents] = useState([])

  useEffect(() => {

    if (selectedComponents) {
      dispatch(setSelectedComponents([]))
    }

    DashboardApi.findComponents().then(function (components) {
      const allComponents = components.map((component) => ({
        label: component.text,
        value: component.value,
        sortOrder: 0,
        x: 0,
        y: 0
      }))
      setAllComponents(allComponents)
      setComponents(allComponents)
    }).catch(err => {
      console.log(err);
    });
  }, [])

  const componentValueSort = (a, b) => {
    if (a.label > b.label) {
      return 1
    }

    if (a.label < b.label) {
      return -1
    }
    return 0
  }

  const handleComponentChange = (addedComponents,  {action, removedValues}) => {
    if (action === 'clear') {
      dispatch(setSelectedComponents(addedComponents))
      return
    }

    const newComponents = components.filter(component => !addedComponents.some(c => c.value === component.value))
    setComponents(newComponents)

    addedComponents.forEach((item, i) => {
      item.sortOrder = i + 1
      if (!item.size) {
        item.size = item.size ?? 12
      }

      item.x = item.x ?? 0;
      item.y = item.y ?? 0;
      item.h = item.h ?? 4;

      const previous = addedComponents[i -1]

      if (previous) {
        item.y = Math.ceil(previous.y + previous.h)

      }
    })

    dispatch(setSelectedComponents(addedComponents))
  }



  const handleSubmit = (values) => {

    const settings = [];
    selectedComponents.forEach(component => {
      settings.push({
        component: component.value,
        order: component.sortOrder,
        size: component.size,
        x: component.x,
        y: component.y

      })
    })

    const data = {
      name: values.name,
      settings
    }

    DashboardApi.create(data).then(dashboard => {
      dispatch(getDashboards(currentUser.companyId))

      if (dashboard) {
        history.push("/dashboard" + dashboard.path)
      }
    })
  }

  return (
    <Container>
      <FlexWrapper className="flex-column">
        <h1 style={{fontSize: 36}}>Skapa Dashboard</h1>
        <SubHeader>
          Välj vilken data du vill sammanställa i din dashboard genom att välja moduler i listan nedan.
        </SubHeader>
      </FlexWrapper>

      <div style={{ marginLeft: '2rem', marginRight: '2rem' }}>
        <Formik
          initialValues={{
            name: '',
          }}
          validationSchema={DashboardSchema}
          onSubmit={handleSubmit}>
          {({errors, touched, isValid}) => (
            <Form>
              <div className="row">
                <FormGroup className="col-xs-12 col-md-3" >
                  <Label>Namn</Label>
                  <div className="mb-2">
                    <Field name="name"
                           className={`form-control${errors.name && touched.name ? ' has-error' : ''}`}
                           placeholder="Namn" style={{ color: '#495057 !important' }}/>
                    {errors.name && touched.name &&
                      <FieldError>{errors.name}</FieldError>
                    }
                  </div>
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                  <div className="d-flex flex-column mb-2">
                    <Label className="text-muted">Moduler</Label>
                    <Select                                          
                      isMulti
                      options={allComponents.sort(componentValueSort)}
                      value={selectedComponents}
                      onChange={handleComponentChange}
                    />
                  </div>
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-4">
                  <button className="btn btn-primary py-2" type="submit" style={{marginTop: '1.8rem'}}>
                    Spara
                  </button>
                </FormGroup>
              </div>
            </Form>
          )}
        </Formik>

        <div className="d-flex flex-column w-100" style={{
          margin: '-0.5rem'
        }}>
          <GridContainer>
            <DashboardGrid />
          </GridContainer>
        </div>
      </div>
    </Container>
  )
}

export default NewDashboard

const DashboardDiv = styled.div`

  @media (min-width: 2500px) {
    width: 90%
  }
  @media (min-width: 3300px) {
    width: 66%
  }
`


const GridContainer = styled.div`
  width: 100%; 
  position: relative;
  
  .react-resizable-handle-e {
    position: absolute;      
    cursor: ew-resize;
    right: 0px;
    bottom: 0px;
    width: 20px;
    height: 100%;
  }
  
`