export const FIND_ALL_REQUEST = 'USER:FIND_ALL_REQUEST';
export const FIND_ALL_SUCCESS = 'USER:FIND_ALL_SUCCESS';
export const FIND_ALL_ERROR = 'USER:FIND_ALL_ERROR';

export const OPEN_CREATE_MODAL = 'USER:OPEN_CREATE_MODAL';
export const CLOSE_CREATE_MODAL = 'USER:CLOSE_CREATE_MODAL';
export const CLEAR_CREATE_ERROR = 'USER:CLEAR_CREATE_ERROR';

export const CREATE_REQUEST = 'USER:CREATE_REQUEST';
export const CREATE_SUCCESS = 'USER:CREATE_SUCCESS';
export const CREATE_ERROR = 'USER:CREATE_ERROR';

export const SET_USER_LIST = 'USER:SET_USER_LIST';

export const GET_THUMB = "USER:GET_IMAGE";
export const IMAGE_UPDATED = "USER:IMAGE_UPDATED";