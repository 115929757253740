import React, {useEffect, useState} from 'react'
import {Doughnut} from "react-chartjs-2";
import styled from "styled-components";
import {Card} from "../Shared/Card";
import {CardBody, Collapse} from "reactstrap";
import {Strong} from "../Shared/Style";
import {DashboardCard} from "./Dashboard.style";
import {ChevronDown, ChevronUp} from "react-feather";
import SortableComponent from "./SortableComponent.component";
import {Scrollbars} from "react-custom-scrollbars-2";
import DashboardCollapse from "./DashboardCollapse.component";
import {Wrapper} from './Dashboard.style'

const CampaignDoughnutGraph = ({teams, orders, name, component, isEditing}) => {

    const [teamNames, setTeamNames] = useState([])
    const [doughnutData, setDoughnutData] = useState([])
    const [isCollapsed, setCollapsed] = useState(true)

    const toggle = () => setCollapsed(!isCollapsed);

    useEffect(() => {
        const teamNames = []
        const doughnutData = []

        if (teams === null){
            return
        }

        teams.forEach(team => {
            teamNames.push(team.teamName)
            doughnutData.push(Math.floor(team.sumOrders))
        })

        setTeamNames(teamNames)
        setDoughnutData(doughnutData)

    }, [teams, orders])


    return (
        <Wrapper>
            <DashboardCollapse component={component}>
                <Scrollbars style={{height: 540, scrollWidth: 5}}>
                <div className="cards px-4">
                    <div className='d-flex justify-content-between w-100'>
                        <div className="align-self-center"></div>
                    </div>
                        <Strong>Försäljning</Strong>
                        <DashboardDoughnut data={{
                            labels: teamNames,
                            datasets: [{
                                label: 'Försäljning',
                                data: doughnutData,
                                fill: true,
                                backgroundColor: [
                                    'rgb(255, 99, 132)',
                                    'rgb(54, 162, 235)',
                                    'rgb(255, 205, 86)',
                                    'rgb(40, 167, 69)'
                                ],
                                borderColor: [
                                    'rgb(255, 99, 132)',
                                    'rgb(54, 162, 235)',
                                    'rgb(255, 205, 86)',
                                    'rgb(40, 167, 69)'
                                ],
                                hoverOffset: 4
                            }]
                        }}
                           options={{
                               plugins: {
                                   legend: {
                                       labels: {
                                           font: {
                                               size: 15
                                           }
                                       }
                                   }
                               },
                               scales: {
                                   x: {
                                       ticks: {
                                           font: {
                                               size: 12
                                           }
                                       }
                                   },
                                   y: {
                                       ticks: {
                                           font: {
                                               size: 12
                                           }
                                       }
                                   }
                               }
                           }}/>
                </div>
                </Scrollbars>
            </DashboardCollapse>
        </Wrapper>
    )
}

export default CampaignDoughnutGraph

const DashboardDoughnut = styled(Doughnut)`
  @media (max-width: 1281px) {
    width: 100% !important;
    height: 100%; !important
    
  }
  @media (min-width: 2000px) {
    width: 600px !important;
    height: auto !important;
  }
`

const DashboardCardBody = styled(CardBody)`
  @media (max-width: 1281px) {
    height: 300px; !important
  }
  @media (min-width: 2000px) {
    width: 600px;
  }
`