import {
  OPEN_CREATE_MODAL,
  CLOSE_CREATE_MODAL,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_ERROR,
  CLEAR_CREATE_ERROR, IMAGE_UPDATED
} from '../types/user.types';

import {userService} from "../services/user.service";
import {findAll} from "./userList.actions";

export function openCreateModal() {
  return {
    type: OPEN_CREATE_MODAL
  }
}

export function closeCreateModal() {
  return {
    type: CLOSE_CREATE_MODAL
  }
}

export function clearCreateError() {
  return {
    type: CLEAR_CREATE_ERROR
  }
}

export function create(user = {}) {

  const request = () => ({ type: CREATE_REQUEST });
  const success = () => ({ type: CREATE_SUCCESS });
  const error = (error) => ({ type: CREATE_ERROR, error });

  return (dispatch) => {
    dispatch(request(user));

    userService.create(user)
    .then(
      async () => {
        dispatch(success());
        dispatch(findAll());
      },
      async (err) => {
        dispatch(error(err.toString()));
      }
    );
  };
}

export function updateImage() {
  return dispatch => dispatch(({ type: IMAGE_UPDATED }));
}