import React, {useState} from 'react';
import {Paginate, Pagination} from '../Shared/Pagination';
import UserImage from '../User/UserImage.component';
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';
import {AchievementNumber} from './Leaderboard.style';


const LeaderboardTopUser = (props) => {
  const {
    users
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const topPercentageSort = (a, b) => {
    if (a.percentage === b.percentage) {
      return b.latestOrder - a.latestOrder
    }
    
    return b.percentage - a.percentage
  }

  const renderUser = (teamMember, index) => {
    let achievementMap = new Map();
    
    if (teamMember && teamMember.achievements) {
      teamMember.achievements.forEach(achievement => {
        if (achievement.position > 5) return;

        if (achievementMap.has(achievement.position)) {
          let value = achievementMap.get(achievement.position) + 1;
          achievementMap.set(achievement.position, value);
        } else {
          achievementMap.set(achievement.position, 1);
        }
      })
    }

    let achievements = [];

    [...achievementMap.entries()]
      .forEach(achievement => achievements.push({
        position: achievement[0],
        amount: achievement[1],
      }));

    const getTrophyTitle = (achievement) => {
      let title = '';

      if (achievement.amount > 1) {
        switch (achievement.position) {
          case 1: {
            title = `${achievement.amount} första platser`
            break;
          }
          case 2: {
            title = `${achievement.amount} andra platser`
            break;
          }
          case 3: {
            title = `${achievement.amount} tredje platser`
            break;
          }
          case 4: {
            title = `${achievement.amount} fjärde platser`
            break;
          }
        }
      }

      return title;
    }

    return (
      <tr className=" v-middle" key={index}>
        <td width="80">
          <div className="avatar-group ">
            <div>
              <span className="w-32 avatar no-shadow">
                {index === 0 && <img src="/assets/img/trophy/T1.png" alt="."/>}
                {index === 1 && <img src="/assets/img/trophy/T12.png" alt="."/>}
                {index === 2 && <img src="/assets/img/trophy/T4.png" alt="."/>}
                {/*index >= 3 && index <= 4 && <img src="/assets/img/trophy/T8.png" alt="."/> */}
                {index >= 3 && <small className="text-muted font-weight-bold">{(index + 1)}</small>}
              </span>
            </div>
            <div className="avatar ajax w-24" data-toggle="tooltip" title="" data-pjax-state=""
                 data-original-title="Cursus">
              <UserImage userId={teamMember.userUniqueId} type="thumb"/>
            </div>
          </div>
        </td>
        <td className="flex">
          <Link to={{pathname: `/profile/${teamMember.userUniqueId}`}} className="item-title text-color h-1x">
            {teamMember.userName}
          </Link>

          <div className="item-company text-muted h-1x">
            {teamMember.teamName}
          </div>
        </td>
        <td align="right" className="pr-4">
          <div className="avatar-group right">
            {/*{
              achievements
                .filter(a => a.position <= 4)
                .sort(topPercentageSort)
                .map((achievement, i) => (
                  <span className="w-24 avatar m-1 no-shadow" key={i}>
                    {achievement.position === 1 && index < 5 &&
                    <img src="/assets/img/trophy/T1.png" alt="." title="1:a plats"/>}
                    {achievement.position === 2 && index < 5 &&
                    <img src="/assets/img/trophy/T12.png" alt="." title="2:a plats"/>}
                    {achievement.position === 3 && index < 5 &&
                    <img src="/assets/img/trophy/T4.png" alt="." title="3:e plats"/>}
                    {achievement.position === 4 && index < 5 && <img src="/assets/img/trophy/T8.png" alt="."/>}

                    {
                      achievement.amount > 1 &&
                      <AchievementNumber title={getTrophyTitle(achievement)}>{achievement.amount}</AchievementNumber>
                    }
                  </span>
                ))
            }*/}
            <span className="item-amount d-none d-sm-block text-sm text-nowrap ml-4">
              <CountUp start={teamMember.percentage} end={teamMember.percentage} separator={" "}/>%
            </span>
          </div>
        </td>
      </tr>
    );
  }
  
  const setPage = (page) => {
    setCurrentPage(page)
  }

  return (
    <React.Fragment>
      <table className="table table-theme v-middle table-hover">
        <tbody>
          {Paginate(users, itemsPerPage, currentPage)
            .sort(topPercentageSort)
            .map((user, index) => renderUser(user, (index + ((currentPage - 1) * itemsPerPage))))
          }
        </tbody>
      </table>

      {users.length > 0 &&
        <div className="card-body">
          <React.Fragment>
            <div className="d-flex">
              <small className="text-muted py-2">Totalt&nbsp;
                <span id="count">{users.length}</span> deltagare
              </small>
            </div>

            <div className="d-flex">
              <Pagination items={users} itemsPerPage={itemsPerPage} currentPage={currentPage} onChange={(page) => setPage(page)}/>
            </div>
          </React.Fragment>
        </div>
      }
    </React.Fragment>
  )
}

export default LeaderboardTopUser;