import React, {useEffect, useState} from 'react'

import { SwitchTransition, CSSTransition } from 'react-transition-group'

import TotalCharts from './TotalCharts.page'
import Ecommerce from './Ecommerce.page'

import * as style from './bonti.scss'

const TotalCarousel = () => {
  const [isTotalActive, setTotalActive] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setTotalActive(!isTotalActive)
    }, 1000 * 30)
  }, [isTotalActive])

  return (
    <>
      <SwitchTransition mode={'out-in'}>
        <CSSTransition
          key={isTotalActive}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="button-container">
            {isTotalActive ? <TotalCharts /> : <Ecommerce />}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </>
  )
}

export default TotalCarousel
