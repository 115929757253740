import {applyMiddleware, compose, createStore} from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from '../reducers/rootReducer'
import {composeWithDevTools} from 'redux-devtools-extension'

export const makeStore = () => {
  let storeEnhancers = composeWithDevTools(applyMiddleware(thunkMiddleware))

  if (process.env.NODE_ENV === 'production') {
    storeEnhancers = compose(applyMiddleware(thunkMiddleware))
  }

  return createStore(
    rootReducer,
    storeEnhancers
  )
}