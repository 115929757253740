import {
  FIND_ALL_REQUEST,
  FIND_ALL_SUCCESS,
  FIND_ALL_ERROR,
  SET_USER_LIST
} from "../types/user.types";

import {userService} from "../services/user.service";

export function findAll() {
  const request = () => ({ type: FIND_ALL_REQUEST });
  const success = (users) => ({ type: FIND_ALL_SUCCESS, users });
  const error = (error) => ({ type: FIND_ALL_ERROR, error });

  return (dispatch) => {
    dispatch(request());

    userService.findAll()
      .then(
        async (users) => {
          dispatch(success(users));

          let list_el = '#user-list';
          window.sr.reveal(list_el+ ' .list-item',{
            origin: 'left',
            scale: 1,
            distance: '50px',
            afterReveal:function (el) {
              window.$(el).css('transform', 'none');
            }
          }, 50);
        },
        async (err) => {
          dispatch(error(err.toString()));
        }
      );
  };
}

export function setUserList(users) {
  return (dispatch) => dispatch({ type: SET_USER_LIST, users });
}