import React from 'react'
import BestDailyOrders from './BestDailyOrder.component'
import {Wrapper} from './Dashboard.style'
import DashboardCollapse from './DashboardCollapse.component'
import { Scrollbars } from 'react-custom-scrollbars-2';

const CampaignDailySales = ({teamMembers, bestDailyOrder, component}) => {
  return (
    <Wrapper>
      <DashboardCollapse component={component}>
          <Scrollbars style={{height: 540, scrollWidth: 5}}>
        <BestDailyOrders teamMembers={teamMembers} bestDailyOrder={bestDailyOrder}/>
          </Scrollbars>
      </DashboardCollapse>
    </Wrapper>
  )
}

export default CampaignDailySales