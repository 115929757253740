import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {logout} from '../../actions/authentication.actions';
import {useLocation} from 'react-router';

const PublicRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={props => {
      return (<Component {...props} {...rest} />);
    }}/>
  );
};

export default PublicRoute;