import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 1 auto;
`

export const Wrapper = styled.div`
  padding: 2rem;
  
  @media (max-width: 991.98px) {
    padding: 1rem;
  }
`

export const FlexWrapper = styled(Wrapper)`
  display: flex;
`

export const Header = styled.h2`
  font-size: 1.2rem;
  color: #192039;
  
  @media (max-width: 767.98px) {
    text-align: center;
  }
`

export const SubHeader = styled.small`
  display: block;
  color: #99a0ac;
  
  @media (max-width: 767.98px) {
    text-align: center;
  }
`

export const Flex = styled.div`
  flex: 1 1 auto;
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  
`

export const FlexDashboard = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 767.98px) {
    flex-direction: column;
  }
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const CampaignButton = styled.div`
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  color: #99a0ac;
  padding: 0.75rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  
  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }
`

export const ContentWrapper = styled.div`
  
`