import React from 'react';
import styled from 'styled-components';
import UserForm from '../components/User/UserForm.component';
import { history } from '../helpers';

const User = (props) => {
  let userId = props.match.params.id;

  return (
    <Container>
      <UserForm
        userId={userId}
        onSubmit={() => history.push('/users')}
        onCancel={() => history.push('/users')}
      />
    </Container>
  );
};

export default User;

const Container = styled.div``;
