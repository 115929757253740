import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {PlusCircle, Maximize, Settings, Bell, Menu, ArrowLeft, ArrowRight} from 'react-feather';
import {Dropdown, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown, Label, Input} from 'reactstrap';
import {useLocation } from 'react-router-dom'

import {useDispatch, useSelector} from 'react-redux';
import CampaignOrderAdd from './CampaignOrderAdd.component';
import {openOrderModal} from '../../actions/campaign.actions';
import UserImage from '../User/UserImage.component';
import {setLightMode, setDarkMode, foldLeftNav, unfoldLeftNav} from '../../actions/layout.actions';
import authService from "../../services/auth.service";
import {DarkCheckBox, LightCheckBox} from "../Theme";
import withSizes from 'react-sizes';

const TopNavigation = ({isMobile}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.authentication.user);
  const selectedCampaign = useSelector(state => state.campaign.selectedCampaign);
  const layoutSettings = useSelector(state => state.layout.settings);
  const currentUser = useSelector(state => state.authentication.user)

  const [isUserDropdownOpen, setUserDropdownOpen] = useState(false);
  const [displayName, setDisplayName] = useState('');
  let location = useLocation().pathname;

  const toggleLeftNav = () => {
    if (layoutSettings.aside === 'folded') {
      dispatch(unfoldLeftNav());
    } else {
      dispatch(foldLeftNav());
    }
  }

  const toggleCampaignOrderModal = () => {
    dispatch(openOrderModal());
  }

  const handleThemeColorChange = (e) => {
    switch (e.target.value) {
      default:
      case 'light': {
        dispatch(setLightMode());
        break;
      }

      case 'dark': {
        dispatch(setDarkMode());
        break;
      }
    }
  }

  const logoutClick = () => {
    authService.logout();
  }

  useEffect(() => {
    if (user) {
      setDisplayName(user.displayName);
    }
  }, []);

  return (
    <div id="header" className="page-header no-bg">
      <div className="navbar navbar-expand-lg">
        <Link to={{pathname: '/'}} className="navbar-brand d-lg-none">
          <i className="zmdi zmdi-view-dashboard"/>
        </Link>

        {currentUser && !currentUser.isBontiUser() &&
          <>
            {selectedCampaign && selectedCampaign.active === 'Active' && location === '/' &&
              <MobileOrderButton className="btn" onClick={toggleCampaignOrderModal}>
                <PlusCircle size={isMobile ? 20 : 16} className="mr-2"/>
                Registrera
              </MobileOrderButton>
            }
          </>
        }

        <div className="collapse navbar-collapse order-2 order-lg-1" id="navbarToggler">
          <div className="pointer mr-2" title={layoutSettings.aside === 'folded' ? "Visa" : "Göm"}>
            {layoutSettings.aside === 'folded' ?
              <Menu size={isMobile ? 20 : 16} onClick={toggleLeftNav}/>
              :
              <Menu size={isMobile ? 20 : 16} onClick={toggleLeftNav}/>
            }
          </div>

          {currentUser && !currentUser.isBontiUser() &&
            <>
              { selectedCampaign && selectedCampaign.active === 'Active' && location === '/' &&
                <button id="order-btn" className="btn btn-xs btn-raised btn-wave btn-icon btn-rounded mb-2 mr-2 red text-white" onClick={toggleCampaignOrderModal} title="Registrera">
                  <PlusCircle size={isMobile ? 20 : 16}/>
                </button>
              }
            </>
          }
        </div>

        <ul className="nav navbar-menu order-1 order-lg-2">
          {/*<li className="nav-item d-none d-sm-block">
            <a href="/#" className="nav-link px-2" data-toggle="fullscreen" data-plugin="fullscreen">
              <Maximize size={isMobile ? 20 : 16}/>
            </a>
          </li>*/}

          <UncontrolledDropdown tag="li" className="nav-item">
            <DropdownToggle tag="a" className="nav-link px-2">
              <Settings size={isMobile ? 20 : 16}/>
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-right mt-3 w-md animate fadeIn">
              <div className="setting px-3">
                <div className="mb-2 text-muted">
                  <strong>Färg läge</strong>
                </div>

                <div>
                  <Label check className="radio radio-inline ui-check ui-check-md" title="Ljust">
                    <Input type="radio"
                           id="light-theme"
                           name="theme-color"
                           value="light"
                           checked={layoutSettings.bg !== 'bg-dark'}
                           onChange={handleThemeColorChange}/>{' '}
                    <LightCheckBox/>
                  </Label>

                  <Label check className="radio radio-inline ui-check ui-check-md" title="Mörkt">
                    <Input type="radio"
                           id="dark-theme"
                           name="theme-color"
                           value="dark"
                           checked={layoutSettings.bg === 'bg-dark'}
                           onChange={handleThemeColorChange}/>{' '}
                    <DarkCheckBox/>
                  </Label>
                </div>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>

          {/*<li className="nav-item dropdown">
            <a href="/#" className="nav-link px-2 mr-lg-2" data-toggle="dropdown">
              <Bell size={isMobile ? 20 : 16}/>
              <span className="badge badge-pill badge-up bg-primary">4</span>
            </a>
            
            <div className="dropdown-menu dropdown-menu-right mt-3 w-md animate fadeIn p-0">
              <div className="scrollable hover" style={{ 'maxHeight': '250px'}}>
                <div className="list list-row">
                  <div className="list-item " data-id="6">
                    <div>
                      <a href="/#">
                        <span className="w-32 avatar gd-danger">
                          <img src="/assets/img/a6.jpg" alt="."/>
                        </span>
                      </a>
                    </div>
                    <div className="flex">
                      <div className="item-feed h-2x">
                        Just saw this on the
                        <a href="/#">@eBay</a> dashboard, dude is an absolute unit.
                      </div>
                    </div>
                  </div>
                  <div className="list-item " data-id="12">
                    <div>
                      <a href="/#">
                        <span className="w-32 avatar gd-info">
                          A
                        </span>
                      </a>
                    </div>
                    <div className="flex">
                      <div className="item-feed h-2x">
                        <a href="/#">Support</a> team updated the status
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex px-3 py-2 b-t">
                <div className="flex">
                  <span>6 Notifications</span>
                </div>
                <a href="page.setting.html">Visa alla
                  <i className="fa fa-angle-right text-muted"></i>
                </a>
              </div>
            </div>
          </li>*/}

          <Dropdown isOpen={isUserDropdownOpen} nav={true} toggle={() => (setUserDropdownOpen(!isUserDropdownOpen))}>
            <DropdownToggle nav={true} className="nav-link d-flex align-items-center px-2 text-primary">
              <span className="avatar w-24" style={{ margin: '-2px' }}>
                <UserImage userId={user.id} type="thumb" />
              </span>
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-right w mt-3 animate fadeIn">
              <DropdownItem header>
                <span>{displayName}</span>
              </DropdownItem>

              {/*<DropdownItem>
                <span className="badge bg-success text-uppercase">Upgrade</span>
                <span>to Pro</span>
              </DropdownItem>*/}

              <Link to={{pathname: `/profile/${user.id}`}} style={{color: '#5E676F'}}>
                <DropdownItem>
                  <span>Profil</span>
                </DropdownItem>
              </Link>

              <Link to={{pathname: '/settings'}} style={{color: '#5E676F'}}>
                <DropdownItem>
                  <span>Mitt konto</span>
                </DropdownItem>
              </Link>

              {/*<DropdownItem>
                <span>Hjälp?</span>
              </DropdownItem>*/}

              <DropdownItem divider />

              <DropdownItem onClick={logoutClick}>
                <span>Logga ut</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <li className="nav-item d-lg-none">
            <a href="/#" className="nav-link px-1" data-toggle="modal" data-target="#aside">
              <Menu size={isMobile ? 20 : 16}/>
            </a>
          </li>
        </ul>
      </div>

      {currentUser && !currentUser.isBontiUser() &&
        <CampaignOrderAdd/>
      }
    </div>
  )
};

const mapSizesToProps = ({width}) => ({
  isMobile: width < 992
});

export default withSizes(mapSizesToProps)(TopNavigation);

const MobileOrderButton = styled.button`
  font-size: 0.725rem;
  font-weight: 600;
  
  i {
    font-size: 1.025rem;
  }

  @media only screen and (min-width: 992px) {
    display: none;
  }
`