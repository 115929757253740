import styled from 'styled-components'
import {Strong} from '../Shared/Style';
import {Card} from "../Shared/Card";
import {CardBody} from "reactstrap";

export const Wrapper = styled.div`
  @media (max-width: 992px) {
    text-align: center;
  }
`

export const ResultValue = styled.div`
  color: var(--green);

  @media (min-width: 1451px) {
    font-size: 45px;
  }
  font-size: 35px;
  font-weight: 700;
`

export const ProgressWrapper = styled.div`
  margin-top: 0.5rem;
  font-weight: 700;
  font-size: 35px;
  
  @media (min-width: 1451px) {
    font-size: 45px;
  }
  
  .progress-bar {
    font-size: 0.65625rem;
    transition: width 2s ease;
  }
`

export const ProgressValue = styled.div`   
  align-self: center;
  margin-left: 0.5rem;
  font-size: 36px;
`

export const BestDailyOrder = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`



export const DashboardCard = styled(Card)`
  height: 100%;
`

export const DashboardCardBody = styled(CardBody)`
  @media (max-width: 1281px) {
   width: 400px;  
  }
`
