import client from '../../helpers/client';

const baseUrl = '/campaigns/default';

export function create(data = {}) {
  return client.getClient()
    .post(baseUrl, data)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export default {
  create,
}