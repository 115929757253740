import React, {useState, useRef, useMemo, useEffect} from 'react';
import {useSelector} from "react-redux";

import {
    Button,
    Form,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table
} from "reactstrap";

import {campaignService} from "../../services/campaign.service";

import { CampaignTeamApi } from "../../api/Campaign/CampaignTeam.api"

import {EventApi} from '../../api/Event/Event.api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const CampaignGoal = () => {
  const campaignInfo = useSelector(state => state.campaign.campaignInfo);
  const campaignTeams = useSelector(state => state.campaign.campaignTeams);

  const { data: events } = useQuery(['events'], () => EventApi.findAll(), {
    initialData: [],
    refetchOnWindowFocus: false,
  });

  /* const { data: campaignEvents } = useQuery(['campaignEvents', campaignInfo.id], () => campaignService.findEvents(campaignInfo.id), {
    initialData: [],
    refetchOnWindowFocus: false,
  }); */

  const defaultCampaignTeam = { id: null, teamName: '', teamMembers: [] };
  const [campaignTeam, setCampaignTeam] = useState(null);

  const toggleTeamModal = (teamToOpen) => {
    if (campaignTeam) {
      setCampaignTeam(null);
    } else {
      if (teamToOpen) {
        setCampaignTeam(teamToOpen);
      } else {
        setCampaignTeam({...defaultCampaignTeam});
      }
    }
  };
  
  const completedGoals = useMemo(() => {
    return campaignTeams
      .filter(t => t.teamMembers.every(m => m.eventGoals.every(g => g.goal && g.goal > 0)))
      .map(t => t.teamId)
  }, [campaignTeams])

  return (
    <div id="campaign-goal-container" className="tab-pane active">
      <div className="form-group">
        <p>
          <strong>Ställ in målpoäng för respektive lag.</strong>
        </p>
      </div>

      <div className="form-group">

        <div className="table-responsive">
          <table className="table table-theme table-row v-middle">
            <thead>
            <tr>
              <th style={{width: '20px'}}></th>
              <th className="text-muted" style={{width: '50px'}}></th>
              <th className="text-muted sortable" data-toggle-class="asc">Lag</th>
              <th style={{width: '50px'}}></th>
            </tr>
            </thead>
            <tbody>
              {
                campaignTeams
                  .sort((a, b) => a.teamName.localeCompare(b.teamName))
                  .map((campaignTeam, index) => {
                    return (
                      <tr className="v-middle" key={index}>
                        <td>
                          {completedGoals.indexOf(campaignTeam.teamId) > -1 &&
                            <i className="fa fa-check text-success" title="Mål för alla aktiviteter satta"></i>
                          }
                        </td>

                        <td>
                          <a href="#">
                            <span className="w-40 avatar gd-warning" title={campaignTeam.teamName}>
                              {campaignTeam.teamName.substring(0, 1).toUpperCase()}
                            </span>
                          </a>
                        </td>
                        
                        <td className='flex'>
                          <button type="button" className="btn item-title text-primary" onClick={() => toggleTeamModal(campaignTeam)}>
                            {campaignTeam.teamName}
                          </button>

                          <div className="item-except text-muted text-sm h-1x">
                            {campaignTeam.teamMembers.length} Deltagare
                          </div>
                        </td>
                      </tr>
                    )
                  })
              }
            </tbody>
          </table>
        </div>
      </div>

      <GoalModal
        events={events}
        campaignTeam={campaignTeam}
        toggle={toggleTeamModal}
      />
      </div>
    );
};

export default CampaignGoal;

/**
 * @typedef {Object} EventModalProps
 * @property {Event[]} events
 * @property {CampaignTeam} campaignTeam
 * @property {function} toggle
 * @property {function} onNameChange
 * @property {function} onUnitChange
 * @property {function} onFixedValueCheck
 * @property {function} onFixedValueChange
 * @property {function} onEventMessageTriggerChange
 * @param {EventModalProps} props 
 */
const GoalModal = ({
    events,
    campaignTeam,
    toggle,
}) => {
  const queryClient = useQueryClient();
  const tableRef = useRef(null);

  const campaignInfo = useSelector(state => state.campaign.campaignInfo);
  const campaignEvents = useSelector(state => state.campaign.campaignEvents);

  const [eventGoals, setEventGoals] = useState(new Map())

  const scrollLeft = (index) => {
    if(index === 0) {
        tableRef.current.scrollLeft = 0
    }
  }

  const handleEventGoalChange = (e, eventGoal) => {
    eventGoals.set(eventGoal.id, e.target.value)
  };

  const getEventUnit = (eventId) => {
    return events
        .filter(e => e.id === eventId)
        .reduce((sum, e) => e.unit, '');
  };

  const { mutate: updateCampaignTeam } = useMutation(['updateCampaignTeam'], (campaignTeam) => 
    CampaignTeamApi.update(campaignInfo.id, campaignTeam), {
      onSuccess: () => {
        queryClient.invalidateQueries(['campaign', campaignInfo.id]);
        toggle();
      }
    });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (campaignInfo.id !== null) {
      const teamMembers = campaignTeam.teamMembers.map(teamMember => ({
        ...teamMember,
        eventGoals: teamMember.eventGoals.map(eventGoal => ({
          ...eventGoal,
          goal: eventGoals.has(eventGoal.id) ? eventGoals.get(eventGoal.id) : eventGoal.goal
        }))
      }));

      const item = {
        ...campaignTeam,
        teamMembers
      }

      updateCampaignTeam(item);
    }
  };

  useEffect(() => {
    if (campaignTeam) {
      campaignTeam.teamMembers.forEach(teamMember => {
        if (teamMember.eventGoals.length === 0) {
          campaignEvents.forEach(campaignEvent => {
            teamMember.eventGoals.push({
              campaignEventId: campaignEvent.id,
              campaignEventName: campaignEvent.eventName,
              eventId: campaignEvent.eventId,
              goal: 0,
            });
          });
        } else {
          teamMember.eventGoals.forEach(eventGoal => {
            if (!eventGoals.has(eventGoal.id)) {
              eventGoals.set(eventGoal.eventId, eventGoal.goal)
            }
          });
        }
      });

      setEventGoals(new Map(eventGoals))
    }
  }, [campaignTeam]);

  if (!campaignTeam) {
    return null
  }

  return (
    <Modal isOpen={campaignTeam !== null} toggle={toggle} size="lg" style={{maxWidth: '55vw', width: '100%'}}>
      <Form onSubmit={handleSubmit}>
        <ModalHeader toggle={toggle}>
            Målsättning {campaignTeam && campaignTeam.teamName}
        </ModalHeader>

        <div ref={tableRef} style={{overflow: 'scroll', scrollBehavior: 'smooth'}}>
            <ModalBody>
              <Table style={{width: campaignEvents.length > 3 ? '': ''}}>
                  <thead>
                      <tr>
                          <th>Deltagare</th>
                          {campaignEvents
                              .sort((e1, e2) => e1.eventName.localeCompare(e2.eventName))
                              .map((campaignEvent, index) => {
                                  return <th key={index}>{campaignEvent.eventName + ` (${getEventUnit(campaignEvent.eventId)})`}</th>
                              })
                          }
                      </tr>
                  </thead>
                  <tbody>
                      {campaignTeam.teamMembers
                          .sort((a, b) => a.lastname.localeCompare(b.lastname))
                          .map((teamMember, index) => {
                              return (
                                  <tr key={index} style={{whiteSpace: 'nowrap'}}>
                                      <th>{teamMember.name}</th>
                                      {teamMember.eventGoals
                                          .sort((e1, e2) => e1.campaignEventName.localeCompare(e2.campaignEventName))
                                          .map((eventGoal, index) => {
                                              return (
                                                  <td key={index}>
                                                      <Input
                                                          type="number"
                                                          className="form-control"
                                                          name="event-goal"
                                                          placeholder="Mål"
                                                          required
                                                          defaultValue={eventGoal.goal}
                                                          onFocus={() => {scrollLeft(index)}}
                                                          onChange={(e) => handleEventGoalChange(e, eventGoal) } />
                                                  </td>
                                              )
                                          })
                                      }
                                  </tr>
                              )
                          })
                      }
                  </tbody>
              </Table>
            </ModalBody>
          </div>

          <ModalFooter>
              <Button color="secondary" onClick={toggle}>Avbryt</Button>
              <Button type="submit" color="primary">Spara</Button>
          </ModalFooter>
      </Form>
    </Modal>
  )
}