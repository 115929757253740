import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'

import * as serviceWorker from './serviceWorker'
import {makeStore} from './store'
import App from './containers/app'
import authService from './services/auth.service'
import client from './helpers/client'

const renderApp = () => render(
  <Provider store={makeStore()}>
    <App/>
  </Provider>,
  document.getElementById('root')
)

if (window.location.pathname.indexOf('bonti') === -1) {
  authService.initKeycloak(renderApp)
} else {
  renderApp(true)
}

client.configure()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()