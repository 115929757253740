import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FormGroup, Label} from 'reactstrap';
import DayPickerInput from "react-day-picker/DayPickerInput";
import 'moment/locale/it';
import {formatDate, parseDate} from "react-day-picker/moment";
import 'react-day-picker/lib/style.css';
import {setCampaignInfo} from "../../actions/campaign.actions";
import './Campaign.styles.scss';

const CampaignInfo = () => {
  const dispatch = useDispatch();
  const dateFormat = 'YYYY-MM-DD';

  const isInfoFormValidated = useSelector(state => state.campaign.isInfoFormValidated);
  const campaignInfo = useSelector(state => state.campaign.campaignInfo);

  let startDateClass = useSelector(state => {
    let cls = '';

    if (isInfoFormValidated) {
      cls += ' ';

      if (state.campaign.campaignInfo.startDate.length > 0)
        cls += 'is-valid';
      else
        cls += 'is-invalid'
     }

    return cls;
  });

  let endDateClass = useSelector(state => {
    let cls = '';

    if (isInfoFormValidated) {
      cls += ' ';

      if (state.campaign.campaignInfo.endDate.length > 0)
        cls += 'is-valid';
      else
        cls += 'is-invalid'
    }

    return cls;
  });

  const onChangeName = (name) => {
    campaignInfo.name = name;
    dispatch(setCampaignInfo(campaignInfo));
  };

  const onChangeStartDate = (date) => {
    campaignInfo.startDate = (date !== undefined) ? date : null;
    dispatch(setCampaignInfo(campaignInfo));
  };

  const onChangeEndDate = (date) => {
    campaignInfo.endDate = (date !== undefined) ? date : null;
    dispatch(setCampaignInfo(campaignInfo));
  };

  const onChangeComment = (e) => {
    campaignInfo.comment = e.target.value;
    dispatch(setCampaignInfo(campaignInfo));
  };

  const onChangeText = (e) => {
    campaignInfo.campaignText = e.target.value;
    dispatch(setCampaignInfo(campaignInfo));
  };

  return (
    <div className="tab-pane active">
      <form id="campaign-info-form" noValidate>
        <div className={`form-group needs-validation ${isInfoFormValidated ? 'was-validated' : ''}`}>
          <label>Namn på kampanj</label>

          <input type="text"
                 className="form-control"
                 placeholder="Namn"
                 name="name"
                 required
                 defaultValue={campaignInfo.name}
                 onChange={(e) => onChangeName(e.target.value)} />
        </div>

        <FormGroup className={`row needs-validation`}>
          <div className="col-sm-6">
            <Label>Startdatum</Label>

            <DayPickerInput
              inputProps={{
                className: 'form-control' + startDateClass,
                required: 'required'
              }}
              formatDate={formatDate}
              format={dateFormat}
              parseDate={parseDate}
              placeholder="YYYY-MM-DD"
              value={ (campaignInfo.startDate !== null) ? formatDate(campaignInfo.startDate, dateFormat) : null }
              onDayChange={onChangeStartDate}
            />
          </div>

          <div className="col-sm-6">
            <Label>Slutdatum</Label>
            <DayPickerInput
              inputProps={{
                className: 'form-control'  + endDateClass,
                required: 'required'
              }}
              formatDate={formatDate}
              format={dateFormat}
              parseDate={parseDate}
              placeholder="YYYY-MM-DD"
              value={ (campaignInfo.endDate !== null) ? formatDate(campaignInfo.endDate, dateFormat) : campaignInfo.endDate }
              onDayChange={onChangeEndDate}/>
          </div>
        </FormGroup>

        <FormGroup>
          <label>Kommentar</label>
          <input type="text"
                 className="form-control"
                 placeholder="Kommentar"
                 defaultValue={campaignInfo.comment}
                 onChange={onChangeComment}/>
        </FormGroup>

        <FormGroup>
          <label>Text</label>
          <input type="text"
                 className="form-control"
                 placeholder="Text"
                 defaultValue={campaignInfo.campaignText}
                 onChange={onChangeText}/>
        </FormGroup>
      </form>
    </div>
  );
};

export default CampaignInfo;