import React from 'react';
import CountUp from "react-countup";

export {
  TeamStatistics
}

const TeamStatistics = ({statistics}) => {
  return (
    <div className="card">
      <div className="card-header">
        <strong>Lagstatistik</strong>
      </div>

      <div className="px-4 py-4">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12- col-sm-4 mb-2 mb-md-0 text-center">
                <span className="avatar no-shadow" style={{textAlign: 'center', align: 'center'}}>
                  <img src="/assets/img/trophy/T11.png" alt="." style={{width: '42px', height: '42px'}}/>
                </span>

                <small className="text-muted m-b">Förstaplats</small>

                <div className="first-place">
                  <CountUp
                    end={statistics && statistics.nrTeamPlacementFirst > 0 ? statistics.nrTeamPlacementFirst : 0}
                    duration="2"
                    decimal="0"
                    separator={' '}
                  />
                  st
                </div>
              </div>

              <div className="col-12- col-sm-4 mb-2 mb-md-0 text-center">
                <span className="avatar no-shadow" style={{textAlign: 'center', align: 'center'}}>
                  <img src="/assets/img/trophy/T9.png" alt="." style={{width: '42px', height: '42px'}}/>
                </span>

                <small className="text-muted m-b">Andraplats</small>

                <div className="second-place">
                  <CountUp
                    end={statistics && statistics.nrTeamPlacementSecond > 0 ? statistics.nrTeamPlacementSecond : 0}
                    duration={2}
                    decimal="0"
                    separator={' '}
                  />
                  st
                </div>
              </div>

              <div className="col-12- col-sm-4" style={{textAlign: 'center'}}>
                <span className="avatar no-shadow" style={{textAlign: 'center', align: 'center'}}>
                  <img src="/assets/img/trophy/T9.png" alt="." style={{width: '42px', height: '42px'}}/>
                </span>

                <small className="text-muted m-b">Tredjeplats</small>

                <div className="third-place">
                  <CountUp
                    end={statistics && statistics.nrTeamPlacementThird > 0 ? statistics.nrTeamPlacementThird : 0}
                    duration={2}
                    decimal="0"
                    separator={' '}/>
                  st
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}