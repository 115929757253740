import Select from 'react-select';

const SelectInput = (props) => {
  const { placeholder } = props

  const customStyles = {
    control: base => ({
      ...base,
      height: 35,
      minHeight: 35
    })
  }

  return (
    <Select styles={customStyles} {...props} placeholder={placeholder ? placeholder : 'Välj'} />
  )
}

export default SelectInput