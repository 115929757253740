import { SET_TEAM_LIST, SET_TEAM } from '../../types/bonti/bonti.types'

export const setTeamList = (teams) => {
  return (dispatch) => {
    dispatch({ type: SET_TEAM_LIST, teams });
  }
}

export const setTeam = (team) => {
  return (dispatch) => {
    dispatch({ type: SET_TEAM, team });
  }
}