import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';

import {Button, Form, FormGroup, Input, Label, DropdownItem} from 'reactstrap';
import { Trash } from 'react-feather';
import {history} from "../../helpers";
import {teamService} from "../../services/team.service";

const TeamForm = (props) => {
  let teamId = props.match.params.id;
  const defaultTeam = { id: null, active: 1, name: '' };
  const [team, setTeam] = useState({...defaultTeam});
  const currentUser = useSelector(state => state.authentication.user)
  const onNameChange = (e) => {
    setTeam({ id: team.id, active: 1, name: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (team.id) {
      teamService
        .update(team.id, team)
        .then(() => {
          history.push('/teams');
        });
    }
    else {
      teamService
        .create(team)
        .then(() => {
          history.push('/teams');
        });
    }
  };

  const handleTeamDelete = (team) => {
    teamService
      .delete(team.id)
      .then(() => {
        history.push("/teams")
      }).catch(err => {
        console.log({ err })
      })
  }

  const handleCancel = () => {
    history.push('/teams');
  };

  useEffect(() => {
    if (teamId && teamId !== null) {
      teamService.get(teamId)
        .then(team => {
          setTeam(team);
        });
    }
  }, []);

  return (
    <div className="page-content page-container">
      <div className="padding">
        <div className="card">
          <div className="card-header">
            <strong> { team.id && team.id.length > 0 ? 'Redigera ' + team.name : 'Nytt lag' }</strong>
          </div>

          <div className="card-body">
            <Form id="team-form" onSubmit={handleSubmit}>
              <div className="form-row">
                <FormGroup className="col-md-12">
                  <Label>Lagnamn</Label>

                  <Input type="text"
                         className="form-control"
                         name="team-name"
                         placeholder="Namn"
                         required
                         value={team.name}
                         onChange={onNameChange} />
                </FormGroup>
              </div>

              <Button color="secondary mr-1" onClick={handleCancel}>Avbryt</Button>
              <Button type="submit" color="primary mr-1" disabled={team.name.length === 0}>Spara</Button>
              { (currentUser.hasRole('Admin')) &&
                <Button color="primary" onClick={() => handleTeamDelete(team)}>
                  <Trash size={16}/>
                  Ta bort
                </Button>
              }
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamForm;