import React from 'react';
import CountUp from "react-countup";
import {Doughnut} from "react-chartjs-2";
import ScoreUtil from "./ScoreUtil";

const UserTotalScore = (props) => {

  let {
    teamMember,
    isGoalObtainable
  } = props;

  let isGoalObtainableColor = (isGoalObtainable ? '#31c971' : '#fb2640');

  const percentage = ScoreUtil.getPercentageFromTeamMember(teamMember);

  return (
    <div className="card flex">
      <div className="card-body text-center">
        <small className="text-muted">Ditt resultat</small>
        <div className="pt-2">
          <div className="pos-rlt" style={{ height: '120px' }}>  {/*style={{ height: '140px'}}*/}
            <div className="pos-abt w-100 h-100 d-flex align-items-center justify-content-center">
              <div>
                <div className="text-highlight text-md">
                  <CountUp end={percentage} duration={2} decimals={0} separator={" "}/>%
                </div>
              </div>
            </div>
            <Doughnut
              data={
                {
                  datasets: [{
                    data: [percentage, (100 - percentage)],
                    backgroundColor: [
                      isGoalObtainableColor,
                      '#eeeeee'
                    ],
                    borderColor: [isGoalObtainableColor, '#eeeeee'],
                    borderWidth: [1, 1, 1, 1, 1]
                  }]
                }}
              options={{
                cutoutPercentage: 90,
                maintainAspectRatio: false,
              }}
              width={800}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserTotalScore;