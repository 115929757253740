import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { Button, FormGroup, Label } from 'reactstrap'
import { setDocumentTitle } from '../../helpers'
import { Card, CardBody, CardHeader } from '../Shared/Card'
import { UserRoleSchema, UserSchema } from '../../api/User'
import UserRoleApi from '../../api/UserRole/UserRole.api'
import UserApi from '../../api/User/User.api'
import { FieldError } from '../Shared/Style'
import styled from 'styled-components'
import { userService } from '../../services/user.service'
import { useSelector } from 'react-redux'
import { Trash } from 'react-feather'

/**
 * @returns {React.ReactElement}
 */
const UserForm = ({ userId, companyId, onSubmit, onCancel, history }) => {
  const defaultUser = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    passwordRepeat: '',
    userRoles: [],
  }
  const currentUser = useSelector((state) => state.authentication.user)
  const [user, setUser] = useState(defaultUser)
  const [roles, setRoles] = useState([])
  const [isUpdating, setUpdating] = useState(false)

  const handleSubmit = (values, actions) => {
    setUpdating(true)

    let data = {
      ...user,
      ...values,
    }

    if (companyId) {
      data.companyId = companyId
    }

    if (actions.status) {
      actions.setStatus(null)
    }

    if (user.id) {
      UserApi.update(user.id, data)
        .then(() => {
          setUpdating(false)

          if (onSubmit) {
            onSubmit()
          }
        })
        .catch(() => {
          setUpdating(false)

          if (onSubmit) {
            onSubmit()
          }
        })
    } else {
      UserApi.create(data)
        .then(() => {
          setUpdating(false)

          if (onSubmit) {
            onSubmit()
          }
        })
        .catch((err) => {
          setUpdating(false)
          actions.setStatus(err)
        })
    }
  }

  const handleUserDelete = (user) => {
    if (!user) return

    userService
      .deleteUser(user.id)
      .then(() => {
        history.push('/users')
      })
      .catch((err) => {
        console.log({ err })
      })
  }

  useEffect(() => {
    if (userId) {
      UserApi.get(userId).then((user) => {
        if (user) {
          setUser(user)
          setDocumentTitle(user.firstName + ' ' + user.lastName)
        }
      })
    }

    UserRoleApi.findAll().then((userRoles) => {
      setRoles(userRoles.map((r) => r.name))
    })
  }, [])

  if (!user) return <React.Fragment />

  return (
    <Container>
      <Card>
        <CardHeader>
          <strong>
            {user && user.id
              ? `Redigera ${user.firstName} ${user.lastName}`
              : 'Ny användare'}
          </strong>
        </CardHeader>

        <CardBody>
          <Formik
            initialValues={user}
            enableReinitialize={true}
            validationSchema={userId ? UserRoleSchema : UserSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isValid, setFieldValue, status }) => (
              <Form>
                {status && status !== 'USER_WITH_EMAIL_EXISTS' && (
                  <FieldError className="mb-4">{status}</FieldError>
                )}

                <div className="form-row">
                  <div className="col-md-6 mb-2">
                    <Label>Förnamn</Label>
                    <Field
                      name="firstName"
                      className={`form-control${
                        errors.firstName && touched.firstName
                          ? ' has-error'
                          : ''
                      }`}
                      placeholder="Förnamn"
                    />
                    {errors.firstName && touched.firstName && (
                      <FieldError>{errors.firstName}</FieldError>
                    )}
                  </div>

                  <div className="col-md-6 mb-2">
                    <Label>Efternamn</Label>
                    <Field
                      name="lastName"
                      className={`form-control${
                        errors.lastName && touched.lastName ? ' has-error' : ''
                      }`}
                      placeholder="Efternamn"
                    />
                    {errors.lastName && touched.lastName && (
                      <FieldError>{errors.lastName}</FieldError>
                    )}
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-md-6 mb-2">
                    <Label>E-post</Label>
                    <Field
                      name="email"
                      className={`form-control${
                        errors.email && touched.email ? ' has-error' : ''
                      }`}
                      placeholder="E-post"
                    />
                    {errors.email && touched.email && (
                      <FieldError>{errors.email}</FieldError>
                    )}

                    {status && status === 'USER_WITH_EMAIL_EXISTS' && (
                      <FieldError>
                        Det finns redan en användare med samma e-post
                      </FieldError>
                    )}
                  </div>

                  <div className="col-md-6 mb-2">
                    <Label>Telefon</Label>
                    <Field
                      name="phone"
                      className="form-control"
                      placeholder="Telefon"
                    />
                    {errors.phone && touched.phone && (
                      <FieldError>{errors.phone}</FieldError>
                    )}
                  </div>
                </div>

                {!userId && (
                  <div className="form-row">
                    <div className="col-md-6 mb-2">
                      <Label>Lösenord</Label>
                      <Field
                        type="password"
                        name="password"
                        placeholder="Lösenord"
                        className={`form-control${
                          errors.password && touched.password
                            ? ' has-error'
                            : ''
                        }`}
                      />
                      {errors.password && touched.password && (
                        <FieldError>{errors.password}</FieldError>
                      )}
                    </div>

                    {values.password.length > 0 && (
                      <div className="col-md-6 mb-2">
                        <Label>Bekräfta lösenord</Label>
                        <Field
                          type="password"
                          name="passwordRepeat"
                          placeholder="Bekräfta lösenord"
                          className={`form-control${
                            errors.passwordRepeat && touched.passwordRepeat
                              ? ' has-error'
                              : ''
                          }`}
                        />
                        {errors.passwordRepeat && touched.passwordRepeat && (
                          <FieldError>{errors.passwordRepeat}</FieldError>
                        )}
                      </div>
                    )}
                  </div>
                )}

                <div className="form-row">
                  <FormGroup className="col-md-12">
                    <Label>Roll</Label>

                    <Field
                      component="select"
                      name="userRoles"
                      className={`form-control${
                        errors.userRoles && touched.userRoles
                          ? ' has-error'
                          : ''
                      }`}
                      placeholder="Roll"
                      multiple={true}
                      onChange={(e) =>
                        setFieldValue(
                          'userRoles',
                          [].slice
                            .call(e.target.selectedOptions)
                            .map((option) => option.value)
                        )
                      }
                    >
                      {roles.map((role, i) => (
                        <option key={i} value={role}>
                          {userService.translateRole(role)}
                        </option>
                      ))}
                    </Field>

                    {errors.userRoles && touched.userRoles && (
                      <FieldError>{errors.userRoles}</FieldError>
                    )}
                  </FormGroup>
                </div>

                <ButtonContainer>
                  <Button color="secondary" onClick={onCancel}>
                    Avbryt
                  </Button>

                  <Button type="submit" color="primary" disabled={!isValid}>
                    {isUpdating && (
                      <React.Fragment>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp; Sparar
                      </React.Fragment>
                    )}

                    {!isUpdating && 'Spara'}
                  </Button>

                  {user?.id && currentUser.hasRole('Admin') && (
                    <Button
                      disabled={user.userRoles.find((role) => role === 'Admin')}
                      color="primary"
                      onClick={() => handleUserDelete(user)}
                    >
                      <Trash size={16} />
                      Ta bort
                    </Button>
                  )}
                </ButtonContainer>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Container>
  )
}

export default withRouter(UserForm)

export const Container = styled.div`
  flex: 1 1 auto;
  @media (min-width: 992px) {
    max-width: 1140px;
    margin: 0 auto;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > :not(:first-child) {
    margin-left: 0.25rem;
  }

  > :not(:last-child) {
    margin-right: 0.25rem;
  }
`
