import {
  CLOSE_EVENT_SCORES,
  GET_DASHBOARDS,
  GET_DASHBOARDS_SUCCESS,
  OPEN_EVENT_SCORES,
  TOGGLE_DASHBOARD_MODAL,
  SET_SELECTED_COMPONENTS,
  SET_SORT_ORDER, SET_COLUMN_SIZE, SET_TMP_COMPONENTS
} from "../types";
import {DashboardApi} from '../api/Dashboard/Dashboard.api';

export const getDashboards = (companyId) => dispatch => {
  dispatch({ type: GET_DASHBOARDS })

  return DashboardApi.findAll(companyId).then((dashboards) => {
    dispatch({type: GET_DASHBOARDS_SUCCESS, dashboards})
  })
}

export function openEventScores() {
  return (dispatch) => {
    dispatch({type: OPEN_EVENT_SCORES});
  };
}

export function closeEventScores() {
  return (dispatch) => {
    dispatch({type: CLOSE_EVENT_SCORES});
  };
}
export function openDashboardModal() {
  return (dispatch) => {
    dispatch({ type: TOGGLE_DASHBOARD_MODAL });
  };
}

export function setSelectedComponents(components) {
  return (dispatch) => {
    dispatch({type: SET_SELECTED_COMPONENTS, components});
  };
}

export function setSortOrder(component, sortOrder) {

    return (dispatch) => {
      dispatch({ type: SET_SORT_ORDER, component, sortOrder });
    };

}
export function setTmpComponents(components) {
  return(dispatch) => {
    dispatch({type: SET_TMP_COMPONENTS, components  })
  }
}