export const SET_LATEST_ACTIVITIES = 'LATEST_ACTIVITY:SET_LATEST_ACTIVITIES';
export const ADD_MESSAGE = 'LATEST_ACTIVITY:ADD_MESSAGE';
export const REMOVE_MESSAGE = 'LATEST_ACTIVITY:REMOVE_MESSAGE';

export const OPEN_COMMENT = 'LATEST_ACTIVITY:OPEN_COMMENT';
export const CLOSE_COMMENTS = 'LATEST_ACTIVITY:CLOSE_COMMENTS';

export const ADD_COMMENT = 'LATEST_ACTIVITY:ADD_COMMENT';
export const EDIT_COMMENT = 'LATEST_ACTIVITY:EDIT_COMMENT';
export const UPDATE_COMMENT = 'LATEST_ACTIVITY:UPDATE_COMMENT';
export const REMOVE_COMMENT = 'LATEST_ACTIVITY:REMOVE_COMMENT';

export const IS_PAGE_LOAD_EVENT = 'LATEST_ACTIVITY:IS_PAGE_LOAD_EVENT';