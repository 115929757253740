import { produce } from 'immer'

import {
  SET_CAMPAIGN_LIST,
  SET_SELECTED_CAMPAIGN,
  SET_INFO_FORM_VALIDATION,
  SET_CAMPAIGN_INFO,
  SELECT_EVENT,
  REMOVE_EVENT,
  SET_EVENT_WEIGHT,
  SET_CAMPAIGN_TEAMS,
  SET_CAMPAIGN_GOAL,
  SET_CAMPAIGN_EVENTS,
  OPEN_ORDER_MODAL,
  CLOSE_ORDER_MODAL,
  SET_EVENT_GOAL,
  SET_EVENT_TEAM_GOAL,
  SET_INITIAL_CAMPAIGN,
  SET_CAMPAIGN_STEPS,
  SET_ACTIVE_CAMPAIGN_STEP,
  CLEAR_FORM,
  SET_DIRTY_FIELD,
  SET_INITIAL_CAMPAIGN_STEP
} from '../types';

const initialState = produce({
  campaignList: [],
  selectedCampaign: null,
  steps: [{
    name: 'info',
    text: 'Information',
    disabled: false
  }, {
    name: 'team',
    text: 'Lag',
    disabled: true
  }, {
    name: 'event',
    text: 'Aktivitet',
    disabled: true
  }, {
    name: 'goal',
    text: 'Målsättning',
    disabled: true
  }, {
    name: 'done',
    text: 'Klart',
    disabled: true
  }],
  initialStep: null,
  step: 'info',
  dirtyFields: [],
  campaignInfo: {
    id: null,
    name: '',
    startDate: null,
    endDate: null,
    comment: '',
    campaignText: ''
  },
  campaignTeams: [],
  campaignEvents: [],
  isInfoFormValidated: false,
  isOrderModalOpen: false,
  formValidation: [],
  orderModal: {
    eventId: null
  }
}, (draftState) => {
});

const campaignReducer = (state = initialState, action) => {

  switch (action.type) {
    case SET_CAMPAIGN_LIST: {
      return {
        ...state,
        campaignList: action.campaigns
      };
    }

    case SET_SELECTED_CAMPAIGN: {
      return {
        ...state,
        selectedCampaign: action.campaign
      };
    }

    case CLEAR_FORM: {
      const { 
        steps,
        step,
        dirtyFields,
        campaignInfo,
        campaignTeams,
        campaignEvents,
        isInfoFormValidated,
        formValidation
       } = initialState

      return {
        ...state,
        steps,
        step,
        dirtyFields,
        campaignInfo,
        campaignTeams,
        campaignEvents,
        isInfoFormValidated,
        formValidation
      };
    }

    case SET_ACTIVE_CAMPAIGN_STEP: {
      const { step } = action
      const steps = []

      state.steps.forEach(step => {
        if (step.name === action.step) {
          steps.push({...step, disabled: false})
        } else {
          steps.push({...step})
        }
      });

      return {
        ...state,
        steps,
        step,
      };
    }

    case SET_CAMPAIGN_STEPS: {
      return {
        ...state,
        steps: action.steps
      };
    }

    case SET_INITIAL_CAMPAIGN_STEP: {
      const { step } = action

      return {
        ...state,
        initialStep: step,
      };
    }

    case SET_INITIAL_CAMPAIGN: {
      const {
        campaignInfo,
        campaignTeams,
        campaignEvents,
      } = action

      const steps = [];

      state.steps.forEach(step => {
        let disabled = true;

        switch (step.name) {
          case 'info': {
            disabled = false;
            break;
          }

          case 'team': {
            if (state.initialStep === 'team') {
              disabled = false;
            }

            if (campaignTeams.length > 0) {
              disabled = false;
            }

            if (campaignInfo) {
              if (campaignInfo.name && campaignInfo.startDate && campaignInfo.endDate) {
                disabled = false;
              }
            }

            break;
          }

          case 'event': {
            if (campaignTeams?.length > 0) {
              disabled = false;
            }
            
            if (campaignEvents.length > 0) {
              disabled = false;
            }

            break;
          }

          case 'goal': {
            campaignTeams.forEach(campaignTeam =>
              campaignTeam.teamMembers.forEach(teamMember => {
                if (teamMember.eventGoals.length > 0)
                  disabled = false;
              })
            );

            if (campaignTeams?.length > 0 && campaignEvents.length > 0) {
              disabled = false;
            }

            break;
          }

          default: {
            break;
          }
        }

        steps.push({
          ...step,
          disabled,
        })
      });

      let step = state.step

      if (state.initialStep) {
        step = state.initialStep
      }

      const formValidation  = [...state.formValidation];


      if (formValidation.indexOf('campaignInfo') === -1) {
        if (campaignInfo) {
          if (campaignInfo.name && campaignInfo.startDate && campaignInfo.endDate) {
            formValidation.push('campaignInfo');
          }
        }
      }
      
      if (formValidation.indexOf('campaignTeams') === -1) {
        if (campaignTeams?.length > 0) {
          formValidation.push('campaignTeams');
        }
      }

      if (formValidation.indexOf('campaignEvents') === -1) {
        if (campaignEvents?.length > 0) {
          formValidation.push('campaignEvents');
        }
      }

      return {
        ...state,
        campaignInfo,
        campaignTeams,
        campaignEvents,
        dirtyFalse: [],
        step,
        steps,
        initialStep: null,
        formValidation
      };
    }

    case SET_CAMPAIGN_INFO: {
      const { campaignInfo } = action

      const dirtyFields = [...state.dirtyFields]
      if (dirtyFields.indexOf('campaignInfo') === -1) {
        dirtyFields.push('campaignInfo');
      }

      const formValidation = [state.formValidation];

      if (formValidation.indexOf('campaignInfo') === -1) {
        if (campaignInfo) {
          if (campaignInfo.name && campaignInfo.startDate && campaignInfo.endDate) {
            formValidation.push('campaignInfo');
          }
        }
      }

      return {
        ...state,
        dirtyFields,
        campaignInfo,
        formValidation
      };
    }

    case SET_CAMPAIGN_TEAMS: {
      const { campaignTeams } = action

      const dirtyFields = [...state.dirtyFields]
      
      if (dirtyFields.indexOf('campaignTeams') === -1) {
        dirtyFields.push('campaignTeams');
      }

      const formValidation = [state.formValidation];

      if (state.formValidation.indexOf('campaignInfo') === -1) {
        if (campaignTeams?.length > 0) {
          formValidation.push('campaignInfo');
        }
      }

      return {
        ...state,
        dirtyFields,
        campaignTeams,
        formValidation
      };
    }

    case SET_CAMPAIGN_EVENTS: {
      const { campaignEvents } = action

      const dirtyFields = [...state.dirtyFields]
      
      if (dirtyFields.indexOf('campaignEvents') === -1) {
        dirtyFields.push('campaignEvents');
      }

      const formValidation = [state.formValidation];

      if (formValidation.formValidation.indexOf('campaignInfo') === -1) {
        if (campaignEvents?.length > 0) {
          formValidation.push('campaignInfo');
        }
      }
      
      return {
        ...state,
        dirtyFields,
        campaignEvents,
        formValidation
      };
    }

    case SET_INFO_FORM_VALIDATION: {
      const { isInfoFormValidated } = action

      return {
        ...state,
        isInfoFormValidated
      };
    }

    case SET_DIRTY_FIELD: {
      const dirtyFields = [...state.dirtyFields]

      if (dirtyFields.indexOf(action.field) === -1) {
        dirtyFields.push(action.field);
      }

      return {
        ...state,
        dirtyFields
      };
    }

    case SELECT_EVENT: {
      const { event } = action

      const campaignEvents  = [...state.campaignEvents].concat(event)

      const dirtyFields = [...state.dirtyFields]

      if (dirtyFields.indexOf('campaignEvents') === -1) {
        dirtyFields.push('campaignEvents');
      }

      const formValidation = [...state.formValidation];

      if (formValidation.indexOf('campaignEvents') === -1) {
        if (campaignEvents?.length > 0) {
          formValidation.push('campaignEvents');
        }
      }

      return {
        ...state,
        dirtyFields,
        campaignEvents,
        formValidation
      };
    }

    case REMOVE_EVENT: {
      const { eventId } = action

      const campaignEvents = [...state.campaignEvents];
      const eventIndex = campaignEvents.map(campaignEvent => campaignEvent.eventId).indexOf(eventId);
      campaignEvents.splice(eventIndex, 1);

      const dirtyFields = [...state.dirtyFields]

      if (dirtyFields.indexOf('campaignEvents') === -1) {
        dirtyFields.push('campaignEvents');
      }

      const formValidation = [...state.formValidation];

      if (formValidation.indexOf('campaignEvents') === -1) {
        if (campaignEvents?.length > 0) {
          formValidation.push('campaignEvents');
        } 
      } else if (campaignEvents?.length === 0) {
        const index = formValidation.indexOf('campaignEvents');
        
        if (index > -1) {
          formValidation.splice(index, 1);
        }
      }

      return {
        ...state,
        dirtyFields,
        campaignEvents,
        formValidation
      };
    }

    case SET_CAMPAIGN_GOAL: {
      const { goal } = action

      return {
        ...state,
        campaignInfo: {
          ...state.campaignInfo,
          goal
        }
      };
    }

    case SET_EVENT_TEAM_GOAL: {
      const { value } = action
      const campaignTeams = []

      state.campaignTeams.forEach(campaignTeam => {
        campaignTeams.push({
          ...campaignTeam,
          teamGoal: (campaignTeam.id === action.campaignTeam.id) ? value : campaignTeam.teamGoal
        })
      });

      return {
        ...state,
        campaignTeams
      };
    }

    case SET_EVENT_GOAL: {
      const campaignTeams = []

      state.campaignTeams.forEach(campaignTeam => {
        campaignTeam.teamMembers.forEach(teamMember => {
          teamMember.eventGoals.forEach(eventGoal => {
            if (eventGoal.id === action.eventGoal.id) {
              eventGoal.goal = action.value;
            }
          });
        });

        campaignTeams.push(campaignTeam);
      });

      return {
        ...state,
        campaignTeams
      };
    }

    case OPEN_ORDER_MODAL: {
      const { eventId } = action

      return {
        ...state,
        isOrderModalOpen: true,
        orderModal: {
          ...state.orderModal,
          eventId: eventId
        }
      };
    }

    case CLOSE_ORDER_MODAL: {
      return {
        ...state,
        isOrderModalOpen: false,
        orderModal: {
          ...state.orderModal,
          eventId: null
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default campaignReducer;