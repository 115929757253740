import React, {useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2';
import moment from "moment";
import {CardBody} from "../Shared/Card";
import {Strong} from "../Shared/Style";
import {DashboardCard} from "./Dashboard.style";
import styled from "styled-components";
import {ChevronDown, ChevronUp} from "react-feather";
import {Collapse} from "reactstrap";
import SortableComponent from "./SortableComponent.component";
//import Scrollbar from "react-scrollbars-custom";
import { Scrollbars } from 'react-custom-scrollbars-2';
import DashboardCollapse from "./DashboardCollapse.component";
import {Wrapper} from './Dashboard.style'

const CampaignTotalLineGraph = ({campaignTotal, campaignSum, name ,component, isEditing}) => {

    const [graphSalesData, setGraphSalesData] = useState([])
    const [graphBudgetData, setGraphBudgetData] = useState([])
    const [isCollapsed, setCollapsed] = useState(true)

    const toggle = () => setCollapsed(!isCollapsed);

    useEffect(() => {
        if (campaignTotal) {
            const goalMonths = new Map()

            let m = moment()
            for (let i = 0; i < 12; i++) {
                const date = m.month(i).format('YYYY-MM')
                goalMonths.set(date, 0)

                if (campaignTotal.goal[date]) {
                    goalMonths.set(date, campaignTotal.goal[date])
                }
            }

            setGraphBudgetData([...goalMonths.entries()].map(o => o[1]))
        }
    }, [campaignTotal])

    useEffect(() => {
        if (campaignSum) {
            const salesMonths = new Map()

            let m = moment()
            for (let i = 0; i < 12; i++) {
                const date = m.month(i).format('YYYY-MM')
                salesMonths.set(date, 0)
            }

            let totalSales = 0

            if (campaignSum.campaignScoreChildViewModelList) {
                campaignSum.campaignScoreChildViewModelList.forEach(campaign => {
                    const month = moment(campaign.startDate).format('YYYY-MM')
                    salesMonths.set(month, campaign.sumOrders)
                    totalSales += campaign.sumOrders
                })
            }

            setGraphSalesData([...salesMonths.entries()].map(o => o[1]))
        }
    }, [campaignSum])

    return (
        <Wrapper>
            <DashboardCollapse component={component}>
                <Scrollbars style={{height: 800, scrollWidth: 5}}>
                <div className="cards px-4">
                    <div className='d-flex justify-content-between w-100'>
                        <div className="align-self-center"></div>
                    </div>

                        <Strong>Försäljning</Strong>
                        <DashboardLine data={{
                            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                            datasets: [{
                                label: 'Försäljning',
                                data: graphSalesData,
                                fill: true,
                                backgroundColor: 'rgb(40, 167, 69, 0.2)',
                                borderColor: 'rgb(40, 167, 69)',
                                tension: 0.3,
                            },
                                {
                                    label: 'Budget',
                                    data: graphBudgetData,
                                    fill: false,
                                    borderColor: 'rgb(54, 162, 235)',
                                    tension: 0.1,
                                }
                            ]
                        }}
                           options={{
                               plugins: {
                                   legend: {
                                       labels: {
                                           font: {
                                               // size: 36
                                           }
                                       }
                                   }
                               },
                               scales: {
                                   x: {
                                       ticks: {
                                           font: {
                                               // size: 28
                                           }
                                       }
                                   },
                                   y: {
                                       ticks: {
                                           font: {
                                               // size: 28
                                           }
                                       }
                                   }
                               }
                           }}/>
                </div>
                </Scrollbars>
            </DashboardCollapse>
        </Wrapper>
    );
}
export default CampaignTotalLineGraph;

const DashboardLine = styled(Line)`
  
  
  @media (min-width: 2000px){
    width: auto !important;
  }
`


