import axios from 'axios';

const baseUrl = '/quinyx';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

export function findAllByDates(dateFrom, dateTo) {
  let url = `${baseUrl}?dateFrom=${dateFrom}&dateTo=${dateTo}`
  
  return http
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function findAllByUserIdsAndDates(userIds, dateFrom, dateTo) {
  let url = `${baseUrl}/users?`
  
  for (let i = 0; i < userIds.length; i++) {
    if (i === 0) {
      url += `userId=${userIds[i]}`
    }
    
    url += `&userId=${userIds[i]}`
  }
  
  url += `&dateFrom=${dateFrom}&dateTo=${dateTo}`
  
  return http
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function findAllByTeamIdAndDates(teamId, dateFrom, dateTo) {
  let url = `${baseUrl}/teams?teamId=${teamId}&dateFrom=${dateFrom}&dateTo=${dateTo}`
  
  return http
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

function verify(...candidates) {
  for (let i = 0; i < candidates.length; i++) {
    if (typeof candidates[i] === 'boolean') {
      continue
    }
    
    if (!candidates[i]) {
      return false
    }
  }
  
  return true
}

export const QuinyxApi = {
  findAllByDates,
  findAllByTeamIdAndDates,
  findAllByUserIdsAndDates
}
