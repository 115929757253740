import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import './scss/theme.scss';
import './app.scss';

import {clearAlerts} from '../actions/alert.actions';
import {history} from '../helpers';

import Routes from '../routes';
import {setUserList} from '../actions/userList.actions';
import {userService} from '../services/user.service';
import CookieConsentPopup from '../components/Cookies/CookieConsentPopup.component';

const App = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.authentication.user);
  const userList = useSelector(state => state.userList.users);

  history.listen((location, action) => {
    dispatch(clearAlerts());
  });

  useEffect(() => {
    if (currentUser && (!userList || userList.length === 0)) {
      userService.findAll()
        .then(users => dispatch(setUserList(users)))
    }
  }, [currentUser, userList]);

  return (
    <>
      <CookieConsentPopup />
      <Routes/>
    </>
  )
};

export default App;