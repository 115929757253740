import {
  FIND_BY_USER_ID,
  FIND_BY_USER_ID_ERROR,
  FIND_BY_USER_ID_SUCCESS,
  SET_STATISTICS
} from '../types/statistics.types';

const initialState = { statistics: [] };

const statisticsReducer = (state = initialState, action) => {

  switch (action.type) {

    case FIND_BY_USER_ID:
      return state;

    case FIND_BY_USER_ID_SUCCESS:
      state.statistics = action.statistics;
      return state;

    case SET_STATISTICS: {
      state.statistics = action.statistics;
      return state;
    }

    case FIND_BY_USER_ID_ERROR: {
      state.statistics = [];
    }

    default:
      return state;
  }
};

export default statisticsReducer;