import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Activity, Archive, Award, Home, MessageSquare, Monitor, PlusCircle, User, Users} from 'react-feather';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import {openOrderModal} from '../../actions/campaign.actions';
import {openShoutoutModal} from '../../actions/shoutout.actions';
import ShoutoutModal from '../Shoutout/ShoutoutModal.component';
import CountUp from 'react-countup';
import {NavHeader, NavText, Sidebar} from './Navigation.styles';
import UserImage from '../User/UserImage.component';
import BontiNavigation from '../Bonti/BontiNavigation';
import {getDashboards, openDashboardModal} from '../../actions/dashboard.actions';
import DashboardModal from "../Dashboard/DashboardModal.component";
import {history} from "../../helpers";

const LeftNavigation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.authentication.user);
  const isShoutoutModalOpen = useSelector(state => state.shoutout.isModalOpen);

  const selectedCampaign = useSelector(state => state.campaign.selectedCampaign);
  const campaignScore = useSelector(state => state.score.score);
  const layoutSettings = useSelector(state => state.layout.settings);
  const dashboards = useSelector(state => state.dashboard.dashboards);

  const [userTeam, setUserTeam] = useState('');
  const [userScore, setUserScore] = useState(0);
  const [userPosition, setUserPosition] = useState(null);
  const [displayName] = useState(currentUser ? currentUser.displayName : '');

  const toggleCampaignOrderModal = (e) => {
    e.preventDefault();
    dispatch(openOrderModal());
  };

  const toggleShoutoutModal = (e) => {
    e.preventDefault();
    dispatch(openShoutoutModal());
  };

  const toggleDashboardModal = (e) => {
    e.preventDefault();
    history.push("/dashboard/add")
  };

  useEffect(() => {

    if (!currentUser) {
      return
    }

    if (campaignScore) {
      let scores = new Map();
      let foundUserScore = false;

      if (campaignScore.campaignTeams) {
        campaignScore.campaignTeams.forEach(campaignTeam => {
          campaignTeam.campaignTeamMembers.forEach(teamMember => {
            if (teamMember.userUniqueId === currentUser.id) {
              setUserScore(Math.floor(parseFloat(teamMember.score) * 100) / 100);
              foundUserScore = true;
            }
            scores.set(teamMember.userUniqueId, teamMember.score);
          });
        });
      }

      if (foundUserScore === false)
        setUserScore(0);

      if (scores.size > 0) {
        let sortedEntries = [...scores.entries()]
          .sort((a, b) => {
            return b[1] - a[1];
          });

        let userEntry = sortedEntries.find(entry => entry[0] === currentUser.id);
        if (userEntry) {
          let position = sortedEntries.indexOf(userEntry) + 1;
          setUserPosition(position);
        } else {
          setUserPosition(0);
        }
      }
    }
  }, [campaignScore]);

  useEffect(() => {

    if (!currentUser) {
      return
    }

    if (selectedCampaign) {
      selectedCampaign.campaignTeams.forEach(campaignTeam => {
        campaignTeam.teamMembers.forEach(teamMember => {
          if (teamMember.userId === currentUser.id) {
            setUserTeam(campaignTeam.teamName);
          }
        });
      });
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (currentUser) {
      dispatch(getDashboards(currentUser.companyId))
    }
  }, [currentUser])

  return (
    <Sidebar id="aside" className={classNames("page-sidenav no-shrink nav-dropdown fade", layoutSettings.aside)} aria-hidden="true">
      <div className="sidenav h-100 modal-dialog bg-white">
        <div className="navbar">
          <Link to={{pathname: '/'}} className="navbar-brand">
            <i className="zmdi zmdi-view-dashboard"/>
            <span className="hidden-folded d-inline l-s-n-1x">Salestrigger</span>
          </Link>
        </div>

        <div className="flex scrollable hover">
          {currentUser && <div className="nav-fold flex-column pt-4">
            <div className="d-flex p-2">
                <span className="avatar w-56 circle">
                  <UserImage userId={currentUser.id} type="thumb"/>
                </span>
            </div>
            <div className="hidden-folded w-100 p-2 pb-4 b-b">
              <div className="text-center">
                {currentUser &&
                <Link to={{pathname: `/profile/${currentUser.id}`}} className="mr-auto text-nowrap text-primary">
                  {displayName}
                  <small className="d-block text-sm text-muted">{userTeam}</small>
                </Link>
                }
              </div>
              <div className="d-flex text-center pt-4">
                {/*{ currentUser && !currentUser.isBontiUser() &&*/}
                {/*  <div className="px-2 flex">*/}
                {/*    <div className="font-weight-bold">*/}
                {/*      <CountUp end={userScore} duration={2} decimals={2} separator={" "}/>*/}
                {/*    </div>*/}
                {/*    <small className="text-muted">Poäng</small>*/}
                {/*  </div>*/}
                {/*}*/}
                <div className="px-2 flex">
                  <div className="font-weight-bold">{userPosition ? userPosition : 0}</div>
                  <small className="text-muted">Placering</small>
                </div>
              </div>
            </div>
          </div>
          }

          { currentUser &&
            <ul className="nav bg">
              <NavHeader className="hidden-folded">
                <span className="text-muted">Start</span>
              </NavHeader>

              <li className={classNames({active: location.pathname === '/'})}>
                <Link to={{pathname: '/'}}>
                  <span className="nav-icon">
                    <Home size={16}/>
                  </span>
                  <NavText className="nav-text">Dashboard</NavText>
                </Link>
              </li>

              <React.Fragment>
                {currentUser && !currentUser.isBontiUser() &&
                  <>
                    { (selectedCampaign && selectedCampaign.active === 'Active') && location.pathname === '/' &&
                    <li>
                      <a href="/#" onClick={toggleCampaignOrderModal}>
                      <span className="nav-icon">
                        <PlusCircle size={16}/>
                      </span>
                        <NavText className="nav-text">Registrera</NavText>
                      </a>
                    </li>
                    }
                  </>
                }

                { currentUser && currentUser.hasRole('Admin', 'TeamLeader') &&
                  <li>
                    <a href="/#" onClick={toggleShoutoutModal}>
                      <span className="nav-icon">
                        <MessageSquare size={16}/>
                      </span>
                      <NavText className="nav-text">Shoutout</NavText>
                    </a>
                  </li>
                }
              </React.Fragment>

              <NavHeader className="hidden-folded">
                <NavText className="text-muted">Sidor</NavText>
              </NavHeader>

              {/*<li className={classNames({ active: location.pathname === '/dashboard/add'})}>*/}
              {/*  <a href="/#" onClick={toggleDashboardModal}>*/}
              {/*        <span className="nav-icon">*/}
              {/*          <PlusCircle size={16}/>*/}
              {/*        </span>*/}
              {/*    <NavText className="nav-text">Ny dashboard</NavText>*/}
              {/*  </a>*/}
              {/*</li>*/}

              {dashboards.filter(dashboard => !dashboard.isPrimary).map((dashboard, i) => (
                <li key={i} className={classNames({active: location.pathname === `/dashboard${dashboard.path}`})}>
                  <Link to={{pathname: `/dashboard${dashboard.path}`}}>
                    <span className="nav-icon">
                      <Monitor size={16}/>
                    </span>
                    <NavText className="nav-text">{dashboard.name}</NavText>
                  </Link>
                </li>
              ))}

              <li className={classNames({active: location.pathname.indexOf('/teamleaderboard') > -1})}>
                <Link to={{pathname: `/teamleaderboard`}}>
                  <span className="nav-icon">
                    <Monitor size={16}/>
                  </span>
                  <NavText className="nav-text">Team Leaderboard</NavText>
                </Link>
              </li>

              <li className={classNames({active: location.pathname.indexOf('/campaignleaderboard') > -1})}>
                <Link to={{pathname: `/campaignleaderboard`}}>
                  <span className="nav-icon">
                    <Monitor size={16}/>
                  </span>
                  <NavText className="nav-text">Kampanj Leaderboard</NavText>
                </Link>
              </li>

              <li className={classNames({active: location.pathname.indexOf('/leaderboard') > -1})}>
                <Link to={{pathname: `/leaderboard${selectedCampaign ? `/${selectedCampaign.id}` : ''}`}}>
                  <span className="nav-icon">
                    <Monitor size={16}/>
                  </span>
                  <NavText className="nav-text">Leaderboard</NavText>
                </Link>
              </li>

              <li className={classNames({active: location.pathname.indexOf('/tv-leaderboard') > -1})}>
                <Link to={{pathname: `/tv-leaderboard${selectedCampaign ? `/${selectedCampaign.id}` : ''}`}}>
                  <span className="nav-icon">
                    <Monitor size={16}/>
                  </span>
                  <NavText className="nav-text">TV Leaderboard</NavText>
                </Link>
              </li>

              <li className={classNames({active: location.pathname === '/orders'})}>
                <Link to={{pathname: '/orders'}}>
                  <span className="nav-icon">
                    <Archive size={16}/>
                  </span>
                  <NavText className="nav-text">Ordrar</NavText>
                </Link>
              </li>
            </ul>
          }

          {(location.pathname.indexOf('bonti') > -1) && (
            <BontiNavigation/>
          )}

          { currentUser && currentUser.hasRole('Admin', 'TeamLeader') &&
            <ul className="nav">
              <NavHeader className="hidden-folded">
                <span className="text-muted">Inställningar</span>
              </NavHeader>

              <li className={classNames({active: location.pathname.indexOf('/campaigns') > -1})}>
                <Link to={{pathname: '/campaigns'}}>
                  <span className="nav-icon">
                    <Award size={16}/>
                  </span>
                  <NavText className="nav-text">Kampanjer</NavText>
                </Link>
              </li>

              <li className={classNames({active: location.pathname === '/users'})}>
                <Link to={{pathname: '/users'}}>
                  <span className="nav-icon">
                    <User size={16}/>
                  </span>
                  <NavText className="nav-text">Användare</NavText>
                </Link>
              </li>

              <li className={classNames({active: location.pathname === '/teams'})}>
                <Link to={{pathname: '/teams'}}>
                  <span className="nav-icon">
                    <Users size={16}/>
                  </span>
                  <NavText className="nav-text">Lag</NavText>
                </Link>
              </li>

              <li className={classNames({active: location.pathname === '/events'})}>
                <Link to={{pathname: '/events'}}>
                  <span className="nav-icon">
                    <Activity size={16}/>
                  </span>
                  <NavText className="nav-text">Aktiviteter</NavText>
                </Link>
              </li>

              { currentUser && currentUser.companyId === 'ca2516ec-545b-445e-894d-fe3bbb5e4b99' &&
                <li className={classNames({active: location.pathname === '/companies'})}>
                  <Link to={{pathname: '/companies'}}>
                    <span className="nav-icon">
                      <Home size={16}/>
                    </span>
                    <NavText className="nav-text">Företag</NavText>
                  </Link>
                </li>
              }
            </ul>
          }
        </div>

        <div className='pt-3'/>
      </div>

      { isShoutoutModalOpen && <ShoutoutModal/> }
      <DashboardModal/>
    </Sidebar>
  )
};

export default LeftNavigation;
