import React from 'react';
import './Loading.styles.scss';

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="d-flex justify-content-center">
        <div className="brand-logo p-4 mt-5 d-flex flex-column h-100">
          <div className="navbar-brand align-self-center">
            <i className="zmdi zmdi-view-dashboard"></i>
            <span className="hidden-folded d-inline l-s-n-1x align-self-center">Salestrigger</span>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default Loading;