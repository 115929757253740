import client from '../helpers/client';

export const shoutoutService = {
  findAll,
  create,
  update,
  delete: deleteShoutout
};

const baseUrl = '/shoutout';

function findAll(limit) {
  let url = baseUrl;

  if (limit) {
    url += '?' + client.getClient().query({ limit: 1 });
  }

  return client.getClient()
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });
}

function create(data = {}) {
  return client.getClient()
    .post(baseUrl, data)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

function update(shoutoutId, data = {}) {
  let url = baseUrl + `/${shoutoutId}/`;

  return client.getClient()
    .patch(url, data)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

function deleteShoutout(shoutoutId) {
  let url = baseUrl + `/${shoutoutId}/`;

  return client.getClient()
    .delete(url)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}