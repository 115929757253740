import client from "../../helpers/client";
import authService from "../../services/auth.service";

const baseUrl = "/users";

export function findAll(companyId) {

  let url = baseUrl;

  if (companyId) {
    url += `?companyId=${companyId}`;
  }

  return client
    .getClient()
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch((err) => {
      if (err.response) {
        let errorMessage = err.response.data || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function get(userId) {
  const url = `${baseUrl}/${userId}`;

  return client
    .getClient()
    .get(url)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      if (err.response) {
        let errorMessage = err.response.data || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function create(data = {}) {
  return client
    .getClient()
    .post(baseUrl, data)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      if (err.response) {
        let errorMessage = err.response.data || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function update(userId, data = {}) {
  const url = `${baseUrl}/${userId}`;

  return client
    .getClient()
    .put(url, data)
    .then((response) => Promise.resolve(response.data))
    .catch((err) => {
      if (err.response) {
        let errorMessage = err.response.data || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function deleteUser(userId) {
  const url = `${baseUrl}/${userId}`;

  return client
    .getClient()
    .delete(url)
    .then((response) => Promise.resolve(response.data))
    .catch((err) => {
      if (err.response) {
        let errorMessage = err.response.data || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function getImage(userId, type) {
  const url = `${baseUrl}/${userId}/image/${type}`;
  return client.getClient().get(url);
}

export function uploadImage(userId, data) {
  const url = `${baseUrl}/upload`;

  return client.getClient().post(url, data, {
    headers: {
      Authorization: `Bearer ${authService.getToken()}`,
      "Content-Type": "multipart/form-data",
    },
  });
}

export function translateRole(role) {
  switch (role) {
    case "Seller": {
      return "Säljare";
    }

    case "TeamLeader": {
      return "Lagledare";
    }
  }

  return role;
}

export default {
  findAll,
  get,
  update,
  create,
  delete: deleteUser,
  getImage,
  uploadImage,
  translateRole,
};
