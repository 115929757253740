import {
  LAYOUT_LIGHT,
  LAYOUT_DARK, LAYOUT_FOLD_NAV, LAYOUT_UNFOLD_NAV
} from "../types/layout.types";

export function setLightMode() {
  return dispatch => dispatch({ type: LAYOUT_LIGHT });
}

export function setDarkMode() {
  return dispatch => dispatch({ type: LAYOUT_DARK });
}

export function foldLeftNav () {
  return dispatch => dispatch({ type: LAYOUT_FOLD_NAV});
}

export function unfoldLeftNav () {
  return dispatch => dispatch({ type: LAYOUT_UNFOLD_NAV});
}