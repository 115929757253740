import {CLOSE_SHOUTOUT_MODAL, OPEN_SHOUTOUT_MODAL,} from '../types';

const initialState = {
  isModalOpen: false
};

const shoutoutReducer = (state = initialState, action) => {
  switch (action.type) {

    case OPEN_SHOUTOUT_MODAL:
      state.isModalOpen = true;
      return state;

    case CLOSE_SHOUTOUT_MODAL:
      state.isModalOpen = false;
      return state;

    default:
      return state;
  }
};

export default shoutoutReducer;