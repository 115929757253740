import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Menu, Plus, Search, Trash, ChevronLeft, ChevronRight} from 'react-feather';
import {Button, ButtonGroup, Input} from 'reactstrap';
import {teamService} from '../../services/team.service';
import {history, setDocumentTitle} from '../../helpers';

const TeamList = () => {
  const [teams, setTeams] = useState([]);
  const [searchPhrase, setSearchPhrase] = useState('');

  const searchFilter = (team) => {
    let name = team.name.toLowerCase();
    return name.indexOf(searchPhrase.toLowerCase()) !== -1;
  };

  const userSort = (userA, userB) => {
    return (userA.firstName + ' ' + userA.lastName)
      .localeCompare((userB.firstName + ' ' + userB.lastName));
  };

  const newTeam = () => {
    history.push('/teams/add');
  };

  const deleteTeam = (team) => {
    teamService.delete(team.id)
      .then(() => teamService.findAll().then(teams => setTeams(teams)));
  };

  useEffect(() => {
    setDocumentTitle('Lag');
    teamService.findAll()
      .then(teams => setTeams(teams));
  }, []);

  return (
    <div id="user-container">
      <div className="d-flex flex fixed-content">
        <div className="d-flex flex" id="content-body">
          <div className="d-flex flex-column flex" id="user-list" data-plugin="user">
            <div className="p-3">
              <div className="toolbar ">

                <div className="btn-group">
                  <button className="btn btn-sm btn-icon btn-white" data-toggle="tooltip" title="Trash" id="btn-trash">
                    <Trash size={16} className="text-muted"/>
                  </button>

                  <button className="btn btn-sm btn-icon btn-white sort " data-sort="item-author" data-toggle="tooltip"
                          title="Sort">
                    <i className="sorting"></i>
                  </button>
                </div>

                <form className="flex">
                  <div className="input-group">
                    <Input type="text"
                           className="form-control form-control-theme form-control-sm search"
                           placeholder="Search"
                           required
                           onChange={(e) => setSearchPhrase(e.target.value)} />

                    <span className="input-group-append">
                      <button className="btn btn-white no-border btn-sm" type="button">
                        <span className="d-flex text-muted">
                          <Search size={16}/>
                        </span>
                      </button>
                    </span>
                  </div>
                </form>

                <ButtonGroup>
                  <Button className="no-border btn-sm" color="white" title="Ny användare" onClick={newTeam}>
                    <Plus size={16} className="mr-1"/>
                    Lägg till
                  </Button>
                </ButtonGroup>

                <button className="btn btn-sm btn-icon btn-white d-md-none" data-toggle="modal" data-target="#content-aside" data-modal>
                  <Menu size={16}/>
                </button>
              </div>
            </div>
            <div className="scroll-y mx-3 mb-0 card">
              <div className={`list list-row ${teams.length === 0 ? 'hide' : ''}`}>
                {
                  teams
                    .filter(searchFilter)
                    .sort(userSort)
                    .map((team, index) => {
                      return (
                        <div className="list-item" key={index}>
                          <div>
                            <Link to={{pathname: `/teams/${team.id}`}}>
                              <span className="w-40 avatar gd-warning" title={team.name}>
                                {team.name.substring(0, 1).toUpperCase()}
                              </span>
                            </Link>
                          </div>

                          <div className="flex">
                            <Link to={{pathname: `/teams/${team.id}`}} className="item-author text-primary">
                              {team.name}
                            </Link>
                          </div>

                          {/*<div className="item-action dropdown">
                            <a href="#" data-toggle="dropdown" className="text-muted">
                              <MoreVertical size={16}/>
                            </a>

                            <div className="dropdown-menu dropdown-menu-right bg-black" role="menu">
                              <a className="dropdown-item trash" onClick={() => deleteTeam(team)}>Ta bort</a>
                            </div>
                          </div>*/}
                        </div>
                      )
                  })}
              </div>

              <div className="no-result hide">
                <div className="p-4 text-center">
                  No Results
                </div>
              </div>
            </div>

            <div className="px-3 py-3 mt-auto">
              <div className="d-flex align-items-center">

                <div className="flex d-flex flex-row">
                  <button className="btn btn-sm no-bg no-shadow px-0 pager-prev">
                    <ChevronLeft size={16}/>
                  </button>
                  <div className="pagination pagination-sm mx-1">
                  </div>
                  <button className="btn btn-sm no-bg no-shadow px-0 pager-next">
                    <ChevronRight size={16}/>
                  </button>
                </div>

                <div>
                  <span className="text-muted">Total:</span>
                  <span id="count"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamList;