import client from '../helpers/client';
import authService from './auth.service';

export const messageService = {
  findByCampaignId,
  create,
  partialUpdate,
  delete: deleteMessage,
  getImage,
  uploadImage
};

const baseUrl = '/messages';

function findByCampaignId(campaignId) {
  let url = baseUrl + `/${campaignId}/`;

  return client.getClient()
    .get(url)
    .then(response => {
      return Promise.resolve(response.data || []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

function create(campaignId, data = {}) {
  let url = baseUrl + `/${campaignId}/`

  return client.getClient()
    .post(url, data)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

function partialUpdate(campaignId, data = {}) {
  let url = baseUrl + `/${campaignId}/`

  return client.getClient()
    .patch(url, data)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

function deleteMessage(messageId) {
  let url = baseUrl + `/${messageId}/`

  return client.getClient()
    .delete(url)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

function getImage(messageId, type) {
  const url = `${baseUrl}/${messageId}/upload/message.png`;
  return client.getClient()
    .get(url);
}

function uploadImage (messageId, data = {}) {
  const url = `${baseUrl}/${messageId}/upload`;

  return client.getClient()
    .post(url, data, {
      headers: {
        'Authorization': `Bearer ${authService.getToken()}`,
        'Content-Type': 'multipart/form-data'
      }
    });
  
}