import React, {Fragment, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Select from 'react-select';
import CountUp from 'react-countup';
import {Pagination} from '../components/Shared/Pagination';
import UserImage from '../components/User/UserImage.component';
import LeaderboardTopTeams from '../components/Leaderboard/LeaderboardTopTeams.component';
import LeaderboardDailyUserTopScore from '../components/Leaderboard/LeaderboardDailyUserTopScore';
import {setCampaignList} from '../actions/campaign.actions';
import {setScore} from '../actions/score.actions';
import {setDocumentTitle} from '../helpers';
import {campaignService} from '../services/campaign.service';
import ScoreUtil from '../components/Score/ScoreUtil';
import moment from 'moment';
import {BontiApi} from "../api/Bonti/Bonti.api";
import {CampaignApi} from "../api/Campaign/Campaign.api";
import {useInterval} from "../hooks/useInterval";
import {BreadCrumb} from "../components/Breadcrumb/Breadcrumb";
import {Flex, FlexDashboard, FlexRow, Wrapper} from "./pages.style";

const TeamLeaderboard = (props) => {
    const currentUser = useSelector(state => state.authentication.user);
    const [data, setData] = useState([]);

    let [dataTopList, setDataTopList] = useState([]);

    const fetchData = () => {

        campaignService.findCompanyCampaignsTeamTopList(currentUser.companyId, 'active')
            .then(campaigns => {
                setDataTopList(campaigns)
            });

    }


    const sumTeamValue = (team) => {

        let sum = 0;
        for (let i = 0; i < team.length; i++) {
            const obj = team[i];
            sum += Number(obj.value);
        }

        return sum;
    }


    useEffect(() => {
        fetchData();
    }, []);

    useInterval(fetchData, 10000);

    return (
        <div className="col-md-12 col-lg-12">
            <BreadCrumb
                title={'Team Leaderboard'}
                breadcrumbs={[{name: 'Dashboard', href: '/'}]}
            />
            <div className="row row-sm">
                <div className="col-md-12 mt-2">
                    <Fragment>
                        <div className="row row-sm gap-2">
                            <Wrapper>
                                <div className="d-md-flex">
                                    <FlexDashboard>
                                            {dataTopList
                                                .map((item, i) => {
                                                    return (
                                                        <div className='p-2'>
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div
                                                                        className="text-muted text-sm sr-item">{item.name}</div>
                                                                    <h5 className="text-highlight sr-item">{item.teams.map((eventName) => eventName.eventName).at(0)}</h5>
                                                                </div>
                                                                <table
                                                                    className="table table-theme v-middle table-hover">
                                                                    <tbody>
                                                                    {item.teams

                                                                        .slice(0, 5)
                                                                        .map((team, index) => (
                                                                            <tr className="v-middle" key={index}>
                                                                                <td width="80">
                                                                                    <div className="avatar-group ">
                                                                                        <div>
                                                                          <span className="w-32 avatar no-shadow">
                                                                            {index === 0 &&
                                                                                <img src="/assets/img/trophy/T1.png"
                                                                                     alt="."/>}
                                                                              {index === 1 &&
                                                                                  <img src="/assets/img/trophy/T12.png"
                                                                                       alt="."/>}
                                                                              {index === 2 &&
                                                                                  <img src="/assets/img/trophy/T4.png"
                                                                                       alt="."/>}
                                                                              {/*index >= 3 && index <= 4 && <img src="/assets/img/trophy/T8.png" alt="."/> */}
                                                                              {index >= 3 && <small
                                                                                  className="text-muted font-weight-bold">{(index + 1)}</small>}
                                                                          </span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="avatar ajax w-24"
                                                                                            data-toggle="tooltip"
                                                                                            title="" data-pjax-state=""
                                                                                            data-original-title="Cursus">
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="flex">
                                                                                    <div
                                                                                        className="item-title text-color h-1x">
                                                                                        {team.teamName}
                                                                                    </div>
                                                                                </td>
                                                                                <td align="right" className="pr-4">
                                                                                    <div className="avatar-group right">
                                                                            <span
                                                                                className="item-amount d-none d-sm-block text-sm text-nowrap ml-4">
                                                                                <CountUp start={0}
                                                                                         end={sumTeamValue(team.list)}
                                                                                         separator={" "}/> {team.list[0].unit}
                                                                            </span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </div>
                                                    )
                                                })}
                                    </FlexDashboard>
                                </div>
                            </Wrapper>

                        </div>
                    </Fragment>
                </div>
            </div>

        </div>
    );
}

export default TeamLeaderboard;

export const ProgressValue = styled.div`
  align-self: center;
  margin-left: 0.5rem;
  font-size: 14px;
`
