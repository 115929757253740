import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Select from 'react-select';
import CountUp from 'react-countup';
import {Pagination} from '../components/Shared/Pagination';
import UserImage from '../components/User/UserImage.component';
import LeaderboardTopTeams from '../components/Leaderboard/LeaderboardTopTeams.component';
import LeaderboardDailyUserTopScore from '../components/Leaderboard/LeaderboardDailyUserTopScore';
import {setCampaignList} from '../actions/campaign.actions';
import {setScore} from '../actions/score.actions';
import {setDocumentTitle} from '../helpers';
import {campaignService} from '../services/campaign.service';
import ScoreUtil from '../components/Score/ScoreUtil';
import moment from 'moment';
import {BontiApi} from "../api/Bonti/Bonti.api";
import {CampaignApi} from "../api/Campaign/Campaign.api";

const Leaderboard = (props) => {
  let campaignId = props.match.params.id;
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.authentication.user);
  const campaigns = useSelector(state => state.campaign.campaignList);
  const campaignScore = useSelector(state => state.score.score);

  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selectedCampaignOption, setSelectedCampaignOption] = useState(null);
  const [userScores, setUserScores] = useState([]);
  const [_goal, setGoal] = useState(null);

  const [isModalOpen, setModalOpen] = useState(false);
  
  const [currentBestParticipantPage, setCurrentBestParticipantPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const loadScores = () => {
    if (selectedCampaign) {
      campaignService.findScores(selectedCampaign.id)
        .then(campaignScore => {
          dispatch(setScore(campaignScore));
        });
    }
  };

  const topPercentageSort = (a, b) => {
    if (a.percentage === b.percentage) {
      return a.latestOrder - b.latestOrder
    }
    
    return b.percentage - a.percentage
  }

  const toggleModal = (e) => {
    e.preventDefault();

    if (isModalOpen) {
      setModalOpen(false);
    }
    else {
      setModalOpen(true);
    }
  };

  const onChangeCampaign = (campaign) => {
    setSelectedCampaignOption(campaign);
  };

  const handleSubmit = (e) => {
    e.preventDefault();


    let campaign = campaigns.find(c => c.id === selectedCampaignOption.value);

    if (campaign) {
      setSelectedCampaign(campaign);
      campaignId = selectedCampaignOption.value;
    }

    setModalOpen(false);
  };

  const topScoreSort = (a, b) => {
    return b.value - a.value;
  }

  const handleBestParticipantsPageChange = (page) => {
    setCurrentBestParticipantPage(page);
  }

  const getTrophyTitle = (achievement) => {
    let title = '';

    if (achievement.amount > 1) {
      switch (achievement.position) {
        case 1: {
          title = `${achievement.amount} första platser`
          break;
        }
        case 2: {
          title = `${achievement.amount} andra platser`
          break;
        }
        case 3: {
          title = `${achievement.amount} tredje platser`
          break;
        }
        case 4: {
          title = `${achievement.amount} fjärde platser`
          break;
        }
      }
    }

    return title;
  }

  useEffect(() => {

    campaignService.findByTeamMember(currentUser)
      .then(campaigns => {
        campaigns = campaigns
          .filter(campaign => moment(campaign.startDate) <= moment());
        
        dispatch(setCampaignList(campaigns));

        let options = [];
        campaigns.forEach(campaign => {
          let option = {
            value: campaign.id,
            label: campaign.name
          };

          options.push(option);
        });

        setCampaignOptions(options);

        if(campaignId != null) {
          let campaign = campaigns.find(campaign => campaign.id === campaignId);
          
          setSelectedCampaign(campaign);
          setSelectedCampaignOption(options.find(option => option.value === campaignId));
          setDocumentTitle(campaign.name);
        }
        /*else if (selectedCampaign != null && selectedCampaign.id != null) {
          setSelectedCampaign(campaigns.find(campaign => campaign.id === selectedCampaign.id));
          setSelectedCampaignOption(options.find(option => option.value === selectedCampaign.id));
        }*/
        else {
          let latestCampaign = campaigns.find(campaign => campaign.active === 'Active');

          if (latestCampaign) {
            setSelectedCampaign(latestCampaign);
            setSelectedCampaignOption(options.find(option => option.value === latestCampaign.id));
            setDocumentTitle(latestCampaign.name);
          }
        }
      });

    loadScores();
  }, []);

  useEffect(() => {
    if (selectedCampaign != null) {
      loadScores()
      
     /* if (selectedCampaign.parent === null) {
        CampaignApi.getParentCampaignTotal(currentUser.companyId)
          .then(result => {
            const userScores = []
            const goal = result.goal.total
            const eventIds = selectedCampaign.campaignEvents
              .map(campaignEvent => campaignEvent.eventId)
            
            if (!goal) {
              return
            }
            
            selectedCampaign.campaignTeams.forEach(campaignTeam => {
              campaignTeam.teamMembers.forEach(teamMember => {
                const percentage = ScoreUtil.getCampaignTotalPercentageFromTeamMember(teamMember, goal, eventIds)
                
                teamMember.teamName = campaignTeam.teamName
                teamMember.percentage = percentage
                
                userScores.push(teamMember)
              })
            })
  
            setUserScores(userScores.sort())
          })
          .catch(err => {
            console.log(err)
          })
      }*/
    }
  }, [selectedCampaign]);
  

  useEffect(() => {
    if (campaignScore) {
      if (selectedCampaign && selectedCampaign.parent === null) {
        CampaignApi.getParentCampaignTotal(currentUser.companyId)
        .then(result => {
          const userScores = []
          const goal = result.goal.total
          const eventIds = selectedCampaign.campaignEvents
            .map(campaignEvent => campaignEvent.eventId)
      
          if (!goal) {
            return
          }
          
          setGoal(goal)
      
          campaignScore.campaignTeams.forEach(campaignTeam => {
            campaignTeam.campaignTeamMembers.forEach(teamMember => {
              const percentage = ScoreUtil.getCampaignTotalPercentageFromTeamMember(teamMember, goal, eventIds)
          
              teamMember.teamName = campaignTeam.teamName
              teamMember.percentage = percentage
              teamMember.parentCampaignGoal = goal
          
              userScores.push(teamMember)
            })
          })
      
          setUserScores(userScores.sort())
        })
        .catch(err => {
          console.log(err)
        })
      } else {
        let userScores = [];
        
        campaignScore.campaignTeams.forEach(campaignTeam => {
          campaignTeam.campaignTeamMembers.forEach(teamMemberScore => {
            teamMemberScore.teamName = campaignTeam.teamName;
            teamMemberScore.percentage = ScoreUtil.getPercentageFromTeamMember(teamMemberScore)
            userScores.push(teamMemberScore);
          });
        });
  
        setUserScores(userScores.sort());
      }
    }
  }, [campaignScore, selectedCampaign]);

  const renderBestParticipantsTable = (itemsPerPage) => {
    let paged = [];

    userScores.sort(topPercentageSort);

    for (let i = (currentBestParticipantPage - 1) * itemsPerPage; i < (currentBestParticipantPage * itemsPerPage) && i < userScores.length; i++) {
      paged.push(userScores[i]);
    }
    return (
      // add offset to index so that the right reward icons is rendered for the right participants
      paged.sort(topPercentageSort).map((user, index) => renderBestParticipant(user, (index+((currentBestParticipantPage-1)*itemsPerPage))))
    );
  }

  const renderBestParticipant = (userScore, index) => {
    let achievementMap = new Map();

    if (userScore && userScore.achievements) {
      userScore.achievements.forEach(achievement => {
        if (achievement.position > 5) return;

        if (achievementMap.has(achievement.position)) {
          let value = achievementMap.get(achievement.position) + 1;
          achievementMap.set(achievement.position, value);
        }
        else {
          achievementMap.set(achievement.position, 1);
        }
      })
    }

    let achievements = [];

    [...achievementMap.entries()]
      .forEach(achievement => achievements.push({
        position: achievement[0],
        amount: achievement[1],
      }));

    return (
      <tr className=" v-middle" key={index}>
        <td width="80">
          <div className="avatar-group ">
            <div>
              <span className="w-32 avatar no-shadow">
                {index === 0 && <img src="/assets/img/trophy/T1.png" alt="."/> }
                {index === 1 && <img src="/assets/img/trophy/T12.png" alt="."/> }
                {index === 2 && <img src="/assets/img/trophy/T4.png" alt="."/> }
                {/*index >= 3 && index <= 4 && <img src="/assets/img/trophy/T8.png" alt="."/> */}
                {index >= 3 && <small className="text-muted font-weight-bold">{(index+1)}</small>}
              </span>
            </div>

            <div className="avatar ajax w-24" data-toggle="tooltip" title="" data-pjax-state="" data-original-title="Cursus">
              <UserImage userId={userScore.userUniqueId} type="thumb"/>
            </div>
          </div>
        </td>
        <td className="flex">
          <Link to={{pathname: `/profile/${userScore.userUniqueId}`}} className="item-title text-color h-1x">
            {userScore.userName}
          </Link>

          <div className="item-company text-muted h-1x">
            {userScore.teamName}
          </div>
        </td>
        <td align="right" className="pr-4">
          <div className="avatar-group right">
            {/* {
              achievements
                .filter(a => a.position <= 4)
                .sort((a, b) => a.position - b.position)
                .map((achievement, i) => (
                  <span className="w-24 avatar m-1 no-shadow" key={i}>
                    { achievement.position === 1 && index < 5 && <img src="/assets/img/trophy/T1.png" alt="."/> }
                    { achievement.position === 2 && index < 5 && <img src="/assets/img/trophy/T12.png" alt="."/> }
                    { achievement.position === 3 && index < 5 && <img src="/assets/img/trophy/T4.png" alt="."/> }
                    { achievement.position === 4 && index < 5 && <img src="/assets/img/trophy/T8.png" alt="."/> }

                    {
                      achievement.amount > 1 &&
                      <AchievementNumber title={getTrophyTitle(achievement)}>{achievement.amount}</AchievementNumber>
                    }
                  </span>
                ))
            } */}
            <span className="item-amount d-none d-sm-block text-sm text-nowrap ml-4">
              <CountUp end={userScore.percentage} separator={" "}/>%
            </span>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div id="content" className="flex ">
      <div className="page-hero page-container " id="page-hero">
        <div className="padding d-flex">
          <div className="page-title">
            <h2 className="text-md text-highlight">
              Ledartavlor
              {selectedCampaign &&
                <React.Fragment>
                  &nbsp;|&nbsp;
                  {selectedCampaign.name}
                  {selectedCampaign.active === 'Inactive' && ' - Slutresultat'}
                </React.Fragment>
              }
            </h2>

            {selectedCampaign &&
              <div><small>{selectedCampaign.startDate} - {selectedCampaign.endDate}</small><br /><br /></div>
            }
          </div>
          <div className="flex">&nbsp;</div>
          <div onClick={toggleModal}>
            <div className="btn btn-md text-muted" >
              <span className="d-none d-sm-inline mx-1" >
                {selectedCampaign ? 'Byt kampanj' : 'Välj kampanj'}
              </span>
              <i data-feather="arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="page-container">
        <div className="padding">
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="text-muted text-sm sr-item">{selectedCampaign && selectedCampaign.name}</div>
                  <h5 className="text-highlight sr-item">Bästa deltagare</h5>
                </div>
                <table className="table table-theme v-middle table-hover">
                  <tbody>
                  { renderBestParticipantsTable(itemsPerPage) }
                  </tbody>
                </table>
                { userScores.length > 0 &&
                  <div className="card-body">
                    <React.Fragment>
                      <div className="d-flex">
                        <small className="text-muted py-2">Totalt&nbsp;
                          <span id="count">
                            {userScores.length}
                          </span> deltagare
                        </small>
                      </div>

                      <div className="d-flex">
                        <Pagination items={userScores} itemsPerPage={itemsPerPage} currentPage={currentBestParticipantPage} onChange={handleBestParticipantsPageChange}/>
                      </div>
                    </React.Fragment>
                  </div>
                }
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <LeaderboardDailyUserTopScore itemsPerPage={5} userScores={userScores} campaign={!!selectedCampaign && selectedCampaign}/>

                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="text-muted text-sm sr-item">{selectedCampaign && selectedCampaign.name}</div>
                      <h5 className="text-highlight sr-item">Bästa lag</h5>
                      <table className="table table-theme v-middle table-hover">
                        <tbody>
                          <LeaderboardTopTeams itemsPerPage={5} goal={_goal} campaign={!!selectedCampaign && selectedCampaign}/>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>
            Välj kampanj
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Kampanj</Label>
              <Select
                defaultValue={selectedCampaignOption}
                options={campaignOptions}
                onChange={onChangeCampaign}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Avbryt</Button>
            <Button type="submit" color="primary">Spara</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}

export default Leaderboard;

const AchievementNumber = styled.div`
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.15rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #ffffff;
  background-color: #fb2640;
  position: absolute;
  bottom: -12px;
`