import {
  SET_SCORE,
} from "../types/score.types";

const initialState = {
  score: null,
};

const scoreReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_SCORE: {
      state.score = action.score;
      return state;
    }

    default: {
      return state;
    }
  }
};

export default scoreReducer;