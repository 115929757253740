import axios from 'axios';

const baseUrl = '/askas';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

function findAll() {
  return http
    .get(baseUrl)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function findAllBetween(from, to) {
  const url = baseUrl + "/orders/" + from + "/" + to

  return http
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : [])
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText
        return Promise.reject(errorMessage)
      }
    })
}

export const AskasApi = {
  findAll,
  findAllBetween
}
