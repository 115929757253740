import React, {Fragment, useEffect, useState} from 'react';
import {Edit, Mail, Trash, XCircle} from 'react-feather';
import {animated, useTransition} from 'react-spring';
import {Form, FormGroup, Input, Button} from 'reactstrap';
import styled from 'styled-components';

import {shoutoutService} from '../../services/shoutout.service';
import {useDispatch, useSelector} from 'react-redux';
import UserImage from '../User/UserImage.component';
import {setLatestAudioEvent} from '../../actions/audio.actions';

const ShoutoutMessage = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.authentication.user);
  const [shoutout, setShoutout] = useState(null);
  const [shoutoutEdit, setShoutoutEdit] = useState(false);
  const [isClosed, setClosed] = useState(false);

  const latestAudioEvent = useSelector(state => state.audio.latestAudioEvent);

  const handleShoutoutEdit = () => {
    setShoutoutEdit(!shoutoutEdit);
  }

  const handleShoutoutDelete =  () => {
    shoutoutService.delete(shoutout.id)
      .then(() => setShoutout(null));
  }

  const handleShoutoutClose = () => {
    setClosed(true);
    setTimeout(() => setShoutout(null), 500);
  }

  const handleTitleChange = (e) => {
    let newShoutout = {...shoutout};
    newShoutout.title = e.target.value;
    setShoutout(newShoutout);
  }

  const handleTextChange = (e) => {
    let newShoutout = {...shoutout};
    newShoutout.text = e.target.value;
    setShoutout(newShoutout);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    shoutoutService.update(shoutout.id, shoutout)
      .then(shoutout => {
        if (shoutout) {
          setShoutout(shoutout);
          setShoutoutEdit(false);
        }
      });
  }

  const transitions = useTransition(shoutout ? [shoutout] : [], p => p, {
    from: { opacity: 0, transform: 'translateY(-100%)'},
    enter: { opacity: 1, transform: 'translateY(0%)'},
    leave: { opacity: 0, transform: 'translateY(0)'},
    reverse: isClosed
  });

  const connect = () => {
    let ws = new WebSocket(process.env.REACT_APP_WS_URL + '/shoutout/' + currentUser.companyId);

    ws.onclose = () => {
      console.info('Closed connection to shoutouts.');

      if (_interval) {
        clearInterval(_interval);
      }
    };

    ws.onerror = (err) => {
      console.error('Shoutout socket encountered error: ', err.message);

      if (_interval) {
        clearInterval(_interval);
      }

      ws.close();
    };

    ws.onmessage = (message) => {
      if (message.data === "pong") {
        return;
      };

      const data = JSON.parse(message.data);

      setShoutout(data);
    };

    const _interval = setInterval(() => {
      if (ws.readyState == ws.CLOSING || ws.readyState == ws.CLOSED) {
        clearInterval(_interval);

        return;
      }

      ws.send("ping");
    }, 30000);
  }

  useEffect(() => {
    if(shoutout != undefined && shoutout.audioUniqueId != undefined && shoutout.audioUniqueId != null) {
      let newAudioEvent = {audioUniqueId: shoutout.audioUniqueId, eventUniqueId: shoutout.id, createdDate: shoutout.createdDate, audioPlayed: 0};

      if((latestAudioEvent === undefined || latestAudioEvent === null) && shoutout.id !== null) {
        dispatch(setLatestAudioEvent(newAudioEvent));
      } else {
        if (shoutout.id !== null
              && Date.parse(shoutout.createdDate) > Date.parse(latestAudioEvent.createdDate)
              && latestAudioEvent.eventUniqueId !== shoutout.id
              && latestAudioEvent.action !== 'delete'
          ) {
          dispatch(setLatestAudioEvent(newAudioEvent));
        }
      }
    }
  }, [shoutout])

  useEffect(() => {
    shoutoutService.findAll(1)
      .then(shoutouts => {
        let shoutout = shoutouts
          .sort((a, b) => a.createdDate - b.createdDate)
          .find(() => true);

        if (shoutout) {
          setShoutout(shoutout);
        }

        connect();
      });
  }, []);

  if (!shoutout) return <React.Fragment/>

  return (
    <Container>
      {transitions.map(({item, props, key}) => {
        return (
          <animated.div id="shoutout-message" key={key} style={{...props}}>
            <div className="alert card alert-dismissible fade show p-4 mb-4">
              <div className="d-flex">
                <span className="w-40 avatar circle gd-success">
                  <Mail size={16}/>
                </span>

                <div className="d-sm-flex" style={{width: '100%'}}>
                  <div className="mx-3 align-self-start w-100">
                    { !shoutoutEdit &&
                      <Fragment>
                        <h6>{shoutout.title}</h6>
                        <small style={{ whiteSpace: 'pre-wrap' }}>
                          {shoutout.text}
                        </small>
                      </Fragment>
                    }

                    { shoutoutEdit &&
                      <Form onSubmit={handleSubmit}>
                        <FormGroup>
                          <Input type="text" defaultValue={shoutout.title} onChange={(e) => handleTitleChange(e)} placeholder="Rubrik"/>
                        </FormGroup>

                        <FormGroup>
                          <Input type="textarea" defaultValue={shoutout.text} onChange={(e) => handleTextChange(e)} placeholder="Text" style={{ resize: 'none' }}/>
                        </FormGroup>

                        <Button type="submit" color="primary" className="float-right">
                          Spara
                        </Button>
                      </Form>
                    }
                  </div>

                  <div className="mx-3 padding author">
                    <a className="tl-author" href="/#" title={shoutout.userName}>
                    <span className="w-56 avatar circle gd-success">
                      <UserImage userId={shoutout.userId} type="thumb" />
                    </span>
                    </a>
                  </div>
                </div>
              </div>

              { currentUser && currentUser.hasRole('Admin', 'TeamLeader') &&
                <div className="d-flex">
                  <div className="d-sm-flex" style={{width: '100%'}}>
                    <div className="mx-3 author" style={{ paddingLeft: '2rem', paddingRight: '2rem',  }}>
                      <a href="/#" className="text-edit" title={shoutoutEdit ? 'Avbryt' : 'Redigera'}
                         onClick={handleShoutoutEdit}>

                        { !shoutoutEdit &&
                        <Edit size={16} className="ml-2"/>
                        }

                        { shoutoutEdit &&
                        <XCircle size={16} className="ml-2"/>
                        }
                      </a>

                      <a href="/#" className="text-danger" title="Ta bort"
                         onClick={handleShoutoutDelete}>
                        <Trash size={16} className="ml-2"/>
                      </a>
                    </div>
                  </div>
                </div>
              }

              <button type="button" className="close" title="Stäng" onClick={handleShoutoutClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </animated.div>
        )
      })}
    </Container>
  );
};

export default ShoutoutMessage;

const Container = styled.div`
  overflow-y: hidden;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 100%;
  max-width: 100%;
  
  .author {
    margin-left: auto !important;
  }
`