import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FormGroup, Label, Form, ModalHeader, ModalBody, ModalFooter, Button, Modal} from 'reactstrap';
import {default as Select} from 'react-select';

import 'react-day-picker/lib/style.css';
import 'moment/locale/it';

import {
  PlusCircle,
} from 'react-feather';

import {teamService} from "../../services/team.service";
import {userService} from "../../services/user.service";

import {campaignService} from "../../services/campaign.service";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { setDirtyField } from '../../actions/campaign.actions';

const CampaignTeam = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const campaignInfo = useSelector(state => state.campaign.campaignInfo);
  const campaignTeams = useSelector(state => state.campaign.campaignTeams);

  const [checkedTeams] = useState(new Map());

  const { data: teams } = useQuery(['teams', campaignTeams], () => teamService.findAll().then(teamResponse => {
    if (!teamResponse) {
      return [];
    }

    let teams = [];

    teamResponse.forEach(team => {
      let existingTeam = campaignTeams.find(campaignTeam => campaignTeam.teamId === team.id);
      
      if (existingTeam) {
        team.teamMembers = existingTeam.teamMembers;
        checkedTeams.set(team.id, true);
      } else {
        team.teamMembers = [];
        checkedTeams.set(team.id, false);
      }

      teams.push(team);
    });

     return teams;
  }), {
    enabled: !!campaignInfo.id,
    refetchOnWindowFocus: false,
    initialData: []
  });

  const { mutateAsync: updateCampaign } = useMutation(['updateCampaign'], (data) => campaignService.update(data.id, data), {
    onSuccess: (res) => {
      setTeam(null);
      queryClient.invalidateQueries(['campaign'])
    }
  });

  const defaultTeam = { id: null, active: 1, name: '', teamMembers: [] };
  const [team, setTeam] = useState(null);

  const toggleTeamModal = (e, teamToOpen) => {
    e.preventDefault();

    if (team) {
      setTeam(null);
    } else {
      if (teamToOpen) {
        setTeam(teamToOpen);
      } else {
        setTeam({...defaultTeam});
      }
    }
  };

  const onNameChange = (e) => {
    setTeam({
      id: team.id,
      active: 1,
      name: e.target.value,
      teamMembers: team.teamMembers
    });

    dispatch(setDirtyField('campaignTeams'));
  };

  /* window.sr.reveal('#team-list .t-r', {
    origin: 'left',
    scale: 1,
    distance: '50px',
    afterReveal: function (el) {
      window.$(el).css('transform', 'none');
    }
  }, 50); */

  return (
    <div id="campaign-team-container" className="tab-pane active">
      <div>
        <p>
          <strong>Välj vilka lag som ska vara med i kampanjen. Klicka på laget för att ställa in individuella deltagare.</strong>
        </p>

        <div id="team-list" className="table-responsive">
          <table className="table table-theme table-row v-middle">
            <thead>
              <tr>
                <th style={{width: '20px'}}></th>
                <th className="text-muted" style={{width: '50px'}}></th>
                <th className="text-muted sortable" data-toggle-class="asc">Lag</th>
                <th style={{width: '50px'}}></th>
              </tr>
            </thead>
            <tbody>
              {
                teams
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((team, index) => {
                    return (
                      <tr className="v-middle t-r" key={index}>
                        <td>
                          {checkedTeams.get(team.id) &&
                            <i className="fa fa-check text-success"></i>
                          }
                        </td>

                        <td>
                          <a href="#" onClick={(e) => toggleTeamModal(e, team)}>
                              <span className="w-40 avatar gd-warning" title={team.name}>
                                {team.name.substring(0, 1).toUpperCase()}
                              </span>
                          </a>
                        </td>

                        <td className="flex">
                          <a href="#" className="item-title text-primary" onClick={(e) => toggleTeamModal(e, team)} title={team.name}>{team.name}</a>
                          <div className="item-except text-muted text-sm h-1x">
                            {team.teamMembers.length} Deltagare
                          </div>
                        </td>

                        <td>
                          <span className="item-amount d-none d-sm-block text-sm"> </span>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>

        <div className="btn btn-md btn-block btn-white pointer">
          <PlusCircle size={16}/>
          <span className="d-none d-sm-inline mx-1" onClick={toggleTeamModal}>Skapa nytt lag</span>
        </div>
      </div>

      <TeamModal
        team={team}
        toggle={toggleTeamModal}
        onNameChange={onNameChange}
        updateCampaign={updateCampaign}
        teams={teams}
      />
    </div>
  );
};

export default CampaignTeam;

const TeamModal = ({ team, toggle, onNameChange, updateCampaign, teams }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const campaignInfo = useSelector(state => state.campaign.campaignInfo);
  const campaignTeams = useSelector(state => state.campaign.campaignTeams);
  const campaignEvents = useSelector(state => state.campaign.campaignEvents);
  const dirtyFields = useSelector(state => state.campaign.dirtyFields);

  const [userOptions, setUserOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { data: users } = useQuery(['users'], () => userService.findAll(), {
    enabled: !!team,
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: saveTeam } = useMutation(['updateTeam'], (teamId) => teamId ?
    teamService.update(teamId, {
      id: teamId,
      active: team.active,
      name: team.name
    }) : 
    teamService.create({
      active: team.active,
      name: team.name
    }), 
  {
    onSuccess: (res) => {
      queryClient.invalidateQueries(['teams'])
    }
  });

  const handleUserChange = (users) => {
    setSelectedUsers(users || []);
    dispatch(setDirtyField('campaignTeams'));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let teamMembers = [];

    selectedUsers.forEach(selectedUser => {
      if(team.id !== null) {
        let existingTeam = campaignTeams.find((team1) => team1.teamId === team.id);

        if (existingTeam) {
          let existingTeamUser = existingTeam.teamMembers.find((teamMember) => teamMember.userId === selectedUser.value);
         
          if (existingTeamUser) {
            teamMembers.push({
              id: existingTeamUser.id,
              userId: existingTeamUser.userId,
              name: existingTeamUser.name,
              eventGoals: existingTeamUser.eventGoals
            });

            return;
          } 
        } 
      }

      teamMembers.push({
        id: null,
        userId: selectedUser.value,
        name: selectedUser.label,
        eventGoals: []
      });
    });

    let updatedTeam = team
    
    // Only save team if it's a new team or if the team name has been changed
    if (dirtyFields.indexOf('campaignTeams') > -1 ) {
      updatedTeam = await saveTeam(team.id);
    }

    // Only create campaign team if there are team members. For the checkbox to be checked.
    if (dirtyFields.indexOf('campaignTeams') === -1 && teamMembers.length === 0) {
      return;
    }

    let campaignTeamList = [];

    campaignTeams.forEach(campaignTeam => {
      if (campaignTeam.teamId === team.id) {
        campaignTeam.teamMembers = teamMembers;
      }

      if (campaignTeam.teamMembers.length === 0) {
        return;
      }

      campaignTeamList.push(campaignTeam);
    });

    const isNewTeam = campaignTeams.find(campaignTeam => campaignTeam.teamId === team.id) === undefined;

    if (isNewTeam && teamMembers.length > 0) {
      campaignTeamList.push({
        teamId: team.id ?? updatedTeam.id,
        teamMembers: teamMembers
      });
    } 

    updateCampaign({
        ...campaignInfo,
          campaignTeams: campaignTeamList,
          campaignEvents,
    });
  };
  
  useEffect(() => {
    return () => {
      setUserOptions([])
      setSelectedUsers([])
    }
  }, [])

  useEffect(() => {
    if (team?.id) {
      if (team.teamMembers && team.teamMembers.length > 0) {
        setSelectedUsers(
          team.teamMembers.map(u => ({
            value: u.userId,
            label: u.name
          }))
        );
      }
    } else {
      setUserOptions([])
      setSelectedUsers([])
    }
  }, [team])

  useEffect(() => {
    if (users && selectedUsers) {
      let options = [];

      users.forEach(user => {
        let existsInTeam = false;

        // Remove option if user is already a member of a team
        teams.forEach(campaignTeam => {
          campaignTeam.teamMembers.forEach(teamMember => {
            if (teamMember.userId === user.id) {
              existsInTeam = true;
            }
          });
        });

        let existsInCurrentTeam = teams
          .some(t => t.id === team?.id && t.teamMembers
            .some(teamMember => teamMember.userId === user.id));

        if (!existsInTeam) {
          options.push({
            value: user.id,
            label: user.firstName + ' ' + user.lastName
          });
        }
        else if (selectedUsers.length === 0 && existsInCurrentTeam) {
          options.push({
            value: user.id,
            label: user.firstName + ' ' + user.lastName
          });
        }
      });

      setUserOptions(options);
    }
  }, [users, selectedUsers]);

  if (!team) {
    return null
  }

  return (
    <Modal isOpen={team !== null} toggle={(e) => toggle(e, team)} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggle}>
            { team.id && team.id.length > 0 ? 'Redigera ' + team.name : 'Nytt lag' }
          </ModalHeader>

          <ModalBody>
            <FormGroup>
              <Label>Lagnamn</Label>

              <input type="text"
                     className="form-control"
                     name="team-name"
                     placeholder="Namn"
                     required
                     value={team.name}
                     onChange={onNameChange} />
            </FormGroup>

            <FormGroup>
              <Label>Lagmedlemmar för kampanjen</Label>

              <Select
                isMulti
                defaultValue={selectedUsers}
                options={userOptions}
                onChange={handleUserChange}
              />
            </FormGroup>
          </ModalBody>

          <ModalFooter>
            <Button color="secondary" onClick={(e) => toggle(e, team)}>Avbryt</Button>
            <Button type="submit" color="primary" disabled={team?.teamName?.length === 0}>Spara</Button>
          </ModalFooter>
        </Form>
      </Modal>
  )
}