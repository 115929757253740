import React, {useEffect, useState} from 'react'
import CountUp from 'react-countup'
import {Progress} from 'reactstrap'
import {FlexRow} from '../../pages/pages.style'

const BontiDailyTeam = (props) => {
  const [teams, setTeams] = useState([])

  useEffect(() => {
    if (props.teams.length > 0) {
      let teams = []
      props.teams.forEach(team => {
        team.value = team.currentMonthSum
        team.percentage = team.currentMonthGoalProcent
        teams.push({...team})
      })

      setTeams([...teams])
    }
  }, [props.teams])

  return (
    <table className="table table-theme v-middle table-hover">
      <tbody>
      {
        teams
          .sort((a, b) => b.value - a.value)
          .slice(0, 1)
          .map((team, i) =>
            <tr className="v-middle" key={i}>
              <td>
                <div className="avatar-group">
                  {i === 0 &&
                  <div title={'#' + (i + 1)}>
                    <span className="w-32 avatar no-shadow">
                      <img src="/assets/img/trophy/T11.png" alt="."/>
                    </span>
                  </div>
                  }

                  {i > 0 && i < 3 &&
                  <div title={'#' + (i + 1)}>
                    <span className="w-32 avatar no-shadow">
                      <img src="/assets/img/trophy/T9.png" alt="."/>
                    </span>
                  </div>
                  }

                  {i > 2 &&
                  <div className="avatar ajax w-32 bg-white no-shadow" title={'#' + (i + 1)}>
                    <small className="text-muted font-weight-bold">{i + 1}</small>
                  </div>
                  }
                </div>
              </td>

              <td className="flex">
                <div className="item-title text-color h-2x">{team.teamName}</div>
                <div className="item-company text-muted h-2x">
                  {team.teamUserCount.length} deltagare
                </div>
              </td>

              <td>
                <CountUp end={team.value} duration={2} decimal={'0'} separator={' '}/> kr
                <FlexRow>
                  <Progress
                    value={team.percentage}
                    color="success"
                    title={team.percentage + '%'}>{team.percentage}%
                  </Progress>
                </FlexRow>
              </td>
            </tr>
          )
      }
      </tbody>
    </table>
  )
}

export default BontiDailyTeam