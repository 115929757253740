import {
  OPEN_IMAGE_MODAL,
  CLOSE_IMAGE_MODAL
} from '../types/profile.types';

const initialState = {isImageModalOpen: false};

const profileReducer = (state = initialState, action) => {

  switch (action.type) {
    case OPEN_IMAGE_MODAL: {
      return {
        isImageModalOpen: true
      }
    }

    case CLOSE_IMAGE_MODAL: {
      return {
        isImageModalOpen: false
      }
    }

    default: {
      return state;
    }
  }
};

export default profileReducer;