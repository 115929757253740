import client from '../helpers/client';
import Keycloak from 'keycloak-js';
import {BONTI_COMPANY_ID} from "../helpers/integrations/bonti";

const _keycloak = new Keycloak('/keycloak.json');

const initKeycloak = (authCallback) => {
  _keycloak
    .init({onLoad: 'login-required'})
    .then(isAuthenticated => {
      if (isAuthenticated) {
        authCallback();
      }
      else {
        login();
      }
    });
}

const getToken = () => {
  return _keycloak.token;
}

const getParsedToken = () => {
  return _keycloak.tokenParsed;
}

const updateToken = (callback) => {
  return _keycloak.updateToken(5)
    .then(callback)
    .catch(login);
}

const login = () => {
  return _keycloak.login();
}

const logout = () => {
  localStorage.clear();
  return _keycloak.logout();
}

const getProfile = () => {
  return _keycloak.loadUserProfile()
}

export function matchPassword(password) {
  const url = '/auth/match-password';

  return client.getClient()
    .post(url, { password: password })
    .then(response => Promise.resolve(response.data.status))
    .catch(err => err.response && Promise.reject(
      (err.response.data) || err.response.statusText)
    )
}

const formatName = (name = "Salestrigger User") => {
  name = name.toLowerCase()

  return name.replace(/(?:^|\s)\S/g, letter => letter.toUpperCase())
}

export function createUserObject(parsedToken, profile) {
  let user = null;

  if (parsedToken) {
    let userId = parsedToken.sub;
    let companyId = (profile.attributes.companyId && profile.attributes.companyId.length > 0) ? profile.attributes.companyId[0] : 0;
    let companyName = (profile.attributes.companyName && profile.attributes.companyName.length > 0) ? profile.attributes.companyName[0] : '';

    // TODO: This doesnt work, parsedToken.profileImage is never set
    let profileImage = 'https://salestrigger.s3-eu-west-1.amazonaws.com/default/profile64x64';
    if (parsedToken.profileImage) {
      profileImage = `https://salestrigger.s3-eu-west-1.amazonaws.com/company/${companyId}/user/${userId}/profile64x64`;
    }

    let roles = [];
    let clientId = process.env.REACT_APP_AUTH_BACKEND_CLIENT_ID;

    if (parsedToken.resource_access[clientId] && parsedToken.resource_access[clientId].roles) {
      roles = parsedToken.resource_access[clientId].roles
    }

    user = {
      id: userId,
      companyId: companyId,
      companyName: companyName,
      displayName: formatName(parsedToken.name),
      email: parsedToken.email,
      profileImage: profileImage,
      roles: roles,
      hasRole: hasRole,
      isBontiUser: isBontiUser
    };
  }

  return user;
}

function hasRole(...roles) {
  return roles.some(role => this.roles.indexOf(role) > -1)
}

function isBontiUser() {
  return this.companyId === BONTI_COMPANY_ID
}

export default {
  initKeycloak,
  getToken,
  getParsedToken,
  updateToken,
  login,
  logout,
  getProfile,
  createUserObject,
  matchPassword
}
