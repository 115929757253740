import React, {useEffect} from 'react';
import styled from 'styled-components';

import OrderForm from '../components/Order/OrderForm.component';
import OrderList from '../components/Order/OrderList.component';
import {useLocation} from 'react-router';
import {setDocumentTitle} from '../helpers';

const Order = (props) => {
  const orderId = props.match.params.id;
  const location = useLocation();

  let showList = location.pathname === '/orders';
  useEffect(() => setDocumentTitle('Ordrar'), []);

  return (
    <Container>
      <Wrapper>
        { showList &&
          <React.Fragment>
            <Header>Ordrar</Header>
            <SubHeader>Alla historiska registreringar.</SubHeader>
          </React.Fragment>
        }

        { !showList &&
          <React.Fragment>
            <Header>Redigera order</Header>
            <SubHeader>Redigera orderns värden</SubHeader>
          </React.Fragment>
        }
      </Wrapper>

      <Wrapper>
        {orderId && orderId.length > 0 ?
          <OrderForm orderId={orderId}/> :
          <OrderList/>
        }
      </Wrapper>
    </Container>
  );
}

export default Order;

const Container = styled.div`
  flex: 1 1 auto;
  
  @media (min-width: 992px) {
      max-width: 1140px;
      margin: 0 auto;
  }
`

const Wrapper = styled.div`
  padding: 2rem;
  
  @media (max-width: 767.98px) {
    padding: 1rem;
  }
  
  @media (max-width: 991.98px) {
    padding: 1.5rem;
  }
`

const Header = styled.h2`
  font-size: 1.2rem;
  color: #192039;
  
  @media (max-width: 767.98px) {
    text-align: center;
  }
`

const SubHeader = styled.small`
  display: block;
  color: #99a0ac;
  
  @media (max-width: 767.98px) {
    text-align: center;
  }
`