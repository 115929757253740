import styled from 'styled-components';

export const TvContainer = styled.div`
  flex: 1 1 auto;
`;

export const AchievementNumber = styled.div`
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.15rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #ffffff;
  cursor: default;
  background-color: #fb2640;
  position: absolute;
  bottom: -12px;
`