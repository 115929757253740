import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {CheckCircle, Edit, MoreVertical, PlusCircle, Trash, XCircle} from 'react-feather';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {campaignService} from '../services/campaign.service';
import {history, setDocumentTitle} from '../helpers';
import classNames from 'classnames';

import withSizes from 'react-sizes';
import styled from 'styled-components';
import moment from 'moment';
import {FlexWrapper} from './pages.style';
import {TableHeader} from '../components/Shared/Table';
import {Pagination} from '../components/Shared/Pagination';
import {BoldMutedText} from '../components/Shared/Style';
import {Tab, Tabs} from "../components/Shared/Tabs";
import { clearCampaignForm } from '../actions/campaign.actions';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const Campaign = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const currentUser = useSelector(state => state.authentication.user);
  
  const [today, setToday] = useState(null);

  const [sortColumn, setSortColumn] = useState('endDate');
  const [sortDirection, setSortDirection] = useState('asc');

  const [currentPage, setCurrentPage] = useState(1);

  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const [activeTab, setActiveTab] = useState('active');

  const { data: campaigns } = useQuery(['campaigns', activeTab], () =>campaignService.findAll(activeTab), {
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const { mutate: deleteCampaign } = useMutation(['deleteCampaign'], (campaignId) => campaignService.delete(campaignId), {
    onSuccess: () => {
      setCampaignToDelete(null);
      queryClient.invalidateQueries(['campaigns']);
    }
  });

  const formatStartDate = (campaign) => {
    let today = moment();
    let createdTimestamp = moment(campaign.startDate);

    if (createdTimestamp.isSame(today, 'd')) {
      return 'Idag';
    }

    return moment(campaign.startDate).locale(moment.locale('sv')).format('MMMM DD, Y');
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  const handleColumnClick = (column) => {
    setCurrentPage(1);
    setSortColumn(column);
    if (sortDirection === 'asc') {
      setSortDirection("desc");
    } else {
      setSortDirection("asc");
    }
  }

  const handleActiveTabChange = (tabKey) => {
    setActiveTab(tabKey);
  }

  const campaignSort = (a, b) => {
    let isDate = moment(a[sortColumn]).isValid();

    if (sortDirection === 'asc') {
      if (isDate) {
        return new Date(a[sortColumn]) - new Date(b[sortColumn]);
      } else {
        return a[sortColumn].localeCompare(b[sortColumn]);
      }
    } else {
      if (isDate) {
        return new Date(b[sortColumn]) - new Date(a[sortColumn]);
      } else {
        return b[sortColumn].localeCompare(a[sortColumn]);
      }
    }
  }

  const formatEndDate = (campaign) => {
    let today = moment();
    let createdTimestamp = moment(campaign.endDate);

    if (createdTimestamp.isSame(today, 'd')) {
      return 'Idag';
    }

    return moment(campaign.endDate).locale(moment.locale('sv')).format('MMMM DD, Y');
  }

  const addCampaign = () => {
    queryClient.invalidateQueries(['campaign']);
    dispatch(clearCampaignForm());
    history.push('/campaigns/add');
  };


  const editCampaign = (campaignId) => {
    history.push(`/campaigns/${campaignId}`);
  }

  const markForDelete = (campaignId) => {
    setCampaignToDelete(campaignId);
  }

  const onCampaignDelete = (campaign) => {
    if (currentUser.hasRole('Admin', 'TeamLeader')) {
      deleteCampaign(campaign.id);
    }
  }

  useEffect(() => {
    setDocumentTitle('Kampanjer');
    setToday(new Date());
  }, [])

  const RenderTable = () => {
    let paged = [];
    let itemsPerPage = 20;

    for (let i = (currentPage - 1) * itemsPerPage; i < (currentPage * itemsPerPage) && i < campaigns.length; i++) {
      paged.push(campaigns[i])
    }

    return (
        <CampaignWrapper>
          {paged.length === 0 && <BoldMutedText>Inga kampanjer</BoldMutedText>}
          { paged.length > 0 &&


            <div className="table-responsive">
              <Table className="table table-theme table-row v-middle">
                <thead>
                  <tr>
                    <TableHeader onClick={() => handleColumnClick('name')} columnName='name' columnText='Kampanj' sortColumn={sortColumn} sortDirection={sortDirection}/>
                    <TableHeader onClick={() => handleColumnClick('startDate')} columnName='startDate' columnText='Start' sortColumn={sortColumn} sortDirection={sortDirection}/>
                    <TableHeader onClick={() => handleColumnClick('endDate')} columnName='endDate' columnText='Slut' sortColumn={sortColumn} sortDirection={sortDirection}/>
                    {<th style={{width: (campaignToDelete !== null) ? '500px' : '50px'}}/>}
                  </tr>
                </thead>
                <tbody>

                {paged && paged
                    .sort(campaignSort)
                    .map((campaign, index) => {
                          const isCampaignDelete = campaign.id === campaignToDelete;

                          const cls = classNames({
                            'v-middle': true,
                            'delete-item': isCampaignDelete
                          });

                          return (
                              <tr className={cls} key={index}>
                                <td>
                                  <AvatarGroup className="avatar-group">
                                    <span className="w-40 avatar gd-warning">
                                       {campaign.name.substring(0, 1).toUpperCase()}
                                    </span>

                                    <div style={{marginLeft: "20px"}}>
                                      <div>
                                        <Link to={{pathname: `/campaigns/${campaign.id}`}}>
                                          {campaign.name}
                                        </Link>
                                      </div>

                                      {campaign.active === "Active" && (new Date(campaign.startDate)) <= today &&
                                        <div className="badge badge-success text-uppercase mr-1">PÅGÅR</div>
                                      }
                                      {campaign.active === "Active" && (new Date(campaign.startDate)) > today &&
                                        <div className="badge badge-info text-uppercase mr-1">INVÄNTAR</div>
                                      }
                                      {campaign.active === "Inactive" &&
                                        <div className="badge badge-danger text-uppercase mr-1">AVSLUTAD</div>
                                      }
                                    </div>
                                  </AvatarGroup>
                                </td>

                                <td>
                                  {formatStartDate(campaign)}
                                </td>

                                <td data-th="Slut">
                                  {formatEndDate(campaign)}
                                </td>
                                <td>
                                  <div className="item-action">
                                    {
                                      isCampaignDelete === false &&
                                      <UncontrolledDropdown>
                                        <DropdownToggle tag="div" className="text-muted" style={{cursor: 'pointer'}}>
                                          <MoreVertical size={16}/>
                                        </DropdownToggle>

                                        <DropdownMenu className="dropdown-menu-right bg-black">
                                          <DropdownItem onClick={() => editCampaign(campaign.id)}>
                                            <Edit size={16}/>
                                            Redigera
                                          </DropdownItem>

                                          {currentUser.hasRole('Admin', 'TeamLeader') &&
                                            <DropdownItem onClick={() => markForDelete(campaign.id)}>
                                              <Trash size={16}/>
                                              Ta bort
                                            </DropdownItem>
                                          }
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    }
                                    {
                                      isCampaignDelete === true &&
                                      <DeleteConfirm>
                                        <DeleteConfirmText>Ta bort?</DeleteConfirmText>

                                        <DeleteConfirmYes title="Ta bort" onClick={() => onCampaignDelete(campaign)}>
                                          <CheckCircle size={20} color="white"/>
                                        </DeleteConfirmYes>

                                        <DeleteConfirmNo title="Avbryt" onClick={() => setCampaignToDelete(null)}>
                                          <XCircle size={20} color="white"/>
                                        </DeleteConfirmNo>
                                      </DeleteConfirm>
                                    }
                                  </div>
                                </td>
                              </tr>
                          )
                        }
                    )}
                </tbody>
              </Table>
            </div>
          }
        </CampaignWrapper>
    );
  };

  return (
    <div className="mb-5">
      <>
        <FlexWrapper>
          <div>
            <Header>Kampanjer</Header>
            <SubHeader>
              Lista med alla kampanjer. Använd<MoreVertical size={16}/>
              för att redigera en kampanj eller skapa en ny genom att klicka på [Skapa kampanj]
            </SubHeader>
          </div>

          <div className="flex"/>

          <div>
            <div className="btn btn-md btn-success"
                  style={{marginLeft: "15px", cursor: "pointer"}}
                  onClick={addCampaign}
            >
              <PlusCircle size={16}/>
              <span className="d-none d-sm-inline mx-1">Skapa kampanj</span>
            </div>
          </div>
        </FlexWrapper>

        <Tabs activeKey={activeTab} onChange={handleActiveTabChange}>
          <Tab tabKey="active" title="Pågående kampanjer">{RenderTable()}</Tab>
          <Tab tabKey="inactive" title="Avslutade kampanjer">{RenderTable()}</Tab>
        </Tabs>

        {campaigns.length > 0 &&
          <CampaignFooter>
            <div className="d-flex">
              <small className="text-muted py-2">Totalt <span id="count">{campaigns.length}</span> kampanjer</small>
            </div>

            <div className="d-flex">
              <Pagination items={campaigns} itemsPerPage={20} currentPage={currentPage} onChange={handlePageChange}/>
            </div>
          </CampaignFooter>
        }
      </>
    </div>
  );
};

const mapSizesToProps = ({width}) => ({
  isMobile: width < 768
});

export default withSizes(mapSizesToProps)(Campaign);

const Header = styled.h2`
  font-size: 1.25rem;
  color: #192039;
  
  @media (max-width: 767.98px) {
    text-align: center;
  }
`

const SubHeader = styled.small`
  display: block;
  color: #99a0ac;
  
  @media (max-width: 767.98px) {
    text-align: center;
  }
`

const CampaignWrapper = styled.div `
  padding: 2rem 2rem 1rem 2rem;
`

const CampaignFooter = styled.div `
  margin-left: 2rem;
  
  @media (max-width: 767.98px) {
    margin-left: 1rem;
  }
  
  @media (max-width: 991.98px) {
    margin-left: 1.5rem;
  }
\`
`

const Table = styled.table`
  padding: 0;
  margin: 0;
  tr.delete-item {
    td {
      background-color: #FB2640;
      color: #FFFFFF;
    }
  }
`

const AvatarGroup = styled.div`
  > div {
    margin-left: 10px;
  }
`

const DeleteConfirm = styled.div`
  display: flex;
  flex-direction: row;
`

const DeleteConfirmText = styled.div`
  flex-grow: 0.3;
`

const DeleteConfirmYes = styled.div`
  flex-grow: 0.3;
  cursor: pointer;
`

const DeleteConfirmNo = styled.div`
  cursor: pointer;
`