import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Plus, Search, ChevronLeft, ChevronRight, MoreVertical, Edit, Trash} from "react-feather";
import {
  Button,
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal, ModalBody, ModalFooter,
  ModalHeader,
  UncontrolledDropdown
} from 'reactstrap';
import {history, setDocumentTitle} from "../../helpers";
import styled from 'styled-components';
import {PageTitle, Toolbar} from '../Shared/Style';
import {Pagination} from '../Shared/Pagination';
import {useSelector} from 'react-redux';
import {EventApi} from '../../api/Event/Event.api';

const EventListComponent = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [modal, setModal] = useState(false);

  const currentUser = useSelector(state => state.authentication.user);

  const searchFilter = (event) => {
    let name = event.name.toLowerCase();
    return name.indexOf(searchPhrase.toLowerCase()) !== -1;
  }

  const eventSort = (eventA, eventB) => {
    return eventA.name.localeCompare(eventB.name);
  }

  const deleteEvent = (eventId) => {
    EventApi.delete(eventId)
      .then(() => {
        EventApi.findAll()
          .then(events => setEvents(events));
        setModal(false);
      });
  }
  const renderList = () => {
    const items = [];
    let itemsPerPage = 20;

    let filteredEvents = events.filter(eventSort);

    for (let i = (currentPage - 1) * itemsPerPage; i < (currentPage * itemsPerPage) && i < filteredEvents.length; i++) {
      items.push(filteredEvents[i]);

    }

    const showDeleteModal = (event) => {
      setSelectedEvent(event)
      setModal(true)
    }

  const DeleteEventModal = (event) => {

    return (
        <Modal centered={true} backdrop={true} isOpen={modal}>
          <ModalHeader>{event.event.name}</ModalHeader>
          <ModalBody>
            <p>Är du säker på att du vill ta bort aktiviteten.<br/> Detta innebär att alla ordrar försvinner på aktiviteten</p>
          </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => deleteEvent(event.event.id)} >Ta bort</Button>{' '}
                <Button color="secondary" onClick={() => setModal(false)}>Avbryt</Button>
            </ModalFooter>
        </Modal>
    );
  }


    return (
      <React.Fragment>
        <div className="card mx-3 mb-0">
          <div className={`list list-row ${events.length.length === 0 ? 'hide' : ''}`}>
            { items
              .filter(searchFilter)
              .sort(eventSort)
              .map((event, index) => (
                <div className="list-item " key={index}>
                  <div>
                    <Link to={{pathname: `/events/${event.id}`}} title={event.name}>
                      <span className="w-40 avatar gd-warning" >
                        {event.name.substring(0, 1).toUpperCase()}
                      </span>
                    </Link>
                  </div>

                  <div className="flex">
                    <Link to={{pathname: `/events/${event.id}`}} className="text-primary">
                      {event.name}
                    </Link>
                  </div>

                  { (event.creatingUserId === currentUser.id || currentUser.hasRole('Admin')) &&
                    <UncontrolledDropdown className="mr-3" style={{position: 'initial'}}>
                      <DropdownToggle tag="div" className="text-muted" style={{cursor: 'pointer'}}>
                        <MoreVertical size={16}/>
                      </DropdownToggle>

                      <DropdownMenu className="dropdown-menu-center mt-3 w-sm animate fadeIn">
                        <DropdownItem onClick={() => history.push(`/events/${event.id}`)}>
                          <Edit size={16}/>
                          Redigera
                        </DropdownItem>

                        <DropdownItem onClick={() => showDeleteModal(event)}>
                          <Trash size={16}/>
                          Ta bort
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  }
                </div>
              ))
            }
          </div>

          { filteredEvents.length === 0 &&
            <NoEvents>Inga aktiviteter</NoEvents>
          }
        </div>

        { filteredEvents.length > 0 &&
          <div className="mx-3 mb-0">
            <div className="d-flex">
              <small className="text-muted py-2">Totalt <span id="count">{events.length}</span> Aktiviteter</small>
            </div>

            <div className="d-flex">
              <Pagination items={filteredEvents} itemsPerPage={20} currentPage={currentPage} onChange={(page) => setCurrentPage(page)}/>
            </div>
          </div>
        }
        <DeleteEventModal event={selectedEvent}/>
      </React.Fragment>
    )
  }

  useEffect(() => {
    setDocumentTitle('Aktiviteter');
    EventApi.findAll()
      .then(events => setEvents(events));
  }, [])

  return (
    <Container>
      <div className="mx-3">
        <PageTitle className="text-highlight">Aktiviteter</PageTitle>
      </div>

      <div className="p-3" id="user-list">
        <Toolbar>
          <form className="flex">
            <div className="input-group">
              <Input type="text"
                     className="form-control form-control-theme form-control-sm search"
                     placeholder="Sök"
                     required
                     onChange={(e) => setSearchPhrase(e.target.value)} />

              <span className="input-group-append">
                <button className="btn btn-white no-border btn-sm" type="button">
                  <span className="d-flex text-muted">
                    <Search size={16}/>
                  </span>
                </button>
              </span>
            </div>
          </form>

          <ButtonGroup>
            <Link to={{pathname: '/events/add'}} className="btn btn-sm btn-white no-border">
              <Plus size={16} className="mr-1"/>
              Lägg till
            </Link>
          </ButtonGroup>
        </Toolbar>
      </div>

      {renderList()}
    </Container>
  );
};

export default EventListComponent;

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`

const NoEvents = styled.div`
  padding: 1.5rem;
  text-align: center;
`