import React, {useEffect, useState} from 'react'
import {Container, ContentWrapper, Flex, FlexRow, FlexWrapper, Wrapper} from '../pages.style'
import {Card, Col, Progress, Row} from 'reactstrap'
import {Line, Doughnut} from 'react-chartjs-2'
import {useDispatch, useSelector} from 'react-redux'

import {BontiApi} from '../../api/Bonti/Bonti.api'
import {CardBody} from '../../components/Shared/Card'
import moment from 'moment'
import {Strong} from '../../components/Shared/Style'
import {ProgressValue, ProgressWrapper, ResultValue} from '../../components/Bonti/Bonti.style'
import CountUp from 'react-countup'
import BontiMonthlyTeam from '../../components/Bonti/BontiMonthlyTeam.component'
import {foldLeftNav, unfoldLeftNav} from '../../actions/layout.actions'
import {Menu} from 'react-feather'

import {BONTI_ASKAS_TEAM_ID, BONTI_COMPANY_ID} from "../../helpers/integrations/bonti";

import * as styles from './bonti.scss'

const Total = ({ type }) => {
  const dispatch = useDispatch()

  const selectedCampaign = useSelector(state => state.campaign.selectedCampaign)
  const currentUser = useSelector(state => state.authentication.user)

  const [teams, setTeams] = useState([])
  const [teamNames, setTeamNames] = useState([])

  const [orders, setOrders] = useState(null)
  const [customers, setCustomers] = useState(0)
  const [orderTotal, setOrderTotal] = useState({total: 0, year: 0, month: 0, week: 0})

  const [totalGoal, setTotalGoal] = useState(0)

  const [doughnutData, setDoughnutData] = useState([])
  const [graphSalesData, setGraphSalesData] = useState([])
  const [graphBudgetData, setGraphBudgetData] = useState([])
  const [graphAverageData, setGraphAverageData] = useState([])
  const [orderValuesYesterday, setOrderValuesYesterday] = useState(0)

  const layoutSettings = useSelector(state => state.layout.settings)

  const toggleLeftNav = () => {
    if (layoutSettings.aside === 'folded') {
      dispatch(unfoldLeftNav());
    } else {
      dispatch(foldLeftNav());
    }
  }

  const getTotalPercentage = () => {
    let percentage = 0

    if (totalGoal > 0) {
      percentage = Math.floor((orderTotal.year / totalGoal * 100))
    }

    return percentage < 100 ? percentage : 100
  }

  const fetchData = () => {
    BontiApi.getYesterdaysCustomers()
      .then(result => {
        if (!result || result == null || result == "") {
          return
        }

        setCustomers(result)
      })
      .catch(err => {
        console.log({err})
      })

    BontiApi.getYesterdaysOrders()
      .then(result => {
        if (!result || result == null || result == "") {
          return
        }

        setOrderValuesYesterday(result)
        console.log(result)
      })
      .catch(err => {
        console.log({err})
      })

    BontiApi.getGoal().then(goal => {
      if (goal) {
        setTotalGoal(goal.totalGoal)

        const monthMap = new Map()
        let m = moment()
        for (let i = 0; i < 12; i++) {
          const date = m.month(i).format('YYYY-MM')
          monthMap.set(date, 0)

          if (goal[date]) {
            monthMap.set(date, goal[date])
          }
        }

        setGraphBudgetData([...monthMap.entries()].map(o => o[1]))
      }
    })

    BontiApi.getOrderTotal().then((result) => {

      if (!result) {
        return
      }

      setOrderTotal({
        total: result.total,
        year: result.year,
        month: result.month,
        week: result.week,
      })
    })

    BontiApi.findOrdersSum(BONTI_COMPANY_ID, undefined).then(orders => {

      if (!orders) {
        return
      }

      setOrders(orders)
      setTeams(orders.teams)
    })
  }

  useEffect(() => {
    dispatch(foldLeftNav())

    fetchData()

    setInterval(() => {
      fetchData()
    }, 1000 * 60 * 5)
  }, [])

  useEffect(() => {
    if (orders) {
      const monthMap = new Map()

      let m = moment()
      for (let i = 0; i < 12; i++) {
        monthMap.set(m.month(i).format('YYYY-MM'), 0)
      }

      let totalSales = 0

      orders.campaignScoreChildViewModelList.forEach(campaign => {
        const month = moment(campaign.startDate).format('YYYY-MM')
        monthMap.set(month, campaign.sumOrders)
        totalSales += campaign.sumOrders
       })

       const averageSales = []
       for (let i = 0; i < 12; i++) {
         averageSales.push(totalSales / orders.campaignScoreChildViewModelList.length)
       }

       setGraphSalesData([...monthMap.entries()].map(o => o[1]))
       setGraphAverageData(averageSales)
    }
  }, [orders])

  useEffect(() => {



    // if (teams.length > 0 && orders.length > 0) {
    //   let teamMembers = []
    //   teams.forEach(team => {
    //     team.teamMembers.forEach(teamMember => {
    //       teamMembers.push({...teamMember})
    //     })
    //   })

    //   setTeamMembers([...teamMembers])
    //
      const teamNames = []
      const doughnutData = []

      teams.forEach(team => {
        teamNames.push(team.teamName)

         // const teamValue = orders
         //  .filter(order => order.teamId === team.teamId)
         //  .reduce((total, order) => total + order.value, 0)

        doughnutData.push(Math.floor(team.sumOrders))

      })

      setTeamNames(teamNames)
      setDoughnutData(doughnutData)

    // }
  }, [teams, orders])

  return (
    <Container id="bonti-container" className="total">
      {!currentUser &&
      <div className="pointer ml-2 mt-2" title={layoutSettings.aside === 'folded' ? "Visa" : "Göm"}>
        <Menu size={16} onClick={toggleLeftNav}/>
      </div>
      }

      <FlexWrapper>
        <div>
          <h2 className="text-md text-highlight">Bonti Total</h2>
        </div>
        <Flex/>
      </FlexWrapper>

      <Wrapper className="pt-0">
        <div className="d-md-flex" style={{justifyContent: 'space-between'}}>
          <ContentWrapper style={{width: '100%'}}>

            <div className="row">
              <div className="col-12">
                {selectedCampaign &&
                <div className="pb-3">
                  <div className="text-muted text-sm sr-item">Bontis årliga resultat</div>
                </div>
                }
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={9}>
                        <Strong className="mb-2">Resultat</Strong>
                      </Col>

                      <Col md={3}>
                        <div className="text-muted">Årsbudget</div>
                      </Col>
                    </Row>

                    {orderTotal &&
                    <React.Fragment>
                      <Row>
                        <Col md={3}>
                          <ResultValue>
                            <CountUp start={0} end={orderTotal.year} duration={2} decimal="0" separator={' '}/> kr
                          </ResultValue>
                          <div>År</div>
                        </Col>

                        <Col md={3}>
                          <ResultValue>
                            <CountUp start={0} end={orderTotal.month} duration={2} decimal="0" separator={' '}/> kr
                          </ResultValue>
                          <div>Månad</div>
                        </Col>

                        <Col md={3}>
                          <ResultValue>
                            <CountUp start={0} end={orderTotal.week} duration={2} decimal="0" separator={' '}/> kr
                          </ResultValue>
                          <div>Vecka</div>
                        </Col>

                        <Col md={3}>
                          <ProgressWrapper>
                            <div className="text-muted mb-1">
                              <CountUp start={totalGoal} end={totalGoal} duration={2} decimal="0" separator={' '}/> kr
                            </div>

                            <FlexRow>
                              <Progress
                                value={getTotalPercentage()}
                                color="success"
                                title={getTotalPercentage() + '%'} style={{width: '100%', alignSelf: 'center'}}>
                              </Progress>
                              <ProgressValue>{getTotalPercentage()}%</ProgressValue>
                            </FlexRow>
                          </ProgressWrapper>
                        </Col>
                      </Row>
                    </React.Fragment>
                    }
                  </CardBody>
                </Card>
              </div>

            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <Card>
                  <CardBody>
                    <Strong>Försäljning</Strong>
                    <Line data={{
                      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                      datasets: [{
                        label: 'Försäljning',
                        data: graphSalesData,
                        fill: true,
                        backgroundColor: 'rgb(40, 167, 69, 0.2)',
                        borderColor: 'rgb(40, 167, 69)',
                        tension: 0.3,
                      },
                        {
                          label: 'Budget',
                          data: graphBudgetData,
                          fill: false,
                          borderColor: 'rgb(54, 162, 235)',
                          tension: 0.1,
                        },
                        {
                          label: 'Snitt',
                          data: graphAverageData,
                          fill: false,
                          backgroundColor: 'rgb(11, 131, 48, 0.4)',
                          borderColor: 'rgb(228, 132, 12)',
                          tension: 0.1,
                        }
                      ]
                    }}
                    options={{
                      plugins: {
                        legend: {
                          labels: {
                            font: {
                              size: 36
                            }
                          }
                        }
                      },
                      scales: {
                        x: {
                          ticks: {
                            font: {
                              size: 28
                            }
                          }
                        },
                        y: {
                          ticks: {
                            font: {
                              size: 28
                            }
                          }
                        }
                      }
                    }}
                    width={500} height={300}/>
                  </CardBody>
                </Card>
              </div>

              <div className="col-12 col-md-3">
                <Card>
                  <CardBody>
                    <div className="text-muted">Försäljning i månad</div>
                    <Strong>Bästa butik</Strong>
                    <BontiMonthlyTeam teams={teams}/>
                  </CardBody>
                </Card>
              </div>
                <div className="col-12 col-md-3">
                  <Card>
                    <CardBody>
                      <Strong>Försäljning</Strong>
                      <Doughnut data={{
                        labels: teamNames,
                        datasets: [{
                          label: 'Försäljning',
                          data: doughnutData,
                          fill: true,
                          backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(40, 167, 69)'
                          ],
                          borderColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(40, 167, 69)'
                          ],
                          hoverOffset: 4
                        }]
                      }}
                                options={{
                                  plugins: {
                                    legend: {
                                      labels: {
                                        font: {
                                          size: 36
                                        }
                                      }
                                    }
                                  },
                                  scales: {
                                    x: {
                                      ticks: {
                                        font: {
                                          size: 28
                                        }
                                      }
                                    },
                                    y: {
                                      ticks: {
                                        font: {
                                          size: 28
                                        }
                                      }
                                    }
                                  }
                                }}
                                width={500} height={300} />
                    </CardBody>
                  </Card>
                  <Card>
                        <CardBody>
                          <Row>
                            <Col md={3}>
                              <div className="avatar w-96 m-2 no-shadow gd-success">
                                <CountUp start={customers} end={customers} duration={2} decimal="0" separator={' '}/>
                              </div>
                            </Col>

                            <Col md={3}>
                              <h3 style={{marginTop: 40}}>Besökare</h3>
                            </Col>

                            <Col md={3}>
                              <div className="avatar w-96 m-2 no-shadow gd-success">
                                <CountUp
                                    start={parseInt((orderValuesYesterday / customers).toFixed(2))}
                                    end={parseInt((orderValuesYesterday / customers).toFixed(2))}
                                    decimal="0"
                                    separator={' '}/>
                              </div>
                            </Col>

                            <Col md={3}>
                              <h3 style={{marginTop: 40}}>kr/h</h3>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                </div>
            </div>
          </ContentWrapper>
        </div>
      </Wrapper>
    </Container>
  )
}

export default Total
