import React, {useEffect, useState} from 'react'
import CountUp from 'react-countup'
import {Strong} from '../Shared/Style'
import {Progress} from "reactstrap"
import {FlexRow} from "../../pages/pages.style"
import {getPercentageFromTeamMember} from "../../helpers/integrations/bonti"

const BontiTeamMember = (props) => {
  const { type } = props

  const [teamMembers, setTeamMembers] = useState([])

  const getTrophy = (position) => {

    if (type === 'daily') {
      return '/assets/img/trophy/T6.png'
    }

    switch(position) {
      case 1:
        return '/assets/img/trophy/T1.png'
      case 2:
        return '/assets/img/trophy/T12.png'
      case 3:
        return '/assets/img/trophy/T4.png'
    }

    return ''
  }

  useEffect(() => {
    let teamMembers = []
    props.teamMembers.forEach(teamMember => {
      teamMember.teamMemberName = teamMember.firstName + ' ' + teamMember.lastName
      teamMember.value = 0

      if (type === 'daily') {
        teamMember.value = teamMember.dailyValue
      }
      else {
        teamMember.value = teamMember.userTotalSum
      }

      let percentage = 0

      if (teamMember.userTotalSum > 0 && teamMember.userTotalGoal > 0) {
        percentage = Math.floor((teamMember.userTotalSum / teamMember.userTotalGoal) * 100)
      }

      teamMember.percentage = percentage

      teamMembers.push({...teamMember})
    })

    setTeamMembers(teamMembers)
  }, [props.teamMembers, props.orders])

  return (
    <table className="table table-theme v-middle table-hover">
      <tbody>
      {
        teamMembers
          .map((teamMember, i) =>
            <tr className="v-middle" key={i}>
              <td>
                <div className="avatar-group">
                  {i < 3 &&
                  <div title={'#' + (i + 1)}>
                    <span className="w-32 avatar no-shadow">
                      <img src={getTrophy(i + 1)} alt="."/>
                    </span>
                  </div>
                  }

                  {i > 2 &&
                  <div className="avatar ajax w-32 bg-white no-shadow" title={'#' + (i + 1)}>
                    <small className="text-muted font-weight-bold">{i + 1}</small>
                  </div>
                  }
                </div>
              </td>

              <td className="flex">
                <div className="item-title text-color h-2x">{teamMember.teamMemberName}</div>
              </td>

              <td>
                <Strong>
                  <CountUp end={teamMember.value} duration={2} decimal={'0'} separator={' '}/> kr
                </Strong>
                <FlexRow>
                  <Progress
                    value={teamMember.percentage}
                    color="success"
                    title={teamMember.percentage + '%'}>{teamMember.percentage}%
                  </Progress>
                </FlexRow>
              </td>

              {type === 'daily' &&
              <React.Fragment>
                <td>0 tim</td>
                <td>Snitt</td>
              </React.Fragment>}
            </tr>
          )
      }
      </tbody>
    </table>
  )
}

export default BontiTeamMember
