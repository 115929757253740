import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {Button, Input} from 'reactstrap';
import {Edit, MessageCircle, PlusCircle, Trash, Image as _Image} from 'react-feather';
import {Scrollbar} from '../Shared/Scrollbar/Scrollbar.component';
import moment from 'moment';
import 'moment/locale/sv';
import styled from 'styled-components';
import CountUp from 'react-countup';

import {
  addComment,
  addMessage,
  closeComments,
  deleteComment,
  editComment,
  setLatestActivities,
  startComment,
  updateComment
} from '../../actions/latestActivityList.actions';
import {setAudioList, setLatestAudioEvent} from '../../actions/audio.actions';

import {messageService} from '../../services/message.service';
import UserImage from '../User/UserImage.component';
import {campaignService} from '../../services/campaign.service';
import {setScore} from '../../actions/score.actions';
import {audioService} from '../../services/audio.service';
import { openImageMessageModal } from '../../actions/dashboard.actions';

import './Activity.scss';
import { CREATE_ERROR } from '../../types/user.types';
import ScoreUtil from '../Score/ScoreUtil';

const ActivityList = ({showTopSellers, history}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.authentication.user);
  const messages = useSelector(state => state.latestActivityList.messages);
  const selectedCampaign = useSelector(state => state.campaign.selectedCampaign);
  const campaignScore = useSelector(state => state.score.score);

  const audioList = useSelector(state => state.audio.audioList);
  const latestAudioEvent = useSelector(state => state.audio.latestAudioEvent);

  const defaultNumOfMessages = 8;

  const [userScores, setUserScores] = useState([]);
  const [numOfMessages, setNumOfMessages] = useState(defaultNumOfMessages);

  // Websocket
  const [webSocket, setWebSocket] = useState(null);

  const [comment, setComment] = useState('');
  const [topScore, setTopScore] = useState(null);

  const fileInput = useRef(null)

  history.listen(() => {
    if (webSocket) {
      webSocket.close();
    }
  });

  const connect = (campaignId) => {
    let url = process.env.REACT_APP_WS_URL + '/messages/' + campaignId;
    let canConnect = true;

    if (webSocket) {
      if (webSocket.readyState === WebSocket.OPEN) {
        if (webSocket.url === url) {
          canConnect = false;
        }
      }
    }

    if (!canConnect)
      return;

    let ws = new WebSocket(url);

    ws.onopen = () => {
      setWebSocket(ws);
    };

    ws.onclose = () => {
      //setTimeout(() => connect(campaignId), 1000);

      if (_interval) {
        clearInterval(_interval)
      }
    };

    ws.onerror = (err) => {
      console.log({ err })

      if (_interval) {
        clearInterval(_interval)
      }

      ws.close();
      setWebSocket(null);
    };

    ws.onmessage = (message) => {
      if (message.data === "pong") {
        return;
      };

      let data = JSON.parse(message.data);

      switch (data.action) {
        case 'new':
          if (data.parentUniqueId) {
            dispatch(addComment(data));

            let newAudioEventComment = {audioUniqueId: '9357e274-7bdc-4ba5-910a-c4881b1d9b2e', eventUniqueId: data.id, createdDate: data.createdDate, audioPlayed: 0};

            if((latestAudioEvent === undefined || latestAudioEvent === null) && data.eventId !== null) {
              dispatch(setLatestAudioEvent(newAudioEventComment));
            } else {
              if (data.eventId !== null && Date.parse(data.createdDate) > Date.parse(latestAudioEvent.createdDate)) {
                dispatch(setLatestAudioEvent(newAudioEventComment));

              }
            }
          }
          else {
            dispatch(addMessage(data));
            loadScores();
            let newAudioEvent = {
              audioUniqueId: data.audioUniqueId,
              eventUniqueId: data.eventId,
              createdDate: data.createdDate,
              audioPlayed: 0
            };

            if ((latestAudioEvent === undefined || latestAudioEvent === null) && data.eventId !== null) {
              dispatch(setLatestAudioEvent(newAudioEvent));
            } else {
              if (data.eventId !== null && Date.parse(data.createdDate) > Date.parse(latestAudioEvent.createdDate)) {
                dispatch(setLatestAudioEvent(newAudioEvent));
              }
            }
          }

          break;

        case 'update':

          if (data.parentUniqueId) {
            dispatch(updateComment(data));
          }
          else {
            loadScores();
          }

          break;

        case 'delete':

          if (data.parentUniqueId) {
            dispatch(deleteComment(data));
          }
          else {
            loadScores();
          }

          break;

        case 'refresh':
          messageService.findByCampaignId(selectedCampaign.id)
            .then(campaignMessages => {
              dispatch(setLatestActivities(campaignMessages));
            });

          loadScores();

          break;
      }
    }

    const _interval = setInterval(() => {
      if (ws.readyState == ws.CLOSING || ws.readyState == ws.CLOSED) {
        clearInterval(_interval)

        return
      }

      ws.send("ping")
    }, 30000)
  }

  const loadScores = () => {
    if (selectedCampaign) {
      campaignService.findScores(selectedCampaign.id)
        .then(campaignScore => {
          dispatch(setScore(campaignScore));
        });
    }
  }

  const handleNumOfMessages = () => {
    if (numOfMessages < messages.length) {
      setNumOfMessages(numOfMessages + 5);
    } else if (numOfMessages >= messages.length) {
      setNumOfMessages(defaultNumOfMessages);
    }
  }

  const handleNewCommentClick = (e, message) => {
    e.preventDefault();

    let isCommenting = message.isCommenting;
    dispatch(closeComments());

    if (!isCommenting) {
      dispatch(startComment(message));
    }
  }

  const onChangeComment = (e) => {
    setComment(e.target.value);
  }

  const handleComentEdit = (e, comment) => {
    e.preventDefault();
    dispatch(editComment(comment, !comment.isEditing));
  }

  const handleCommentDelete = (e, comment) => {
    e.preventDefault();
    messageService.delete(comment.id);
  }

  const saveMessageComment = (e, message) => {
    e.preventDefault()

    if (comment == null || comment == ""  || comment.trim() == "") return

    if (message.parentUniqueId) {
      messageService.partialUpdate(message.id, { message: comment });
    }
    else {
      let data = {
        campaignOrderUniqueId: selectedCampaign.id,
        parentUniqueId: message.id,
        message: comment
      };


      if (!!comment._image) {
        const data = new FormData()

        data.append('file', comment._image);
        data.append('filename', 'message.png');

        messageService.uploadImage(comment.id, data).then().catch(err => {
          console.log({ err })
        })
      } else {
        messageService.create(selectedCampaign.id, data);
      }
    }
  }

  useEffect(() => {
    // Get a list of all available audios
    audioService.findAll()
      .then(audioList => {
        let audioItems = [];

        audioList.forEach(audio => {
          let option = {
            id: audio.unique_id,
            location: audio.location,
            volume: audio.volume,
          };
          audioItems.push(option);
        });

        dispatch(setAudioList(audioItems));
      });
  }, []);

  useEffect(() => {
    if((latestAudioEvent !== undefined && latestAudioEvent !== null) && latestAudioEvent.audioPlayed === 0 && audioList !== undefined) {
      let audio = audioList.find(a => a.id === latestAudioEvent.audioUniqueId);
      if(audio !== undefined && audio !== null)
       playAudio(new Audio(audio.location), audio.volume);
      latestAudioEvent.audioPlayed = 1;
      dispatch(setLatestAudioEvent(latestAudioEvent));
    }

  }, [latestAudioEvent]);

  useEffect(() => {
    if (selectedCampaign) {
      connect(selectedCampaign.id);
      loadScores();

      messageService.findByCampaignId(selectedCampaign.id)
        .then(campaignMessages => {
          dispatch(setLatestActivities(campaignMessages));
        });
    }
  }, [selectedCampaign]);

  useEffect(() => {
    let isCancelled = false;

    if (campaignScore) {
      let userScores = [];

      if (!isCancelled) {

        campaignScore.campaignTeams.forEach(campaignTeam => {
          campaignTeam.campaignTeamMembers.forEach(teamMemberScore => {
            teamMemberScore.teamName = campaignTeam.teamName;
            teamMemberScore.percentageToday = ScoreUtil.getPercentageTodayFromTeamMember(teamMemberScore)
            userScores.push(teamMemberScore);
          });
        });

        setUserScores(userScores
          .sort((a, b) => b.value - a.value)
          .slice(0, 3)
        );
      }
    }

    return () => {
      isCancelled = true
    }
  }, [campaignScore]);

  useEffect(() => {
    if (messages.length > 0) {
      let topScore = messages.reduce((e, a) => {
        return e.value > a.value ? e : a;
      });

      setTopScore(topScore);
    }
  }, [messages]);

  const newImage = () => {
    fileInput && fileInput.current && fileInput.current.click();
  }

  const playAudio = (audioFile, volume = 0.7) => {
    if(audioFile !== null) {
      audioFile.volume = volume;
      audioFile.play();
    }
  }

  const handleFileChange = async (e, message) => {
    e.preventDefault()

    if (!e.target.files || !e.target.files[0]) return
    const data = new FormData();
    
    data.append('file', e.target.files[0]);
    data.append('filename', 'message.png');

    message._image = e.target.files[0];

    setComment(message)

    try {
      const result = await messageService.uploadImage(message.id, data)
    } catch (error) {
      console.log("Något gick snett...", { error })
    }
  }

  const calculatePercent = (userScore) => {
    let totalGoal = 0;

    userScore.eventGoals.forEach(eventGoal => {
      totalGoal += eventGoal.goal;
    });

    const percent = (userScore.valueToday > 0) ? Math.ceil(userScore.valueToday / totalGoal * 100) : 0

    return percent >= 100 ? 100 : percent;
}

  return (
    <Container id="latest-activity" className="block w-lg w-auto-lg no-shrink pb-1 limit-posts">
      <div className="hidden">
        <audio id="register" src="../assets/wav/register.wav" />
      </div>

      { showTopSellers &&
        <div>
          <StickyTop>
            <div className="text-muted text-sm sr-item">Dagens</div>
            <h5 className="text-highlight sr-item mb-0">Toppsäljare</h5>
          </StickyTop>

          <table className="table table-theme v-middle table-hover">
            <tbody>
            {
              userScores
              .sort((a, b) => b.percentageToday - a.percentageToday)
              .map((userScore, index) => (
                <tr className=" v-middle" key={index}>
                  <td>
                    <div className="avatar-group">
                      <div>
                        <span className="w-32 avatar no-shadow">
                          <small className="text-muted font-weight-bold">{(index+1)}</small>
                        </span>
                      </div>

                      <div className="avatar ajax w-24" title={userScore.userName}>
                        <UserImage userId={userScore.userUniqueId} type="thumb"/>
                      </div>
                    </div>
                  </td>

                  <td className="flex">
                    <Link to={{pathname: `/profile/${userScore.userUniqueId}`}} className="item-title text-color h-1x" title={userScore.userName}>
                      {userScore.userName}
                    </Link>

                    <div className="item-company text-muted h-1x">
                      {userScore.teamName}
                    </div>
                  </td>

                  <td>
                    <span className="item-amount d-none d-sm-block text-sm text-nowrap ml-4">
                      <CountUp end={ScoreUtil.getPercentageTodayFromTeamMember(userScore)} decimals={2} separator={" "}></CountUp>%
                    </span>
                  </td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>
      }

      <StickyTop>
        <div className="text-muted text-sm sr-item">Alla deltagare</div>
        <h5 className="text-highlight sr-item mb-0">Senaste aktivitet</h5>
      </StickyTop>

      <Scrollbar style={{height: 965, scrollWidth: 5}}>
        <TimeLine>
            <div className="text-muted pl-3 pt-1">{ messages.length === 0 && 'Ingen aktivitet' }</div>

          <TimeLineContent>
            { messages
                .sort((a, b) => b.createdUnixTimestamp - a.createdUnixTimestamp)
                .slice(0, numOfMessages)
                .map((message, index) => {

                return (
                  <div className="tl-item" key={index}>
                    <div className="tl-dot">
                      <a className="tl-author" href="/#">
                        <span className="w-32 avatar circle gd-success">
                          <UserImage userId={message.userId} type="thumb" />
                        </span>
                      </a>
                    </div>

                    <div className="tl-content">
                      <div className="item-title">
                        <a href={`/profile/${message.userId}`}>{message.userName}</a>
                      </div>

                      <div className="tl-body">{message.orderValue} {message.unit}</div>

                      <div className="tl-ordertype">
                        <span className="badge badge-success text-uppercase mr-1">{message.eventName}</span>
                      </div>

                      <TimelineDate title={message.createdDate}>
                        <div className="mb-1">{moment(message.createdDate).locale(moment.locale('sv')).fromNow()}</div>

                        <TimelineButton className="text-blue" title="Kommentera" onClick={(e) => handleNewCommentClick(e, message) }>
                          <MessageCircle size={16} />
                        </TimelineButton>
                      </TimelineDate>

                      {
                        message.isCommenting &&
                        <div className="comment my-3">
                          <Input type="textarea" name="message-comment" placeholder="Skriv en kommentar..." onChange={(e) => onChangeComment(e, message)} style={{resize: 'none'}} />

                          <Button type="button" color="primary" size="sm" style={{width: '100%'}} onClick={(e) => saveMessageComment(e, message)}>
                            Kommentera
                            <span className="ml-2 float-right">
                              <MessageCircle size={16} />
                            </span>
                          </Button>
                          <Button type="button" color="upload" size="sm" style={{width: '100%', marginTop: "2px"}} onClick={newImage}>
                            Ladda upp bild
                            <span className="ml-2 float-right">
                              <_Image size={16} />
                            </span>
                          </Button>
                          <FileInput accept="image/jpeg,image/png,image/webp,image/gif" ref={fileInput} onChange={(e) => handleFileChange(e, message)}/>
                        </div>
                      }

                      {message.comments
                        .sort((a, b) => b.createdUnixTimestamp - a.createdUnixTimestamp)
                        .map((comment, index) => {

                        return (
                          <div className="comment" key={index}>
                            <div style={{ display: 'flex', padding: '10px' }}>

                              <div style={{ marginRight: '10px' }}>
                                <span className="w-32 avatar circle gd-success">
                                  <UserImage userId={comment.userId} type="thumb" />
                                </span>
                              </div>

                              <div>
                                <a href={`/users/${comment.userId}`}>{comment.userName}</a>

                                <div>
                                  {
                                    !comment.isEditing &&
                                      <div style={{ margin: '3px 0px 6px 0px' }}>{comment.message}</div>
                                  }
                                  {
                                    !comment.isEditing && comment.imageUrl &&
                                      <img src={comment.imageUrl} style={{maxHeight: 150, maxWidth: 135}} />
                                  }

                                  <TimelineDate title={comment.createdDate}>
                                    {moment(comment.createdDate).locale(moment.locale('sv')).fromNow()}

                                    <div className="mt-1">
                                      {
                                        currentUser && (currentUser.id === comment.userId || currentUser.hasRole('Admin')) &&
                                        <React.Fragment>
                                          {!comment.imageUrl && (
                                            <TimelineButton className="text-edit" title={comment.isEditing ? 'Avbryt' : 'Redigera'}
                                                onClick={(e) => handleComentEdit(e, comment)}>
                                              <Edit size={16} className="mr-2"/>
                                            </TimelineButton>
                                          )}

                                          <TimelineButton className="text-danger" title="Ta bort"
                                             onClick={(e) => handleCommentDelete(e, comment)}>
                                            <Trash size={16}/>
                                          </TimelineButton>
                                        </React.Fragment>
                                      }
                                    </div>
                                  </TimelineDate>
                                </div>
                              </div>
                            </div>

                            {
                              comment.isEditing &&
                              <div>
                                <Input type="textarea"
                                       name="message-comment"
                                       placeholder="Kommentar"
                                       defaultValue={comment.message}
                                       onChange={(e) => onChangeComment(e, comment)}
                                       style={{resize: 'none'}} />

                                <Button type="button" color="primary" size="sm" style={{ width: '100%' }} onClick={(e) => saveMessageComment(e, comment)}>
                                  Spara
                                  <span className="ml-2 float-right">
                                    <MessageCircle size={16} />
                                  </span>
                                </Button>
                              </div>
                            }
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })
            }
          </TimeLineContent>
        </TimeLine>
      </Scrollbar>

      { messages.length > 7 &&
        <ViewMoreWrapper>
          <button className="btn btn-sm btn-outline-primary btn-rounded" onClick={handleNumOfMessages}>
            <PlusCircle className="mr-1" size={16}/>{(messages.length > 7 && numOfMessages < messages.length) ? 'Visa fler' : 'Visa färre'}
          </button>
        </ViewMoreWrapper>
      }

    </Container>
  );
};

export default withRouter(ActivityList);

const Container = styled.div`
  .bg-sticky {
    background-color: inherit;
  }

  @media (min-width: 768px) {
    margin-left: 1rem;
  }
`

const FileInput = styled.input.attrs({
  type: 'file'
})`
  display: none;
`

const StickyTop = styled.div`
  //position: sticky;
  top: 0;
  z-index: 1020;
  padding: 1rem 1rem 0 1rem;
  background: inherit;
`

const TimeLine = styled.div`
  position: relative;
  margin: 0;
  font-size: 0.725rem;
`

const TimeLineContent = styled.div`
  padding: 20px 16px;
`

const TimelineDate = styled.div`
  color: #99A0AC;
  font-size: 0.85em;
  margin-top: 0.25rem;
  min-width: 100px;
  max-width: 150px;
  
  @media (max-width: 768px) {
    max-width: 100%;
  }
`

const TimelineButton = styled.div`
  display: inline-block;
  cursor: pointer;
  
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4285714286;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid rgba(135, 150, 165, 0.15);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 5px;
  }
`

const ViewMoreWrapper = styled.div`
  text-align: center;
  padding-top: 21px;
  padding-bottom: 21px;
  border-top: 1px solid rgba(160, 175, 185, 0.15);
`