import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {PlusCircle} from 'react-feather';
import {useSelector} from 'react-redux';
import UserImage from '../../User/UserImage.component';
import CountUp from 'react-countup';
import ScoreUtil from '../ScoreUtil';
import moment from 'moment';

const UserTopscore = () => {
  const selectedCampaign = useSelector(state => state.campaign.selectedCampaign);
  const campaignScore = useSelector(state => state.score.score);
  const [userScores, setUserScores] = useState([]);

  const topPercentageSort = (a, b) => {
    if (a.percentage === 100 && b.percentage === 100) {
      const aTime =  moment(a.eventGoals[0].eventCompleted)
      const bTime = moment(b.eventGoals[0].eventCompleted)

      return aTime.isBefore(bTime) ? -1 : 1;
    }

    return b.percentage - a.percentage;

  }

  useEffect(() => {
    if (campaignScore?.campaignTeams) {
      let userScores = [];


      campaignScore.campaignTeams.forEach(campaignTeam => {
        campaignTeam.campaignTeamMembers.forEach(teamMember => {
          teamMember.teamName = campaignTeam.teamName;
          teamMember.percentage = ScoreUtil.getPercentageFromTeamMember(teamMember);

          userScores.push(teamMember);
        });
      });
      setUserScores(userScores);
    }




  }, [campaignScore]);

  // console.log('userScores', userScores.map(orders => orders.orders))

  const renderUser = (user, index) => {
    return (
      <tr className="v-middle" key={index}>
        <td>
          <div className="avatar-group">
            <div>
                <span className="w-32 avatar no-shadow">
                  {index === 0 &&
                    <img src="assets/img/trophy/T1.png" alt="." title="1:a plats"/>
                  }
                  {index === 1 &&
                    <img src="assets/img/trophy/T12.png" alt="." title="2:a plats"/>
                  }
                  {index === 2 &&
                    <img src="assets/img/trophy/T4.png" alt="." title="3:e plats"/>
                  }
                  {index > 2 && <small className="text-muted font-weight-bold" title={`${index+1}:e plats`}>{(index+1)}</small>}
                </span>
            </div>
            <div className="avatar ajax w-24" data-toggle="tooltip" title="" data-pjax-state=""
               data-original-title="Cursus">
              <UserImage userId={user.userUniqueId} type="thumb" />
            </div>
          </div>
        </td>
        <td className="flex">
          <a href={`/profile/${user.userUniqueId}`} className="item-title text-color h-1x">{user.userName}</a>
          <div className="item-company text-muted h-1x">
            {user.teamName}
          </div>
        </td>
        <td className="item-amount d-none d-sm-block text-sm text-nowrap" title={user.percentage + '% av mål uppnått'}>
          <CountUp end={user.percentage} separator={" "}/>%
        </td>
      </tr>
    );
  };

  return (
    <div className="col-sm-6">
      <div className="card">
        <div className="card-body">
          <div className="text-muted text-sm sr-item">{selectedCampaign.name}</div>
          <h5 className="text-highlight sr-item">Bästa deltagare</h5>
        </div>
        <table className="table table-theme v-middle table-hover">
          <tbody>
            { userScores
              .sort(topPercentageSort)
              .slice(0, 5)
              .map((user, index) => renderUser(user, index))
            }
          </tbody>
        </table>

        <div className="text-center mb-4">
          <Link to={{pathname: `/leaderboard/${selectedCampaign.id}`}}>
            <button className="btn btn-sm btn-outline-primary btn-rounded">
              <PlusCircle size={16} className="mr-1"/> Visa alla
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserTopscore;