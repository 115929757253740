import React, {useEffect, useState} from 'react';

import {Button, Form, FormGroup, Input, InputGroup, Label} from 'reactstrap';

import {default as Select} from 'react-select';
import {formatDate} from 'react-day-picker/moment';
import {dateFormat, history} from '../../helpers';
import {OrderEventAttributesApi} from "../../api/Order/OrderEventAttribute.api";
import OrderAttribute from "./OrderAttributes.component";
import {EventApi} from '../../api/Event/Event.api';
import {OrderApi} from '../../api/Order/Order.api';
import {EventAttributesApi} from "../../api/EventAttribute";

const OrderForm = ({orderId}) => {
    const defaultOrder = {id: orderId, eventId: '', value: 0, userName: ''};

    const [order, setOrder] = useState({...defaultOrder});
    const [event, setEvent] = useState();
    const [attributeData, setAttributeData] = useState([]);

    const [events, setEvents] = useState([]);
    const [eventOptions, setEventOptions] = useState([]);
    const [selectedEventOption, setSelectedEventOption] = useState(null);

    const handleEventChange = (option) => {
        setOrder({...order, eventId: option.value});
    }

    const handleValueChange = (e) => {
        setOrder({...order, value: e.target.value});
    }

    const handleAttributeChange = attributes => {
        setAttributeData(attributes)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (order.id) {
            await OrderApi
                .update(order.id, order)
                .then(() => {

                    if(attributeData.length === 0) {
                        history.push('/orders')
                    }

                    let promises = []

                    attributeData.forEach(attribute => {
                        let data = {
                            value: attribute.value,
                        }

                        if (attribute.optionId) {
                            data.eventAttributeOptionId = attribute.optionId
                        }

                        promises.push(OrderEventAttributesApi.update(attribute.orderEventAttributeId, data))
                    })

                    Promise.all([promises]).then(() => history.push('/orders'))
                })
        }
    }

    const cancel = () => {
        history.push('/orders');
    }

    useEffect(async () => {
        if (orderId) {
            const order = await OrderApi.get(orderId)
            setOrder(order);

            EventApi.findAll()
                .then(events => {
                    //let availableEvents = events.filter(event => campaign.campaignEvents.some(e => e.eventId === event.id));
                    setEvents(events);
                    setEventOptions(events.map(e => ({label: e.name, value: e.id})));
                })
        }
    }, [])

    useEffect(() => {
        let event = events.find(event => event.id === order.eventId);

        if (event) {
            setSelectedEventOption({
                label: event.name, value: event.id
            });
        }
    }, [order, events])

    useEffect(() => {
        setEvent(events.find(event => event.id === selectedEventOption.value))
    }, [selectedEventOption])

    useEffect(async () => {
        if (event) {
            if (event.hasAttribute) {
                const eventAttributes = await EventAttributesApi.findAll(event.id)
                const orderAttributes = await OrderEventAttributesApi.findAll(order.id)
                const valueMap = new Map()
                orderAttributes.forEach(attribute => valueMap.set(attribute.eventAttributeId, attribute))

                eventAttributes.forEach(attribute => {
                    if (valueMap.has(attribute.id)) {
                        const item = valueMap.get(attribute.id)
                        attribute.orderEventAttributeId = item.id

                        if (item.eventAttributeOptionId) {
                            attribute.eventAttributeOptions.forEach(option => {
                                if (option.id === item.eventAttributeOptionId) {
                                    attribute.optionId = option.id
                                    attribute.value = option.name
                                }

                            })
                        } else {
                            attribute.value = item.value
                        }
                    } else {
                        attribute.value = ''
                    }
                })
                setAttributeData(eventAttributes)
            }
        }
    }, [event])

    if (!order) return <React.Fragment/>

    return (
        <div className="page-content page-container">
            <div className="padding">
                <div className="card">
                    <div className="card-body">
                        <Form id="order-form" onSubmit={handleSubmit}>
                            <div className="form-row">
                                <FormGroup className="col-xs-12 col-md-6">
                                    <Label>Användare</Label>
                                    <input type="text" className="form-control" readOnly={true} value={order.userName}/>
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-6">
                                    <Label>Datum</Label>
                                    <input type="text" className="form-control" readOnly={true}
                                           value={formatDate(order.createdTimestamp, dateFormat)}/>
                                </FormGroup>
                            </div>

                            <div className="form-row">
                                <FormGroup className="col-md-6">
                                    <Label>Aktivitet</Label>
                                    <Select
                                        options={eventOptions}
                                        onChange={handleEventChange}
                                        value={selectedEventOption}
                                    />
                                </FormGroup>

                                {event &&
                                    <FormGroup className="col-md-6">
                                        <Label>Värde</Label>

                                        <InputGroup className="mb-3">
                                            <Input type="number"
                                                   name="order-value"
                                                   placeholder="Värde"
                                                   disabled={event.useFixedValue}
                                                   defaultValue={order.value}
                                                   onChange={handleValueChange}/>

                                            <div className="input-group-append">
                                                <span className="input-group-text">{event.unit}</span>
                                            </div>
                                        </InputGroup>
                                    </FormGroup>
                                }
                            </div>

                            {attributeData.length > 0 &&
                                <OrderAttribute
                                    eventAttributes={attributeData}
                                    onChange={attributes => handleAttributeChange(attributes)}
                                />
                            }

                            <Button color="secondary mr-1" onClick={cancel}>Avbryt</Button>
                            <Button type="submit" color="primary" disabled={order.eventId.length === 0}>Spara</Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderForm;