import {useSelector} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import {campaignService} from '../services/campaign.service';
import CountUp from "react-countup";
import {Progress} from "reactstrap";
import {ProgressWrapper} from "../components/Dashboard/Dashboard.style";
import styled from "styled-components";
import {BreadCrumb} from "../components/Breadcrumb/Breadcrumb";
import {Flex, Wrapper} from "./pages.style";
import ShoutoutMessage from "../components/Shoutout/ShoutoutMessage.component";
import LeaderboardTopUser from "../components/Leaderboard/LeaderboardTopUser.component";
import LeaderboardTopTeams from "../components/Leaderboard/LeaderboardTopTeams.component";
import UserImage from "../components/User/UserImage.component";
import {Link} from "react-router-dom";
import ActivityList from "../components/Dashboard/ActivityList.component";
import {Paginate} from "../components/Shared/Pagination";
import {useInterval} from "../hooks/useInterval";


const CampaignLeaderboard = () => {

    const currentUser = useSelector(state => state.authentication.user);
    const [data, setData] = useState([]);

    let [dataTopList, setDataTopList] = useState([]);

    const fetchData = () => {

        campaignService.findCompanyCampaignsTopList(currentUser.companyId, 'active')
            .then(campaigns => {
                setDataTopList(campaigns)
            });

        // campaignService.findCompanyCampaigns(currentUser.companyId, 'active')
        //     .then(campaigns => {
        //         setData(campaigns)
        //     });
    }

    console.log(dataTopList)

    useEffect(() => {
        fetchData();
    }, []);

    useInterval(fetchData, 10000);




    return (

        <div className="col-md-12 col-lg-12">
            <BreadCrumb
                title={'Kampanj Leaderboard'}
                breadcrumbs={[{name: 'Dashboard', href: '/'}]}
            />
            <div className="row row-sm">
                <div className="col-md-12 mt-4">
                    <Fragment>
                        <div className="row row-sm gap-2">
                            {dataTopList
                                .map((item, i) => {
                                    let user = item.firstName + " " + item.lastName;
                                    let eventName = item.campaignName + " - " + item.teamName;

                                    return (
                                        <Wrapper className="pt-0">
                                            <div className="d-md-flex">
                                                <Flex>
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="text-muted text-sm sr-item">{eventName && eventName}</div>
                                                            <h5 className="text-highlight sr-item">{item.eventName}</h5>
                                                        </div>
                                                        <table className="table table-theme v-middle table-hover">
                                                            <tbody>
                                                            {item.list
                                                                .sort((a, b) => b.value - a.value, {reverse: false})
                                                                .slice(0, 5)
                                                                .map((user, index) => <UserTopList teamMember={user}
                                                                                                   index={index}
                                                                                                   key={index}/>)
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Flex>
                                            </div>
                                        </Wrapper>
                                    )
                                })
                            }
                        </div>
                    </Fragment>
                </div>
            </div>

            {/*/!*<div className="row row-sm">*/}
            {/*    <div className="col-md-9">*/}
            {/*        <Fragment>*/}
            {/*            <div className="row row-sm gap-2">*/}

            {/*                {data*/}
            {/*                    .map((item, i) => {*/}
            {/*                        let eventName = item.campaignName;*/}
            {/*                        let unit = item.unit;*/}
            {/*                        let value = Math.round(item.value);*/}
            {/*                        let goal = Math.round(item.goal);*/}
            {/*                        let team = item.teamName;*/}
            {/*                        let eventCampaignScores = item.goalAchieved;*/}
            {/*                        let isGoalObtainable = true;*/}

            {/*                        let percent = Math.ceil((value / goal) * 100);*/}

            {/*                        return (*/}
            {/*                            <div className="mx-50">*/}
            {/*                                <div className={`card${value === 0 ? ' o5' : ''}`} key={i}>*/}
            {/*                                    <div className="card-body">*/}
            {/*                                        <div className="d-md-flex">*/}
            {/*                                            <div className="flex">*/}
            {/*                                                <div className="item-amount text-highlight">*/}
            {/*                                                    {eventName}*/}
            {/*                                                </div>*/}
            {/*                                                <div>{item.eventName} {unit} (lag {team})</div>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}

            {/*                                        <ProgressWrapper>*/}
            {/*                                            <div*/}
            {/*                                                className="text-muted text-md mb-1">{value} / {goal} {unit}</div>*/}

            {/*                                            <Progress*/}
            {/*                                                value={percent}*/}
            {/*                                                color={isGoalObtainable ? 'success' : 'danger'}*/}
            {/*                                                title={percent + '%'}*/}
            {/*                                                className='text-md'*/}
            {/*                                            >*/}
            {/*                                                {percent >= 15 &&*/}
            {/*                                                    <ProgressValue title={percent + '%'}>*/}
            {/*                                                        <CountUp end={percent} duration={"2"}/>%*/}
            {/*                                                    </ProgressValue>*/}
            {/*                                                }*/}
            {/*                                            </Progress>*/}
            {/*                                        </ProgressWrapper>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        )*/}
            {/*                    })*/}
            {/*                }*/}
            {/*            </div>*/}
            {/*        </Fragment>*/}
            {/*    </div>*/}
            {/*</div>*!/*/}

        </div>
    );
};

export default CampaignLeaderboard;

export const ProgressValue = styled.div`
  align-self: center;
  margin-left: 0.5rem;
  font-size: 14px;
`
const UserTopList = ({teamMember, index}) => {
    // console.log(teamMember);
    return (
        <tr className=" v-middle">
            <td width="80">
                <div className="avatar-group ">
                    <div>
              <span className="w-32 avatar no-shadow">
                {index === 0 && <img src="/assets/img/trophy/T1.png" alt="."/>}
                  {index === 1 && <img src="/assets/img/trophy/T12.png" alt="."/>}
                  {index === 2 && <img src="/assets/img/trophy/T4.png" alt="."/>}
                  {/*index >= 3 && index <= 4 && <img src="/assets/img/trophy/T8.png" alt="."/> */}
                  {index >= 3 && <small className="text-muted font-weight-bold">{(index + 1)}</small>}
              </span>
                    </div>
                    <div className="avatar ajax w-24" data-toggle="tooltip" title="" data-pjax-state=""
                         data-original-title="Cursus">
                        {/*<UserImage userId={teamMember.userUniqueId} type="thumb"/>*/}
                    </div>
                </div>
            </td>
            <td className="flex">
                {/*<Link to={{pathname: `/profile/${teamMember.userUniqueId}`}} className="item-title text-color h-1x">
                    {teamMember.userName}
                </Link>*/}
                <div className="item-title text-color h-1x">
                    {teamMember.firstName} {teamMember.lastName}
                </div>
            </td>
            <td align="right" className="pr-4">
                <div className="avatar-group right">
                    <span className="item-amount d-none d-sm-block text-sm text-nowrap ml-4">
                        <CountUp  start={0} end={Math.ceil(teamMember.value)} separator={" "} /> {teamMember.unit}
                    </span>
                </div>
            </td>
        </tr>
    );
}