import {
  SET_LATEST_ACTIVITIES,
  OPEN_COMMENT,
  CLOSE_COMMENTS,
  EDIT_COMMENT,
  ADD_MESSAGE,
  REMOVE_MESSAGE,
  REMOVE_COMMENT,
  ADD_COMMENT,
  UPDATE_COMMENT,
  IS_PAGE_LOAD_EVENT
} from "../types/latestActivityList.types";

export function setLatestActivities(messages) {
  return (dispatch) => {
    dispatch({ type: SET_LATEST_ACTIVITIES, messages });
  };
}

export function addMessage(message) {
  return (dispatch) => dispatch({ type: ADD_MESSAGE, message });
}

export function deleteMessage(message) {
  return (dispatch) => dispatch({ type: REMOVE_MESSAGE, message });
}

export function startComment(message) {
  return (dispatch) => {
    dispatch({ type: OPEN_COMMENT, message });
  };
}

export function closeComments(message) {
  return (dispatch) => dispatch({ type: CLOSE_COMMENTS, message });
}

export function addComment(comment) {
  return (dispatch) => dispatch({ type: ADD_COMMENT, comment });
}

export function editComment(comment, isEditing) {
  return (dispatch) => dispatch({ type: EDIT_COMMENT, comment, isEditing });
}

export function updateComment(comment) {
  return (dispatch) => dispatch({ type: UPDATE_COMMENT, comment });
}

export function deleteComment(comment) {
  return (dispatch) => dispatch({ type: REMOVE_COMMENT, comment });
}