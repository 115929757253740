import React, {useEffect, useState} from 'react'
import {Container, ContentWrapper, Flex, FlexRow, FlexWrapper, Wrapper} from '../pages.style'
import {Card, Col, Progress, Row} from 'reactstrap'
import {Line, Doughnut} from 'react-chartjs-2'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router'

import {BontiApi} from '../../api/Bonti/Bonti.api'
import {CardBody} from '../../components/Shared/Card'
import moment from 'moment'
import {Strong} from '../../components/Shared/Style'
import {BestDailyOrder, ProgressValue, ProgressWrapper, ResultValue} from '../../components/Bonti/Bonti.style'
import CountUp from 'react-countup'
import BontiDailyTeam from '../../components/Bonti/BontiDailyTeam.component'
import BontiTeamMember from '../../components/Bonti/BontiTeamMember.component'
import {foldLeftNav, unfoldLeftNav} from '../../actions/layout.actions'
import {Menu} from 'react-feather'

import {BONTI_COMPANY_ID} from "../../helpers/integrations/bonti";

import * as styles from './bonti.scss'

const Total = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const selectedCampaign = useSelector(state => state.campaign.selectedCampaign)
  const currentUser = useSelector(state => state.authentication.user)

  const [teams, setTeams] = useState([])
  const [teamMembers, setTeamMembers] = useState([])

  const [customers, setCustomers] = useState(0)
  const [orderTotal, setOrderTotal] = useState({total: 0, year: 0, month: 0, week: 0})

  const [bestDailyOrder, setBestDailyOrder] = useState(null)

  const [totalGoal, setTotalGoal] = useState(0)
  const [orderValuesYesterday, setOrderValuesYesterday] = useState(0)

  const layoutSettings = useSelector(state => state.layout.settings)

  const toggleLeftNav = () => {
    if (layoutSettings.aside === 'folded') {
      dispatch(unfoldLeftNav());
    } else {
      dispatch(foldLeftNav());
    }
  }

  const getTotalPercentage = () => {
    let percentage = 0

    if (totalGoal > 0) {
      percentage = Math.floor((orderTotal.total / totalGoal * 100))
    }

    return percentage < 100 ? percentage : 100
  }

  const fetchData = () => {
    BontiApi.getYesterdaysCustomers()
      .then(result => {
        if (!result || result == null || result == "") {
          return
        }

        setCustomers(result)
      })
      .catch(err => {
        console.log({err})
      })

    BontiApi.getYesterdaysOrders()
      .then(result => {
        if (!result || result == null || result == "") {
          return
        }

        setOrderValuesYesterday(result)
      })
      .catch(err => {
        console.log({err})
      })

    BontiApi.getGoal().then(goal => {
      if (goal) {
        setTotalGoal(goal.totalGoal)

        const monthMap = new Map()
        let m = moment()
        for (let i = 0; i < 12; i++) {
          const date = m.month(i).format('YYYY-MM')
          monthMap.set(date, 0)

          if (goal[date]) {
            monthMap.set(date, goal[date])
          }
        }
      }
    })

    BontiApi.getOrderTotal().then((result) => {

      if (!result) {
        return
      }

      setOrderTotal({
        total: result.total,
        year: result.year,
        month: result.month,
        week: result.week,
      })
    })

    BontiApi.findOrdersSum(BONTI_COMPANY_ID).then(orderSum => {
      if (!orderSum) {
        return
      }

      setTeams(orderSum.teams)
      setTeamMembers(orderSum.users)

      if (orderSum.bestDailyOrder) {
        setBestDailyOrder(orderSum.bestDailyOrder)
      }
    })
  }

  useEffect(() => {
    fetchData()

    setInterval(() => {
      fetchData()
    }, 1000 * 60 * 5)
  }, [])

  /*useEffect(() => {

    if (teams.length > 0) {
      let teamMembers = []
      teams.forEach(team => {
        team.teamMembers.forEach(teamMember => {
          teamMembers.push({...teamMember})
        })
      })

      setTeamMembers([...teamMembers])

      const teamNames = []

      teams.forEach(team => {
        teamNames.push(team.teamName)
      })

      setTeamNames(teamNames)
    }
  }, [teams])*/

  return (
    <Container id="bonti-container" className="total">
      {!currentUser &&
      <div className="pointer ml-2 mt-2" title={layoutSettings.aside === 'folded' ? "Visa" : "Göm"}>
        <Menu size={16} onClick={toggleLeftNav}/>
      </div>
      }

      <FlexWrapper>
        <div>
          <h2 className="text-md text-highlight">
            Bonti Total
          </h2>
        </div>
        <Flex/>
      </FlexWrapper>

      <Wrapper className="pt-0">
        <div className="d-md-flex" style={{justifyContent: 'space-between'}}>
          <ContentWrapper style={{width: '100%'}}>

            <div className="row">
              <div className="col-12">
                {selectedCampaign &&
                <div className="pb-3">
                  <div className="text-muted text-sm sr-item">Bontis årliga resultat</div>
                </div>
                }
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-8">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={9}>
                        <Strong className="mb-2">Resultat</Strong>
                      </Col>

                      <Col md={3}>
                        <div className="text-muted">Årsbudget</div>
                      </Col>
                    </Row>

                    {orderTotal &&
                    <React.Fragment>
                      <Row>
                        <Col md={3}>
                          <ResultValue>
                            <CountUp start={0} end={orderTotal.year} duration={2} decimal="0" separator={' '}/> kr
                          </ResultValue>
                          <div>År</div>
                        </Col>

                        <Col md={3}>
                          <ResultValue>
                            <CountUp start={0} end={orderTotal.month} duration={2} decimal="0" separator={' '}/> kr
                          </ResultValue>
                          <div>Månad</div>
                        </Col>

                        <Col md={3}>
                          <ResultValue>
                            <CountUp start={0} end={orderTotal.week} duration={2} decimal="0" separator={' '}/> kr
                          </ResultValue>
                          <div>Vecka</div>
                        </Col>

                        <Col md={3}>
                          <ProgressWrapper>
                            <div className="text-muted mb-1">
                              <CountUp start={totalGoal} end={totalGoal} duration={2} decimal="0" separator={' '}/> kr
                            </div>

                            <FlexRow>
                              <Progress
                                value={getTotalPercentage()}
                                color="success"
                                title={getTotalPercentage() + '%'} style={{width: '100%'}}>
                              </Progress>
                              <ProgressValue>{getTotalPercentage()}%</ProgressValue>
                            </FlexRow>
                          </ProgressWrapper>
                        </Col>
                      </Row>
                    </React.Fragment>
                    }
                  </CardBody>
                </Card>
              </div>

              <div className="col-12 col-md-4">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={3}>
                        <div className="avatar w-96 m-2 no-shadow gd-success">
                          <CountUp start={customers} end={customers} duration={2} decimal="0" separator={' '}/>
                        </div>
                      </Col>

                      <Col md={3}>
                        <h3 style={{marginTop: 40}}>Besökare</h3>
                      </Col>

                      <Col md={3}>
                        <div className="avatar w-96 m-2 no-shadow gd-success">
                          <CountUp
                            start={parseInt((orderValuesYesterday / customers).toFixed(2))}
                            end={parseInt((orderValuesYesterday / customers).toFixed(2))}
                            decimal="0"
                            separator={' '}/>
                        </div>
                      </Col>

                      <Col md={3}>
                        <h3 style={{marginTop: 40}}>kr/h</h3>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </div>

            <div className="row">
              <div className="col-8">
                <Card>
                  <CardBody>
                    <div className="text-muted">Totalt</div>
                    <Strong>Bästa deltagare</Strong>
                    <BontiTeamMember type="total" teamMembers={teamMembers} />
                  </CardBody>
                </Card>
              </div>

              <div className="col-4">
                <div className="row">
                  <div className="col-12">
                    <Card>
                      <CardBody>
                        <div className="text-muted">Dagens</div>
                        <Strong className="mb-2">Största affär</Strong>

                        {bestDailyOrder ?
                          <FlexRow>
                            <img alt="" src="https://salestrigger.s3-eu-west-1.amazonaws.com/default/profile64x64"
                                 width={48} height={48} style={{flexGrow: 0, flexShrink: 0}}/>

                            <BestDailyOrder>
                              <div>
                                <CountUp end={bestDailyOrder.value} duration={2} decimal="0" separator={' '}/> kr
                                av {bestDailyOrder.userName}
                                <div className="text-muted">{bestDailyOrder.teamName}</div>
                              </div>
                            </BestDailyOrder>
                          </FlexRow>
                          : <>Ingen affär idag</>
                        }
                      </CardBody>
                    </Card>
                  </div>

                  <div className="col-12">
                    <Card>
                      <CardBody>
                        <div className="text-muted">Dagens</div>
                        <Strong>Bästa deltagare</Strong>
                        <BontiTeamMember type="daily" teamMembers={teamMembers} />
                      </CardBody>
                    </Card>
                  </div>

                  <div className="col-12">
                    <Card>
                      <CardBody>
                        <div className="text-muted">Dagens</div>
                        <Strong>Bästa butik</Strong>
                        <BontiDailyTeam teams={teams} />
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </ContentWrapper>
        </div>
      </Wrapper>
    </Container>
  )
}

export default Total
