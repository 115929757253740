import client from "../helpers/client";

export const statisticsService = {
  findByUser
};

export function findByUser(userId) {
  return client.getClient()
    .get('/statistics/user/' + userId)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      let errorMessage = (err.response.data) || err.response.statusText;
      return Promise.reject(errorMessage);
    });
}