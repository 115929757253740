import {ChevronDown, ChevronUp, Trash} from 'react-feather';
import {Button, Input} from 'reactstrap';
import styled from 'styled-components';

const AttributeOptions = ({ options, onDisplayOrderChange, onNameChange, onDelete }) => {
  return (
    <>
      <div className='form-row mb-2'>
        <div className='col-md-12'>
          {options.length > 0 && <label>Alternativ</label>}

          <OptionList>
            {options
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((option) => (
                <div className='mb-2' key={option.displayOrder}>
                  <div className='d-flex'>
                    <Input type="text"
                           className="form-control mr-2"
                           name=""
                           placeholder="Namn"
                           required
                           value={option.name}
                           onChange={(e) => onNameChange(e.target.value, option)}/>

                    <Button color='secondary mr-1'
                            title='Flytta upp'
                            disabled={option.displayOrder === 0}
                            onClick={() => onDisplayOrderChange(option, 'up')}>
                      <ChevronUp size={15} />
                    </Button>

                    <Button color='secondary mr-1'
                            title='Flytta ned'
                            disabled={option.displayOrder === options.length - 1}
                            onClick={() => onDisplayOrderChange(option, 'down')}>
                      <ChevronDown size={15} />
                    </Button>

                    <Button color='primary mr-1'
                      title='Ta bort'
                      onClick={() => onDelete(option)}>
                      <Trash size={15} />
                    </Button>
                  </div>
                </div>
              ))}
          </OptionList>
        </div>
      </div>
    </>
  )
}

export default AttributeOptions

const OptionList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%
`