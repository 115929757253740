import React from 'react';
import {Link} from 'react-router-dom';
import './Campaign.styles.scss';

const CampaignDone = () => {
  return (
    <div className="tab-pane active">
      <div className="form-group">
        <p>
          <strong>Grattis!</strong>
        </p>
        <p>Kampanjen har sparats</p>

        <div>
          <Link to={{pathname: '/campaigns'}}>
            <button type="button" className="btn btn-primary">
              Visa kampanjer
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CampaignDone;