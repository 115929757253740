import {LAYOUT_LIGHT, LAYOUT_DARK, LAYOUT_FOLD_NAV, LAYOUT_UNFOLD_NAV} from '../types/layout.types';

import {getLocalStorage} from '../services/storage.service';

const initialState = {
  settings: {
    aside: '',
    bg: '',
    header: 'bg-body',
    main: '',
  }
};

const storage = getLocalStorage();
const storageKey = 'layoutSettings';

if (storage.hasItem(storageKey)) {
  let settings = storage.getItem(storageKey);

  if (settings) {
    initialState.settings = settings;
  }
}
else {
  storage.setItem(storageKey, initialState.settings);
}

const layoutReducer = (state = initialState, action) => {

  switch (action.type) {

    case LAYOUT_LIGHT: {
      const settings = {
        aside: '',
        bg: '',
        main: ''
      };

      localStorage.setItem("layoutSettings", JSON.stringify(settings));
      state.settings = settings;

      return state;
    }

    case LAYOUT_DARK: {
      const settings = {
        aside: '',
        bg: 'bg-dark',
        main: 'dark-fill'
      };

      localStorage.setItem('layoutSettings', JSON.stringify(settings));
      state.settings = settings;

      return state;
    }

    case LAYOUT_FOLD_NAV: {

      const settings = {
        aside: 'folded',
        bg: state.settings.bg,
        main: state.settings.main
      }

      state.settings = settings;

      return state;
    }

    case LAYOUT_UNFOLD_NAV: {

      const settings = {
        aside: '',
        bg: state.settings.bg,
        main: state.settings.main
      }

      state.settings = settings;

      return state;
    }

    default: {
      return state;
    }
  }
}

export default layoutReducer;