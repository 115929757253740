import React, {useEffect, useState} from 'react'

import {Card, CardBody, CardHeader} from '../Shared/Card'
import {Button, Input} from 'reactstrap'
import {Plus} from 'react-feather'
import {EventAttributesApi} from '../../api/EventAttribute'

import Select from '../Shared/Form/Select'

import {BoldMutedText} from '../Shared/Style'
import AttributeList from './Attribute/AttributeList'
import AttributeOptions from './Attribute/AttributeOptions';

const EventAttributeForm = ({ event }) => {
  const defaultAttribute = { description: '', type: '', eventAttributeOptions: [], isEditing: false }

  const [title, setTitle] = useState('Attribut')
  const [selectedAttribute, setSelectedAttribute] = useState({...defaultAttribute})

  const [attributeList, setAttributeList] = useState([])
  const [selectedAttributeTypeOption, setSelectedAttributeTypeOption] = useState(null)

  const [attributeTypeOptions] = useState([
    {value: "TEXT", label: "TEXT"},
    {value: "RADIO", label: "RADIO"}
  ])

  const [isAdding, setAdding] = useState(false)
  const [isEditing, setEditing] = useState(false)

  const handleNewAttribute = () => {
    setAdding(true)

    setSelectedAttribute({
      description: '',
      type: '',
      eventAttributeOptions: [],
      isEditing: true
    })
  }

  const handleEditClick = (e) => {
    setEditing(true)
    e.isEditing = true

    const option = attributeTypeOptions.find(option => option.value === e.type)

    if (option) {
      setSelectedAttributeTypeOption(option)
      setSelectedAttribute({...selectedAttribute, ...e })
    }
  }

  const handleDeleteClick = async (attribute) => {
    EventAttributesApi.remove(attribute.id)
      .then(async () => {
        let attributes = await EventAttributesApi.findAll(event.id)
        const newAttributes = attributeList.filter(a => !a.id)

        if (newAttributes.length > 0) {
          attributes = attributes.concat(newAttributes)
        }
        console.log(attributes)
        setAttributeList(fixAttributeDisplayOrder(attributes))
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleAttributeDisplayOrderClick = async (attr, direction) => {
    let i = attr.displayOrder
    let newDisplayOrder = null

    if (direction === 'up' && i > 0) {
      newDisplayOrder = i - 1
    } else if (direction === 'down' && i < attributeList.length - 1) {
      newDisplayOrder = i + 1
    }

    if (newDisplayOrder != null) {
      await EventAttributesApi.save({
        id: attr.id ? attr.id : null,
        displayOrder: newDisplayOrder
      })
      console.log(attributeList)
      const attributes = await EventAttributesApi.findAll(event.id)
      console.log(attributes)
      setAttributeList(attributes)
    }
  }

  const fixAttributeDisplayOrder = (attributes) => {

    let newAttributeEvents = [...attributes]

    return newAttributeEvents.map((attr, i) => {
      attr.displayOrder = i

      return attr
    })
  }

  const fixOptionsDisplayOrder = (attributes) => {
    let newAttributeOptions = [...attributes]

    return newAttributeOptions.map((attr) => {
      attr.eventAttributeOptions.map((opt, i) => {
        opt.displayOrder = i
      })
      return attr
    })
  }

  const handleAttributeNameChange = (e) => {
    let newAttributeEvent = {...selectedAttribute}
    newAttributeEvent.description = e.target.value
    setSelectedAttribute(newAttributeEvent)
  }

  const handleAttributeTypeChange = (e) => {
    let newAttributeEvent = {...selectedAttribute}
    newAttributeEvent.type = e.value

    if (newAttributeEvent.type === 'RADIO') {
      if (newAttributeEvent.eventAttributeOptions.length === 0) {
        newAttributeEvent.eventAttributeOptions.push({
          name: '',
          eventAttributeId: selectedAttribute.id ? selectedAttribute.id : null,
          displayOrder: 0
        });
      }
    }

    setSelectedAttribute(newAttributeEvent)

    let types = attributeTypeOptions.find(o => o.value === e.value)
    setSelectedAttributeTypeOption(types)
  }

  const handleAttributeOptionNameChange = (value, option) => {
    let updateName = [...selectedAttribute.eventAttributeOptions]
    updateName.map(o => {
      if (o === option) {
        o.name = value
      }
    })

    setSelectedAttribute({ ...selectedAttribute, eventAttributeOptions: updateName})
  }

  const handleAddOption = (e) => {
    e.preventDefault()

    if (selectedAttribute) {
      let options = selectedAttribute.eventAttributeOptions

      options.push({
        eventAttributeId: selectedAttribute.id,
        displayOrder: options.length,
        name: ''
      })

      setSelectedAttribute({...selectedAttribute, eventAttributeOptions: options})
    }
  }

  const handleDeleteOption = (option) => {
    const newOptions = selectedAttribute.eventAttributeOptions.filter(opt => opt.displayOrder !== option.displayOrder)
    const attr = { ...selectedAttribute, eventAttributeOptions: newOptions}
    setSelectedAttribute(attr)

    const attributes = attributeList.map(a => {
      if (a.displayOrder === attr.displayOrder) {
        return attr
      }

      return a
    })

    setAttributeList(attributes)
    fixOptionsDisplayOrder(attributes)
  }

  const handleOptionDisplayOrderClick = (option, direction) => {
    let i = option.displayOrder

    const options = [...selectedAttribute.eventAttributeOptions]
    if (direction === 'up' && i > 0) {
      const previousIndex = i - 1
      options[i].displayOrder = previousIndex

      if (options[previousIndex]) {
        options[previousIndex].displayOrder = i
      }
    } else if (direction === 'down' && i < options.length - 1) {
      const nextIndex = i + 1
      options[i].displayOrder = nextIndex

      if (options[nextIndex]) {
        options[nextIndex].displayOrder = i
      }
    }

    setSelectedAttribute({...selectedAttribute, eventAttributeOptions: options})
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    let attribute = {...selectedAttribute}

    if (isAdding) {
      attribute.displayOrder = attributeList.length
      attribute.saved = false
    }

    await EventAttributesApi.save({
      ...attribute,
      id: attribute.id ? attribute.id : null,
      eventId: event.id,
      description: attribute.description,
      type: attribute.type,
      eventAttributeOptions: attribute.eventAttributeOptions
    })

    const attributes = await EventAttributesApi.findAll(event.id)
    setAttributeList(attributes)
    cancelEdit()
  }

  const cancelEdit = () => {
    setSelectedAttribute(defaultAttribute)
    setSelectedAttributeTypeOption(null)
    setAdding(false)
    setEditing(false)
  }

  const isFormValid = () => {
    if (selectedAttribute) {
      if (selectedAttribute.description.length === 0) {
        return false
      }

      if (['TEXT', 'RADIO'].indexOf(selectedAttribute.type) === -1) {
        return false
      }
    }

    return true
  }

  useEffect(() => {
    if (event && event.id) {
      EventAttributesApi.findAll(event.id)
        .then(_attributes => {
          setAttributeList(_attributes)
        })
    }
  }, [])

  useEffect(() => {
    let title = 'Attribut'

    if (isAdding) {
      title = 'Nytt attribut'
    }

    if (isEditing) {
      title = selectedAttribute && selectedAttribute.id ? `Redigera ${selectedAttribute.description}` : 'Redigera attribut'
    }

    setTitle(title)
  }, [isAdding, isEditing])

  if (!event) {
    return null
  }

  return (
    <Card>
      <CardHeader className='d-flex justify-content-between'>
        <strong>{title}</strong>

        {(!isAdding && !isEditing) &&
          <Button onClick={() => handleNewAttribute()} color='secondary'>
            <Plus size={16} />
          </Button>
        }
      </CardHeader>

      <CardBody>
        {(!selectedAttribute.isEditing) && attributeList.length === 0 && <BoldMutedText>Inga attribut</BoldMutedText>}

        {!selectedAttribute || !selectedAttribute.isEditing &&
          <AttributeList
            attributes={attributeList}
            onDisplayOrderChange={handleAttributeDisplayOrderClick}
            onEdit={handleEditClick}
            onDelete={handleDeleteClick}
          />
        }

        <div className="mb-2">
          <div>
            {selectedAttribute && selectedAttribute.isEditing &&
              <form onSubmit={handleSubmit}>
                <div className='form-row mb-4'>
                  <div className='col-md-6'>
                    <Input type="text"
                           className="form-control"
                           name=""
                           placeholder="Namn"
                           required
                           value={selectedAttribute.description}
                           onChange={handleAttributeNameChange}/>
                  </div>

                  <div className='col-md-6'>
                    <Select options={attributeTypeOptions}
                      value={selectedAttributeTypeOption ? selectedAttributeTypeOption : null}
                      onChange={handleAttributeTypeChange}
                    />
                  </div>
                </div>

                {selectedAttributeTypeOption && selectedAttributeTypeOption.value === 'RADIO' &&
                  <>
                    <AttributeOptions
                      options={selectedAttribute.eventAttributeOptions}
                      onDisplayOrderChange={handleOptionDisplayOrderClick}
                      onNameChange={handleAttributeOptionNameChange}
                      onDelete={handleDeleteOption}
                    />
                    <div className='form-row mb-4'>
                      <div className='col-md-12'>
                        <Button onClick={handleAddOption} color="secondary mr-1">
                          Nytt alternativ
                        </Button>
                      </div>
                    </div>
                  </>
                }

                <div className='form-row justify-content-end'>
                  <div className='col-md-2 d-flex justify-content-end'>
                    <Button type='button' color="secondary mr-1"
                            onClick={cancelEdit}>
                      Avbryt
                    </Button>

                    <Button type="submit" color='primary'
                            disabled={!isFormValid()}>
                      Spara
                    </Button>
                  </div>
                </div>
              </form>
            }
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default EventAttributeForm