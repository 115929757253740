import {LOAD_USER_LIST} from '../types';

const defaultState = { loadUserList: false };

const companyReducer = (state = defaultState, action) => {

  switch (action.type) {

    case LOAD_USER_LIST: {
      return {...state, loadUserList: true};
    }

    default:
      return state
  }
}

export default companyReducer;