import client from '../../helpers/client'

const baseUrl = '/user-roles'

const findAll = () => {
  return client.getClient()
    .get(baseUrl)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    })
}

const findAvailable = (userId) => {
  let url = `${baseUrl}/${userId}/available`

  return client.getClient()
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    })
}

export default {
  findAll,
  findAvailable
}