import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {userService} from '../../services/user.service';
import UserImage from '../User/UserImage.component';
import {Pagination, Paginate} from '../Shared/Pagination';
import {Card, CardHeader} from '../Shared/Card';

const ProfileUserList = () => {
  const currentUser = useSelector(state => state.authentication.user);
  const userList = useSelector(state => state.userList.users || []);

  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const notCurrentUser = (user) => user.id !== currentUser.id;
  const userSort = (userA, userB) => (userA.firstName + ' ' + userA.lastName)
      .localeCompare((userB.firstName + ' ' + userB.lastName));

  useEffect(() => {
    let users = userList.filter(notCurrentUser).sort(userSort);
    setUsers(users);
  }, [userList]);

  return (
    <Card className="sticky" style={{ zIndex: 0 }}>
      <CardHeader>
        <strong>Kollegor</strong>
      </CardHeader>

      <div className="list list-row">
        { Paginate(users, 10, currentPage)
          .map((user, index) => (
            <div className="list-item " key={index}>
              <div>
                <a href="#">
                  <span className="w-40 avatar">
                    <span className="b-white avatar-right"/>
                    <UserImage userId={user.id} type="thumb"/>
                  </span>
                </a>
              </div>
              <div className="flex">
                <a href={`/profile/${user.id}`} className="item-author text-color ">{ user.firstName } { user.lastName }</a>

                <div>
                  { user.userRoles.map((role, index) =>
                    <span className="text-muted" key={index}>{ userService.translateRole(role) }&nbsp;</span>
                  )}
                </div>
              </div>
            </div>
        ))}

        { users.length > 0 &&
          <PaginationWrapper>
            <Pagination
              items={users}
              itemsPerPage={10}
              currentPage={currentPage}
              onChange={(page) => setCurrentPage(page)}
            />
          </PaginationWrapper>
        }
      </div>
    </Card>
  );
};

export default ProfileUserList;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
`