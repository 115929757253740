import React, {useEffect, useState} from 'react'
import {FlexRow} from '../../pages/pages.style'
import {CardBody, Collapse, Progress} from 'reactstrap'
import CountUp from 'react-countup'
import {QuinyxApi} from "../../api/Bonti/Quinyx.api";
import moment from "moment";
import {Strong} from "../Shared/Style";
import styled from "styled-components";
import {Card} from "../Shared/Card";
import {DashboardCard} from "./Dashboard.style";
import {ChevronDown, ChevronUp} from "react-feather";
import SortableComponent from "./SortableComponent.component";
import {Scrollbars} from "react-custom-scrollbars-2";
import DashboardCollapse from "./DashboardCollapse.component";
import {Wrapper} from './Dashboard.style'


const CampaignMonthlyTeam = ({teams, name, component, isEditing}) => {

    const [monthlyTeams, setMonthlyTeams] = useState([])
    const [isCollapsed, setCollapsed] = useState(true)

    const toggle = () => setCollapsed(!isCollapsed);

    useEffect(() => {
        let monthlyTeams = []
        if (teams === null){
            return
        }
        teams.forEach((team) => {

            QuinyxApi.findAllByTeamIdAndDates(team.teamId, moment().startOf("month").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD"))
                .then(result => {
                    team.value = team.currentMonthSum
                    team.percentage = team.currentMonthGoalProcent //calculateTeamPercentage(orders, team)

                    const hours = result.length > 0 && result
                        .map(res => res.hours)
                        .reduce((a, b) => {
                            return a + b
                        })

                    if (hours) {
                        team.monthlyAverage = Math.round(team.value / hours)
                    }

                    monthlyTeams.push({...team})
                })
                .catch(err => {
                    console.log(err)
                })
        })

        setMonthlyTeams(monthlyTeams)
    }, [teams])

    return (
        <Wrapper>
            <DashboardCollapse component={component}>
                <Scrollbars style={{height: 540, scrollWidth: 5}}>
                <div className="cards px-4">
                    <div className='d-flex justify-content-between w-100'>
                        <div className="align-self-center"></div>
                    </div>
                        <table className="table table-theme v-middle table-hover">
                            <tbody>
                            {
                                monthlyTeams
                                    .sort((a, b) => b.percentage - a.percentage)
                                    .map((team, i) =>
                                            <tr className="v-middle" key={i}>
                                                <td className='w-32'>
                                                    <div className="avatar-group">
                                                        {i === 0 &&
                                                            <div title={'#' + (i + 1)}>
                    <span className="w-32 avatar no-shadow">
                      <img src="/assets/img/trophy/T11.png" alt="."/>
                    </span>
                                                            </div>
                                                        }

                                                        {i > 0 && i < 3 &&
                                                            <div title={'#' + (i + 1)}>
                      <span className="w-32 avatar no-shadow">
                        <img src="/assets/img/trophy/T9.png" alt="."/>
                      </span>
                                                            </div>
                                                        }

                                                        {i > 2 &&
                                                            <div className="avatar ajax w-32 bg-white no-shadow" title={'#' + (i + 1)}>
                                                                <small className="text-muted font-weight-bold">{i + 1}</small>
                                                            </div>
                                                        }
                                                    </div>
                                                </td>

                                                <td className="flex">
                                                    <div className="item-title text-color h-2x">{team.teamName}</div>
                                                    <div className="item-company text-muted"
                                                         title={"Antal lagmedlemmar: " + team.teamUserCount}>
                                                        {team.monthlyAverage ? team.monthlyAverage + "kr/h snitt" : ""}
                                                    </div>

                                                    <CountUp start={0} end={team.currentMonthSum} duration={2} decimal={'0'}
                                                             separator={' '}/> kr
                                                    <FlexRow>
                                                        <Progress
                                                            style={{width: "100%"}}
                                                            value={team.percentage}
                                                            color="success"
                                                            title={team.percentage + '%'}>{team.percentage}%
                                                        </Progress>
                                                    </FlexRow>

                                                </td>


                                            </tr>
                                    )
                            }
                            </tbody>
                        </table>
                </div>
                </Scrollbars>
            </DashboardCollapse>
        </Wrapper>
    )
}

export default CampaignMonthlyTeam


