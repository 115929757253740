import {LOGOUT, AUTHENTICATE} from '../types/authentication.types';
import authService from '../services/auth.service';

export function authenticate() {
  return dispatch => {

    authService.getProfile()
      .then(profile => {
        let user = authService.createUserObject(authService.getParsedToken(), profile);
        dispatch({ type: AUTHENTICATE, isAuthenticated: true, user });
      })
  }
}

export function logout() {
  return {
    type: LOGOUT
  }
}