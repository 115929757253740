import React, {useEffect, useState} from 'react'
import {Container, FlexWrapper, Wrapper} from './pages.style'
import {useDispatch, useSelector} from "react-redux"
import CampaignTotalSum from '../components/Dashboard/CampaignTotalSum.component'
import CampaignTotal from '../components/Dashboard/CampaignTotalSum.component'
import CampaignTotalBar from '../components/Dashboard/CampaignTotalBar.component'
import {campaignService} from '../services/campaign.service'
import {setCampaignList} from '../actions/campaign.actions'
import {history, setDocumentTitle} from '../helpers'
import {CampaignApi} from '../api/Campaign/Campaign.api'
import CampaignTotalLineGraph from "../components/Dashboard/CampaignTotalLineGraph.component";
import BestTeamMember from "../components/Dashboard/BestTeamMember.component";
import CampaignDailySales from "../components/Dashboard/CampaignDailySales.component";
import styled from 'styled-components';
import CampaignBestSellSum from "../components/Dashboard/CampaignBestSellSum.component";
import CampaignBestSellQuantity from "../components/Dashboard/CampaignBestSellQuantity.component";
import CampaignMonthlyTeam from "../components/Dashboard/CampaignMonthlyTeam.component";
import CampaignDoughnutGraph from "../components/Dashboard/CampaignDoughnutGraph";
import {BontiApi} from "../api/Bonti/Bonti.api";
import CampaignRSCountData from "../components/Dashboard/CampaignRSCountData";
import {DashboardApi} from "../api/Dashboard/Dashboard.api";
import {getDashboards, setSelectedComponents, setTmpComponents} from "../actions/dashboard.actions";
import ConfirmationModal from "../components/Dashboard/ConfirmationModal.component";
import DashboardGrid from "../components/Dashboard/DashboardGrid.component";
import {Field, Form, Formik} from "formik";
import {DashboardSchema} from "../api/Dashboard/Dashboard.schema";
import {FormGroup, Label} from "reactstrap";
import {FieldError} from "../components/Shared/Style";
import Select from "react-select";
import EditDashboardGridComponent from "../components/Dashboard/EditDashboardGrid.component";
import settings from "./Settings";

const Magnus = (props) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.authentication.user)
  const dashboards = useSelector(state => state.dashboard.dashboards)
  const selectedComponents = useSelector(state => state.dashboard.selectedComponents)
  const tmpComponents = useSelector(state => state.dashboard.tmpComponents)

  const [componentMap, setComponentMap] = useState(new Map())

  const [currentDashboard, setCurrentDashboard] = useState(null)
  const [activeCampaign, setActiveCampaign] = useState(null)

  const [campaignTotal, setCampaignTotal] = useState(null)
  const [campaignTotalSum, setCampaignTotalSum] = useState(null)
  const [bestDailyOrder, setBestDailyOrder] = useState(null)

  const [topItems, setTopItems] = useState(null)
  const [topSumArticles, setTopSumArticle] = useState(null)

  const [teams, setTeams] = useState([])
  const [teamMembers, setTeamMembers] = useState([])

  const [dashboardToDelete, setDashboardToDelete] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const [dashboardToEdit, setDashboardToEdit] = useState(null);
  const [isEditing, setIsEditing] = useState(false)

  const [components, setComponents] = useState([])
  const [allComponents, setAllComponents] = useState([])

  const loadDashboard = () => {
    if (dashboards && dashboards.length > 0) {
      const {pathname} = props.location
      const currentDashboard = dashboards.find(dashboard => pathname === `/dashboard${dashboard.path}`)

      if (currentDashboard) {
        setCurrentDashboard(currentDashboard)
        setIsEditing(false)
      }
    }
  }

  useEffect(() => {

    if (selectedComponents) {
      dispatch(setSelectedComponents([]))
    }

    DashboardApi.findComponents().then(function (components) {
      const allComponents = components.map((component) => ({
        label: component.text,
        value: component.value,
        sortOrder: 0,
        x: 0,
        y: 0
      }))
      setAllComponents(allComponents)
      setComponents(allComponents)
    }).catch(err => {
      console.log(err);
    });
  }, [])

  const markForDelete = (dashboardId) => {
    setDashboardToDelete(dashboardId);
    setModalOpen(true)
  }

  const deleteDashboard = () => {
    if (!dashboardToDelete) {
      setModalOpen(false)
      return
    }

    DashboardApi.delete(dashboardToDelete)
      .then(() => {
        setDashboardToDelete(null);
        setModalOpen(false);
        const nextDashboard = dashboards.find(dashboard => dashboard.id !== dashboardToDelete)

        dispatch(getDashboards(currentUser.companyId))
        if (nextDashboard) {
          history.push(`/dashboard${nextDashboard.path}`)
        } else {
          history.push("/")
        }
      });
  }

  const handleComponentChange = (addedComponents, {action, removedValues}) => {
    if (action === 'clear') {
      addedComponents = addedComponents.map((setting) => {
        const item = componentMap.has(setting.component) ? componentMap.get(setting.component) : ""
        return ({
          value: setting.component,
          sortOrder: setting.order,
          size: setting.size,
          label: item.name,
          x: setting.x,
          y: setting.y
        })
      })
      dispatch(setSelectedComponents(addedComponents))
      return
    }

    const newComponents = components.filter(component => !addedComponents.some(c => c.value === component.value))
    setComponents(newComponents)

    addedComponents.forEach((item, i) => {
      item.sortOrder = i + 1
      if (!item.size) {
        item.size = item.size ?? 12
      }

      item.x = item.x ?? 0;
      item.y = item.y ?? 0;
      item.h = item.h ?? 4;

      const previous = addedComponents[i -1]

      if (previous) {
        item.y = Math.ceil(previous.y + previous.h)

      }
    })

    dispatch(setSelectedComponents(addedComponents))
  }

  const cancelDelete = () => {
    setModalOpen(false)
  }

  const cancelEdit = () => {
    markForEdit(null)
  }

  const markForEdit = (dashboardId) => {
    if (dashboardId) {
      setDashboardToEdit(dashboardId)
      setIsEditing(true)
    } else {
      setDashboardToEdit(null)
      setIsEditing(false)
    }
  }

  const handleSubmit = (values) => {
    if (!currentDashboard) return

    const settings = [];
    tmpComponents.forEach(component => {
      settings.push({
        component: component.value,
        order: component.sortOrder,
        size: component.size,
        x: component.x,
        y: component.y

      })
    })

    const data = {
      name: values.name,
      settings: settings
    }

    DashboardApi.update(currentDashboard.id, data)
      .then((updatedDashboard) => {
        setModalOpen(false)
        setIsEditing(false)
        setDashboardToEdit(null)

        if (updatedDashboard) {
          setCurrentDashboard(updatedDashboard)
        }
      })
  }

  useEffect(() => {
    if (currentUser) {
      campaignService.findByTeamMember(currentUser)
        .then(campaigns => {
          dispatch(setCampaignList(campaigns))

          const activeCampaign = campaigns.find(campaign => campaign.active === 'Active' && campaign.parent === null)

          if (activeCampaign) {
            setDocumentTitle(activeCampaign.name)
            setActiveCampaign(activeCampaign)
          }
        })

      DashboardApi.findComponents().then(function (components) {
        const componentMap = new Map()
        components.forEach((component) => {
          componentMap.set(component.value, {category: component.category, name: component.text})
        })
        setComponentMap(componentMap)
      }).catch(err => {
        console.log(err)
      });
    }
  }, [currentUser])

  useEffect(() => {
    loadDashboard()
  }, [dashboards, props.location])



  useEffect(async () => {
    if (currentDashboard) {
      const selectedComponents = currentDashboard.settings.map(setting => {
        const item = componentMap.has(setting.component) ? componentMap.get(setting.component) : ""


        return ({
          value: setting.component,
          sortOrder: setting.order,
          size: setting.size,
          label: item.name,
          x: setting.x,
          y: setting.y
        })
      })

      dispatch(setSelectedComponents(selectedComponents))

      const isCampaignTotal = currentDashboard.settings.some(setting => setting.component === "CampaignTotalBar" || "CampaignTotalSum")
      const isCampaignTotalLineGraph = currentDashboard.settings.some(setting => setting.component === "CampaignTotalLineGraph")
      const isBestTeamMember = currentDashboard.settings.some(setting => setting.component === "BestTeamMember")
      const isCampaignDailySales = currentDashboard.settings.some(setting => setting.component === "CampaignDailySales")
      const isCampaignBestSellSum = currentDashboard.settings.some(setting => setting.component === "CampaignBestSellSum")
      const isCampaignBestSellQuantity = currentDashboard.settings.some(setting => setting.component === "CampaignBestSellQuantity")
      const isCampaignDoughnutGraph = currentDashboard.settings.some(setting => setting.component === "CampaignDoughnutGraph")

      if (isCampaignTotal || isCampaignTotalLineGraph || isBestTeamMember || isCampaignDailySales || isCampaignDoughnutGraph) {
        CampaignApi.getTotal(currentUser.companyId, currentDashboard.teamId).then(total => {
          setCampaignTotal(total)
        })

        CampaignApi.getCampaignTotal(currentUser.companyId, currentDashboard.teamId).then(data => {
          if (!data) {
            return
          }
          setCampaignTotalSum(data)

          setTeams(data.teams)
          setTeamMembers(data.users)

          if (data.bestDailyOrder) {
            setBestDailyOrder(data.bestDailyOrder)
          }
        })
      }

      if (isCampaignBestSellSum || isCampaignBestSellQuantity) {
        try {
          const result = await BontiApi.findEcommerceOrderArticles()
          const articleCount = await BontiApi.findEcommerceOrderArticles(true)

          if (articleCount) {
            setTopItems(articleCount)
          }

          if (result) {
            setTopSumArticle(result)
          }
        } catch {

        }
      }
    }
  }, [currentDashboard])

  if (!currentDashboard) {
    return null
  }

  return (
    <Container>
      <FlexWrapper className="pb-2">
        <div className="w-100">
          <small>Kampanj</small>

          <h1 className="d-flex">
            {activeCampaign && (
              <>
                {activeCampaign && activeCampaign.name}
                <span className="d-flex justify-content-center align-items-center text-md ml-2">
                  {activeCampaign.startDate} - {activeCampaign.endDate}
                </span>
              </>
            )}
          </h1>
        </div>
      </FlexWrapper>

      {isEditing && (
        <Formik
          initialValues={{
            name: currentDashboard?.name,
          }}
          validationSchema={DashboardSchema}
          onSubmit={handleSubmit}
        >
          {({errors, touched, isValid}) => (
          <GridContainer>
            <Form>
              <FlexWrapper className="pb-0" style={{paddingTop: 0}}>
                <div className="d-flex flex-column w-100">
                    <div className="row">
                      <FormGroup className="col-xs-12 col-md-4">
                        <Label>Namn</Label>
                        <div className="mb-2">
                          <Field name="name" className={`form-control${errors.name && touched.name ? ' has-error' : ''}`} placeholder="Namn" style={{color: '#495057 !important'}} autoComplete="off"/>
                          {errors.name && touched.name &&
                            <FieldError>{errors.name}</FieldError>
                          }
                        </div>
                      </FormGroup>

                      <FormGroup className="col-xs-12 col-md-4" style={{ zIndex: 999999 }}>
                        <div className="d-flex flex-column mb-2" style={{ zIndex: 999999 }}>
                          <Label className="text-muted">Moduler</Label>
                          <Select
                            isMulti
                            options={allComponents}
                            value={selectedComponents}
                            onChange={handleComponentChange}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          />
                        </div>
                      </FormGroup>
                    </div>
                </div>

                <div className="d-flex pt-4" style={{ gap: '1rem' }}>
                  <div className="d-flex flex-column" style={{ width: 150 }}>
                    <button className="btn btn-primary mt-2 w-100" type="submit">
                      Spara
                    </button>
                  </div>

                  <div className="d-flex flex-column" style={{ width: 150 }}>
                    <button className="btn btn-primary mt-2 mr-2 w-100" onClick={cancelEdit}>
                      Avbryt
                    </button>
                  </div>
                </div>
              </FlexWrapper>

              <div className="px-4">
                <EditDashboardGridComponent dashboardId={dashboardToEdit} selectedComponents={selectedComponents}/>
                {/*<DashboardGrid dashboardId={dashboardToEdit} />*/}
              </div>
            </Form>
          </GridContainer>
          )}
        </Formik>
      )}

      {!isEditing && (
        <>
          <FlexWrapper style={{paddingTop: 0}}>
            <div className="d-flex flex-column w-100">
              <GridContainer>
                <small>Dashboard</small>
                <h2 className="col-xs-12 col-md-3 p-0 text-md">{currentDashboard.name}</h2>
              </GridContainer>
            </div>

            <div className="d-flex" style={{ gap: '1rem' }}>
              <div className="d-flex flex-column" style={{alignItems: "flex-end", width: 150}}>
                <button className="btn btn-primary mt-2 w-100" onClick={() => markForEdit(currentDashboard.id)}>
                  Redigera
                </button>
              </div>

              <div className="d-flex flex-column" style={{alignItems: "flex-end", width: 150}}>
                <button className="btn btn-primary mt-2 w-100" onClick={() => markForDelete(currentDashboard.id)}>
                  Ta bort dashboard
                </button>
              </div>
            </div>
          </FlexWrapper>

          <Wrapper style={{paddingTop: 0}}>
            <DashboardDiv className="row">
              {currentDashboard && currentDashboard.settings
                .map((settings, i) => (
                  <div className={`col col-md-${settings.size} mb-4 `} key={i}>
                    {settings.component === 'CampaignTotalSum' && <CampaignTotal campaignTotal={campaignTotal} component={componentMap.get(settings.component)}/>}
                    {settings.component === 'CampaignTotalBar' && <CampaignTotalBar campaignTotal={campaignTotal} campaignSum={campaignTotalSum} component={componentMap.get(settings.component)}/>}
                    {settings.component === 'BestTeamMember' && <BestTeamMember type="total" activeCampaign={activeCampaign} currentDashboard={currentDashboard} teamMembers={teamMembers} component={componentMap.get(settings.component)}/>}
                    {/*TODO: lägg till daily på BestTeamMember*/}
                    {/*<DashboardCollapse component={settings.component}> </DashboardCollapse>*/}
                    {settings.component === 'CampaignBestSellSum' && <CampaignBestSellSum articles={topSumArticles} name={settings.name} component={componentMap.get(settings.component)}/>}
                    {settings.component === 'CampaignBestSellQuantity' && <CampaignBestSellQuantity articles={topItems} component={componentMap.get(settings.component)}/>}
                    {settings.component === 'CampaignMonthlyTeam' && <CampaignMonthlyTeam teams={teams} component={componentMap.get(settings.component)}/>}
                    {settings.component === 'CampaignDailySales' && <CampaignDailySales teamMembers={teamMembers} bestDailyOrder={bestDailyOrder} component={componentMap.get(settings.component)}/>}
                    {settings.component === 'CampaignDailyTeamMember' && <CampaignDailySales teamMembers={teamMembers} bestDailyOrder={bestDailyOrder} component={componentMap.get(settings.component)}/>}

                    {settings.component === 'CampaignDailyBestDeal' && <CampaignDailySales teamMembers={teamMembers} bestDailyOrder={bestDailyOrder} component={componentMap.get(settings.component)}/>}
                    {/*<Row>
                      <Col xs={12}>
                        <BestTeamMember type="daily" teamMembers={teamMembers}/>
                      </Col>
                    </Row>*/}
                    {settings.component === 'CampaignTotalLineGraph' && <CampaignTotalLineGraph campaignTotal={campaignTotal} campaignSum={campaignTotalSum} component={componentMap.get(settings.component)}/>}
                    {settings.component === 'CampaignDoughnutGraph' && <CampaignDoughnutGraph teams={teams} orders={campaignTotal} component={componentMap.get(settings.component)}/>}
                    {settings.component === 'CampaignRSCountData' && <CampaignRSCountData component={componentMap.get(settings.component)}/>}
                  </div>
                ))}
            </DashboardDiv>
          </Wrapper>
        </>
      )}

      <ConfirmationModal
        name={currentDashboard.name}
        isOpen={isModalOpen}
        toggle={cancelDelete}
        onConfirm={deleteDashboard}
      />
    </Container>
  )
}

export default Magnus

const DashboardDiv = styled.div`
  @media (min-width: 2500px) {
    width: 90%
  }
  @media (min-width: 3300px) {
    width: 66%
  }
`

const GridContainer = styled.div`
  width: 100%;
  position: relative;

  .react-resizable-handle-e {
    position: absolute;
    cursor: ew-resize;
    right: 0px;
    bottom: 0px;
    width: 20px;
    height: 100%;
  }
`
