import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Plus, Search } from 'react-feather';
import { ButtonGroup, Input } from 'reactstrap';
import styled from 'styled-components';
import { setDocumentTitle } from '../../helpers';
import { findAll } from '../../actions/userList.actions';
import { userService } from '../../services/user.service';
import UserImage from './UserImage.component';
import { Pagination } from '../Shared/Pagination';
import { Toolbar } from '../Shared/Style';

const UserListComponent = () => {
  const dispatch = useDispatch();
  const [searchPhrase, setSearchPhrase] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setDocumentTitle('Användare');
    dispatch(findAll());
  }, []);

  const users = useSelector((state) => state.userList.users || []);

  const searchFilter = (user) => {
    let name = user.firstName.toLowerCase() + ' ' + user.lastName.toLowerCase();
    return name.indexOf(searchPhrase.toLowerCase()) !== -1;
  };

  const userSort = (userA, userB) => {
    return (userA.firstName + ' ' + userA.lastName).localeCompare(
      userB.firstName + ' ' + userB.lastName
    );
  };

  const renderTable = () => {
    const items = [];
    let itemsPerPage = 10;

    let filteredUsers = users.filter(searchFilter);

    for (
      let i = (currentPage - 1) * itemsPerPage;
      i < currentPage * itemsPerPage && i < filteredUsers.length;
      i++
    ) {
      items.push(filteredUsers[i]);
    }

    return (
      <React.Fragment>
        <div className="card mx-3 mb-0">
          <div
            className={`list list-row ${
              users.length.length === 0 ? 'hide' : ''
            }`}
          >
            {items.sort(userSort).map((user, index) => (
              <div className="list-item " key={index}>
                <div>
                  <Link
                    to={{ pathname: `/users/${user.id}` }}
                    title={user.firstName + ' ' + user.lastName}
                  >
                    <span className="w-40 avatar gd-primary">
                      <span className="b-white avatar-right" />
                      <UserImage userId={user.id} type="thumb" />
                    </span>
                  </Link>
                </div>

                <div className="flex">
                  <Link
                    to={{ pathname: `/users/${user.id}` }}
                    className="text-primary"
                    title={user.firstName + ' ' + user.lastName}
                  >
                    {user.firstName} {user.lastName}
                  </Link>

                  <div
                    className="item-mail text-muted h-1x d-none d-sm-block"
                    title={user.userRoles.join(' ')}
                  >
                    {user.userRoles.map((role, index) => {
                      return (
                        <span key={index}>
                          {userService.translateRole(role)}&nbsp;
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {filteredUsers.length === 0 && <NoUsers>Inga användare</NoUsers>}
        </div>

        {filteredUsers.length > 0 && (
          <div className="mx-3 mb-0">
            <div className="d-flex">
              <small className="text-muted py-2">
                Totalt <span id="count">{users.length}</span> Användare
              </small>
            </div>

            <div className="d-flex">
              <Pagination
                items={filteredUsers}
                itemsPerPage={10}
                currentPage={currentPage}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <Container>
      <div className="mx-3">
        <h2 className="text-md text-highlight">Användare</h2>
      </div>

      <div className="p-3" id="user-list">
        <Toolbar>
          <form className="flex">
            <div className="input-group">
              <Input
                type="text"
                className="form-control form-control-theme form-control-sm search"
                placeholder="Sök"
                onChange={(e) => setSearchPhrase(e.target.value)}
              />

              <span className="input-group-append">
                <button
                  className="btn btn-white no-border btn-sm"
                  type="button"
                >
                  <span className="d-flex text-muted">
                    <Search size={16} />
                  </span>
                </button>
              </span>
            </div>
          </form>

          <ButtonGroup>
            <Link
              to={{ pathname: '/users/add' }}
              className="btn btn-sm btn-white no-border"
            >
              <Plus size={16} className="mr-1" />
              Lägg till
            </Link>
          </ButtonGroup>
        </Toolbar>
      </div>

      {renderTable()}
    </Container>
  );
};

export default UserListComponent;

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

const NoUsers = styled.div`
  padding: 1.5rem;
  text-align: center;
`;
