import React, {useEffect} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {authenticate, logout} from '../../actions/authentication.actions';
import Loading from '../Loading/Loading.component';

const PrivateRoute = ({component: Component, ...rest}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.authentication.user);
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);

  useEffect(() => {
    dispatch(authenticate());
  }, []);

  return (
    <Route {...rest} render={props => {

      if (!isAuthenticated) {
        return <Loading/>
      }

      if (user) {
        if (rest.role && rest.role.length > 0) {
          if (!user.hasRole(...rest.role)) {
            return <Redirect to={'/'}/>
          }
        }

        return (<Component {...props} {...rest} />);
      }
      else {
        dispatch(logout());
      }
    }}/>
  );
};

export default PrivateRoute;