import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {Camera} from 'react-feather';
import UserImageModal from './UserImageModal';
import { openImageModal } from '../../actions/profile.actions';

const UserImageEdit = (props) => {

  const {
    userId,
    width,
    height
  } = props

  const version = Math.random() * 1000;
  const dispatch = useDispatch();
  const userList = useSelector(state => state.userList.users);
  const [url, setUrl] = useState(null);

  const handleCameraClick = () => {
    dispatch(openImageModal());
  }

  const imageUpdated = useSelector(state => state.user.imageUpdated); // Reloads image

  useEffect(() => {
    if (userList && userList.length > 0) {
      let user = userList.find(user => user.id === userId);

      if (user) {
        setUrl(user.profileImage + '64x64?v=' + version);
      }
    }
  }, [userList, imageUpdated])

  return (
    <React.Fragment>
      <OuterImageWrapper>
        <FlexWrapper>
          <InnerImageWrapper style={{flexGrow: 1}}>
            <ImageWrapper>
              { url &&
                <ProfileImage
                  src={url}
                  width={width}
                  height={height}
                  alt=""
                />
              }
            </ImageWrapper>

            <ImageOverlay/>

            <FileInputWrapper>
              <CameraWrapper role="button" onClick={handleCameraClick}>
                <CameraCenter>
                  <Camera size={20} color="white"/>
                </CameraCenter>
              </CameraWrapper>
            </FileInputWrapper>
          </InnerImageWrapper>
        </FlexWrapper>
      </OuterImageWrapper>

      <UserImageModal />
    </React.Fragment>
  );
};

export default UserImageEdit;

const FlexWrapper = styled.div`
  position: relative;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  align-items: stretch;
`

const OuterImageWrapper = styled(FlexWrapper)`
  max-width: 8rem;
  width: 64px;
  height: 64px;
  border-radius: 9999px;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0 5px 10px 0 rgba(50, 50, 50, 0.15);
`

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  align-items: stretch;
  opacity: 0.75;
  height: 100%;
  width: 100%;
`

const ImageWrapper = styled(FlexWrapper)`
  flex-grow: 1;
  overflow: hidden;
`

const ProfileImage = styled.img`
  border-radius: inherit;
  width: 100%;
`

const FileInputWrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  opacity: 0.75;
  height: 100%;
  width: 100%;
`

const CameraWrapper = styled(FlexWrapper)`
  display: none;
  min-width: 39px;
  min-height: 39px;
  outline-style: none;
  transition-property: background-color, box-shadow;
  transition-duration: 0.2s;
  height: 0;
  padding-left: 0;
  padding-right: 0;
  margin: -10px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 9999px;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
`

const CameraCenter = styled(FlexWrapper)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #FFFFFF;
  line-height: 1;
  font-weight: bold;
  overflow-wrap: break-word;
  min-width: 0;
  max-width: 100%;
`

/*const FileInput = styled.input.attrs({
  type: 'file'
})`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  z-index: -1;
  position: absolute;
  overflow-x: hidden;
  overflow-y: hidden;
`*/

const InnerImageWrapper = styled(FlexWrapper)`
  flex-grow: 1;
  
  &:hover {
    ${ImageOverlay} {
      background-color: rgba(0, 0, 0, 0.3);
    }
    
    ${CameraWrapper} {
      display: flex;
    }
  }
`