import React, { useState } from 'react';
import { Plus, Trash } from 'react-feather';
import styled from 'styled-components';
import { userService } from '../../services/user.service';
import UserImage from './UserImage.component';
import { Toolbar } from '../Shared/Style';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import UserApi from '../../api/User/User.api';

import {
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import CompanyDefaultCampaign from '../Company/CompanyDefaultCampaign';

const UserListComponent = ({ companyId, onToggleForm }) => {
  const queryClient = useQueryClient();

  const [confirmUser, setConfirmUser] = useState(null);

  const { data: users, refetch } = useQuery(
    ['companyUsers', companyId],
    () => UserApi.findAll(companyId),
    {
      initialData: [],
    }
  );

  const { mutate: deleteUser } = useMutation(
    ['deleteUser'],
    (userId) => UserApi.delete(userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('companyUsers');
        setConfirmUser(null);
      },
    }
  );

  const userSort = (userA, userB) => {
    return (userA.firstName + ' ' + userA.lastName).localeCompare(
      userB.firstName + ' ' + userB.lastName
    );
  };

  return (
    <Container>
      <Toolbar className="mb-2 d-flex justify-content-end">
        <ButtonGroup>
          <button
            type="button"
            className="btn btn-sm btn-white no-border"
            onClick={onToggleForm}
          >
            <Plus size={16} className="mr-1" />
            Lägg till
          </button>
        </ButtonGroup>
      </Toolbar>

      <div className="card mb-4">
        <div
          className={`list list-row ${users.length.length === 0 ? 'hide' : ''}`}
        >
          {users.sort(userSort).map((user, index) => (
            <div className="list-item " key={index}>
              <div>
                <span className="w-40 avatar gd-primary">
                  <span className="b-white avatar-right" />
                  <UserImage userId={user.id} type="thumb" />
                </span>
              </div>

              <div className="flex">
                <div>
                  {user.firstName} {user.lastName}
                </div>

                <div
                  className="item-mail text-muted h-1x d-none d-sm-block"
                  title={user.userRoles.join(' ')}
                >
                  {user.userRoles.map((role, index) => {
                    return (
                      <span key={index}>
                        {userService.translateRole(role)}&nbsp;
                      </span>
                    );
                  })}
                </div>
              </div>

              <Button
                type="button"
                color="primary"
                title="Ta bort"
                onClick={() => setConfirmUser(user)}
              >
                <Trash size={20} />
              </Button>
            </div>
          ))}
        </div>

        {users.length === 0 && <NoUsers>Inga användare</NoUsers>}
      </div>

      {/* { users.length > 0 &&
          <div className="mx-3 mb-0">
            <div className="d-flex">
              <small className="text-muted py-2">Totalt <span id="count">{users.length}</span> Användare</small>
            </div>

            <div className="d-flex">
              <Pagination items={users} itemsPerPage={10} currentPage={currentPage} onChange={(page) => setCurrentPage(page)}/>
            </div>
          </div>
        } */}

      <CompanyDefaultCampaign
        companyId={companyId}
        submitCallback={() => refetch()}
      />

      <Modal isOpen={confirmUser !== null} toggle={() => setConfirmUser(null)}>
        <ModalHeader toggle={() => setConfirmUser(null)}>
          Ta bort{' '}
          {confirmUser && `${confirmUser.firstName} ${confirmUser.lastName}`}?
        </ModalHeader>
        <ModalBody>Är du säker på att du vill ta bort användaren?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteUser(confirmUser.id)}>
            Ja
          </Button>{' '}
          <Button color="secondary" onClick={() => setConfirmUser(null)}>
            Avbryt
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default UserListComponent;

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

const NoUsers = styled.div`
  padding: 1.5rem;
  text-align: center;
`;
