import React, {useEffect, useState} from 'react';
import UserImage from "../User/UserImage.component";
import {Link} from "react-router-dom";
import CountUp from "react-countup";
import ScoreUtil from "../Score/ScoreUtil"

const LeaderboardDailyUserTopScore = (props) => {

    let {
        userScores,
        campaign
    } = props;

    let [users, setUsers] = useState([]);

    useEffect(() => {
        let users

        /*
        if (campaign.parent === null) {
            users = userScores
                .map(user => {
                    user.percentageToday = ScoreUtil.getCampaignTotalPercentageTodayFromTeamMember(user)
                    return user
                })
                .sort((a, b) => b.percentageToday - a.percentageToday);
        } else {
         */
            users = userScores
                .map(user => {
                    user.percentageToday = ScoreUtil.getPercentageTodayFromTeamMember(user)
                    return user
                })
            .sort((a, b) => b.percentageToday - a.percentageToday)
        //}
        
        setUsers(users.slice(0, 3));
    }, [campaign, userScores]);

    return (
        <div className="col-md-12">
            <div className="card">
                <div className="card-body">
                    <div className="text-muted text-sm sr-item">{campaign.name}</div>
                    <h5 className="text-highlight sr-item">Dagens bästa deltagare</h5>

                    <table className="table table-theme v-middle table-hover">
                        <tbody>
                        { users.map((userScore, index) => (
                            <tr className="v-middle" key={index}>
                                <td width="80">
                                    <div className="avatar-group">
                                        <div>
                                          <span className="w-32 avatar no-shadow">
                                            {index <= 2 &&
                                            <img src="../assets/img/trophy/T6.png" alt="."/>
                                            }
                                              {index > 2 &&
                                              <small className="text-muted font-weight-bold">{(index + 1)}</small>
                                              }
                                          </span>
                                        </div>
                                        <div className="avatar ajax w-24" data-toggle="tooltip" title=""
                                             data-pjax-state=""
                                             data-original-title="Cursus">
                                            <UserImage userId={userScore.userUniqueId} type="thumb"/>
                                        </div>
                                    </div>
                                </td>
                                <td className="flex">
                                    <Link to={{pathname: `/profile/${userScore.userUniqueId}`}} className="item-title text-color h-1x">
                                        {userScore.userName}
                                    </Link>
                                    <div className="item-company text-muted h-1x">
                                        {userScore.teamName}
                                    </div>
                                </td>
                                <td align="right" className="pr-4">
                                    <span className="item-amount d-none d-sm-block text-sm text-nowrap">
                                        <CountUp end={userScore.percentageToday} duration={"2"} separator={" "}/>%
                                    </span>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default LeaderboardDailyUserTopScore;