import {
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";

import {openDashboardModal} from "../../actions/dashboard.actions";
import {useDispatch, useSelector} from "react-redux";

export default function DashboardModal() {
    const dispatch = useDispatch();
    const isModalOpen = useSelector(state => state.dashboard.isModalOpen);

    const toggleModal = () => {
        dispatch(openDashboardModal());
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
            <Form onSubmit={handleSubmit}>
                <ModalHeader toggle={toggleModal}>Registrera</ModalHeader>

                <ModalBody>
                    Hello world
                </ModalBody>

                <ModalFooter>
                    {/*<Button color="secondary" onClick={toggleModal}>Avbryt</Button>*/}
                    {/*<Button type="submit" color="primary" disabled={isFormDisabled()}>Spara</Button>*/}
                </ModalFooter>
            </Form>
        </Modal>
    )
}