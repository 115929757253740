import {CLOSE_SHOUTOUT_MODAL, OPEN_SHOUTOUT_MODAL} from "../types/shoutout.types";

export function openShoutoutModal() {
  return (dispatch) => {
    dispatch({type: OPEN_SHOUTOUT_MODAL});
  };
}

export function closeShoutoutModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_SHOUTOUT_MODAL});
  };
}