import styled from 'styled-components';

export const PageTitle = styled.h2`
  font-size: 1.25rem;
  color: #192039;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
`

export const MutedText = styled.div`
  color: #99a0ac;
`

export const BoldMutedText = styled(MutedText)`
  font-weight: bold;
`

export const Small = styled.small`
  display: block;
  color: #99a0ac;
  font-size: 0.825rem;
  font-weight: 400;
`

export const Strong = styled.strong`
  display: block;
  font-weight: bold;
`