import styled from 'styled-components';
import {CheckCircle, ChevronDown, ChevronUp, Edit, Trash, XCircle} from 'react-feather';
import {Scrollbar} from '../../Shared/Scrollbar/Scrollbar.component';
import React, {useState} from 'react';

import classNames from 'classnames';

const AttributeList = ({attributes, onDisplayOrderChange, onEdit, onDelete, className}) => {
  const [attributeToDelete, setAttributeToDelete] = useState(null);

  return (
    <Scrollbar style={{height: 500, scrollWidth: 5}}>
      <Wrapper className={className}>
        {attributes
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .map((attribute) => (
            <AttributeCard className={classNames('mb-2', { 'delete-item':  attribute.id === attributeToDelete})} key={attribute.displayOrder}>
              <AttributeOrder>
                {attribute.id !== attributeToDelete &&
                  <>
                    {attribute.displayOrder > 0 &&
                      <DisplayOrderButton>
                        <ChevronUp
                          onClick={() => onDisplayOrderChange(attribute, 'up')}
                          title='Flytta up'
                        />
                      </DisplayOrderButton>
                    }

                    {attribute.displayOrder < attributes.length - 1 &&
                      <DisplayOrderButton>
                        <ChevronDown
                          onClick={() => onDisplayOrderChange(attribute, 'down')}
                          title='Flytta ned'
                        />
                      </DisplayOrderButton>
                    }
                  </>
                }
              </AttributeOrder>

              <AttributeContent>
                <div>
                  <div>Namn: {attribute.description}</div>
                  <div>Typ: {attribute.type}</div>
                </div>

                {attribute.id !== attributeToDelete &&
                  <>
                    <DisplayOrderButton className="text-edit"
                                    onClick={() => onEdit(attribute)}
                                    title={'Redigera'}>
                      <Edit size={16}/>
                    </DisplayOrderButton>

                    <DisplayOrderButton className="text-danger"
                                    onClick={() => setAttributeToDelete(attribute.id)}
                                    title="Ta bort">
                      <Trash size={16}/>
                    </DisplayOrderButton>
                  </>
                }

              </AttributeContent>

              {attribute.id === attributeToDelete &&
                <DeleteConfirm>
                  <DeleteConfirmText>Ta bort?</DeleteConfirmText>

                  <DeleteConfirmYes title="Ta bort" onClick={() => onDelete(attribute)}>
                    <CheckCircle size={20} color="white"/>
                  </DeleteConfirmYes>

                  <DeleteConfirmNo title="Avbryt" onClick={() => setAttributeToDelete(null)}>
                    <XCircle size={20} color="white" />
                  </DeleteConfirmNo>
                </DeleteConfirm>
              }
            </AttributeCard>
          ))}
      </Wrapper>
    </Scrollbar>
  )
}

export default AttributeList

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const AttributeCard = styled.div`
  display: flex;
  background: #f8f8f8;
  
  &.delete-item {
    background-color: #FB2640;
    color: #FFFFFF;

    .text-muted {
      color: #FFFFFF !important;
    }
  }
`

const AttributeOrder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  width: 3.5rem;
`

const AttributeContent = styled.div`
  padding: 1rem;
  width: 100%;

  @media (min-width: 992px) {
    padding-right: 0;
  }
`

export const DisplayOrderButton = styled.div`
  display: inline-block;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4285714286;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border: 1px solid rgba(135, 150, 165, 0.15);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 5px;
  }
`

const DeleteConfirm = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-self: flex-end;
  padding: 0 1rem;
  min-width: 140px;
  //width: 100%;

  @media (max-width: 992px) {
    
  }
`

const DeleteConfirmText = styled.div`
  margin-right: 0.5rem;
`

const DeleteConfirmYes = styled.div`
  cursor: pointer;
  margin-right: 0.5rem;
`

const DeleteConfirmNo = styled.div`
  cursor: pointer;
`