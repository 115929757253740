import React, {useState} from 'react';
import {Button, Modal} from 'reactstrap';

const ConfirmationModal = ({name, isOpen, toggle, onConfirm}) => {



  return (
    <div id="confirmation-modal" className="d-flex flex-column">
      <Modal className="" isOpen={isOpen} toggle={toggle} size="lg">
        <div style={{height: "150px", marginTop: "15px"}}>
          <h1 className=" font-weight-bold text-center">Ta bort dashboard</h1>
          <p className="text-center">Är du säker på att du vill ta bort "{name}"?</p>
          <div className="flex-column" style={{textAlign: "center"}}>
            <Button className="btn-primary w-25 mr-1" onClick={onConfirm}>Ja</Button>
            <Button className="btn-primary w-25" onClick={toggle}>Nej</Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ConfirmationModal
