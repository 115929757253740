import React, {useEffect, useState} from 'react';
import UserImage from "../User/UserImage.component";
import CountUp from "react-countup";
import {useSelector} from "react-redux";
import ScoreUtil from '../Score/ScoreUtil';

const LeaderboardTopTeams = (props) => {
  const itemsPerPage = props.itemsPerPage;
  const campaign = props.campaign
  const campaignScore = useSelector(state => state.score.score);
  const [topTeams, setTopTeams] = useState([]);
  const _goal = props.goal
  
  const getGoal = (team) => {
    if (campaign.parent === null) {
      return _goal
    } else {
      const sum = (r, a) => {
        return Array.isArray(a) ? a.reduce(sum, r) : r + a
      }
  
      const goals = team.campaignTeamMembers
        .map(teamMember => teamMember.eventGoals.map(eventGoal => {
          return eventGoal.goal
        }))
  
      return goals.reduce(sum, 0)
    }
  }
  
  const displayPercentage = (team) => {
    if (campaign.parent === null) {
      return team.percentage
    } else {
      return ScoreUtil.getPercentageFromTeamMembers(team.campaignTeamMembers)
    }
  }

  useEffect(() => {
    if (campaignScore) {
      /*
      let topTeams = [...campaignScore.campaignTeams]
        .map(campaignTeam => {
          campaignTeam.campaignTeamMembers.sort((a, b) => b.value - a.value);
          return campaignTeam;
        })
        .sort((a, b) => b.value - a.value)
        .slice(0, itemsPerPage);
      */
      
      if (campaign.parent === null) {
        const eventIds = campaign.campaignEvents
          .map(campaignEvent => campaignEvent.eventId)

        const topTeams = [...campaignScore.campaignTeams]
          .map(campaignTeam => {
            campaignTeam.percentage = ScoreUtil.getCampaignTotalPercentageFromTeamMembers(campaignTeam.campaignTeamMembers, _goal, eventIds)
            return campaignTeam
          })
          .sort((a, b) => {
            return b.percentage - a.percentage
          })
          .slice(0, itemsPerPage)
        
        setTopTeams(topTeams)
      } else {
        const topTeams = [...campaignScore.campaignTeams]
          .map(campaignTeam => {
            campaignTeam.percentage = ScoreUtil.getPercentageFromTeamMembers(campaignTeam.campaignTeamMembers)
            return campaignTeam
          })
          .sort((a, b) => {
            return b.percentage - a.percentage
          })
          .slice(0, itemsPerPage)
  
        setTopTeams(topTeams);
      }
    }
  }, [campaignScore, campaign, _goal]);

  return (topTeams &&
    topTeams.map((team, index) =>
      <tr className=" v-middle" key={index}>
        <td width="80">
          <div className="avatar-group ">
            <div>
                <span className="w-32 avatar no-shadow">
                  {index === 0 && <img src="/assets/img/trophy/T11.png" alt="."/>}
                  {index === 1 && <img src="/assets/img/trophy/T9.png" alt="."/>}
                  {index === 2 && <img src="/assets/img/trophy/T9.png" alt="."/>}
                  {index > 2 && <small className="text-muted font-weight-bold">{(index + 1)}</small>}

                </span>
            </div>
            {team.campaignTeamMembers.slice(0, 3).map((teamMember, i) =>
              <div className="avatar ajax w-24" title={teamMember.userName} key={i}>
                {index === 0 && <UserImage userId={teamMember.userUniqueId} type="thumb"/>}
                {index === 1 && <UserImage userId={teamMember.userUniqueId} type="thumb"/>}
                {index === 2 && <UserImage userId={teamMember.userUniqueId} type="thumb"/>}
              </div>
            )}
          </div>

        </td>
        <td className="flex">
          <div className="item-title text-color h-1x">{team.teamName}</div>
          <div className="item-company text-muted h-1x">
            {team.campaignTeamMembers.length} deltagare
          </div>
        </td>
        <td className="right pr-4">
            <span title={Math.ceil(team.value) + " / " + getGoal(team) + "kr"} className="item-amount d-none d-sm-block text-sm text-nowrap">
              <CountUp end={displayPercentage(team)} separator={" "}/>%
            </span>
        </td>
      </tr>
    )
  );
}

export default LeaderboardTopTeams;