import React, {useEffect, useState} from 'react';
import {userService} from '../../services/user.service';

const ProfileInfo = ({userId}) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    userService.get(userId)
      .then(user => setUser(user));
  }, []);

  return (
    <div className="Profile">
      { !user && 'Hittade inte aktiv användare'}

      { user &&
        <div className="card">
          <div className="px-4 py-4">
            <div className="row">
              <div className="col-12 col-sm-6 mb-2">
                <small className="text-muted">Förnamn</small>
                <div className="my-2">{user.firstName}</div>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <small className="text-muted">Efternamn</small>
                <div className="my-2">{user.lastName}</div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 mb-2">
                <small className="text-muted">Epost</small>
                <div className="my-2">{user.email}</div>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <small className="text-muted">Telefon</small>
                <div className="my-2">{user.phone}</div>
              </div>
            </div>
          </div>
        </div>
    }
    </div>
  );
};

export default ProfileInfo;