import React, {useEffect, useState} from 'react';
import {ChevronRight, ChevronDown, Lock, Trash2} from 'react-feather';
import {Collapse, FormGroup} from 'reactstrap';
import {Formik, Form, Field} from 'formik';

import {Title, Item, LogoutButton, FieldError, UpdateImageButton, DeleteImageButton} from './Settings.style';
import {Flex} from '../../pages/pages.style';
import {useDispatch, useSelector} from 'react-redux';
import {userService} from '../../services/user.service';
import {UserInfoSchema, UserPasswordSchema} from '../../api/User';
import UserImageModal from '../User/UserImageModal';
import {openImageModal} from '../../actions/profile.actions';
import UserImage from '../User/UserImage.component';
import authService from '../../services/auth.service';

const AccountSettings = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.authentication.user);

  const [user, setUser] = useState();
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [isPasswordOpen, setPasswordOpen] = useState(false);
  const [isPaymentOpen, setPaymentOpen] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  useEffect(() => {
    loadUser();
  }, []);

  const toggleProfile = () => {
    setProfileOpen(!isProfileOpen);
  }

  const togglePassword = () => {
    setPasswordOpen(!isPasswordOpen);
  }

  const togglePayment = () => {
    setPaymentOpen(!isPaymentOpen);
  }

  const handleLogoutClick = () => {
    authService.logout();
  }

  const handleProfileSubmit = (values) => {
    setUpdating(true);

    let userForUpdate = {
      active: user.active,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      userRoles: user.userRoles
    };

    userService.update(user.id, userForUpdate)
      .then(() => {
        loadUser();
        setUpdating(false);
        setProfileOpen(false);
      })
      .catch(() => setUpdating(false));
  }

  /** Password **/

  const [currentPassword, setCurrentPassword] = useState('');
  const [passwordMatches, setPasswordMatches] = useState(null);

  useEffect(() => {
    if (currentPassword) {
      const delayDebounceFn = setTimeout(() => {
        authService.matchPassword(currentPassword)
          .then(status => setPasswordMatches(status))
      }, 500)

      return () => clearTimeout(delayDebounceFn);
    }
  }, [currentPassword]);

  const handlePasswordSubmit = (values) => {
    setUpdating(true);

    let userForUpdate = {
      ...user,
      password: values.password,
      passwordRepeat: values.passwordRepeat
    };

    userService.update(user.id, userForUpdate)
      .then(() => {
        loadUser();
        setUpdating(false);
        setPasswordOpen(false);
      })
      .catch(() => setUpdating(false));
  }

  const loadUser = () => {
    userService.get(currentUser.id)
      .then(user => setUser(user));
  }

  if (!user) return <React.Fragment/>

  return (
    <React.Fragment>
      <Title>Konto</Title>

      <div className="card">
        <Item>
          <div>
            <span className="w-48 avatar circle pointer" onClick={toggleProfile}>
              <UserImage userId={currentUser.id}/>
            </span>
          </div>

          <div className="mx-3 d-none d-md-block pointer" onClick={toggleProfile}>
            <strong>{currentUser.displayName}</strong>
            <div className="text-sm text-muted">{currentUser.email}</div>
          </div>

          <Flex/>

          <div className="mx-3 pointer" onClick={toggleProfile}>
            { isProfileOpen && <ChevronDown size={16}/>}
            { !isProfileOpen && <ChevronRight size={16}/>}
          </div>

          <div>
            <LogoutButton className="text-prmary text-sm" onClick={handleLogoutClick}>
              Logga ut
            </LogoutButton>
          </div>
        </Item>

        <Collapse className="p-4" isOpen={isProfileOpen}>
          <Formik initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phone: user.phone
          }}
          validationSchema={UserInfoSchema}
          onSubmit={handleProfileSubmit}>
            { ({errors, touched, isValid}) => (
              <Form>
                <FormGroup>
                  <UpdateImageButton type="button" onClick={() => dispatch(openImageModal())}>
                    Uppdatera profilbild
                  </UpdateImageButton>

                  {/*<DeleteImageButton>
                    <Trash2 size={20} color="#FB2640"/>
                  </DeleteImageButton>*/}
                </FormGroup>

                <div className="form-row">
                  <div className="col-md-6 mb-2">
                    <label>Förnamn</label>
                    <Field name="firstName" className={`form-control${errors.firstName && touched.firstName ? ' has-error' : ''}`} placeholder="Förnamn" />
                    {errors.firstName && touched.firstName ? (
                      <FieldError>{errors.firstName}</FieldError>
                    ) : null}
                  </div>

                  <div className="col-md-6 mb-2">
                    <label>Efternamn</label>
                    <Field name="lastName" className={`form-control${errors.lastName && touched.lastName ? ' has-error' : ''}`} placeholder="Efternamn" />
                    {errors.lastName && touched.lastName ? (
                      <FieldError>{errors.lastName}</FieldError>
                    ) : null}
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-md-6 mb-2">
                    <label>E-post</label>
                    <Field name="email" className={`form-control${errors.email && touched.email ? ' has-error' : ''}`} placeholder="E-post" />
                    {errors.email && touched.email ? (
                      <FieldError>{errors.email}</FieldError>
                    ) : null}
                  </div>

                  <div className="col-md-6 mb-2">
                    <label>Telefon</label>
                    <Field name="phone" className="form-control" placeholder="Telefon" />
                    {errors.phone && touched.phone ? (
                      <FieldError>{errors.phone}</FieldError>
                    ) : null}
                  </div>
                </div>

                <button type="submit" className="btn btn-primary mt-2" disabled={!isValid}>
                  { isUpdating &&
                    <React.Fragment>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> &nbsp;
                    </React.Fragment>
                  }

                  {isUpdating ? 'Sparar' : 'Spara'}
                </button>
              </Form>
            )}
          </Formik>
        </Collapse>

        <Item onClick={togglePassword}>
          <Lock size={16}/>

          <div className="px-3">
            <div>Lösenord</div>
          </div>

          <Flex/>

          <div>
            { isPasswordOpen && <ChevronDown size={16}/>}
            { !isPasswordOpen && <ChevronRight size={16}/>}
          </div>
        </Item>

        <Collapse className="p-4" isOpen={isPasswordOpen}>
          <Formik initialValues={{
            currentPassword: '',
            password: '',
            passwordRepeat: '',
          }}
          validationSchema={UserPasswordSchema}
          onSubmit={handlePasswordSubmit}>
            { ({errors, touched, isValid}) => {
              const invalidPassword = touched.currentPassword && passwordMatches === false;

              return (
              <Form>
                <FormGroup>
                  <label>Nuvarande lösenord</label>

                  <Field name="currentPassword"
                         type="password"
                         className={`form-control${invalidPassword ? ' has-error' : ''}`}
                         placeholder="Nuvarande lösenord"
                         onKeyUp={(e) => setCurrentPassword(e.target.value)}
                  />

                  { invalidPassword &&
                    <FieldError>Felaktigt lösenord</FieldError>
                  }
                </FormGroup>

                { passwordMatches === true &&
                  <React.Fragment>
                    <FormGroup>
                      <label>Nytt lösenord</label>

                      <Field name="password"
                             type="password"
                             className={`form-control${errors.password && touched.password ? ' has-error' : ''}`}
                             placeholder="Nytt lösenord"
                      />

                      { errors.password && touched.password &&
                        <FieldError>{errors.password}</FieldError>
                      }
                    </FormGroup>

                    <FormGroup>
                      <label>Bekräfta lösenord</label>

                      <Field name="passwordRepeat"
                             type="password"
                             className={`form-control${errors.passwordRepeat && touched.passwordRepeat ? ' has-error' : ''}`}
                             placeholder="Bekräfta lösenord"
                      />

                      { errors.passwordRepeat && touched.passwordRepeat &&
                        <FieldError>{errors.passwordRepeat}</FieldError>
                      }
                    </FormGroup>

                    <button type="submit" className="btn btn-primary mt-2" disabled={!isValid}>
                      { isUpdating &&
                        <React.Fragment>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>&nbsp;
                          Sparar
                        </React.Fragment>
                      }
                      {!isUpdating && 'Spara'}
                    </button>
                  </React.Fragment>
                }
              </Form>
            )}}
          </Formik>
        </Collapse>

        {/*<Item onClick={togglePayment}>
          <CreditCard size={16}/>

          <div className="px-3">
            <div>Payment methods</div>
          </div>

          <Flex/>

          <div>
            { isPaymentOpen && <ChevronDown size={16}/>}
            { !isPaymentOpen && <ChevronRight size={16}/>}
          </div>
        </Item>

        <Collapse className="p-4" isOpen={isPaymentOpen}>
          <form role="form">
            <div className="form-group">
              <label>Paypal account</label>
              <input type="input" className="form-control"/>
            </div>
            <button type="submit" className="btn btn-primary mt-2">Update</button>
          </form>
        </Collapse>*/}
      </div>

      <UserImageModal/>
      {/*img={tmpImage} url={imageUrl}*/}
    </React.Fragment>
  );
}

export default AccountSettings;