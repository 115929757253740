import {
  OPEN_IMAGE_MODAL,
  CLOSE_IMAGE_MODAL,
} from '../types/profile.types';

export function openImageModal() {
  return dispatch => dispatch({ type: OPEN_IMAGE_MODAL });
}

export function closeImageModal() {
  return dispatch => dispatch({ type: CLOSE_IMAGE_MODAL });
}