import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Scrollbar} from '../../Shared/Scrollbar/Scrollbar.component';
import UserImage from '../../User/UserImage.component';
import CountUp from 'react-countup';
import {ProgressValue, ProgressWrapper, TeamLabel, TeamName, UserListItem} from './TeamScore.style';
import ScoreUtil from '../ScoreUtil';
import {PlusCircle} from 'react-feather';
import {Link} from 'react-router-dom';
import {Progress} from 'reactstrap';

const TeamScore = () => {
  const currentUser = useSelector(state => state.authentication.user);
  const selectedCampaign = useSelector(state => state.campaign.selectedCampaign);
  const campaignScore = useSelector(state => state.score.score);

  const [campaignTeam, setCampaignTeam] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamPercentage, setTeamPercentage] = useState(0);

  useEffect(() => {
    if (campaignScore) {
      let teamMembers = [];

      let userTeam = campaignScore?.campaignTeams.find(team => team.campaignTeamMembers
          .find(teamMember => teamMember.userUniqueId === currentUser.id));

      if (userTeam) {
        setCampaignTeam(userTeam);

        userTeam.campaignTeamMembers.forEach(teamMember => {
          teamMember.percentage = ScoreUtil.getPercentageFromTeamMember(teamMember);
          teamMembers.push(teamMember);
        });
        setTeamMembers(teamMembers);
      }
    }
  }, [campaignScore]);

  useEffect(() => {
    if (teamMembers) {
      setTeamPercentage(ScoreUtil.getPercentageFromTeamMembers(teamMembers));
    }
  }, [teamMembers]);

  const teamSort = (a, b) => {
    if (a.percentage === b.percentage) {
      return b.latestOrder - a.latestOrder
    }
    
    return b.percentage - a.percentage
  }

  const renderTeamError = () => {
    if (campaignTeam === null) {
      return (
        <div className="text-sm sr-item ml-3 mt-2">Inget lag tilldelad på kampanjen</div>
      );
    }

    if (campaignTeam.value <= 0) {
      return (
        <Fragment>
          <h5 className="text-highlight sr-item ml-3">{campaignTeam.teamName}</h5>
          <div className="text-sm sr-item ml-3 mt-2">Laget har inga poäng</div>
        </Fragment>
      );
    }
  };

  const accomplishedGoals = (teamMember) => {
    let goals = 0;

    teamMember.eventGoals.forEach(eventGoal => {
      let eventOrders = teamMember.orders.filter(order => order.eventId === eventGoal.eventId);
      let eventOrderValue = eventOrders.reduce((total, order) => total + order.value, 0);
      if (eventOrderValue >= eventGoal.goal) goals++;
    });

    return goals;
  }

  const renderTeamMember = (teamMember, index) => {
    const eventGoalAmount = teamMember.eventGoals.length;

    return (
      <tr className="v-middle" key={index}>
        <td>
          <UserListItem>
            <a href={`/profile/${teamMember.userUniqueId}`}>
              <UserImage userId={teamMember.userUniqueId} type="thumb"/>
              <span>
                {teamMember.userName}
              </span>
            </a>
          </UserListItem>
        </td>
        <td>
          <span className="item-amount d-none d-sm-block text-sm text-nowrap text-center" title={teamMember.percentage + '% av mål uppnått'}>
            <CountUp end={teamMember.percentage} duration={"2"}/>%
            <div className="text-muted font-weight-100">{accomplishedGoals(teamMember)}/{eventGoalAmount} Mål</div>
          </span>
        </td>
      </tr>
    );
  };

  return (
    <div className="col-md-12 col-lg-4 d-flex">
      <div className="card flex">
        <div className="card-body pl-0 pr-0">
          <TeamLabel>Ditt lag</TeamLabel>

          {renderTeamError()}

          {
            campaignTeam &&
            <React.Fragment>
              <TeamName className="text-highlight">{campaignTeam.teamName}</TeamName>

              <ProgressWrapper style={{width: "100%"}}>
                <Progress
                  value={teamPercentage}
                  color={ScoreUtil.isTeamGoalObtainable(selectedCampaign, teamMembers) ? 'success' : 'danger'}
                  title={teamPercentage + '%'}>
                  {teamPercentage >= 15 &&
                  <ProgressValue title={teamPercentage}>
                    <CountUp end={teamPercentage} duration={"2"}/>%
                  </ProgressValue>
                  }
                </Progress>
              </ProgressWrapper>

              <Scrollbar style={{height: 250}}>
                <table className="table table-theme v-middle table-hover mt-2 mb-1  ">
                  <tbody>
                    {
                      teamMembers
                          .sort(teamSort)
                          .map((teamMember, index) => renderTeamMember(teamMember, index))
                    }
                  </tbody>
                </table>
              </Scrollbar>

              <div className="text-center">
                  <Link to={{pathname: `/leaderboard/${selectedCampaign.id}`}}>
                    <button className="btn btn-sm btn-outline-primary btn-rounded">
                      <PlusCircle size={16} className="mr-1" /> Visa
                      alla
                    </button>
                  </Link>
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    </div>
  );
};

export default TeamScore;