import client from '../helpers/client';

export const orderHistoryService = {
  findByUserAndPeriod
};

export function findByUserAndPeriod(userId, from, to) {
  return client.getClient()
    .get('/orders?filter=user&from=' + from + '&to=' + to + '&id=' + userId)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      let errorMessage = (err.response.data) || err.response.statusText;
      return Promise.reject(errorMessage);
    });
}