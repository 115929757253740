import axios from 'axios';
import authService from '../services/auth.service';

const _axios = axios.create();

const configure = () => {
  _axios.interceptors.request.use(config => {
    const cb = () => {
      config.headers.Authorization = `Bearer ${authService.getToken()}`;
      return Promise.resolve(config);
    };

    return authService.updateToken(cb);
  })
}

const getClient = () => {

  const defaultOptions = {};

  return {
    get: (uri, options = {}) => _axios.get(process.env.REACT_APP_API_URL + uri, {
      ...defaultOptions,
      ...options
    }),
    post: (uri, data, options = {}) => _axios.post(process.env.REACT_APP_API_URL + uri, data, {
      ...defaultOptions,
      ...options
    }),
    put: (uri, data, options = {}) => _axios.put(process.env.REACT_APP_API_URL + uri, data, {
      ...defaultOptions,
      ...options
    }),
    patch: (uri, data, options = {}) => _axios.patch(process.env.REACT_APP_API_URL + uri, data, {
      ...defaultOptions,
      ...options
    }),
    delete: (uri, options = {}) => _axios.delete(process.env.REACT_APP_API_URL + uri, {
      ...defaultOptions,
      ...options
    }),
    query: buildQuery
  };
};

const buildQuery = (data) => {
  let query = [];

  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
    }
  }

  return query.join('&');
}

export default {
  configure,
  getClient
};