import React from "react";
import CookieConsent from "react-cookie-consent";

const CookieConsentPopup = () => {
    return (
    <div className="text-center">
        <CookieConsent
            buttonClasses="btn btn-success mt-3"
            declineButtonClasses="btn btn-primary mr-3 mt-3"
            disableStyles={true}
            disableButtonStyles={true}
            enableDeclineButton={true}
            style={{zIndex: "1050", backgroundColor: "rgba(0,0,0,0.9)"}}
            containerClasses="p-3 text-white fixed-bottom w-100"
            buttonText="Acceptera cookies"
            declineButtonText="Acceptera inte">
            <span>Denna webbsida använder cookies för att förbättra användarupplevelsen</span>
        </CookieConsent>
    </div>
)};

export default CookieConsentPopup;