import client from '../../helpers/client';

const baseUrl = '/campaigns/selected';

export function get() {
  return client.getClient()
    .get(baseUrl)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function create(data = {}) {
  return client.getClient()
    .post(baseUrl, data)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function update(id, data = {}) {
  const url = `${baseUrl}/${id}`;

  return client.getClient()
    .put(url, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export const SelectedCampaignApi = {
  get,
  create,
  update,
}