import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ArrowRight} from 'react-feather';
import Select from 'react-select';
import {Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import moment from 'moment';
import {setCampaignList} from '../actions/campaign.actions';
import {campaignService} from '../services/campaign.service';
import UserImage from '../components/User/UserImage.component';
import {setScore} from '../actions/score.actions';
import LeaderboardTopTeams from '../components/Leaderboard/LeaderboardTopTeams.component';
import ActivityList from '../components/Dashboard/ActivityList.component';
import ShoutoutMessage from '../components/Shoutout/ShoutoutMessage.component';
import {CampaignButton, Flex, FlexWrapper, Wrapper} from './pages.style';
import {setDocumentTitle} from '../helpers';
import ScoreUtil from '../components/Score/ScoreUtil';
import LeaderboardTopUser from '../components/Leaderboard/LeaderboardTopUser.component';
import {CampaignApi} from "../api/Campaign/Campaign.api";
import {TvContainer} from '../components/Leaderboard/Leaderboard.style';

const TvLeaderboard = (props) => {
  let campaignId = props.match.params.id;
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.authentication.user);
  const userList = useSelector(state => state.userList.users);
  const campaigns = useSelector(state => state.campaign.campaignList);
  const campaignScore = useSelector(state => state.score.score);

  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selectedCampaignOption, setSelectedCampaignOption] = useState(null);
  const [userScores, setUserScores] = useState([]);
  const [dailyTopSeller, setDailyTopSeller] = useState(null);
  const [_goal, setGoal] = useState(null);

  const [isModalOpen, setModalOpen] = useState(false);

  const loadScores = () => {
    if (selectedCampaign) {
      campaignService.findScores(selectedCampaign.id)
        .then(campaignScore => {
          dispatch(setScore(campaignScore));
        });
    }
  };

  const toggleModal = (e) => {
    e.preventDefault();

    if (isModalOpen) {
      setModalOpen(false);
    }
    else {
      setModalOpen(true);
    }
  };

  const onChangeCampaign = (campaign) => {
    setSelectedCampaignOption(campaign);
  };

  const handleSubmit = (e) => {
    e.preventDefault();


    let campaign = campaigns && campaigns.find(c => c.id === selectedCampaignOption.value);
    
    if (campaign) {
      setSelectedCampaign(campaign);
      campaignId = selectedCampaignOption.value;
    }

    setModalOpen(false);
  };

  useEffect(() => {
    campaignService.findByTeamMember(currentUser)
      .then(campaigns => {
        campaigns = campaigns
          .filter(campaign => moment(campaign.startDate) <= moment())
        
        dispatch(setCampaignList(campaigns));

        let options = [];
        campaigns.forEach(campaign => {
          
          let option = {
            value: campaign.id,
            label: campaign.name
          };

          options.push(option);
        });

        setCampaignOptions(options);

        if(campaignId != null) {
          let campaign = campaigns.find(campaign => campaign.id === campaignId);
          setSelectedCampaign(campaign);
          setSelectedCampaignOption(options.find(option => option.value === campaignId));
          setDocumentTitle(campaign.name);
        }
        /*else if (selectedCampaign != null && selectedCampaign.id != null) {
          setSelectedCampaign(campaigns.find(campaign => campaign.id === selectedCampaign.id));
          setSelectedCampaignOption(options.find(option => option.value === selectedCampaign.id));
        }*/
        else {
          //TODO: match campaign start/end date to current month
          let latestCampaign = campaigns.find(campaign => campaign.active === 'Active');
          
          if (latestCampaign) {
            setSelectedCampaign(latestCampaign);
            setSelectedCampaignOption(options.find(option => option.value === latestCampaign.id));
            setDocumentTitle(latestCampaign.name);
          }
        }
      });

    loadScores();

  }, []);

  useEffect(() => {
    if (selectedCampaign != null) {
      loadScores();
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (campaignScore) {
      if (selectedCampaign && selectedCampaign.parent === null) {
        CampaignApi.getParentCampaignTotal(currentUser.companyId)
          .then(result => {
            const userScores = []
            const goal = result.goal.total
            const eventIds = selectedCampaign.campaignEvents
              .map(campaignEvent => campaignEvent.eventId)
          
            if (!goal) {
              return
            }
          
            setGoal(goal)
          
            campaignScore.campaignTeams.forEach(campaignTeam => {
              campaignTeam.campaignTeamMembers.forEach(teamMember => {
                const percentage = ScoreUtil.getCampaignTotalPercentageFromTeamMember(teamMember, goal, eventIds)
              
                teamMember.teamName = campaignTeam.teamName
                teamMember.percentage = percentage
                teamMember.parentCampaignGoal = goal
              
                userScores.push(teamMember)
              })
            })
          
            setUserScores(userScores.sort())
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        let userScores = [];
      
        campaignScore.campaignTeams.forEach(campaignTeam => {
          campaignTeam.campaignTeamMembers.forEach(teamMemberScore => {
            teamMemberScore.teamName = campaignTeam.teamName;
            teamMemberScore.percentage = ScoreUtil.getPercentageFromTeamMember(teamMemberScore)
            userScores.push(teamMemberScore);
          });
        });
      
        setUserScores(userScores.sort());
      }
    }
  }, [campaignScore]);

  return (
    <TvContainer>
      <FlexWrapper>
        <div>
          <h2 className="text-md text-highlight">
            Ledartavlor
            { selectedCampaign &&
              <React.Fragment>
                &nbsp;|&nbsp;
                {selectedCampaign.name}
                {selectedCampaign.active === 'Inactive' && ' - Slutresultat'}
              </React.Fragment>
            }
          </h2>

          {selectedCampaign &&
            <div><small>{selectedCampaign.startDate} - {selectedCampaign.endDate}</small><br /><br /></div>
          }
        </div>

        <Flex/>

        <div>
          <CampaignButton onClick={toggleModal}>
            <span className="d-none d-sm-inline mx-1">
              {selectedCampaign ? 'Byt kampanj' : 'Välj kampanj'}
            </span>
            <ArrowRight size={16}/>
          </CampaignButton>
        </div>

      </FlexWrapper>

      <Wrapper className="pt-0">
        <div className="d-md-flex">
          <Flex>
            <div className="row">
              <ShoutoutMessage />
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="text-muted text-sm sr-item">{selectedCampaign && selectedCampaign.name}</div>
                    <h5 className="text-highlight sr-item">Bästa deltagare</h5>
                  </div>
                  <LeaderboardTopUser users={userScores.sort((a, b) => b.percentage -  a.percentage)} />
                </div>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="text-muted text-sm sr-item">{selectedCampaign && selectedCampaign.name}</div>
                        <h5 className="text-highlight sr-item">Bästa lag</h5>
                        <table className="table table-theme v-middle table-hover">
                          <tbody>
                            <LeaderboardTopTeams itemsPerPage={5} goal={_goal} campaign={!!selectedCampaign && selectedCampaign}/>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            { dailyTopSeller &&
              <div>
                <div className="flex">
                  <div className="nav-fold flex-column pt-4">
                    <div>Dagens bästa säljare</div>

                    <div className="d-flex p-2">
                      <span className="avatar w-96 circle">
                        <UserImage userId={currentUser.id} type="thumb"/>
                      </span>
                    </div>

                    <Link to={{pathname: `/profiles/${dailyTopSeller.id}`}}>
                      {dailyTopSeller.firstName} {dailyTopSeller.lastName}
                    </Link>
                  </div>
                </div>
              </div>
            }
          </Flex>

          <div>
            { selectedCampaign &&
              <ActivityList showTopSellers={true}/>
            }
          </div>
        </div>
      </Wrapper>

      <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>
            Välj kampanj
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Kampanj</Label>
              <Select
                defaultValue={selectedCampaignOption}
                options={campaignOptions}
                onChange={onChangeCampaign}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Avbryt</Button>
            <Button type="submit" color="primary">Spara</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </TvContainer>
  );
}

export default TvLeaderboard;