import {Card, Progress} from 'reactstrap'
import {Strong} from '../Shared/Style'
import React, {useEffect, useState} from 'react'
import {Wrapper} from './Dashboard.style'
import {CardBody} from '../Shared/Card'
import CountUp from "react-countup";
import {FlexRow} from "../../pages/pages.style";
import {QuinyxApi} from "../../api/Bonti/Quinyx.api";
import {ChevronDown, ChevronUp} from "react-feather";
import Scrollbar from "react-scrollbars-custom";
import DashboardCollapse from './DashboardCollapse.component';
import {Scrollbars} from "react-custom-scrollbars-2";

const BestTeamMember = (props) => {
  const {type, currentDashboard, activeCampaign, component} = props

  const [teamMembers, setTeamMembers] = useState([])
  const [times, setTimes] = useState(null)

  const getTrophy = (position) => {
    if (type === 'daily') {
      return '/assets/img/trophy/T6.png'
    }

    switch (position) {
      case 1:
        return '/assets/img/trophy/T1.png'
      case 2:
        return '/assets/img/trophy/T12.png'
      case 3:
        return '/assets/img/trophy/T4.png'
    }

    return ''
  }

  const getAverage = (teamMember) => {
    if (!currentDashboard || !times) {
      return
    }

    const id = teamMember.userID

    let quinyxTime = currentDashboard && times && times
      .filter(time => time.userId === id)

    if (!quinyxTime || quinyxTime.length === 0) {
      return false;
    }

    let total = quinyxTime
      .map(time => time.hours)
      .reduce((a, b) => a + b)

    return total && (teamMember.value / total).toFixed(2)
  }

  const getHours = (teamMember) => {
    const id = teamMember.userID

    let quinyxTime = currentDashboard && times && times
      .filter(time => time.userId === id)

    if (!quinyxTime || quinyxTime.length === 0) {
      return 0;
    }

    const hours = quinyxTime
      .map(time => time.hours)
      .reduce((a, b) => a + b)

    return hours.toFixed(2)
  }

  useEffect(() => {
    if (currentDashboard && activeCampaign && teamMembers) {
      const ids = teamMembers
        .map(teamMember => teamMember.userID);

      QuinyxApi.findAllByUserIdsAndDates(ids, activeCampaign.startDate, activeCampaign.endDate)
        .then(result => {
          setTimes(result)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [currentDashboard, activeCampaign, teamMembers])

  useEffect(() => {
    let teamMembers = []

    if (!props.teamMembers) {
      return
    }

    props.teamMembers.forEach(teamMember => {
      teamMember.teamMemberName = teamMember.firstName + ' ' + teamMember.lastName
      teamMember.value = 0

      if (type === 'daily') {
        teamMember.value = teamMember.dailyValue
      } else {
        teamMember.value = teamMember.userTotalSum
      }

      let percentage = 0

      if (teamMember.userTotalSum > 0 && teamMember.userTotalGoal > 0) {
        percentage = Math.floor((teamMember.userTotalSum / teamMember.userTotalGoal) * 100)
      }

      teamMember.percentage = percentage

      teamMembers.push({...teamMember})
    })

    setTeamMembers(teamMembers)
  }, [props.teamMembers, props.orders])

  return (
    <>
      {/*{type === 'daily' && <div className="text-muted">Dagens</div>}
      {type === 'total' && <div className="text-muted">Totalt</div>}
      <Strong>Bästa deltagare</Strong>*/}
      <Wrapper>
        <DashboardCollapse component={component}>
          <Scrollbars style={{height: 540, scrollWidth: 5}}>
            <table className="table table-theme v-middle table-hover">
              <tbody>
              {
                teamMembers
                  .sort((a, b) => b.value - a.value)
                  .map((teamMember, i) =>
                    <tr className="v-middle" key={i}>
                      <td>
                        <div className="avatar-group">
                          {i < 3 &&
                            <div title={'#' + (i + 1)}>
                          <span className="w-32 avatar no-shadow">
                            <img src={getTrophy(i + 1)} alt="."/>
                          </span>
                            </div>
                          }

                          {i > 2 &&
                            <div className="avatar ajax w-32 bg-white no-shadow"
                                 title={'#' + (i + 1)}>
                              <small className="text-muted font-weight-bold">{i + 1}</small>
                            </div>
                          }
                        </div>
                      </td>

                      <td className="flex">
                        <div className="item-title text-color h-2x">{teamMember.teamMemberName}</div>
                      </td>

                      <td className='w-100'>
                        <Strong>
                          <CountUp end={teamMember.value} duration={2} decimal={'0'} separator={' '}/> kr
                        </Strong>

                        {type !== 'daily' && (
                          <React.Fragment>
                            <Strong title={"Arbetade timmar: " + getHours(teamMember)}>
                              {currentDashboard && teamMember && getAverage(teamMember) && (
                                <>{getAverage(teamMember)}kr/h snitt</>
                              )}
                            </Strong>

                            <FlexRow>
                              <Progress
                                value={teamMember.percentage}
                                color="success"
                                title={teamMember.percentage + '%'}
                                className='w-100'>{teamMember.percentage}%
                              </Progress>
                            </FlexRow>
                          </React.Fragment>
                        )}
                      </td>
                    </tr>
                  )
              }
              </tbody>
            </table>
          </Scrollbars>
        </DashboardCollapse>
      </Wrapper>
    </>
  )
}

export default BestTeamMember
