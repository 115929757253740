import client from '../../helpers/client';

const baseUrl = '/orders/event-attribute';

function findAll(orderId) {
  return client.getClient()
    .get(`${baseUrl}?orderId=${orderId}`)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });
}

function update(id, data) {
  return client.getClient()
      .patch(`${baseUrl}/${id}`, data)
      .then(response => {
        return Promise.resolve(response.data ? response.data : []);
      })
      .catch(err => {
        if (err.response) {
          let errorMessage = (err.response.data) || err.response.statusText;
          return Promise.reject(errorMessage);
        }

        return Promise.resolve([]);
      });
}

export const OrderEventAttributesApi = {
  findAll,
  update
}