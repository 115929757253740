import * as Yup from 'yup';

export const UserInfoSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(1, 'Förnamnet är för kort!')
    .max(45, 'Förnamnet är för långt!')
    .required('Förnamn saknas!'),
  lastName: Yup.string()
    .min(1, 'Efternamnet är för kort!')
    .max(45, 'Efternamnet är för långt!')
    .required('Efternamn saknas!'),
  email: Yup.string()
    .email('Felaktig e-post')
    .max(255, 'E-posten är för lång!')
    .required('E-post saknas!'),
  phone: Yup.string()
    .max(45, 'Telefonnummret är för långt!')
});

export const UserSchema = UserInfoSchema.shape({
  password: Yup.string()
    .min(8, 'Lösenordet måste vara minst 8 tecken!')
    .required('Lösenord saknas!'),

  passwordRepeat: Yup.string()
    .oneOf([Yup.ref('password')], 'Lösenorden matchar inte')
    .required('Bekräfta lösenordet!'),

  userRoles: Yup.array()
    .required('Roll saknas')
});

export const UserRoleSchema = UserInfoSchema.shape({
  userRoles: Yup.array()
    .required('Roll saknas')
});

export const UserPasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Felaktigt lösenord'),

  password: Yup.string()
    .min(8, 'Lösenordet måste vara minst 8 tecken!')
    .required('Lösenord saknas!'),

  passwordRepeat: Yup.string()
    .oneOf([Yup.ref('password')], 'Lösenorden matchar inte')
    .required('Bekräfta lösenordet!'),
});