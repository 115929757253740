import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';
import {PlusCircle} from 'react-feather';
import UserImage from '../../User/UserImage.component';
import ScoreUtil from "../ScoreUtil";

const TeamTopScore = () => {
  const selectedCampaign = useSelector(state => state.campaign.selectedCampaign);
  const campaignScore = useSelector(state => state.score.score);

  const [topTeams, setTopTeams] = useState([]);

  useEffect(() => {
    if (campaignScore?.campaignTeams) {
      let topTeams = [...campaignScore.campaignTeams]
        .map((a) => {
          a.teamPercent = ScoreUtil.getPercentageFromTeamMembers(a.campaignTeamMembers)
          return a;
        })
        .sort((a, b) => b.teamPercent - a.teamPercent)
        .slice(0, 5)

      setTopTeams(topTeams);
    }
  }, [campaignScore]);

  return (
    <div className="col-sm-6">
      <div className="card">

        <div className="card-body">
          <div className="text-muted text-sm sr-item">{selectedCampaign.name}</div>
          <h5 className="text-highlight sr-item">Bästa lag</h5>

          { topTeams.length === 0 &&
            <div className="text-muted">Inga lag</div>
          }
        </div>

        <table className="table table-theme v-middle table-hover">
          <tbody>
          {
            topTeams
            .map((team, i) =>
              <tr className="v-middle" key={i}>
                <td>
                  <div className="avatar-group">
                    { i === 0 &&
                      <div title={'#' + (i + 1)}>
                        <span className="w-32 avatar no-shadow">
                          <img src="assets/img/trophy/T11.png" alt="."/>
                        </span>
                      </div>
                    }

                    { i > 0 && i < 3 &&
                      <div title={'#' + (i + 1)}>
                          <span className="w-32 avatar no-shadow">
                            <img src="assets/img/trophy/T9.png" alt="."/>
                          </span>
                      </div>
                    }

                    { i > 2 &&
                      <div className="avatar ajax w-32 bg-white no-shadow" title={'#' + (i + 1)}>
                        <small className="text-muted font-weight-bold">{i + 1}</small>
                      </div>
                    }

                    {team.campaignTeamMembers
                      .slice(0,3)
                      .map((teamMember, i) =>
                      <div className="avatar ajax w-24" title={teamMember.userName} key={i}>
                        { i === 0 && <UserImage userId={teamMember.userUniqueId} type="thumb" /> }
                        { i === 1 && <UserImage userId={teamMember.userUniqueId} type="thumb" /> }
                        { i === 2 && <UserImage userId={teamMember.userUniqueId} type="thumb" /> }
                      </div>
                    )}
                  </div>
                </td>

                <td className="flex">
                  <div className="item-title text-color h-1x">{team.teamName}</div>
                  <div className="item-company text-muted h-1x">
                    {team.campaignTeamMembers.length} deltagare
                  </div>
                </td>

                <td>
                  <span className="item-amount d-none d-sm-block text-sm text-nowrap" title={team.teamPercent + '% av mål uppnått'}>
                    <CountUp end={team.teamPercent} separator={" "}/>%
                  </span>
                </td>
              </tr>
            )
          }
          </tbody>
        </table>

        <div className="text-center mb-4">
          <Link to={{pathname: `/leaderboard/${selectedCampaign.id}`}}>
            <button className="btn btn-sm btn-outline-primary btn-rounded">
              <PlusCircle className="mr-1" size={16}/> Visa alla
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TeamTopScore;