import React, {useEffect} from 'react'
import {Container, ContentWrapper, FlexRow, Wrapper} from '../../pages/pages.style'
import {Card, Col, Progress, Row} from 'reactstrap'
import {Line} from 'react-chartjs-2'
import {CardBody} from '../Shared/Card'

import {Strong} from '../Shared/Style'
import {BestDailyOrder, ProgressValue, ProgressWrapper, ResultValue} from './Bonti.style'
import CountUp from 'react-countup'
import BontiDailyTeam from '../../components/Bonti/BontiDailyTeam.component'
import BontiTeamMember from '../../components/Bonti/BontiTeamMember.component'
import BontiMonthlyTeam from '../../components/Bonti/BontiMonthlyTeam.component'
import {Menu} from 'react-feather'
import {foldLeftNav, unfoldLeftNav} from '../../actions/layout.actions'
import {useDispatch, useSelector} from 'react-redux'

const Team = ({
  selectedTeam,
  totalGoal,
  orderTotal,
  teamMembers,
  bestDailyOrder,
  graphSalesData,
  graphBudgetData,
  graphAverageData
}) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.authentication.user)
  const layoutSettings = useSelector(state => state.layout.settings)

  const teams = useSelector(state => state.bonti.teams)

  const toggleLeftNav = () => {
    if (layoutSettings.aside === 'folded') {
      dispatch(unfoldLeftNav());
    } else {
      dispatch(foldLeftNav());
    }
  }

  const getTotalPercentage = () => {
    let percentage = 0

    if (totalGoal > 0) {
      percentage = Math.floor((orderTotal.total / totalGoal * 100))
    }

    return percentage < 100 ? percentage : 100
  }

  useEffect(() => {
    dispatch(foldLeftNav())
  }, [])

  return (
    <Container id="bonti-container">
      { !currentUser &&
      <div className="pointer ml-2 mt-2" title={layoutSettings.aside === 'folded' ? "Visa" : "Göm"}>
        <Menu size={16} onClick={toggleLeftNav}/>
      </div>
      }

      <Wrapper>
        <div className="d-md-flex" style={{justifyContent: 'space-between'}}>
          <ContentWrapper style={{width: '100%'}}>
            <div className="row">
              <div className="col-12">
                {selectedTeam &&
                <div className="pb-3">
                  <h2>{selectedTeam.teamName}</h2>
                </div>
                }
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-8">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={9}>
                        <Strong className="mb-2">Resultat</Strong>
                      </Col>

                      <Col md={3}>
                        <div className="text-muted">Årsbudget</div>
                      </Col>
                    </Row>

                    {orderTotal &&
                    <React.Fragment>
                      <Row>
                        <Col md={3}>
                          <ResultValue>
                            <CountUp start={0} end={orderTotal.year} duration={2} decimal="0" separator={' '}/> kr
                          </ResultValue>
                          <div>År</div>
                        </Col>

                        <Col md={3}>
                          <ResultValue>
                            <CountUp start={0} end={orderTotal.month} duration={2} decimal="0" separator={' '}/> kr
                          </ResultValue>
                          <div>Månad</div>
                        </Col>

                        <Col md={3}>
                          <ResultValue>
                            <CountUp start={0} end={orderTotal.week} duration={2} decimal="0" separator={' '}/> kr
                          </ResultValue>
                          <div>Vecka</div>
                        </Col>

                        <Col md={3}>
                          <ProgressWrapper>
                            <div className="text-muted mb-1">
                              <CountUp start={totalGoal} end={totalGoal} duration={2} decimal="0" separator={' '}/> kr
                            </div>

                            <FlexRow>
                              <Progress
                                value={getTotalPercentage()}
                                color="success"
                                title={getTotalPercentage() + '%'} style={{width: '100%'}}>
                              </Progress>
                              <ProgressValue>{getTotalPercentage()}%</ProgressValue>
                            </FlexRow>

                          </ProgressWrapper>
                        </Col>
                      </Row>
                    </React.Fragment>
                    }
                  </CardBody>
                </Card>
              </div>
            </div>

            <div className="row">
              <div className="col-8">
                <Card>
                  <CardBody>
                    <Strong>Försäljning</Strong>
                    <Line data={{
                      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                      datasets: [{
                        label: 'Försäljning',
                        data: graphSalesData,
                        fill: true,
                        backgroundColor: 'rgb(40, 167, 69, 0.2)',
                        borderColor: 'rgb(40, 167, 69)',
                        tension: 0.3,
                      },
                      {
                        label: 'Budget',
                        data: graphBudgetData,
                        fill: false,
                        borderColor: 'rgb(54, 162, 235)',
                        tension: 0.1,
                      },
                      {
                        label: 'Average',
                        data: graphAverageData,
                        fill: false,
                        backgroundColor: 'rgb(11, 131, 48, 0.4)',
                        borderColor: 'rgb(228, 132, 12)',
                        tension: 0.1,
                      }]
                    }}
                    options={{
                      plugins: {
                        legend: {
                          labels: {
                            font: {
                              size: 36
                            }
                          }
                        }
                      },
                      scales: {
                        x: {
                          ticks: {
                            font: {
                              size: 28
                            }
                          }
                        },
                        y: {
                          ticks: {
                            font: {
                              size: 28
                            }
                          }
                        }
                      }
                    }}
                    width={500} height={300} />
                  </CardBody>
                </Card>
              </div>

              <div className="col-12 col-md-4">
                <Card>
                  <CardBody>
                    <div className="text-muted">Försäljning i månad</div>
                    <Strong>Bästa butik</Strong>
                    <BontiMonthlyTeam teams={teams} />
                  </CardBody>
                </Card>
              </div>
            </div>

            <div className="row">
              <div className="col-8">
                <Card>
                  <CardBody>
                    <div className="text-muted">Totalt</div>
                    <Strong>Bästa deltagare</Strong>
                    <BontiTeamMember type="total" teamMembers={teamMembers} />
                  </CardBody>
                </Card>
              </div>

              <div className="col-4">
                <div className="row">
                  <div className="col-12">
                    <Card>
                      <CardBody>
                        <div className="text-muted">Dagens</div>
                        <Strong className="mb-2">Största affär</Strong>

                        {bestDailyOrder ?
                          <FlexRow>
                            <img alt="" src="https://salestrigger.s3-eu-west-1.amazonaws.com/default/profile64x64"
                                 width={48} height={48} style={{flexGrow: 0, flexShrink: 0}}/>

                            <BestDailyOrder>
                              <div>
                                <CountUp end={bestDailyOrder.value} duration={2} decimal="0" separator={' '}/> kr
                                av {bestDailyOrder.userName}
                                <div className="text-muted">{bestDailyOrder.teamName}</div>
                              </div>
                            </BestDailyOrder>
                          </FlexRow>
                          : <div>Ingen affär idag</div>
                        }
                      </CardBody>
                    </Card>
                  </div>

                  <div className="col-12">
                    <Card>
                      <CardBody>
                        <div className="text-muted">Dagens</div>
                        <Strong>Bästa deltagare</Strong>
                        <BontiTeamMember type="daily" teamMembers={teamMembers} />
                      </CardBody>
                    </Card>
                  </div>

                  <div className="col-12">
                    <Card>
                      <CardBody>
                        <div className="text-muted">Dagens</div>
                        <Strong>Bästa butik</Strong>
                        <BontiDailyTeam teams={teams} />
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </ContentWrapper>
        </div>
      </Wrapper>
    </Container>
  )
}

export default Team
