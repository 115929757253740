import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {findByUserIdAndPeriod} from "../../actions/orderHistoryList.actions";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {formatDate, parseDate} from "react-day-picker/moment";
import './Profile.styles.scss';
import {campaignService} from "../../services/campaign.service";
import Select from "react-select";
import moment from "moment/moment";

const ProfileUserHistory = ({userId}) => {
  const currentUser = useSelector(state => state.authentication.user);
  const dispatch = useDispatch();
  const dateFormat = 'YYYY-MM-DD';
  const orderHistoryList = useSelector(state => state.orderHistoryList.orderHistoryList || []);

  const [campaignOptions, setCampaignOptions] = useState([]);
  const [startDate, setStartDate] = useState(formatDate((new Date(Date.now())), dateFormat));
  const [endDate, setEndDate] = useState(null);
  const [selectedCampaignFilter, setSelectedCampaignFilter] = useState({value: '0', title: 'Alla'});

  const filterHistoryByCampaign = (e) => {
    setSelectedCampaignFilter(e);
  }

  const onChangeStartDate = (date) => {
    setStartDate((date !== undefined) ? date : null);
    dispatch(findByUserIdAndPeriod(userId, formatDate(date, dateFormat), formatDate(endDate, dateFormat)));
  }

  const onChangeEndDate = (date) => {
    setEndDate((date !== undefined) ? date : null);
    dispatch(findByUserIdAndPeriod(userId, formatDate(startDate, dateFormat), formatDate(date, dateFormat)));
  }

  const campaignFilter = (orderHistory) => {
     return (
       orderHistory.campaignUniqueId === selectedCampaignFilter.value ||
       selectedCampaignFilter.value === '0'
     )
  }

  const formatReadableDate = (campaignOrder) => {
    let today = moment();
    let orderDate = moment(campaignOrder.orderDate);

    if (orderDate.isSame(today, 'd')) {
      return 'Idag';
    }

    return moment(campaignOrder.orderDate).locale(moment.locale('sv')).format('MMMM DD');
  }

  useEffect(() => {
    let _endDate = new Date(Date.now());
    let _startDate = new Date(Date.now()).setDate(_endDate.getDate() - 6);

    setStartDate(formatDate(_startDate, dateFormat));
    setEndDate(formatDate(_endDate, dateFormat));

    dispatch(findByUserIdAndPeriod(userId, formatDate(_startDate, dateFormat), formatDate(_endDate, dateFormat)));

    /*
    campaignService.findByTeamMember(currentUser)
      .then(campaigns => {
        let options = [];
        options.push({value: '0', label: 'Alla'});
        campaigns.map((campaign) => {
          options.push({value: campaign.id, label: campaign.name});
        });
        setCampaignOptions(options);
      });
     */
  }, [])

  return (
    <div className="profile-user-history" id={'profile-user-history'}>
      <div className="card p-4">
        <div className="row">
          {/*
          <div className="col-12 col-md-6 mb-2">
            <div className="small text-muted">Kampanj</div>

            {
              campaignOptions.length > 0 ?
                <div>
                  <Select
                    options={campaignOptions}
                    defaultValue={campaignOptions[0]}
                    onChange={filterHistoryByCampaign}
                    id='campaign-select'
                  />
                </div>
                :
                <Select
                  options={campaignOptions}
                  onChange={filterHistoryByCampaign}
                  id='campaign-select'
                />
            }
          </div>
          */}

          <div className="col-12 col-md-6 mb-2">
            <div className="small text-muted">Startdatum</div>

            <DayPickerInput
              inputProps={{
                className: 'form-control',
                required: 'required'
              }}
              formatDate={formatDate}
              format={dateFormat}
              parseDate={parseDate}
              placeholder="YYYY-MM-DD"
              value={startDate}
              onDayChange={onChangeStartDate}
              id="start-date"
            />
          </div>

          <div className="col-12 col-md-6 mb-2">
            <small className="text-muted">Slutdatum</small>

            <DayPickerInput
              inputProps={{
                className: 'form-control',
                required: 'required'
              }}
              formatDate={formatDate}
              format={dateFormat}
              parseDate={parseDate}
              placeholder="YYYY-MM-DD"
              value={endDate}
              onDayChange={onChangeEndDate}
              id="end-date"
            />
          </div>
        </div>
      </div>

      { orderHistoryList.filter(campaignFilter).length > 0 &&
        <div className="card p-4">
          <div className="timeline animates animates-fadeInUp">
            {
              orderHistoryList
                .filter(campaignFilter)
                .map((history, index) => (
                  <React.Fragment key={index}>
                    { index > 0 &&
                      <div className="tl-item  " key={index}>
                        <div className="tl-dot ">
                        </div>
                        <div className="tl-content">
                          <div className="" style={{ fontWeight:'bold'}}>
                            { history.eventName }, { history.value } {history.unit}
                          </div>
                          <div className="" style={{textTransform: 'capitalize'}}>
                            {/* history.campaignName */}{ formatReadableDate(history) }, { history.teamName }
                          </div>
                          <div className="tl-date text-muted mt-1">{ history.createdTimestamp }</div>
                        </div>
                      </div>
                    }

                    { index === 0 &&
                      <div className="tl-item  active" key={index}>
                        <div className="tl-dot ">
                        </div>
                        <div className="tl-content">
                          <div className="" style={{ fontWeight:'bold'}}>
                            { history.eventName }, { history.value } {history.unit}
                          </div>
                          <div className="" style={{textTransform: 'capitalize'}}>
                              {/* history.campaignName */}{ formatReadableDate(history) }, { history.teamName }
                          </div>
                          <div className="tl-date text-muted mt-1">{ history.createdTimestamp }</div>
                        </div>
                      </div>
                    }
                  </React.Fragment>
                ))
            }
          </div>
        </div>
      }
    </div>
  );
};

export default ProfileUserHistory;