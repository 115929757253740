import {
  SET_CAMPAIGN_LIST,
  SET_SELECTED_CAMPAIGN,
  SET_CAMPAIGN_INFO,
  SET_INFO_FORM_VALIDATION,
  SET_CAMPAIGN_TEAMS,
  SET_CAMPAIGN_TEAM_MEMBERS,
  SELECT_EVENT,
  REMOVE_EVENT,
  SET_CAMPAIGN_EVENTS,
  SET_CAMPAIGN_GOAL,
  OPEN_ORDER_MODAL, CLOSE_ORDER_MODAL, SET_EVENT_GOAL, SET_EVENT_TEAM_GOAL, SET_INITIAL_CAMPAIGN, SET_ACTIVE_CAMPAIGN_STEP, SET_CAMPAIGN_STEPS, CLEAR_FORM, SET_DIRTY_FIELD, SET_INITIAL_CAMPAIGN_STEP,
} from '../types';

export function setCampaignList(campaigns) {
  return (dispatch) => dispatch({ type: SET_CAMPAIGN_LIST, campaigns });
}

export function setSelectedCampaign(campaign) {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_CAMPAIGN, campaign });
  };
}

export function clearCampaignForm () {
  return (dispatch) => dispatch({ type: CLEAR_FORM })
}

export function setInitialCampaignStep(step) {
  return (dispatch) => dispatch({ type: SET_INITIAL_CAMPAIGN_STEP, step });
}

export function setActiveCampaignStep(step) {
  return (dispatch) => dispatch({ type: SET_ACTIVE_CAMPAIGN_STEP, step });
}

export function setCampaignSteps(steps) {
  return (dispatch) => dispatch({ type: SET_CAMPAIGN_STEPS, steps });
}

export function setInitialCampaign(campaignInfo, campaignTeams, campaignEvents) {
  return (dispatch) => dispatch({ type: SET_INITIAL_CAMPAIGN, campaignInfo, campaignTeams, campaignEvents });
}

export function setInfoFormValidation(isInfoFormValidated) {
  return (dispatch) => dispatch({ type: SET_INFO_FORM_VALIDATION, isInfoFormValidated });
}

export function setDirtyField(field) {
  return (dispatch) => dispatch({ type: SET_DIRTY_FIELD, field });
}

export function setCampaignInfo(campaignInfo) {
  return (dispatch) => dispatch({ type: SET_CAMPAIGN_INFO, campaignInfo });
}

export function setCampaignTeams(campaignTeams) {
  return (dispatch) => {
    dispatch({ type: SET_CAMPAIGN_TEAMS, campaignTeams });
  };
}

/* export function addCampaignTeam(team) {
  return (dispatch) => {
    dispatch({ type: SELECT_TEAM, team });
  };
} */

/* export function removeCampaignTeam(teamId) {
  return (dispatch) => {
    dispatch({ type: REMOVE_TEAM, teamId });
  };
}
 */

export function setTeamMembers(campaignTeam, teamMembers) {
  return (dispatch) => {
    dispatch({ type: SET_CAMPAIGN_TEAM_MEMBERS, campaignTeam, teamMembers });
  };
}

export function addCampaignEvent(event) {
  return (dispatch) => {
    dispatch({ type: SELECT_EVENT, event });
  };
}

export function removeCampaignEvent(eventId) {
  return (dispatch) => {
    dispatch({ type: REMOVE_EVENT, eventId });
  };
}

export function setCampaignEvents(campaignEvents) {
  return (dispatch) => {
    dispatch({ type: SET_CAMPAIGN_EVENTS, campaignEvents });
  };
}

export function setEventGoal(eventGoal, value) {
  return (dispatch) => {
    dispatch({ type: SET_EVENT_GOAL, eventGoal , value});
  };
}

export function openOrderModal(eventId = null) {
  return (dispatch) => {
    dispatch({ type: OPEN_ORDER_MODAL, eventId });
  };
}

export function closeOrderModal() {
  return (dispatch) => {
    dispatch({ type: CLOSE_ORDER_MODAL });
  };
}