import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {findByUserId} from '../../actions/statistics.actions';
import {TeamStatistics, TopEventResult, TotalEventResult, UserStatistics} from './Overview';

const ProfileOverview = ({userId}) => {
  const dispatch = useDispatch();
  const statistics = useSelector(state => state.statistics.statistics);

  useEffect(() => {
    dispatch(findByUserId(userId));
  }, []);

  if (!statistics) return <div>Ingen data</div>

  return (
    <div className="ProfileOverview">
      {
        <div className="ProfileOverviewContainer">
          <UserStatistics statistics={statistics}/>
          <TeamStatistics statistics={statistics}/>
          <TotalEventResult statistics={statistics}/>
          <TopEventResult statistics={statistics}/>
        </div>
      }
    </div>
  );
};

export default ProfileOverview;