import React, {useEffect, useState} from 'react'
import Team from '../../components/Bonti/Team.component';
import {BontiApi, findTeamOrderSum} from '../../api/Bonti/Bonti.api';
import {BONTI_COMPANY_ID, bontiTeams} from '../../helpers/integrations/bonti';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {setTeam, setTeamList} from '../../actions/bonti/bonti.actions';

const TeamContainer = ({ teamName }) => {
  const dispatch = useDispatch()

  const [selectedTeam, setSelectedTeam] = useState(null)
  const [orderTotal, setOrderTotal] = useState({ total: 0, year: 0, month: 0, week: 0 })
  const [totalGoal, setTotalGoal] = useState(0)

  const [teamMembers, setTeamMembers] = useState([])
  const [bestDailyOrder, setBestDailyOrder] = useState(null)

  const [graphSalesData, setGraphSalesData] = useState([])
  const [graphBudgetData, setGraphBudgetData] = useState([])
  const [graphAverageData, setGraphAverageData] = useState([])

  useEffect(() => {
    if (!(bontiTeams[teamName]) || !(bontiTeams[teamName].teamId)) return

    let teamId = bontiTeams[teamName].teamId

    BontiApi.findTeamOrderSum(BONTI_COMPANY_ID, teamId).then(data => {

      if (!data) {
        return
      }

      setTeamList(data.teams)
      setTeamMembers(data.users)

      if (data.bestDailyOrder) {
        setBestDailyOrder(data.bestDailyOrder)
      }

      data.teams.forEach(team => {
        if (team.teamId === teamId) {
          setSelectedTeam(team)
          dispatch(setTeam(team))
        }
      })

      // GRAPH

      const monthMap = new Map()

      let m = moment()
      for (let i = 0; i < 12; i++) {
        monthMap.set(m.month(i).format('YYYY-MM'), 0)
      }

      let totalSales = 0

      data.campaignScoreChildViewModelList.forEach(campaign => {
        const month = moment(campaign.startDate).format('YYYY-MM')
        monthMap.set(month, campaign.sumOrders)
        totalSales += campaign.sumOrders
      })

      const averageSales = []
      for (let i = 0; i < 12; i++) {
        averageSales.push(totalSales / data.campaignScoreChildViewModelList.length)
      }

      setGraphSalesData([...monthMap.entries()].map(o => o[1]))
      setGraphAverageData(averageSales)
    })
  }, [teamName])

  useEffect(() => {
    if (selectedTeam) {
      BontiApi.getOrderTotal(selectedTeam.teamId).then((result) => {

        if (!result) {
          return
        }

        setOrderTotal({
          total: result.total,
          year: result.year,
          month: result.month,
          week: result.week,
        })

        dispatch(setTeam({...selectedTeam, orderTotal: {
          total: result.total,
          year: result.year,
          month: result.month,
          week: result.week
        }}))
      })

      BontiApi.getGoal(selectedTeam.teamId).then(goal => {
        if (goal) {
          setTotalGoal(goal.totalGoal)

          const monthMap = new Map()
          let m = moment()
          for (let i = 0; i < 12; i++) {
            const date = m.month(i).format('YYYY-MM')
            monthMap.set(date, 0)

            if (goal[date]) {
              monthMap.set(date, goal[date])
            }
          }

          setGraphBudgetData([...monthMap.entries()].map(o => o[1]))
        }
      })
    }
  }, [selectedTeam])

  /*useEffect(() => {
    if (selectedTeam && orders.length > 0) {
      const now = moment()
      // GRAPH

      const monthMap = new Map()

      let m = moment()
      for (let i = 0; i < 12; i++) {
        monthMap.set(m.month(i).format('YYYY-MM'), 0)
      }

      let totalSales = 0

      teamOrders.forEach(order => {
        const month = moment(order.orderDate).format('YYYY-MM')

        if (monthMap.has(month)) {
          monthMap.set(month, monthMap.get(month) + order.value)
        } else {
          monthMap.set(month, order.value)
        }

        totalSales += order.value
      })

      const monthsWithValues = [...monthMap.entries()].map(o => o[1]).reduce((total, value, i) => value > 0 ? total + 1 : total, 0)
      const averageSales = []
      for (let i = 0; i < 12; i++) {
        averageSales.push(totalSales / monthsWithValues)
      }

      setGraphSalesData([...monthMap.entries()].map(o => o[1]))
      setGraphAverageData(averageSales)
    }
  }, [selectedTeam, orders])*/

  if (!(teamName in bontiTeams) || !teamName || teamName === "") return <React.Fragment />

  return (
    <Team
      selectedTeam={selectedTeam}
      totalGoal={totalGoal}
      orderTotal={orderTotal}
      teamMembers={teamMembers}
      bestDailyOrder={bestDailyOrder}
      graphSalesData={graphSalesData}
      graphBudgetData={graphBudgetData}
      graphAverageData={graphAverageData}
    />
  )
}

export default TeamContainer