import client from '../../helpers/client';

const baseUrl = '/eventattribute';

function findAll(eventid) {
  let url = `${baseUrl}`;
  if(eventid)
    url += "?eventId=" + eventid;

  return client.getClient()
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });
}

function get(campaignId) {
  return client.getClient()
    .get(baseUrl + `/${campaignId}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject({
          message: errorMessage,
          status: err.response.status
        });
      }
    });
}

function findByTeamMember(user, status = null) {
  status = (status !== null ? '?status=' + status : '');

  return client.getClient()
    .get(baseUrl + status)
    .then(response => {

      if (user.hasRole('Admin', 'TeamLeader')) {
        return Promise.resolve(response.data ? response.data : []);
      }

      let campaigns = response.data.filter(campaign => {
        if (campaign.active === 'Active') {
          let teamWithUser = campaign.campaignTeams
            .find(team => team.teamMembers
              .find(teamMember => teamMember.userId === user.id));

          return teamWithUser !== undefined;
        }

        return false;
      });

      return Promise.resolve(campaigns ? campaigns : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });
}

export function save(data = {}) {
  let url = baseUrl
  let request

  if (data.id) {
    url += `/${data.id}`
    request = client.getClient().patch(url, data)
  }
  else {
    request = client.getClient().post(url, data)
  }

  return request
      .then(response => Promise.resolve(response.data))
      .catch(err => {
        if (err.response) {
          let errorMessage = (err.response.data) || err.response.statusText;
          return Promise.reject(errorMessage);
        }
      });
}

export function create(data = {}) {
  let url = baseUrl + `/insert?eventId=${data.eventId}&companyId=${data.companyId}&description=${data.description}&type=${data.type}&displayOrder=${data.displayOrder}`

  return client.getClient()
    .put(url)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function update(data = {}) {
  const url = `${baseUrl}/update`;

  return client.getClient()
    .patch(url, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export function remove(id = {}) {
  const url = `${baseUrl}/${id}`;

  return client.getClient()
    .delete(url)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export const EventAttributesApi = {
  findAll,
  get,
  save,
  create,
  update,
  remove,
}