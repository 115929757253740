import React from 'react';
import CountUp from "react-countup";

export {
  TotalEventResult
}

const TotalEventResult = ({statistics}) => {

  if (!statistics.eventStatisticsTotal)
    return <React.Fragment/>

  return (
    <div className="ProfileOverviewContainer">
      <h5>Aktivitetsresultat - Totalt</h5>

      <div className="row mb-2">
        { statistics.eventStatisticsTotal
          .map((eventStatistic, index) => (
            eventStatistic !== undefined &&
            <div className="col-12 col-md-4" key={index}>
              <div className="card">
                <div className="card-body">
                  <div className="d-md-flex">
                    <div className="flex">
                      <div className="item-amount text-highlight">
                        <CountUp end={eventStatistic.value} duration={2} decimal="0" separator={" "}/> {eventStatistic.eventUnit}
                      </div>

                      <small className="text-muted h-1x mb-1">{eventStatistic.eventName}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}