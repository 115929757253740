import React from 'react';
import {Container, Wrapper, Header, SubHeader} from './pages.style';
import AccountSettings from '../components/Settings/AccountSettings.component';
import NotificationSettings from '../components/Settings/NotificationSettings.component';
import EmailSettings from '../components/Settings/EmailSettings.component';

const Settings = (props) => {
  return (
    <Container>
      <Wrapper>
        <div className="page-title">
          <h2 className="text-md text-highlight">Inställningar</h2>
          <small className="text-muted">Konfigurera ditt konto</small>
        </div>
      </Wrapper>

      <Wrapper>
        <div id="accordion">
          <AccountSettings/>
          {/*<NotificationSettings/>
          <EmailSettings/>*/}
        </div>
      </Wrapper>
    </Container>
  );
}

export default Settings;