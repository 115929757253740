import client from '../helpers/client';

export const audioService = {
  findAll,
}

const baseUrl = '/audio';

function findAll() {
  return client.getClient()
    .get(baseUrl)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });
}