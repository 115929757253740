import {Responsive, WidthProvider} from "react-grid-layout";
import {useDispatch, useSelector} from "react-redux";
import {setColumnSize, setSelectedComponents, setTmpComponents} from "../../actions/dashboard.actions";
import {DashboardApi} from "../../api/Dashboard/Dashboard.api";
import CampaignTotal from "./CampaignTotalSum.component";
import CampaignTotalBar from "./CampaignTotalBar.component";
import BestTeamMember from "./BestTeamMember.component";
import CampaignBestSellSum from "./CampaignBestSellSum.component";
import CampaignBestSellQuantity from "./CampaignBestSellQuantity.component";
import CampaignMonthlyTeam from "./CampaignMonthlyTeam.component";
import CampaignDailySales from "./CampaignDailySales.component";
import CampaignTotalLineGraph from "./CampaignTotalLineGraph.component";
import CampaignDoughnutGraph from "./CampaignDoughnutGraph";
import CampaignRSCountData from "./CampaignRSCountData";
import {useEffect, useState} from "react";
import { SizeMe } from 'react-sizeme'

const EditDashboardGrid = ({dashboardId, onSubmit }) => {

    const dispatch = useDispatch()
    const dashboards = useSelector(state => state.dashboard.dashboards)
    // const tmpComponents = useSelector(state => state.dashboard.tmpComponents)
    const selectedComponents = useSelector(state => state.dashboard.selectedComponents)
    const [componentMap, setComponentMap] = useState(new Map())


    const [layout, setLayout] = useState({
        currentBreakpoint: "lg",
        compactType: "vertical",
        mounted: false,
        layouts: generateLayout()
    })


    const handleResize = (layout) => {

        const newSelectedComponents = [...selectedComponents].map((component, i) => ({
            label: component.label,
            size: component.size,
            sortOrder: component.sortOrder,
            value: component.value,
            x: component.x,
            y: component.y
        }))

        layout.forEach((item, i) => {
            const selectedComponent = newSelectedComponents.find(c => c.value === item.i)
            if (selectedComponent) {
                selectedComponent.size = item.w
                selectedComponent.x = item.x
                selectedComponent.y = Math.ceil(item.y)



            }
        })


        dispatch(setSelectedComponents(newSelectedComponents))

    }

    const onBreakpointChange = (breakpoint) => {
        setLayout({...layout,
            currentBreakpoint: breakpoint
        })
    }

    const handleSortOrder = (layout, previous, c) => {

        const newSelectedComponents = [...selectedComponents]

        layout
            .sort((a, b) => a.y - b.y || a.x - b.x)
            .forEach((item, i) => {
                const selectedComponent = newSelectedComponents.find(c => c.value === item.i)
                if (selectedComponent) {
                    selectedComponent.x = item.x
                    selectedComponent.sortOrder = i + 1
                    selectedComponent.y = Math.ceil(item.y)

                        if (item.y < Math.ceil(item.h)) {


                            const sameRow = layout.filter((l) => l.y === item.y);
                            const len = (sameRow.map(l => l.w).reduce((total, value) => total + value, 0))

                            if (len < 12) {
                                selectedComponent.y = Math.ceil(item.y)
                            }

                        }
                }
            })


        dispatch(setSelectedComponents(newSelectedComponents))

    }

    function generateLayout() {

        const availableHandles = ["e"];

        let layout = selectedComponents && selectedComponents.map((selectedComponent, i) => {

            let height = 2
            let x = selectedComponent.x
            let w = selectedComponent.size
            let isResizable = true

            switch (selectedComponent.value) {
                case 'CampaignTotalBar':
                    height = 4.4
                    w = 12
                    isResizable = false
                    break
                case 'CampaignTotalLineGraph':
                    height = 5.6
                    w = 12
                    isResizable = false
                    break
                case 'BestTeamMember':
                    height = 4
                    break
                case 'CampaignBestSellSum':
                    height = 4
                    break
                case 'CampaignBestSellQuantity':
                    height = 4
                    break
                case 'CampaignRSCountData':
                    height = 4
                    break
                case 'CampaignDoughnutGraph':
                    height = 4
                    w = 12
                    isResizable = false
                    break
                case 'CampaignDailySales':
                    height = 4
                    break
                case 'CampaignDailyTeamMember':
                    height = 4
                    break
                case 'CampaignMonthlyTeam':
                    height = 4
                    break
                case 'CampaignTotalSum':
                    height = 4
                    break
            }

            let y = Math.ceil(selectedComponent.y ? selectedComponent.y : 0)


            return ({
                y,
                x,
                w,
                h: height,
                i: selectedComponent.value,
                resizeHandles: availableHandles,
                isResizable,

            })
        })

        return layout
    }

    useEffect(() => {
        setLayout({
            ...layout,
            layouts: { lg: generateLayout() },
            mounted: true
        })

        DashboardApi.findComponents().then(function (components) {
            const componentMap = new Map()
            components.forEach((component) => {
                componentMap.set(component.value, { category: component.category, name: component.text })
            })
            setComponentMap(componentMap)

        }).catch(err => {
            console.log(err)
        });

        dispatch(setTmpComponents(selectedComponents))
    },[selectedComponents])



    return (
        <SizeMe>
            {({ size }) => (
                <Responsive className="layout"
                   width={size.width}
                   cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                   layouts={layout.layouts}
                   measureBeforeMount={false}
                   onBreakpointChange={onBreakpointChange}
                   compactType={layout.compactType}
                   onDragStop={handleSortOrder}
                   preventCollision={!layout.compactType}
                   onResizeStop={handleResize}
                   draggableHandle=".drag-handle">
                    {selectedComponents && selectedComponents.map((component) => (
                        <div className={`col col-md-${component.size} p-0 `} key={component.value} >
                            <div className="drag-handle"></div>
                            {component.value === 'CampaignTotalSum' && <CampaignTotal campaignTotal={null} component={component} name={component.label}  component={componentMap.get(component.value)}/>}
                            {component.value === 'CampaignTotalBar' && <CampaignTotalBar campaignTotal={null} campaignSum={null} component={component} name={component.label} component={componentMap.get(component.value)}/>}
                            {component.value === 'BestTeamMember' &&  <BestTeamMember type="total" activeCampaign={null} component={component} currentDashboard={null} teamMembers={null} name={component.label} component={componentMap.get(component.value)}/>}
                            {/*TODO: lägg till dayly på BestTeamMember*/}
                            {/* <DashboardCollapse component={componentMap.get(component.value)}></DashboardCollapse> */}
                            {component.value === 'CampaignBestSellSum' && <CampaignBestSellSum articles={null} name={component.label} component={component} component={componentMap.get(component.value)}/>}
                            {component.value === 'CampaignBestSellQuantity' && <CampaignBestSellQuantity articles={null} name={component.label} component={component} component={componentMap.get(component.value)}/>}
                            {component.value === 'CampaignMonthlyTeam' && <CampaignMonthlyTeam teams={null} name={component.label} component={component} component={componentMap.get(component.value)}/>}
                            {component.value === 'CampaignDailySales' && <CampaignDailySales teamMembers={null} bestDailyOrder={null} component={componentMap.get(component.value)} name={component.label} />}
                            {component.value === 'CampaignDailyTeamMember' &&  <CampaignDailySales teamMembers={null} bestDailyOrder={null}  component={componentMap.get(component.value)}/> }
                            {component.value === 'CampaignTotalLineGraph' && <CampaignTotalLineGraph campaignTotal={null} campaignSum={null} component={component} name={component.label} component={componentMap.get(component.value)}/>}
                            {component.value === 'CampaignDoughnutGraph' && <CampaignDoughnutGraph teams={null} orders={null} component={component} name={component.label} component={componentMap.get(component.value)}/>}
                            {component.value === 'CampaignRSCountData' && <CampaignRSCountData component={component} name={component.label} component={componentMap.get(component.value)}/>}
                        </div>
                    ))}
                </Responsive>
            )}
        </SizeMe>
    )
}

export default EditDashboardGrid