import React, {useEffect, useState} from 'react';
import {
    Button,
    Form,
    FormGroup,
    FormFeedback,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import {default as Select} from 'react-select';

import {useDispatch, useSelector} from 'react-redux';
import {closeOrderModal, openOrderModal} from '../../actions/campaign.actions';
import {campaignService} from '../../services/campaign.service';
import {setScore} from '../../actions/score.actions';

import {EventAttributesApi} from '../../api/EventAttribute';
import styled from "styled-components";
import {EventApi} from '../../api/Event/Event.api';

const CampaignOrderAdd = () => {
        const dispatch = useDispatch();

        const selectedCampaign = useSelector(state => state.campaign.selectedCampaign);
        const currentUser = useSelector(state => state.authentication.user);
        const isModalOpen = useSelector(state => state.campaign.isOrderModalOpen);
        const selectedEventId = useSelector(state => state.campaign.orderModal.eventId);

        const [campaignEventOptions, setCampaignEventOptions] = useState([]);
        const [events, setEvents] = useState([]);

        const [campaignEvent, setCampaignEvent] = useState(null);
        const [selectedCampaignOption, setSelectedCampaignOption] = useState(null);
        const [orderValue, setOrderValue] = useState('');
        // const [attributeValue, setAttributeValue] = useState('');
        // const [attributeId, setAttributeId] = useState('');
        const [users, setUsers] = useState([])
        const [selectedCampaignUser, setSelectedCampaignUser] = useState(null)

        const [eventAttribute, setEventAttribute] = useState([]);
        const [error, setError] = useState(null)

        const toggleModal = () => {
            if (isModalOpen) {
                dispatch(closeOrderModal());
            } else {
                dispatch(openOrderModal());
            }
        };

        const handleCampaignEventChange = (selectedEvent) => {
            let campaignEvent = selectedCampaign?.campaignEvents.find(e => e.id === selectedEvent.value);
            let event = events.find(event => event.id === campaignEvent.eventId);

            if (event) {
              campaignEvent.event = event;
              setCampaignEvent(campaignEvent);
              setSelectedCampaignOption(selectedEvent);
              
              if (event.useFixedValue) {
                  setOrderValue(campaignEvent.event.fixedValue)
              }
            }

            // Fix for when a new event has been added and the page hasn't been refreshed
            if(event === undefined) {
              EventApi.findAll()
                .then(function(events){
                  setEvents(events);
                  
                  event = events.find(event => event.id === campaignEvent.eventId);
                  
                  if (event) {
                    campaignEvent.event = event;
                    setCampaignEvent(campaignEvent);
                    setSelectedCampaignOption(selectedEvent);
                    if (event.useFixedValue) {
                      setOrderValue(campaignEvent.event.fixedValue)
                    }
                  }
               });
            }
        };

        const isFormDisabled = () => {
            let isDisabled = true;

            if (campaignEvent !== null) {
                let value = parseInt(orderValue);
                value = isNaN(value) ? 0 : value;

                let attributeValueIsOk = true;
                console.log(value)
                console.log(eventAttribute)

                eventAttribute.forEach(obj => {
                    if (!obj.value || (obj.value && obj.value.length === 0)) {
                        attributeValueIsOk = false;
                    }
                });

                if (value > 0 && attributeValueIsOk) {
                    isDisabled = false;
                }
            }

            return isDisabled;
        };

        const handleSubmit = (e) => {
            e.preventDefault();

            if (error) {
                setError(null);
            }

            const campaignTeam = selectedCampaign.campaignTeams
                .find(team => team.teamMembers
                    .find(teamMember => teamMember.userId === selectedCampaignUser?.value));

            if (!campaignTeam) {
                setError('Välj en användare');
                console.log(selectedCampaign)
                console.log(selectedCampaignUser)
                return
            }

            campaignService.createOrder(selectedCampaign.id, {
                teamId: campaignTeam.teamId,
                eventId: campaignEvent.eventId,
                userId: selectedCampaignUser.value,
                value: orderValue,
                attributes: eventAttribute
            })
                .then(() => {
                    dispatch(closeOrderModal());

                    if (selectedCampaign) {
                        campaignService.findScores(selectedCampaign.id)
                            .then(campaignScore => {
                                dispatch(setScore(campaignScore));
                            });
                    }
                });
        };

        const setCampaignEvents = () => {
            let eventOptions = [];

            if (selectedCampaign?.campaignEvents) {
                selectedCampaign.campaignEvents.forEach(campaignEvent => {
                    eventOptions.push({
                        value: campaignEvent.id,
                        label: campaignEvent.eventName
                    });
                });
            }

            setCampaignEventOptions(eventOptions);
        }

        const setCampaignUsers = () => {
            let campaignTeams = [];

            if (selectedCampaign?.campaignTeams) {
                selectedCampaign.campaignTeams.forEach(team => {
                    let members = team.teamMembers
                    members.sort((a, b) => {
                        return a.lastname.localeCompare(b.lastname)
                    })

                    let memberOptions = []
                    members.forEach(member => {
                        if (member.userId === currentUser.id) {
                            setSelectedCampaignUser({
                                label: currentUser.displayName,
                                value: currentUser.id
                            });
                        }

                        memberOptions.push({
                            label: member.name,
                            value: member.userId
                        })
                    })

                    campaignTeams.push({
                        label: team.teamName,
                        options: memberOptions
                    })
                });

                campaignTeams.sort((a, b ) => a.label.localeCompare(b.label));
                setUsers(campaignTeams);
            }
        }

        useEffect(() => {
            setCampaignEvents();
            EventApi.findAll().then(events => setEvents(events));
        }, []);

        useEffect(() => {
            setSelectedCampaignUser(null);
            setCampaignEvent(null);
            setOrderValue('');
            setSelectedCampaignOption(null);
            setEventAttribute([]);

            if (isModalOpen) {
                setCampaignUsers();
            }
        }, [isModalOpen]);

        useEffect(() => {
            if (campaignEvent) {
                if (campaignEvent.event.hasAttribute) {
                    EventAttributesApi.findAll(campaignEvent.event.id).then(callback => {
                        setEventAttribute(callback);
                    });
                } else {
                    setEventAttribute([]);
                    // setAttributeValue('');
                    // setAttributeId('');
                }
            }
        }, [campaignEvent])

        useEffect(() => {
            setCampaignEvents();
            setCampaignUsers();
        }, [selectedCampaign]);

        useEffect(() => {
            if (selectedEventId !== null) {
                let event = campaignEventOptions.find((event) => event.value === selectedEventId);
                handleCampaignEventChange(event);
            }
        }, [selectedEventId])

        const renderAttributes = (attribute, i) => {

            if (attribute.type === 'RADIO') {
                return (
                    <EventAttributeVertical key={i}>
                        <strong>{attribute.description}</strong>

                        {attribute.type === 'RADIO' &&
                            attribute.eventAttributeOptions.map((option, x) => (
                                <div key={x}>
                                    <label>
                                    <input type="radio" name={attribute.id} value={option.id}
                                           onChange={(e) => {
                                               attribute.optionId = option.id
                                               attribute.value = option.name;
                                            //    setAttributeValue({...attribute});
                                           }}
                                    /> {option.name}
                                    </label>
                                </div>
                            ))
                        }
                    </EventAttributeVertical>
                )
            }

            return (
                <EventAttributeVertical key={i}>
                    <strong>{attribute.description}</strong>
                    <div>
                        {attribute.type === 'TEXT' &&
                            <Input key={attribute.id} type="text"
                                   name={`description_${i}`}
                                   placeholder="Värde"
                                   onChange={(e) => {
                                     const newAttributes = [...eventAttribute];

                                     newAttributes.forEach(a => {
                                       if (a.id === attribute.id) {
                                         a.value = e.target.value;
                                       }
                                     });

                                     setEventAttribute(newAttributes);
                                     // attribute.value = e.target.value;
                                     //    setAttributeValue({...attribute});
                                   }}
                            />
                        }
                    </div>
                </EventAttributeVertical>
            )
        }

        return (
            <div id="campaign-order">
                <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
                    <Form onSubmit={handleSubmit}>
                        <ModalHeader toggle={toggleModal}>Registrera</ModalHeader>

                        <ModalBody>
                            <React.Fragment>
                                <FormFeedback className='d-block mb-2' valid={error === null}>
                                    {error}
                                </FormFeedback>

                                {currentUser.hasRole('Admin') &&
                                    <FormGroup>
                                        <Label>Användare</Label>
                                        <Select
                                            options={users}
                                            onChange={e => setSelectedCampaignUser(e)}
                                            value={selectedCampaignUser}
                                        />
                                    </FormGroup>
                                }

                                <FormGroup>
                                    <Label>Aktivitet</Label>
                                    <Select
                                        options={campaignEventOptions}
                                        onChange={handleCampaignEventChange}
                                        value={selectedCampaignOption}
                                    />
                                </FormGroup>

                                {
                                    campaignEvent &&
                                    <FormGroup>
                                        <Label>Värde</Label>

                                        <InputGroup className="mb-3">
                                            <Input type="number"
                                                   name="value"
                                                   placeholder="Värde"
                                                   disabled={campaignEvent.event.useFixedValue}
                                                   defaultValue={orderValue}
                                                   onChange={(e) => setOrderValue(e.target.value)}/>

                                            <div className="input-group-append">
                                                <span className="input-group-text">{campaignEvent.event.unit}</span>
                                            </div>
                                        </InputGroup>
                                    </FormGroup>
                                }

                                {eventAttribute.map((attribute, i) => renderAttributes(attribute, i))}
                            </React.Fragment>
                        </ModalBody>

                        <ModalFooter>
                            <Button color="secondary" onClick={toggleModal}>Avbryt</Button>
                            <Button type="submit" color="primary" disabled={isFormDisabled()}>Spara</Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
        );
    }
;

export default CampaignOrderAdd;

const EventAttribute = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    
    > div {
        margin-left: 1rem;
    }
`

const EventAttributeVertical = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
`