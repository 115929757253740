import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {userService} from '../services/user.service';
import ProfileUserList from '../components/Profile/ProfileUserList.component';
import ProfileUserHistory from '../components/Profile/ProfileUserHistory.component';
import ProfileInfo from '../components/Profile/ProfileInfo.component';
import ProfileOverview from '../components/Profile/ProfileOverview.component';

import styled from 'styled-components';
import UserImageEdit from '../components/User/UserImageEdit.component';
import UserImage from '../components/User/UserImage.component';
import {setDocumentTitle} from '../helpers';

const Profile = (props) => {
  let userId = props.match.params.id;
  const currentUser = useSelector(state => state.authentication.user);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!userId) userId = currentUser.id;

    userService.get(userId)
      .then(user => {
        if (user) {
          setUser(user);
          setDocumentTitle(user.firstName + ' ' + user.lastName);
        }
      });
  }, []);

  return (
    <div className="Profile">
      {user &&
        <div>
          <div className="page-hero page-container " id="page-hero">
            <div className="padding d-flex">
              <div className="page-title">
                <h2 className="text-md text-highlight">Profil</h2>
                <small className="text-muted">Din personliga information</small>
              </div>
              <div className="flex"></div>
            </div>
          </div>

          <div className="page-content page-container" id="page-content">
            <div className="padding sr">
              <div className="card">
                <div className="card-header bg-dark bg-img p-0 no-border" style={{backgroundImage: 'url(/assets/img/b3.jpg)'}}>
                  <ProfileHeader>
                    <div className="d-md-flex">
                      <div className="p-4">
                        <div className="d-flex">
                          { user.id === currentUser.id && <UserImageEdit userId={user.id} width={64} height={64}/>}

                          { user.id !== currentUser.id &&
                            <span className="avatar w-64">
                              <UserImage userId={user.id} width={64} height={64}/>
                            </span>
                          }

                          <div className="mx-3">
                            <h5 className="mt-2">{user.firstName} {user.lastName}</h5>
                            <div className="text-fade text-sm">
                              <span className="m-r">{user.email}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <span className="flex"></span>
                    </div>
                  </ProfileHeader>
                </div>

                <div className="p-3">
                  <div className="d-flex">
                    <ul className="nav nav-pills">
                      <li className="nav-item">
                        <a className="nav-link active" href="#" data-toggle="tab" data-target="#profile-overview">Översikt</a>
                      </li>

                      <li className="nav-item">
                        <a className="nav-link" href="#" data-toggle="tab" data-target="#profile-activity">Aktivitet</a>
                      </li>

                      <li className="nav-item">
                        <a className="nav-link" href="#" data-toggle="tab" data-target="#profile-info">Profil</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-7 col-lg-8">
                  <div className="tab-content">
                    <div className="tab-pane fade show active" id="profile-overview">
                      <ProfileOverview userId={user.id}/>
                    </div>

                    <div className="tab-pane fade" id="profile-activity">
                      <ProfileUserHistory userId={user.id}/>
                    </div>

                    <div className="tab-pane fade" id="profile-info">
                      <ProfileInfo userId={user.id}/>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-5 col-lg-4">
                  <ProfileUserList/>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Profile;

const ProfileHeader = styled.div`
  background-color: rgba(251, 38, 64, 0.8);
  color: #FFFFFF;
  border-radius: 6px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`