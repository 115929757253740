import styled from 'styled-components';

const Clickable = styled.div`
  &:hover {
    cursor: pointer;
  }
`

export {
  Clickable
};