import React from 'react'
import TeamContainer from '../../containers/bonti/Team.container'

const TeamPage = (props) => {
  const teamName = props.match.params.team

  return (
    <TeamContainer teamName={teamName} />
  )
}

export default TeamPage
