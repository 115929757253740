import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Search, Plus, Menu } from 'react-feather'
import { CompanyApi } from '../../api/Company'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, Input } from 'reactstrap'
import { history, setDocumentTitle } from '../../helpers'

export { CompanyList }

const CompanyList = () => {
  const [companies, setCompanies] = useState([])
  const [searchPhrase, setSearchPhrase] = useState('')
  const [lastActivities, setLastActivities] = useState([])

  const searchFilter = (event) => {
    let name = event.name.toLowerCase()
    return name.indexOf(searchPhrase.toLowerCase()) !== -1
  }

  const newEvent = () => {
    history.push('/companies/add')
  }

  useEffect(() => {
    setDocumentTitle('Företag')

    CompanyApi.findAll().then((companies) => {
      setCompanies(companies ?? [])
    })

    CompanyApi.getLastActivity().then((lastActivities) => {
      setLastActivities(lastActivities ?? [])
    })
  }, [])

  return (
    <Container>
      <div className="mx-3">
        <h2 className="text-md text-highlight">Företag</h2>
      </div>

      <div className="p-3">
        <div className="toolbar">
          <form className="flex">
            <div className="input-group">
              <Input
                type="text"
                className="form-control form-control-theme form-control-sm search"
                placeholder="Sök"
                onChange={(e) => setSearchPhrase(e.target.value)}
              />

              <span className="input-group-append">
                <button
                  className="btn btn-white no-border btn-sm"
                  type="button"
                >
                  <span className="d-flex text-muted">
                    <Search size={16} />
                  </span>
                </button>
              </span>
            </div>
          </form>

          <ButtonGroup>
            <Button
              className="no-border btn-sm"
              color="white"
              title="Ny aktivitet"
              onClick={newEvent}
            >
              <Plus size={16} className="mr-1" />
              Lägg till
            </Button>
          </ButtonGroup>

          <button
            className="btn btn-sm btn-icon btn-white d-md-none"
            data-toggle="modal"
            data-target="#content-aside"
            data-modal
          >
            <Menu size={16} />
          </button>
        </div>
      </div>

      <div className="mx-3 card">
        <div className="list list-row">
          {companies.filter(searchFilter).map((company, index) => {
            return (
              <div className="list-item" key={index}>
                <div>
                  <Link to={{ pathname: `/companies/${company.id}` }}>
                    <span
                      className="w-40 avatar gd-warning"
                      title={company.name}
                    >
                      {company.name.substring(0, 1).toUpperCase()}
                    </span>
                  </Link>
                </div>

                <div className="flex">
                  <Link
                    to={{ pathname: `/companies/${company.id}` }}
                    className="item-author text-primary"
                  >
                    {company.name}
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="mx-3">
        <h2 className="text-md text-highlight">Senaste aktivitet</h2>
      </div>

      <div className="mx-3 card">
        <div className="list list-row">
          {lastActivities.filter(searchFilter).map((lastActivity, index) => {
            return (
              <div className="list-item" key={index}>
                <div className="flex">
                  {lastActivity.name} {lastActivity.logDate}{' '}
                  {lastActivity.email}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`
