import {EventAttributesApi} from "../../api/EventAttribute";
import attributeList, {DisplayOrderButton} from "../Event/Attribute/AttributeList";
import React, {useState} from "react";
import {CheckCircle, ChevronDown, ChevronUp, Edit, Trash, XCircle} from "react-feather";
import classNames from "classnames";
import {Scrollbar} from "../Shared/Scrollbar/Scrollbar.component";
import styled from "styled-components";
import {Wrapper} from "./Dashboard.style";
import {useDispatch, useSelector} from "react-redux";
import {setSortOrder} from "../../actions/dashboard.actions";

const SortableComponent = ({ component}) => {
    const dispatch = useDispatch()
    const selectedComponents = useSelector(state => state.dashboard.selectedComponents)

    const sortUp = () => {
        if (component.sortOrder === 0) {
            return
        }

        dispatch(setSortOrder(component.value, component.sortOrder -1))
    }

    const sortDown = () => {

        if (component.sortOrder >= selectedComponents.length){
            return
        }
        dispatch(setSortOrder(component.value, component.sortOrder +1))
    }


    return (

        <div className="d-flex flex-column">
            <button className="btn" onClick={sortUp}><ChevronUp/></button>
            <button className="btn" onClick={sortDown}> <ChevronDown/> </button>
        </div>
    )
}

export default SortableComponent

const AttributeOrder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  width: 3.5rem;
`
const AttributeCard = styled.div`
  display: flex;
  background: #f8f8f8;
  
  &.delete-item {
    background-color: #FB2640;
    color: #FFFFFF;

    .text-muted {
      color: #FFFFFF !important;
    }
  }
`