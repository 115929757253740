import {
  FIND_BY_USER_ID_AND_PERIOD,
  FIND_ORDER_HISTORY_BY_USER_ID_SUCCESS,
  FIND_ORDER_HISTORY_BY_USER_ID_ERROR,
  SET_ORDER_HISTORY_LIST
} from "../types/orderHistory.types";

import {orderHistoryService} from '../services/orderhistory.service';

export function findByUserIdAndPeriod(userId, from, to) {
  const request = () => ({ type: FIND_BY_USER_ID_AND_PERIOD });
  const success = (orderHistoryList) => ({ type: FIND_ORDER_HISTORY_BY_USER_ID_SUCCESS, orderHistoryList });
  const error = (error) => ({ type: FIND_ORDER_HISTORY_BY_USER_ID_ERROR, error });

  return (dispatch) => {
    dispatch(request());

    orderHistoryService.findByUserAndPeriod(userId, from, to)
      .then(
        async (orderHistoryList) => {
          dispatch(success(orderHistoryList));
        },
        async (err) => {
          dispatch(error(err.toString()));
        }
      );
  };
}

export function setOrderHistoryList(orderHistoryList) {
  return (dispatch) => dispatch({ type: SET_ORDER_HISTORY_LIST, orderHistoryList });
}