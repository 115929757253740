import {Col, Progress, Row, Collapse} from 'reactstrap'
import {Strong} from '../Shared/Style'
import React, {useEffect, useState} from 'react'
import {DashboardCard, ProgressValue, ProgressWrapper, ResultValue} from './Dashboard.style'
import CountUp from 'react-countup'
import {FlexRow} from '../../pages/pages.style'
import {Column} from '../Shared/Grid/Grid.style'
import {CardBody} from '../../components/Shared/Card'
import {ChevronDown, ChevronUp} from "react-feather";
import styled from 'styled-components';
import {Scrollbars} from "react-custom-scrollbars-2";
import DashboardCollapse from "./DashboardCollapse.component";
import {Wrapper} from './Dashboard.style'

const CampaignTotal = ({campaignTotal, name, component, isEditing}) => {

  const [isCollapsed, setCollapsed] = useState(true)
  const toggle = () => setCollapsed(!isCollapsed);

  const getTotalPercentage = () => {
    let percentage = 0

    const goal = campaignTotal.goal.total

    if (goal > 0) {
      percentage = Math.floor((campaignTotal.total / goal * 100))
    }

    return percentage < 100 ? percentage : 100
  }

  return (

     <Wrapper>   
      <DashboardCollapse component={component}>
            <Scrollbars style={{height: 540, scrollWidth: 5}}>
              <div className="cards px-4">
                <div className='d-flex justify-content-between w-100'>
                  <div className="align-self-center"></div>
                </div>
                  <Row>
                    <Col lg={9}>
                      {/*<Strong className="mb-2">Resultat</Strong>*/}
                      {campaignTotal &&
                        <Row>
                          <Column lg={4}>
                            <ResultValue>
                              <CountUp start={0} end={campaignTotal.year} duration={2} decimal="0"
                                      separator={' '}/> kr
                            </ResultValue>
                            <div>År</div>
                          </Column>

                          <Column lg={4}>
                            <ResultValue>
                              <CountUp start={0} end={campaignTotal.month} duration={2}
                                      decimal="0" separator={' '}/> kr
                            </ResultValue>
                            <div>Månad</div>
                          </Column>

                          <Column lg={4}>
                            <ResultValue>
                              <CountUp start={0} end={campaignTotal.week} duration={2} decimal="0"
                                      separator={' '}/> kr
                            </ResultValue>
                            <div>Vecka</div>
                          </Column>
                        </Row>
                      }
                    </Col>

                    <Col lg={3}>
                      <Strong className="mb-2">Budget</Strong>
                      {campaignTotal &&
                        <ProgressWrapper>
                          <div className="text-muted mb-1">
                            <CountUp start={campaignTotal.goal.total} end={campaignTotal.goal.total}
                                    duration={2} decimal="0" separator={' '}/>
                          </div>

                          <FlexRow>
                            <Progress
                              value={getTotalPercentage()}
                              color="success"
                              title={getTotalPercentage() + '%'}
                              style={{width: '100%', alignSelf: 'center'}}>
                            </Progress>
                            <ProgressValue>{getTotalPercentage()}%</ProgressValue>
                          </FlexRow>
                        </ProgressWrapper>
                      }
                    </Col>
                  </Row>
              </div>
            </Scrollbars>

      </DashboardCollapse>
    </Wrapper>
  )
}

export default CampaignTotal

const CollapseButton = styled.button`
    padding: 0 !important;
    &:hover {
        background: transparent !important;
        border: 0;
        box-shadow: none;
    }
`