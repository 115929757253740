import client from '../../helpers/client'
import axios from 'axios'

const baseUrl = '/orders'

function findOrders(campaignId, from, to) {
  let url = `${baseUrl}?filter=campaign&id=${campaignId}`

  if (parseInt(campaignId) === 0) {
    url = `${baseUrl}?filter=all`
  }

  url += `&from=${from}&to=${to}`

  return client.getClient()
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : [])
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText
        return Promise.reject(errorMessage)
      }

      return Promise.resolve([])
    })
}

function findOrderCount(activeTab, campaignId, from, to) {
  let url = `${baseUrl}/count?filter=campaign&id=${campaignId}`

  if(parseInt(campaignId) === 0) {
    url = `${baseUrl}/count?filter=all`
  }

  if (activeTab === "user") {
    if (parseInt(campaignId) !== 0) {
      url = `${baseUrl}/count?filter=both&id=${campaignId}`
    } else {
      url = `${baseUrl}/count?filter=user`
    }
  }

  url += `&from=${from}&to=${to}`

  return client.getClient()
      .get(url)
      .then(response => {
        return Promise.resolve(response.data ? response.data : [])
      })
      .catch(err => {
        if (err.response) {
          let errorMessage = (err.response.data) || err.response.statusText
          return Promise.reject(errorMessage)
        }

        return Promise.resolve([])
      })
}

function findPagedOrders(activeTab, campaignId, from, to, offset, limit) {
  let url = `${baseUrl}?filter=campaign&id=${campaignId}`

  if (parseInt(campaignId) === 0) {
    url = `${baseUrl}?filter=all`
  }

  if (activeTab === "user") {
    if (parseInt(campaignId) !== 0) {
      url = `${baseUrl}?filter=both&id=${campaignId}`
    } else {
      url = `${baseUrl}?filter=user`
    }
  }


  url += `&from=${from}&to=${to}`

  url += `&offset=${offset}&limit=${limit}`

  return client.getClient()
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : [])
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText
        return Promise.reject(errorMessage)
      }

      return Promise.resolve([])
    })
}

function get(orderId) {
  const url = baseUrl + '/' + orderId

  return client.getClient()
    .get(url)
    .then(response => {
      return Promise.resolve(response.data)
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText
        return Promise.reject(errorMessage)
      }
    })
}

function getCustomers(companyId) {
  const url = "/rscount/" + companyId

  const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  return http
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data.totalCustomers : 0)
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = err.response.data || err.response.statusText

        return Promise.reject(errorMessage)
      }
    })
}

function update (orderId, order) {
  const url = baseUrl + '/' + orderId

  return client.getClient()
    .put(url, order)
    .then(response => {
      return Promise.resolve(response.data)
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText
        return Promise.reject(errorMessage)
      }
    })
}

function deleteOrder(orderId) {
  const url = baseUrl + '/' + orderId

  return client.getClient()
    .delete(url)
    .then(response => {
      return Promise.resolve(response.data)
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText
        return Promise.reject(errorMessage)
      }
    })
}

export const OrderApi = {
  get,
  update,
  delete: deleteOrder,
  findOrders,
  findOrderCount,
  findPagedOrders,
  getCustomers
}