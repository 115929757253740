import React, {useEffect, useState} from 'react';
import CountUp from 'react-countup';
import {Progress} from 'reactstrap';
import styled from 'styled-components';
import ScoreUtil from '../ScoreUtil';

const TeamTotalScore = (props) => {

  let {
    teamMembers,
    isGoalObtainable
  } = props;

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    setPercentage(ScoreUtil.getPercentageFromTeamMembers(teamMembers));
  }, [teamMembers]);

  return (
    <div className="card flex">
      <div className="card-body text-center">
        <small className="text-muted">Ditt lags resultat</small>

        <div className="pt-2">
          <div className="pos-rlt" style={{height: '20px'}}>
            <div className="pos-abt w-100 h-100 d-flex align-items-center justify-content-center">
              <ProgressWrapper style={{width: "100%"}}>
                <Progress
                  value={percentage}
                  color={isGoalObtainable ? 'success' : 'danger'}
                  title={percentage + '%'}>
                  {percentage >= 15 &&
                  <ProgressValue title={percentage}>
                    <CountUp end={percentage} duration={"2"}/>%
                  </ProgressValue>
                  }
                </Progress>
              </ProgressWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamTotalScore;

const ProgressWrapper = styled.div`
  margin-top: 0.5rem;
  
  .progress-bar {
    font-size: 0.65625rem;
    transition: width 2s ease;
  }
`;

const ProgressValue = styled.div``;