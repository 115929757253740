import React, {useEffect, useState} from 'react';
import './Pagination.style.scss';
import classNames from "classnames";
import {waitFor} from "@testing-library/react";
import {ArrowLeft, ArrowRight, ChevronLeft, ChevronRight} from "react-feather";

export {
  Pagination,
  Paginate
}

const Pagination = (props) => {
  const [currentPage, setCurrentPage] = useState([]);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [pageCount, setPageCount] = useState()

  const calculatePageNumbers = async () => {

    let pageLimit;
    let pageNumbers = [];
    let pageCount;

    if (props.count !== undefined) {
      pageCount = Math.ceil(props.count / props.itemsPerPage);

      if(pageCount < props.pageLimit) {
        pageLimit = pageCount
      } else {
        pageLimit = props.pageLimit
      }

      const start = Math.floor((props.currentPage - 1) / pageLimit) * pageLimit;

      for (let i = 0; i < pageLimit; i++) {
          pageNumbers.push(start + i + 1)
        }

    } else {
      pageCount = Math.ceil(props.items.length / props.itemsPerPage);
      for (let i = 1; i <= Math.ceil(props.items.length / props.itemsPerPage); i++) {
        pageNumbers.push(i);
      }

    }

    setPageCount(pageCount)
    setPageNumbers(pageNumbers);
  }

  useEffect(() => {
    setCurrentPage(props.currentPage)
    calculatePageNumbers();
  }, []);

  useEffect(() => setCurrentPage(props.currentPage), [props.currentPage]);
  useEffect(() => calculatePageNumbers(), [props.items, props.count]);

  const handlePageClick = (e, page) => {
    e.preventDefault();
    if (typeof props.onChange === 'function') {
      props.onChange(page)
    }
  }

  if (pageNumbers.length === 1) {
    return <React.Fragment/>
  }

  const previousClasses = classNames({
    'page-item': true,
    'disabled': currentPage <= 1
  });

  const nextClasses = classNames({
    'page-item': true,
    'disabled': currentPage === pageCount
  });

  const mainClass = classNames({
    'pagination': true,
    'pagination-xs': (props.size === 'xs'),
    'pagination-sm': (props.size === 'sm'),
    'pagination-md': (props.size === 'md'),
    'pagination-lg': (props.size === 'lg'),
  });

  return (
    <ul className={mainClass}>

      { pageCount > props.pageLimit &&
        <li className={previousClasses}>
          <a className="page-link" href="#" onClick={(e) => handlePageClick(e, 1)}>
            <ChevronLeft size={16}/>
            <span className="sr-only">First</span>
          </a>
        </li>
      }


      <li className={previousClasses}>
        <a className="page-link" href="#" onClick={(e) => handlePageClick(e, currentPage - 1)}>
          <ArrowLeft size={16}/>
          <span className="sr-only">Previous</span>
        </a>
      </li>
      {pageNumbers
          .map((page, i) => (
              page <= pageCount &&
          <li className={`page-item${page === currentPage ? ' active' : ''}`} key={i}>
            <a className="page-link" href="#" onClick={(e) => handlePageClick(e, page)}>
              {page} <span className="sr-only">(current)</span>
            </a>
          </li>
      ))}


      <li className={nextClasses}>
        <a className="page-link" href="#" onClick={(e) => handlePageClick(e, currentPage + 1)}>
          <ArrowRight size={16}/>
          <span className="sr-only">Next</span>
        </a>
      </li>
      {pageCount > props.pageLimit &&
          <li className={nextClasses}>
            <a className="page-link" href="#" onClick={(e) => handlePageClick(e, pageCount)}>
              <ChevronRight size={16}/>
              <span className="sr-only">Last</span>
            </a>
          </li>
      }
    </ul>
  );
};

const Paginate = (items, itemsPerPage, currentPage) => {
  const pagedItems = [];

  for (let i = (currentPage - 1) * itemsPerPage; i < (currentPage * itemsPerPage) && i < items.length; i++) {
    pagedItems.push(items[i])
  }

  return pagedItems;
}