import client from '../../helpers/client'

const baseUrl = '/events'

function findAll() {
  return client.getClient()
    .get(baseUrl)
    .then(response => {
      return Promise.resolve(response.data ? response.data : [])
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText
        return Promise.reject(errorMessage)
      }

      return Promise.resolve([])
    })
}

function get(eventId) {
  let url = baseUrl + `/${eventId}`

  return client.getClient()
    .get(url)
    .then(response => {
      return Promise.resolve(response.data)
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText
        return Promise.reject(errorMessage)
      }
    })
}

function create(data = {}) {
  const request = client.getClient()

  return request.post(baseUrl, data)
    .then(response => {
      return Promise.resolve(response.data)
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText
        return Promise.reject(errorMessage)
      }
    })
}

function update(id, data = {}) {
  const request = client.getClient()

  return request.put(baseUrl + '/' + id, data)
    .then(response => {
      return Promise.resolve(response.data)
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText
        return Promise.reject(errorMessage)
      }
    })
}

function deleteEvent(id) {
  let url = baseUrl + '/' + id

  return client.getClient().delete(url)
    .then(response => {
      return Promise.resolve(response.data)
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText
        return Promise.reject(errorMessage)
      }
    })
}

export const EventApi = {
  findAll,
  get,
  create,
  update,
  delete: deleteEvent
}