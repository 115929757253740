import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Card, CardBody } from '../Shared/Card';
import DefaultCampaignApi from '../../api/Campaign/DefaultCampaign.api';
import styled from 'styled-components';

const CompanyDefaultCampaign = ({ companyId, submitCallback }) => {
  const [isUpdating, setUpdating] = useState(false);

  const setupCampaigns = () => {
    setUpdating(true);

    let data = {
      companyId: companyId,
    };

    DefaultCampaignApi.create(data)
      .then(() => {
        setUpdating(false);

        if (submitCallback) {
          submitCallback();
        }
      })
      .catch(() => {
        setUpdating(false);

        if (submitCallback) {
          submitCallback();
        }
      });
  };

  return (
    <Container>
      <Card>
        <CardBody>
          <ButtonContainer>
            <Button type="submit" color="primary" onClick={setupCampaigns}>
              {isUpdating && (
                <React.Fragment>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp; Sparar
                </React.Fragment>
              )}

              {!isUpdating && 'Skapa kampanjer'}
            </Button>
          </ButtonContainer>
        </CardBody>
      </Card>
    </Container>
  );
};

export default withRouter(CompanyDefaultCampaign);

export const Container = styled.div`
  flex: 1 1 auto;
  @media (min-width: 992px) {
    max-width: 1140px;
    margin: 0 auto;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > :not(:first-child) {
    margin-left: 0.25rem;
  }

  > :not(:last-child) {
    margin-right: 0.25rem;
  }
`;
