import styled from 'styled-components';

export const TeamLabel = styled.div`
  font-size: 0.825rem;
  color: #99a0ac;
  padding-left: 1rem;
  padding-right: 1rem;
`

export const TeamName = styled.div`
  color: #192039;
  font-size: 1.09375rem;
  padding-left: 1rem;
  padding-right: 1rem;
`

export const UserListItem = styled.div`
  flex: 1 1 auto;
  font-size: 0.875rem;
  
  img {
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
  }
`;

export const ProgressWrapper = styled.div`
  margin-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  
  .progress-bar {
    font-size: 0.65625rem;
    transition: width 2s ease;
  }
`;

export const ProgressValue = styled.div``;