import styled from 'styled-components'

export const Sidebar = styled.div`
  background-color: #FFFFFF;
  
  &.folded ul li {
    > a {
      margin: 0 !important;
    }
  
    &.active > a {
      background-color: transparent !important;
      .nav-icon {
        background: #fb2640 !important;
      }
    }
  }
  
  ul li {
    &.active:hover .nav-icon {
      opacity: 1 !important;
    }
    
    &.active > a {
      background: #fb2640 !important;
      border-radius: 2rem !important;
      color: #fff !important;
      font-weight: 600;
    }
    
    a {
      margin: 0 1rem;
      font-weight: 400;
    }
    
    &.active .nav-icon svg {
      height: 16px !important;
      width: 16px !important;
    }
    
    &:hover .nav-icon {
      color: $blue !important;
      opacity: 0.65 !important;
      
      svg {
        height: 19px;
        width: 19px;
      }
    }
    
    // Sub menu
    ul.nav li {
      &.active {
        > a {
          background: transparent !important;
          color: #fb2640 !important;
          font-weight: bold;
        }
      }
    }
  }
`

export const NavHeader = styled.div`
  padding: 1rem 2rem 0.5rem 2rem;
  font-size: 85%;
`

export const NavText = styled.span`
  margin: 0 .5rem;
  line-height: 1.125rem;
  transition: opacity: .15s ease-in-out;
  padding: 0.6875rem 0;
  position: relative;
  
  &:after {
    border-bottom: 3px solid #fb2640;
    position: absolute;
    left: 0;
    bottom: 5px;
    content: '';
    width: 0px; 
    transition: all 700ms ease-in-out;
    opacity: 1;
  }
  
  &:hover:after {
    width: 100%;
  }
`