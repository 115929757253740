import {
  FIND_ORDER_HISTORY_BY_USER_ID_ERROR,
  FIND_ORDER_HISTORY_BY_USER_ID_SUCCESS,
  SET_ORDER_HISTORY_LIST,
} from '../types/orderHistory.types';

const initialState = { orderHistoryList: [] };

const orderHistoryListReducer = (state = initialState, action) => {

  switch (action.type) {

    case FIND_ORDER_HISTORY_BY_USER_ID_SUCCESS:
      state.orderHistoryList = action.orderHistoryList;
      return state;
      /*return {
        orderHistoryList: action.orderHistoryList
      };*/

    case SET_ORDER_HISTORY_LIST: {
      state.orderHistoryList = action.orderHistoryList;
      return state;
    }

    case FIND_ORDER_HISTORY_BY_USER_ID_ERROR: {
      state.orderHistoryList = [];
      return state;
    }

    default:
      return state;
  }
};

export default orderHistoryListReducer;