import {
  ALERT_SUCCESS,
  ALERT_ERROR,
  ALERT_CLEAR
} from "../types/alert.types";

export function alertSuccess(message) {
  return { type: ALERT_SUCCESS, message };
}

export function alertError(message) {
  return { type: ALERT_ERROR, message };
}

export function clearAlerts() {
  return { type: ALERT_CLEAR };
}