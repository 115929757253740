import client from '../../helpers/client';

const baseUrl = '/campaigns';

export function update(campaignId, data = {}) {
  const url = `${baseUrl}/${campaignId}/teams/${data.id}`;

  return client.getClient()
    .put(url, data)
    .then(response => Promise.resolve(response.data))
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

export const CampaignTeamApi = {
  update,
}