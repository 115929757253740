import React, {Fragment, useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ArrowRight} from 'react-feather';
import {Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';

import {setCampaignList, setSelectedCampaign} from '../actions/campaign.actions';
import {campaignService} from '../services/campaign.service';

import ShoutoutMessage from '../components/Shoutout/ShoutoutMessage.component';
import ActivityList from '../components/Dashboard/ActivityList.component';
import UserScore from '../components/Score/User/UserScore.component';
import TeamScore from '../components/Score/Team/TeamScore.component';
import UserTopscore from '../components/Score/User/UserTopScore.component';
import TeamTopScore from '../components/Score/Team/TeamTopScore.component';
import {Container, Wrapper, Flex, FlexWrapper, CampaignButton, ContentWrapper} from './pages.style';
import {setDocumentTitle} from '../helpers';

import {SelectedCampaignApi} from '../api/Campaign/SelectedCampaign.api';

const Dashboard = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.authentication.user);
  const campaigns = useSelector(state => state.campaign.campaignList);
  const selectedCampaign = useSelector(state => state.campaign.selectedCampaign);
  // Wait for scores to load before displaying shoutout
  const campaignScore = useSelector(state => state.score.score);

  const [companyName, setCompanyName] = useState('');
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selectedCampaignOption, setSelectedCampaignOption] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = (e) => {
    e.preventDefault();

    if (isModalOpen) {
      setModalOpen(false);
    }
    else {
      setModalOpen(true);
    }
  }

  const onChangeCampaign = (campaign) => {
    setSelectedCampaignOption(campaign);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let campaign = campaigns.find(c => c.id === selectedCampaignOption.value);

    if (campaign && selectedCampaign) {
      SelectedCampaignApi.update(selectedCampaign.selectedCampaignId, {campaignId: campaign.id})
        .then(() => {
          SelectedCampaignApi.get()
            .then(selectedCampaign => dispatch(setSelectedCampaign({...campaign, selectedCampaignId: selectedCampaign.id})));
        });
    }

    setModalOpen(false);
  }

  useEffect(() => {
    if (currentUser.companyName) {
      setCompanyName(currentUser.companyName);
    }

    campaignService.findByTeamMember(currentUser, 'active')
      .then(campaigns => {
        campaigns = campaigns
          .filter(campaign => moment(campaign.startDate) <= moment());
        
        dispatch(setCampaignList(campaigns));

        setCampaignOptions(
          campaigns.map(campaign => ({
            value: campaign.id,
            label: campaign.name
          }))
        );
      }).catch((err) => {
        console.error(err)
        dispatch(setCampaignList([]));
      });
  }, [])

  useEffect(() => {
    if (campaigns?.length === 0) {
      dispatch(setSelectedCampaign(null));
    }

    if (campaigns && campaigns.length > 0) {
      SelectedCampaignApi.get()
        .then(selectedCampaign => {
          if (selectedCampaign?.campaignId) {
            let campaign = campaigns.find(campaign => campaign.id === selectedCampaign.campaignId &&
                campaign.active === 'Active' &&
                moment(campaign.startDate) <= moment()
            );

            if (campaign) {
              dispatch(setSelectedCampaign({...campaign, selectedCampaignId: selectedCampaign.id}));
              setDocumentTitle(campaign.name);
            } else {
              // If selected campaign is not active, use latest active campaign.
              let latestCampaign = campaigns.find(campaign => campaign.active === 'Active' && moment(campaign.startDate) <= moment());

              if (latestCampaign) {
                setDocumentTitle(latestCampaign.name);

                SelectedCampaignApi.update(selectedCampaign.id, {campaignId: latestCampaign.id})
                  .then(() => {
                    SelectedCampaignApi.get()
                      .then(selectedCampaign => {
                          if (selectedCampaign) {
                            dispatch(setSelectedCampaign({...latestCampaign, selectedCampaignId: selectedCampaign.id}));
                          }
                      });
                  });
              }
            }
          }
          else {
            let latestCampaign = campaigns.find(campaign => campaign.active === 'Active' && moment(campaign.startDate) <= moment());

            if (latestCampaign) {
              setDocumentTitle(latestCampaign.name);

              SelectedCampaignApi.create({campaignId: latestCampaign.id})
                .then(selectedCampaign => {
                  if (selectedCampaign) {
                    dispatch(setSelectedCampaign({...latestCampaign, selectedCampaignId: selectedCampaign.id}));
                  }
                })
                .catch(err => console.error(err));
            }
          }
        });
    }
  }, [campaigns])

  useEffect(() => {
    if (campaignOptions && campaignOptions.length > 0 && selectedCampaign) {
      setSelectedCampaignOption(campaignOptions.find(option => option.value === selectedCampaign.id));
    }
  }, [campaignOptions, selectedCampaign])

  return (
    <Container>
      <FlexWrapper>
        <div>
          <h2 className="text-md text-highlight">
            {companyName}
            {companyName && selectedCampaign && (' - ') }
            {selectedCampaign && selectedCampaign.name}
          </h2>

          {selectedCampaign &&
            <div><small>{selectedCampaign.startDate} - {selectedCampaign.endDate}</small><br /><br /></div>
          }

          <small className="text-muted">
            Välkommen till Salestrigger <Link to={{pathname: `/profile/${currentUser.id}`}}><strong className="text-primary">{currentUser.displayName}</strong></Link>!
            <br/>Uppe till höger hittar du inställningar både för att förändra utseende och ditt konto.
          </small>
        </div>

        <Flex/>

        <div>
          <CampaignButton onClick={toggleModal}>
            <span className="d-none d-sm-inline mx-1">
              {selectedCampaign ? 'Byt kampanj' : 'Välj kampanj'}
            </span>
            <ArrowRight size={16}/>
          </CampaignButton>
        </div>
      </FlexWrapper>

      <Wrapper className="pt-0">
        <div className="d-md-flex" style={{ justifyContent: 'space-between'}}>
          <ContentWrapper>
            <div className="row">
              {campaignScore && <ShoutoutMessage />}

              <div className="col-12">
                { selectedCampaign &&
                  <div className="pb-3">
                    <div className="text-muted text-sm sr-item">Ditt personliga resultat</div>
                    <h5 className="text-highlight sr-item">{currentUser.displayName}</h5>
                  </div>
                }

                { !selectedCampaign &&
                  <div className="pb-3">
                    <h5>Ingen kampanj vald</h5>
                  </div>
                }
              </div>
            </div>

            { selectedCampaign &&
              <Fragment>
                <div className="row">
                  <UserScore/>
                  <TeamScore/>
                  <UserTopscore/>
                  <TeamTopScore/>
                </div>
              </Fragment>
            }
          </ContentWrapper>

          {selectedCampaign && <ActivityList/>}
        </div>
      </Wrapper>

      <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>
           Välj kampanj
          </ModalHeader>

          <ModalBody>
            <FormGroup>
              <Label>Kampanj</Label>

              <Select
                defaultValue={selectedCampaignOption}
                options={campaignOptions}
                onChange={onChangeCampaign}
              />
            </FormGroup>

          </ModalBody>

          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Avbryt</Button>
            <Button type="submit" color="primary">Spara</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Container>
  );
}

export default Dashboard;