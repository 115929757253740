import React from 'react'
import {Wrapper} from './Dashboard.style'
import {FlexRow} from '../../pages/pages.style'
import CountUp from 'react-countup'
import {BestDailyOrder} from './Dashboard.style.js'

const BestDailyOrders = ({ bestDailyOrder }) => {
    return (
        <Wrapper>
          {bestDailyOrder ?
            <FlexRow>
              <img alt="" src="https://salestrigger.s3-eu-west-1.amazonaws.com/default/profile64x64" width={48} height={48} style={{flexGrow: 0, flexShrink: 0}}/>

              <BestDailyOrder>
                <div>
                  <CountUp end={bestDailyOrder.value ? bestDailyOrder.value : 0} duration={2} decimal="0" separator={' '}/> kr
                  av {bestDailyOrder.userName}
                  <div className="text-muted">{bestDailyOrder.teamName}</div>
                </div>
              </BestDailyOrder>
            </FlexRow>
            : <div>Ingen affär idag</div>
          }
        </Wrapper>
    )
}

export default BestDailyOrders