import client from "../helpers/client";

export const teamService = {
  findAll,
  get,
  create,
  update,
  delete: deleteTeam
};

const baseUrl = '/teams';

function findAll() {
  return client.getClient()
    .get(baseUrl)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });
}

function get(teamId) {
  const url = `${baseUrl}/${teamId}`;

  return client.getClient()
      .get(url)
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(err => {
        if (err.response) {
          let errorMessage = (err.response.data) || err.response.statusText;
          return Promise.reject(errorMessage);
        }
      });
}

function create(data = {}) {
  return client.getClient()
    .post(baseUrl, data)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

function update(teamId, data = {}) {
  const url = `${baseUrl}/${teamId}`;

  return client.getClient()
    .put(url, data)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

function deleteTeam(teamId) {
  const url = `${baseUrl}/${teamId}`;

  return client.getClient()
    .delete(url)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}