import React from 'react';
import CountUp from "react-countup";

export {
  UserStatistics
}

const UserStatistics = ({statistics}) => {
  return (
    <div className="card">
      <div className="card-header">
        <strong>Individuell Statistik</strong>
      </div>

      <div className="px-4 py-4">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-4 mb-2 mb-md-0 text-center">
                  <span className="avatar no-shadow" style={{textAlign: 'center', align: 'center'}}>
                    <img src="/assets/img/trophy/T1.png" alt="." style={{width: '42px', height: '42px'}} />
                  </span>

                <small className="text-muted mb-2">Förstaplats</small>

                <div className="first-place">
                  <CountUp
                    end={statistics && statistics.nrPersonalPlacementFirst > 0 ? statistics.nrPersonalPlacementFirst : 0}
                    duration="2"
                    decimal="0"
                    separator={' '}
                  />
                  st
                </div>
              </div>

              <div className="col-12 col-sm-4 mb-2 mb-md-0 text-center">
                  <span className="avatar no-shadow" style={{textAlign: 'center', width: '100%'}}>
                    <img src="/assets/img/trophy/T12.png" alt="." style={{width: '42px', height: '42px'}} />
                  </span>

                <small className="text-muted m-b">Andraplats</small>

                <div className="second-place">
                  <CountUp
                    end={statistics && statistics.nrPersonalPlacementSecond > 0 ? statistics.nrPersonalPlacementSecond : 0}
                    duration="2"
                    decimal="0"
                    separator={' '}
                  />
                  st
                </div>
              </div>

              <div className="col-12 col-sm-4 text-center">
                  <span className="avatar no-shadow" style={{textAlign: 'center', align: 'center'}}>
                    <img src="/assets/img/trophy/T4.png" alt="." style={{width: '42px', height: '42px'}} />
                  </span>

                <small className="text-muted m-b">Tredjeplats</small>

                <div className="third-place">
                  <CountUp end={statistics && statistics.nrPersonalPlacementThird > 0 ? statistics.nrPersonalPlacementThird : 0}
                           duration="2"
                           decimal="0"
                           separator={' '}
                  />
                  st
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}