import client from '../helpers/client';

const baseUrl = '/campaigns';

function findAll(status) {
  status = (status !== null ? '?status=' + status : '');
  return client.getClient()
    .get(baseUrl + status)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });
}

function findCompanyCampaignsTopList(company_id, status) {
  company_id = '?company_id=' + company_id;
  status = (status !== null ? '&status=' + status : '');

  return client.getClient()
      .get(baseUrl + '/companycampaigns/toplist' + company_id + status)
      .then(response => {
        return Promise.resolve(response.data ? response.data : []);
      })
      .catch(err => {
        if (err.response) {
          let errorMessage = (err.response.data) || err.response.statusText;
          return Promise.reject(errorMessage);
        }

        return Promise.resolve([]);
      });
}

function findCompanyCampaignsTeamTopList(company_id, status) {
  company_id = '?company_id=' + company_id;
  status = (status !== null ? '&status=' + status : '');

  return client.getClient()
      .get(baseUrl + '/companycampaigns/teamtoplist' + company_id + status)
      .then(response => {
        return Promise.resolve(response.data ? response.data : []);
      })
      .catch(err => {
        if (err.response) {
          let errorMessage = (err.response.data) || err.response.statusText;
          return Promise.reject(errorMessage);
        }

        return Promise.resolve([]);
      });
}
function findCompanyCampaigns(company_id, status) {
  company_id = '?company_id=' + company_id;
  status = (status !== null ? '&status=' + status : '');

  return client.getClient()
      .get(baseUrl + '/companycampaigns' + company_id + status)
      .then(response => {
        return Promise.resolve(response.data ? response.data : []);
      })
      .catch(err => {
        if (err.response) {
          let errorMessage = (err.response.data) || err.response.statusText;
          return Promise.reject(errorMessage);
        }

        return Promise.resolve([]);
      });
}

function findByTeamMember(user, status = null) {
  status = (status !== null ? '?status=' + status : '');

  return client.getClient()
    .get(baseUrl + status)
    .then(response => {
        return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });
}

function get(campaignId) {
  return client.getClient()
    .get(baseUrl + `/${campaignId}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject({
          message: errorMessage,
          status: err.response.status
        });
      }
    });
}

function create(data = {}) {
  return client.getClient()
    .post(baseUrl, data)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

function update(campaignId, data = {}) {
  let url = baseUrl + '/' + campaignId;

  return client.getClient()
    .put(url, data)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

function deleteCampaign(campaignId) {
  let url = baseUrl + '/' + campaignId;

  return client.getClient()
    .delete(url)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

function findTeams(campaignId) {
  let url = baseUrl + `/${campaignId}/teams`;

  return client.getClient()
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });
}

function findEvents(campaignId) {
  let url = baseUrl + `/${campaignId}/events`;

  return client.getClient()
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });
}

function findOrders(campaignId) {
  let url = baseUrl + `/${campaignId}/orders`;

  return client.getClient()
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });
}

function createOrder(campaignId, data = {}) {
  let url = baseUrl + `/${campaignId}/orders`;

  return client.getClient()
    .post(url, data)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }
    });
}

function findScores(campaignId) {
  let url = baseUrl + `/${campaignId}/scores`;

  return client.getClient()
    .get(url)
    .then(response => {
      return Promise.resolve(response.data ? response.data : []);
    })
    .catch(err => {
      if (err.response) {
        let errorMessage = (err.response.data) || err.response.statusText;
        return Promise.reject(errorMessage);
      }

      return Promise.resolve([]);
    });
}

export const campaignService = {
  findCompanyCampaigns,
  findCompanyCampaignsTopList,
  findCompanyCampaignsTeamTopList,
  findAll,
  findByTeamMember,
  get,
  create,
  update,
  delete:
  deleteCampaign,
  findTeams,
  findEvents,
  findOrders,
  createOrder,
  findScores
}