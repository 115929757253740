import {
  CLOSE_EVENT_SCORES,
  GET_DASHBOARDS,
  GET_DASHBOARDS_SUCCESS,
  OPEN_EVENT_SCORES, SET_SELECTED_COMPONENTS,
  SET_SORT_ORDER,
  TOGGLE_DASHBOARD_MODAL,
  SET_COLUMN_SIZE, SET_TMP_COMPONENTS

} from '../types/dashboard.types';
import {SET_EVENT_WEIGHT} from "../types";

const initialState = {
  dashboards: [],
  isEventScoresOpen: false,
  isModalOpen: false,
  selectedComponents: [],
};

const dashboardReducer = (state = initialState, action) => {


  switch (action.type) {

    case GET_DASHBOARDS_SUCCESS: {
      state.dashboards = action.dashboards
      return state;
    }

    case OPEN_EVENT_SCORES:
      state.isEventScoresOpen = true;
      return state;

    case CLOSE_EVENT_SCORES:
      state.isEventScoresOpen = false;
      return state;

    case TOGGLE_DASHBOARD_MODAL:
      state.isModalOpen = !state.isModalOpen;
      return state;

    case SET_SELECTED_COMPONENTS:
      state.selectedComponents = action.components;
      return state;

    case SET_SORT_ORDER:
      console.log(action)
      state.selectedComponents.forEach((selectedComponent, i) => {
        if (selectedComponent.value === action.component) {
          state.selectedComponents.splice(i, 1)
          state.selectedComponents.splice(action.sortOrder, 0, selectedComponent)
        }
      });

      const newSelectedComponents = [...state.selectedComponents]
      newSelectedComponents.forEach((selectedComponent, i) => {
        selectedComponent.sortOrder = i
      })

      state.selectedComponents = newSelectedComponents;

      return state;

    case SET_TMP_COMPONENTS:
       state.tmpComponents = action.components
      return state;

    default:
      return state;

  }
};

export default dashboardReducer;